import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
// import './PatientQueueList.css';
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
          {
            textAlign: "center",
            display: "flex !important",
            justifyContent: "center !important",
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

export default function Followuplist() {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const navigate = useNavigate();

  const [rows, setRows] = React.useState([]);



  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  console.log(filteredRows)
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);


  const handleRequestProceed = (params) => {
    console.log(params);
    const serialid = params.row.id;
    localStorage.setItem("PatientID", serialid);
    // axios
    //   .post('http://127.0.0.1:8000/treatmentrecord/update_appointment_reg_process', {
    //     serialid: serialid,
    //   })
    //   .then((response) => {
    //     console.log(response.data.message);
    //   })
    //   .catch((error) => {
    //     console.error('Error updating record:', error);
    //   });
    navigate("/Treament-CounselorProcedure");
  };

  const [columns] = React.useState([
    { field: "PatientName", headerName: "Patient Name", width: 150 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 130 },
    // { field: 'Status', headerName: 'Status', width: 100 },
    {
      field: "consultationstatus",
      headerName: "Dcotor Consultaion Status",
      width: 200,
    },
    { field: "procedurestatus", headerName: "Procedure Status", width: 200 },
    {
      field: "nextapppoinmentdate",
      headerName: "Next Appointment",
      width: 150,
    },

  ]);

  // useEffect(() => {
  //   axios.get('http://127.0.0.1:8000/Counselor/counselor_nextappointment_alert')
  //     .then((response) => {
  //       console.log(response.data);

  //       // Extracting names from the response data
  //       const names = response.data.map(item => item.PatientName).join(', ');

  //       // Displaying all names in the alert message
  //       alert(names);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  useEffect(() => {
    fetch(
      `http://127.0.0.1:8000/doctorsworkbench/for_followup_queuelist_pdfemail?location=${userRecord?.location}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' is an array as per the provided data structure
        console.log(data);
        const Records = data.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          PhoneNumber: userdata.PhoneNumber,
          consultationstatus: userdata.ConsultationStatus,
          procedurestatus: userdata.Status,
          nextapppoinmentdate: userdata.next_appointment_date,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [userRecord?.location]);

  columns[4].renderCell = ({ row }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: row.nextapppoinmentdate === "Tomorrow" ? "black" : "black",
        backgroundColor:
          row.nextapppoinmentdate === "Tomorrow"
            ? "var(--selectbackgroundcolor)"
            : "transparent", // Light green
      }}
    >
      {row.nextapppoinmentdate}
    </div>
  );

  //   useEffect(() => {
  //     const createdby = userRecord?.username
  //     const location = userRecord?.location
  //     axios.post(`http://127.0.0.1:8000/Counselor/insertrequest_fornextappoinment?location=${location}&createdby=${createdby}`)
  //       .then((response) => {
  //         console.log(response.data);

  //       })
  //       .catch((error) => {
  //         console.error('Error fetching data:', error);
  //       });
  //   }, [userRecord]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === "FirstName") {
      setSearchQuery(value);
    } else if (id === "PhoneNo") {
      setSearchQuery1(value);
    }
  };
  
  
  useEffect(() => {
    const filteredData = rows.filter((row) => {
      const lowerCaseSupplierName = row.PatientName.toLowerCase();
      const lowerCasePhoneNo = row.PhoneNumber.toString();
  
      const startsWithFirstName = lowerCaseSupplierName.startsWith(searchQuery.toLowerCase());
      const startsWithPhoneNo = lowerCasePhoneNo.startsWith(searchQuery1.toLowerCase());
  
      return (startsWithFirstName || !searchQuery) && (startsWithPhoneNo || !searchQuery1);
    });
  
    // If there is a search query, sort the data to bring the left-to-right matching rows to the top
    const sortedData = searchQuery || searchQuery1
      ? [...filteredData].sort((a, b) => {
          const aStartsWith = a.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) || a.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());
          const bStartsWith = b.PatientName.toLowerCase().startsWith(searchQuery.toLowerCase()) || b.PhoneNumber.toString().toLowerCase().startsWith(searchQuery1.toLowerCase());
  
          return bStartsWith - aStartsWith;
        })
      : filteredData;
  
    setFilteredRows(sortedData);
    setPage(0);
  }, [searchQuery, searchQuery1, rows]);
  
  

  const highlightSearchTerm = (text, searchQuery, searchQuery1) => {
    if (!text || (!searchQuery && !searchQuery1)) {
      return text;
    }
  
    const lowerCaseText = typeof text === 'string' ? text.toLowerCase() : text.toString().toLowerCase();
    const lowerCaseSearchTerm = searchQuery ? searchQuery.toString().toLowerCase() : '';
    const lowerCaseSearchTerm1 = searchQuery1 ? searchQuery1.toString().toLowerCase() : '';
  
    let highlightedText = text.toString();
  
    if ((lowerCaseSearchTerm && typeof lowerCaseText === 'string') || (lowerCaseSearchTerm1 && typeof lowerCaseText === 'string')) {
      const searchTerm = lowerCaseSearchTerm || lowerCaseSearchTerm1;
      const startIndex = lowerCaseText.indexOf(searchTerm);
  
      if (startIndex === 0) { // Check if the search term starts from the beginning
        const endIndex = startIndex + searchTerm.length;
        highlightedText = (
          <>
            <span style={{ backgroundColor: 'var(--ProjectColor)', fontWeight: 'bold',padding: '2px 0px' }}>
              {lowerCaseText.substring(0, endIndex)}
            </span>
            {lowerCaseText.substring(endIndex)}
          </>
        );
      }
    }
  
    return highlightedText;
  };

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Follow Up List</h4>
        </div>
        {/* <form onSubmit={handleSearch}> */}
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Patient Name 
            <span>:</span></label>
            <input
              type="text"
              id="FirstName"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Phone No <span>:</span></label>
            <input
              type="number"
              id="PhoneNo"
              value={searchQuery1}
              onChange={handleSearchChange}
              placeholder="Enter the Phone No"
            />
          </div>
          {/* <button className="btn_1" type="submit">
              <SearchIcon />
            </button> */}
          {/* <button
              className="btn_1"
              onClick={() => handleProcedureForm()}
              title="New Procedure"
            >
              <LoupeIcon />
            </button> */}
        </div>
        {/* </form> */}
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns.map((column) => ({
                ...column,
                renderCell: (params) => {
                  if (column.field === "PatientName") {
                    return highlightSearchTerm(params.value, searchQuery);
                  } else if (column.field === "PhoneNumber") {
                    return highlightSearchTerm(params.value, searchQuery1);
                  } else {
                    return params.value;
                  }
                  
                },
              }))}
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className="data_grid"
            />
            {showdown > 0 && filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {filteredRows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}


      </div>
    </>
  );
}
