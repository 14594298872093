import React, { useEffect, useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";
const IpWardChange = () => {
  const [RoomChangeform, setRoomChangeform] = useState({
    RoomId: "",
    BuildingName: "",
    BlockName: "",
    FloorName: "",
    WardType: "",
    RoomType: "",
    RoomNo: "",
    BedNo: "",
    Charge: "",
    Location: "",
  });
  const [showroomdata,setshowroomdata]=useState([])
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    setRoomChangeform((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };


    useEffect(()=>{
        axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Id_Change`)
            .then((response)=>{
                setshowroomdata(response.data)
                console.log(response.data)
                
                
            })
            .catch((error)=>{
                console.log(error)
            })
    },[])
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const sumitchange =()=>{

  }
  const handlesearchsumma=()=>{
    
    if(RoomChangeform.RoomId){
        axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Id_Change_fulldata?Roomid=${RoomChangeform.RoomId}`)
        .then((response)=>{
            console.log(response.data)
            const data =response.data[0]
            if(data){
                setRoomChangeform({
                    RoomId: data.S_No,
                    BuildingName: data.BuildingName,
                    BlockName: data.BlockName,
                    FloorName: data.FloorName	,
                    WardType: data.WardName,
                    RoomType: data.RoomType,
                    RoomNo: data.RoomNo,
                    BedNo: data.BedNo,
                    Charge: data.Charge,
                    Location: data.Location,
                })
            }else{
                alert('No Room Found at these Id ${}')
                setRoomChangeform({
                    RoomId: "",
                    BuildingName: "",
                    BlockName: "",
                    FloorName: "",
                    WardType: "",
                    RoomType: "",
                    RoomNo: "",
                    BedNo: "",
                    Charge: "",
                    Location: "",
                })
            }
            
        })
        .catch((error)=>{
            console.log(error)
        })
    }
  }
  return (
    <>
    <div className="Supplier_Master_Container">
    <div className="Total_input_container">
          {Object.keys(RoomChangeform).map((labelname, index) => (
            <div className="inp_container_all_intakeoutput" key={index}>
              <label>
                {formatLabel(labelname)} <span>:</span>
              </label>
              
                <input
                  type='text'
                  name={labelname}
                  list="sumaadatalist1"
                  // placeholder={`Enter the ${formatLabel(labelname)}`}
                  value={RoomChangeform[labelname]}
                  readOnly={labelname !== "RoomId"}
                  onChange={handleInputChange}
                />
                <datalist
                id="sumaadatalist1">
                    {
                        showroomdata.map((p,indx)=>(
                            <option key={indx} value={p.S_No}>{
                                `${p.WardName} ${p.RoomType} ${p.RoomNo} ${p.BedNo}`
                            }</option>
                        ))
                    }
                </datalist>
                {labelname==='RoomId'&&<span onClick={handlesearchsumma}><SearchIcon/></span>}
              
            </div>
          ))}
        </div>
        <div style={{display:'grid',placeItems:'center',width:'100%'}}>
          <button  className='btn-add' onClick={sumitchange}>
            Submit
          </button>
        </div>
    </div>
    </>
    );
};

export default IpWardChange;
