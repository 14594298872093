import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import SearchIcon from "@mui/icons-material/Search";
import LoupeIcon from "@mui/icons-material/Loupe";
import axios from "axios";
// import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#54d854bf",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  #54d854bf !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});


const Concernlist = ( ) => {
  const navigate = useNavigate();

  const userRecord=useSelector(state=>state.userRecord?.UserData);

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )

  const [page, setPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [summa, setsumma] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openImageView, setopenImageView] = useState(false);
  const [modalContent, setModalContent] = useState('');
  // const [openModal3, setOpenModal3] = React.useState(false);
  // const [selectedImage, setSelectedImage] = React.useState(null);
  const yourStyles={
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }
  
  console.log('qqq', summa)

  const [filteredRows, setFilteredRows] = useState([]);
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  // Define the handleAdd function to handle the "Edit" button click
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);

  const handleVisibilityClick = (ConcernForm) => {
    setModalContent(ConcernForm);
    setopenImageView(true)
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    axios
      .get(`http://127.0.0.1:8000/appointmentmanagement/get_concernform?location=${userRecord?.location}`)
      .then((response) => {
        const data = response.data;
        console.log(data)
        setsumma(
          data.map((row) => ({
            id: row.PatientID,
            PatientName: row.PatientName,
            Date: row.Date,
            location:row.location,
            ConcernForm: row.ConcernForm,
          }))
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [userRecord?.location]);

  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const filteredData = summa.filter((row) => {
      const lowerCasePatientName = row.PatientName.toLowerCase();
      return lowerCasePatientName.includes(lowerCaseQuery);
    });
    setFilteredRows(filteredData);
  }, [searchQuery, summa]);

  const handleRequestForm = () => {
    navigate("/Home/Register_concern_Form");
  };

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "id", headerName: "PatientID", width: 150 },
    { field: "PatientName", headerName: "Patient Name", width: 200 },
    { field: "Date", headerName: "Date", width: 150 },
    { field: "location", headerName: "Branch Name", width: 150 },
    {
      field: "ConcernForm",
      headerName: "ConcernForm",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleVisibilityClick(params.value)}
          >
            <VisibilityIcon />
          </Button>
        </>
      ),
    },
  ];

  


  return (
    <>
      <Helmet>
        <title>ConcernForm List</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h4>Concern Form List</h4>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Patient Name <span>:</span></label>
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Enter the Patient Name"
            />
          </div>
          {/* <button className="btn_1" type="submit">
            <SearchIcon />
          </button> */}
          <button
            className="btn_1"
            type="submit"
            onClick={() => handleRequestForm()}
            title="Add New Stock"
          >
            <LoupeIcon />
          </button>
        </div>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                // rows={summa}
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>




      </div>

    </>
  );
};
export default Concernlist;
