import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./IpNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IpIntakeOutput = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const [summa, setsumma] = useState([]);
  const showdown = summa.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    if(type==='Intake'){
      setPage(params.page);
    }else{
      setPage1(params.page);
    }
  };
  const [type, setType] = useState("Intake");
  const [currentTime, setCurrentTime] = useState('');
  const [IntakeData, setIntakeData] = useState({
    IntakeType: "",
    IntakeMode: "",
    Site: "",
    Measurement: "",
    MeasurementType: "ml",
    Date: "",
    Time: "",
    Duration: "",
    DurationType: "hours",
    Remarks: "",
  });
  const [OutputData, setOutputData] = useState({
    OutputType: "",
    Measurement: "",
    MeasurementType:'ml',
    Date: "",
    Time: "",
    Remarks: "",
  });
  const [getdatastate, setgetdatastate] = useState(false);
  const blockInvalidChar = e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
  const warnmessage = (warnmsg) => {
    toast.warn(`${warnmsg}`, {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: { marginTop: "50px" },
    });
};
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    if (type === "Intake") {
      setIntakeData((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setOutputData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  const handlecleardata=()=>{
    setIntakeData((prev)=>({
      ...prev,
      IntakeType: "",
      IntakeMode: "",
      Site: "",
      Measurement: "",
      MeasurementType: "ml",
      Duration: "",
      DurationType: "hours",
      Remarks: "",
    }))
    setOutputData((prev)=>({
      ...prev,
      OutputType: "",
      Measurement: "",
      MeasurementType:'ml',
      Remarks: "",
    }))
  }
 
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const formattedTime = format(now, 'HH:mm:ss a');
      console.log(formattedTime)
      const newdate= format(new Date(),'yyyy-MM-dd')
      setCurrentTime(formattedTime);
      setIntakeData((prev)=>({
        ...prev,
        Time:formattedTime,
        Date:newdate,
      }))
      setOutputData((prev)=>({
        ...prev,
        Time:formattedTime,
        Date:newdate,
      }))
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  useEffect(()=>{
if(IpNurseQueSelectedRow &&IpNurseQueSelectedRow?.Booking_Id){
  axios.get(`http://127.0.0.1:8000/ipregistration/get_Intake_Output_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&gettype=${type}`)
  .then((response)=>{
    const data = response.data;
      console.log("222", data);
      setsumma([
        ...data.map((row, ind) => ({
          id: ind + 1,
          ...row,
        })),
      ]);
  })
  .catch((error)=>{
    console.log(error)
  })
}
  },[getdatastate,type,IpNurseQueSelectedRow,IpNurseQueSelectedRow?.Booking_Id])
  const handleChange = (event) => {
    setType(event.target.value);

  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const dynamicColumns = [
          {
            field: 'id',
            headerName: 'S_No',
            width: 40,
          },
          ...Object.keys(type==='Intake'?IntakeData:OutputData)
          .filter(labelname=>labelname !== 'MeasurementType' && labelname !=='DurationType')
          .map((labelname, index) => {
            const formattedLabel = formatLabel(labelname);
            const labelWidth = getTextWidth(formattedLabel);
           
            return {
              field: labelname,
              headerName: formattedLabel,
              width: ['Remarks','Site','Date','Time'].find((f)=>f===labelname) ? labelWidth + 100 : labelWidth + 30,
            };
          })
          
        ]
    
        const handlesubmitdata = () => {
          const requiredfields = [
            ...Object.keys(type === 'Intake' ? IntakeData : OutputData)
          ].filter(key => key !== 'Remarks');
        
          const exist = requiredfields.filter(field => type === 'Intake' ? !IntakeData[field] : !OutputData[field]);
        
          if (exist.length > 0) {
            warnmessage(`The following fields are required: ${exist.join(", ")}`);
          } else {
            const senddata = {
              ...((type === 'Intake') ? IntakeData : OutputData),
              Location: userRecord?.location,
              RecordedBy: userRecord?.username,
              Booking_Id: IpNurseQueSelectedRow?.Booking_Id,
              Inserttype:type,
            };
            
           axios.post(`http://127.0.0.1:8000/ipregistration/insert_Intake_Output_datas`,senddata)
            .then((response)=>{
              console.log(response)
              setgetdatastate(!getdatastate)
              handlecleardata()
            })
            .catch((error)=>{
              console.log(error)
            });
          }
        };
        
  return (
    <>
      <div className="Supplier_Master_Container">
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Intake"
              style={{
                height: "30px",
                width: "100px",
                backgroundColor:
                  type === "Intake"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              Intake
            </ToggleButton>
            <ToggleButton
              value="Output"
              style={{
                backgroundColor:
                  type === "Output"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
                width: "100px",
                height: "30px",
              }}
              className="togglebutton_container"
            >
              Output
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {type === "Intake" ? (
          <div className="Total_input_container">
            <div className="inp_container_all_intakeoutput">
              <label>
                Intake Type <span>:</span>
              </label>

              <select
                name="IntakeType"
                value={IntakeData.IntakeType}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Solid">Solid</option>
                <option value="SemiSolid">Semi Solid</option>
                <option value="Fulid">Fulid</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Intake Mode <span>:</span>
              </label>

              <select
                name="IntakeMode"
                value={IntakeData.IntakeMode}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Oral">Oral</option>
                <option value="IV">IV</option>
                <option value="RylesTube">RylesTube</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Site <span>:</span>
              </label>

              <select
                name="Site"
                value={IntakeData.Site}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Oral">Oral</option>
                <option value="IV">IV</option>
                <option value="RylesTube">RylesTube</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Date <span>:</span>
              </label>

              <input name="Date" type="text" value={IntakeData.Date} disabled />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Time <span>:</span>
              </label>

              <input name="Time" type="text" value={IntakeData.Time} disabled />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Duration <span>:</span>
              </label>

              <input
                name="Duration"
                type="number"
                onKeyDown={blockInvalidChar}
                style={{ width: "20px" }}
                value={IntakeData.Duration}
                onChange={handleInputChange}
              />
              <select
                name="DurationType"
                style={{ width: "110px" }}
                value={IntakeData.DurationType}
                onChange={handleInputChange}
              >
                <option value="hours">hours</option>
                <option value="minutes">minutes</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Measurement <span>:</span>
              </label>

              <input
                name="Measurement"
                type="number"
                onKeyDown={blockInvalidChar}
                style={{ width: "20px" }}
                value={IntakeData.Measurement}
                onChange={handleInputChange}
              />
              <select
                name="MeasurementType"
                style={{ width: "110px" }}
                value={IntakeData.MeasurementType}
                onChange={handleInputChange}
              >
                <option value="grams">grams</option>
                <option value="ml">ml</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Remarks <span>:</span>
              </label>

              <textarea name="Remarks" value={IntakeData.Remarks} onChange={handleInputChange} />
            </div>
          </div>
        ) : (
          <div className="Total_input_container">
            <div className="inp_container_all_intakeoutput">
              <label>
                Output Type <span>:</span>
              </label>

              <select
                name="OutputType"
                value={OutputData.OutputType}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                <option value="Vomit">Vomit</option>
                <option value="Urine">Urine</option>
                <option value="Stules">Stules</option>
              </select>
            </div>

            <div className="inp_container_all_intakeoutput">
              <label>
                Date <span>:</span>
              </label>

              <input name="Date" type="text" value={OutputData.Date} disabled />
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Time <span>:</span>
              </label>

              <input name="Time" type="text" value={OutputData.Time} disabled />
            </div>

            <div className="inp_container_all_intakeoutput">
              <label>
                Measurement <span>:</span>
              </label>

              <input
                name="Measurement"
                type="number"
                onKeyDown={blockInvalidChar}
                style={{ width: "20px" }}
                value={OutputData.Measurement}
                onChange={handleInputChange}
              />
              <select
                name="MeasurementType"
                style={{ width: "110px" }}
                value={OutputData.MeasurementType}
                onChange={handleInputChange}
              >
                <option value="grams">grams</option>
                <option value="ml">ml</option>
              </select>
            </div>
            <div className="inp_container_all_intakeoutput">
              <label>
                Remarks <span>:</span>
              </label>

              <textarea name="Remarks" value={OutputData.Remarks} onChange={handleInputChange}/>
            </div>
          </div>
        )}
        <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <button className="btn-add" onClick={handlesubmitdata}>Add</button>
        </div>

        <div className="IP_grid">
          <ThemeProvider theme={theme}>
            <div className="IP_grid_1">
              <DataGrid
                rows={type==='Intake'?summa.slice(page * pageSize, (page + 1) * pageSize):summa.slice(page1 * pageSize, (page1 + 1) * pageSize)} // Display only the current page's data
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" Ip_data_grid"
              />
              {showdown > 0 && summa.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      type==='Intake'?
                      setPage((prevPage) => Math.max(prevPage - 1, 0)):
                      setPage1((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={type==='Intake'?page:page1 === 0}
                  >
                    Previous
                  </button>
                  Page {type==='Intake'?page:page1 + 1} of {totalPages}
                  <button
                    onClick={() =>
                      type==='Intake'?
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      ):
                      setPage1((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                    }
                    disabled={type==='Intake'?page:page1 === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && summa.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
      <ToastContainer/>
    </>
  );
};

export default IpIntakeOutput;
