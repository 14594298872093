import { React, useState, useRef, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import Webcam from "react-webcam";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { differenceInYears } from "date-fns";

const EmployeeRegister = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const foremployeeedit = useSelector(
    (state) => state.userRecord?.foremployeeedit
  );
  const [SelectedFile, setSelectedFile] = useState(null);

  console.log(foremployeeedit);
  const webcamRef1 = useRef(null);
  const Navigate = useNavigate();
  const [expanded, setExpanded] = useState("panel1");
  const [showFile, setShowFile] = useState({
    file1: false,
  });
  const [isImageCaptured1, setIsImageCaptured1] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [rollname, setrollname] = useState([]);
  const [location, setlocation] = useState([]);

  const [department, setdepartment] = useState([]);
  const [formData, setFormData] = useState({
    name: "",
    fatherName: "",
    title: "",
    dob: "",
    age: "",
    gender: "",
    email: "",
    qualification: "",
    maritalStatus: "",
    aadharnumber: "",
    phone: "",
    alternatePhone: "",
    communicationAddress: "",
    permanentAddress: "",
    nationality: "",
    reference1: "",
    reference1No: "",
    photo: null,

    employeeId: "",
    department: "",
    CapturedFile123: "",
    designation: "",
    specialist: "",
    dateOfJoining: "",
    manager: "",
    sickLeave: "",
    casualLeave: "",
    totalLeave: "",
    status: "",
    createdby: userRecord?.username,
    locations: "",
    branchlocation: userRecord?.location,

    salaryType: "",
    payScale: "",
    // basicPay: "",
    perHour: "",
    commission: "",
    fixedamount: "",
    comissionAmount: "",
    remarks: "",
    travel: "",
    hrallowance: "",
    medical: "",

    accountName: "",
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    pannumber: "",

    epfnumber: "",
    uannumber: "",
    esiamount: "",

    // CapturedFile1: "",
    // CapturedFile2: "",
    // CapturedFile3: "",
    // CapturedFile4: "",
    // CapturedFile5: "",
  });

  // -------------------------------------
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);

  useEffect(() => {
    // Use an axios request within useEffect to avoid infinite rendering
    axios
      .get("http://127.0.0.1:8000/patientmanagement/detect_device")
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  //   --------------------------
  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/HRmanagement/getRole_bydept?department=${formData.department}`
      )
      .then((response) => {
        console.log(response.data);
        setrollname(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`http://127.0.0.1:8000/usercontrol/getlocationdata`)
      .then((response) => {
        console.log(response.data);
        setlocation(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    axios
      .get(`http://127.0.0.1:8000/usercontrol/getDepartment`)
      .then((response) => {
        console.log(response.data);
        setdepartment(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    // const ctc = formData.ctc; // Assuming formData is an object containing the ctc property
    // const basicAmount = 0.4 * ctc;
    // const pfforemployee = 0.12 * basicAmount;
    // const esiamount = 0.0075 * basicAmount;

    // console.log(basicAmount)

    // setFormData((prev) => ({
    //   ...prev,
    //   basicPay: basicAmount,
    //   pfEmployee: pfforemployee,
    //   esiamount: esiamount,
    // }));
  }, [userRecord?.location, formData.department, formData.ctc]);

  const base64toFile = (base64String, fileName, mimeType) => {
    if (!base64String) {
      console.error("base64String is undefined or null.");
      return null;
    }

    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const paddedBase64String = base64String + padding;

    try {
      const byteString = atob(paddedBase64String);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);

      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }

      const blob = new Blob([arrayBuffer], { type: mimeType });
      return new File([blob], fileName, { type: mimeType });
    } catch (error) {
      console.error("Error decoding base64 string:", error);
      return null;
    }
  };

  useEffect(() => {
    if (foremployeeedit && foremployeeedit.length > 0) {
      const updatedFormData = { ...(foremployeeedit[0] || "") };

      // Convert base64 strings to File objects
      updatedFormData.photo = base64toFile(
        foremployeeedit[0].photo,
        "captured_image.jpg",
        "image/jpeg"
      );
      updatedFormData.CapturedFile123 = base64toFile(
        foremployeeedit[0]?.CapturedFile123 || "",
        "sign.pdf",
        "application/pdf"
      );
      updatedFormData.CapturedFile1 = base64toFile(
        foremployeeedit[0]?.CapturedFile1 || "",
        "file1.pdf",
        "application/pdf"
      );
      updatedFormData.CapturedFile2 = base64toFile(
        foremployeeedit[0]?.CapturedFile2 || "",
        "file2.pdf",
        "application/pdf"
      );
      updatedFormData.CapturedFile3 = base64toFile(
        foremployeeedit[0]?.CapturedFile3 || "",
        "file3.pdf",
        "application/pdf"
      );
      updatedFormData.CapturedFile4 = base64toFile(
        foremployeeedit[0]?.CapturedFile4 || "",
        "file4.pdf",
        "application/pdf"
      );
      updatedFormData.CapturedFile5 = base64toFile(
        foremployeeedit[0]?.CapturedFile5 || "",
        "file5.pdf",
        "application/pdf"
      );
      updatedFormData.createdby = userRecord?.username;
      updatedFormData.branchlocation = userRecord?.location;

      setFormData(updatedFormData);
    } else {
      cleardata();
      axios
        .get(
          `http://127.0.0.1:8000/HRmanagement/get_employee_Id?location=${userRecord?.location}`
        )
        .then((response) => {
          console.log(response.data);
          setFormData((prevData) => ({
            ...prevData,
            employeeId: response.data.nextemployeeid,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [foremployeeedit]);

  const cleardata = () => {
    setFormData({
      name: "",
      fatherName: "",
      title: "",
      dob: "",
      age: "",
      gender: "",
      email: "",
      qualification: "",
      maritalStatus: "",
      aadharnumber: "",
      phone: "",
      alternatePhone: "",
      communicationAddress: "",
      permanentAddress: "",
      nationality: "",
      reference1: "",
      reference1No: "",
      photo: "",

      employeeId: "",
      department: "",
      CapturedFile123: "",
      designation: "",
      dateOfJoining: "",
      manager: "",
      status: "",
      createdby: userRecord?.username,
      locations: "",
      branchlocation: userRecord?.location,

      salaryType: "",
      payScale: "",
      // basicPay: "",
      perHour: "",
      commission: "",
      fixedamount: "",
      comissionAmount: "",
      remarks: "",
      ctc: "",
      travel: "",
      hrallowance: "",
      medical: "",
      pfEmployee: "",
      pfEmployeer: "",

      accountName: "",
      accountNumber: "",
      bankName: "",
      branch: "",
      ifscCode: "",
      pannumber: "",

      epfnumber: "",
      uannumber: "",
      esiamount: "",

      CapturedFile1: "",
      CapturedFile2: "",
      CapturedFile3: "",
      CapturedFile4: "",
      CapturedFile5: "",
    });
  };

  const buttonText =
    foremployeeedit && foremployeeedit.length > 0 ? "Update" : "Register";

  console.log("WSWS", formData);

  const handleRegister = () => {
    console.log("formdata : ", formData);

    // Create a FormData object
    const formData1 = new FormData();

    // Append data to FormData object
    Object.keys(formData).forEach((key) => {
      formData1.append(key, formData[key]);
    });

    // List of required fields
    const requiredFields = [
      "name",
      "fatherName",
      "title",
      "dob",
      "age",
      "gender",
      "email",
      "qualification",
      "aadharnumber",
      "phone",
      "communicationAddress",
      "nationality",
      "permanentAddress",
      "department",
      "designation",
      "accountName",
      "accountNumber",
      "bankName",
      "ifscCode",
      "pannumber",
    ];

    // Check for empty required fields
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    // If there are empty fields, show an alert and return
    if (emptyFields.length > 0) {
      alert(`The following fields are required: ${emptyFields.join(", ")}`);
      return;
    }

    // Make the axios request
    axios
      .post(
        "http://127.0.0.1:8000/HRmanagement/insert_Employee_Register",
        formData1,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((response) => {
        console.log(response);
        successMsg("Saved Successfully");
        cleardata();
        setTimeout(() => {
          Navigate("/Home/Employee-List");
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        errmsg("Error occurred");
      });
  };

  const [salaryType, setSalaryType] = useState("fixed");

  const handleSalaryTypeChange = (e) => {
    setSalaryType(e.target.value);
  };

  const [formData3, setFormData3] = useState({
    CapturedImage1: null,
  });

  const handleChange12 = (e) => {
    const { name, value, files } = e.target;

    if (name === "dob") {
      const newDate = new Date();
      const oldDate = new Date(value);
      const age = differenceInYears(newDate, oldDate);

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        age: age,
      }));
    } else {
      if (
        name === "alternatePhone" ||
        name === "phone" ||
        name === "reference1No"
      ) {
        const newval = value.length;
        if (newval <= 10) {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        } else {
          alert("Phone No must contain 10 digits");
        }
      } else if (
        (name === "CapturedFile123" ||
          name === "photo" ||
          name === "CapturedFile1" ||
          name === "CapturedFile2" ||
          name === "CapturedFile3" ||
          name === "CapturedFile4" ||
          name === "CapturedFile5") &&
        files.length > 0
      ) {
        // Access the file object itself
        const fileObject = e.target.files[0];

        setFormData((prevData) => ({
          ...prevData,
          [name]: fileObject,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const handleRecaptureImage1 = () => {
    // setCapturedImage(null);
    setFormData3((prev) => ({
      ...prev,
      CapturedImage1: null,
    }));
    setIsImageCaptured1(false);
  };

  const handleCaptureImage1 = () => {
    const imageSrc = webcamRef1.current.getScreenshot();
    const blob = imageSrc
      ? dataURItoBlob(imageSrc, "captured_image.jpg")
      : null;

    setFormData({
      ...formData,
      photo:
        blob instanceof Blob ? new File([blob], "captured_image.jpg") : null,
    });
    setIsImageCaptured1(true);
  };

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleChangeSickLeave = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      sickLeave: value,
      totalLeave: calculateTotalLeave(value, prevData.casualLeave),
    }));
  };

  const handleChangeCasualLeave = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      casualLeave: value,
      totalLeave: calculateTotalLeave(prevData.sickLeave, value),
    }));
  };

  const calculateTotalLeave = (sickLeave, casualLeave) => {
    const sickLeaveValue = parseInt(sickLeave) || 0;
    const casualLeaveValue = parseInt(casualLeave) || 0;
    return sickLeaveValue + casualLeaveValue;
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const warnmessage = (wmsg) => {
    toast.warn(`${wmsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleFileChange = (event) => {
    setSelectedFile(null);
    const { name } = event.target;
    setSelectedFile(event.target.files[0]);
    console.log("Service file selected:", event.target.files[0]);
    // Additional handling based on the name attribute
    if (name === "Personal") {
      // Handle Service file
      console.log("Service file selected:", event.target.files[0]);
    } else if (name === "Roll") {
      // Handle Client file
      console.log("Client file selected:", event.target.files[0]);
    } else if (name === "Finance") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    } else if (name === "Bank") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    } else if (name === "EPF") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    } else if (name === "Documents") {
      // Handle Insurance file
      console.log("Insurance file selected:", event.target.files[0]);
    }
  };

  const handleCsvupload = (type) => {
    console.log(SelectedFile);
    const formData = new FormData();
    formData.append("file", SelectedFile);

    if (SelectedFile) {
      if (type === "Personal") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_personaldetails_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')
            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      } else if (type === "Roll") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_rollmanagement_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      } else if (type === "Finance") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_financedetails_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      } else if (type === "Bank") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_bankdetails_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      } else if (type === "EPF") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_epfdetails_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      } else if (type === "Documents") {
        axios
          .post(
            `http://127.0.0.1:8000/HRmanagement/post_documentsdetails_csvfile`,
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            console.log(response);
            successMsg('File Processed Successfully')

            setSelectedFile(null);
          })
          .catch((error) => {
            console.log(error);
            errmsg(error)
          });
      }
    }
  };

  return (
    <div>
      <Accordion
        expanded={
          expanded !== "panel2" &&
          expanded !== "panel3" &&
          expanded !== "panel4" &&
          expanded !== "panel5" &&
          expanded !== "panel6"
        }
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Personal Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Employee ID <span>:</span>
                </label>
                <input
                  type="text"
                  name="employeeId"
                  value={formData.employeeId}

                  onChange={handleChange12}
                  required
                  readOnly
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="username">
                  Title <span>:</span>
                </label>
                <select
                  id="title"
                  name="title"
                  value={formData.title}
                  onChange={handleChange12}
                  required
                >
                  <option className="">Select</option>
                  <option className="optnselect1" value="Dr">
                    Dr.
                  </option>
                  <option className="optnselect1" value="Mr">
                    Mr.
                  </option>
                  <option className="optnselect1" value="Ms">
                    Ms.
                  </option>
                  <option className="optnselect1" value="Mrs">
                    Mrs.
                  </option>
                </select>
              </div>
              <div class="RegisForm_1">
                <label>
                  Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange12}
              
                  required
                />
              </div>

              <div class="RegisForm_1">
                <label>
                  Last Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="fatherName"
                  value={formData.fatherName}
       
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  D.O.B <span>:</span>
                </label>
                <input
                  type="date"
                  name="dob"
                  value={formData.dob}
                  onChange={handleChange12}
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Age <span>:</span>
                </label>
                <input
                  type="text"
                  name="age"
                  // pattern="[0-9]*"
                  value={formData.age}
            
                  onChange={handleChange12}
                  readOnly
                />
              </div>

              <div class="RegisForm_1">
                <label>
                  Gender <span>:</span>
                </label>
                <select
                  name="gender"
                  required
                  value={formData.gender}
                  onChange={handleChange12}
                >
                  <option value="">Select</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>
              <div class="RegisForm_1">
                <label>
                  Email <span>:</span>
                </label>
                <input
                  type="email"
                  name="email"
                  pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                  value={formData.email}
    
                  onChange={handleChange12}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Qualification <span>:</span>
                </label>
                <input
                  type="text"
                  name="qualification"
                  value={formData.qualification}
              
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Marital Status <span>:</span>
                </label>
                <select
                  name="maritalStatus"
                  value={formData.maritalStatus}
                  onChange={handleChange12}
                  required
                >
                  <option value="">Select</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widowed">Widowed</option>
                </select>
              </div>
              <div class="RegisForm_1">
                <label>
                  Aadhar Number <span>:</span>
                </label>
                <input
                  type="number"
                  name="aadharnumber"
                  value={formData.aadharnumber}
       
                  onChange={handleChange12}
                  required
                />
              </div>

              <div class="RegisForm_1">
                <label>
                  Phone <span>:</span>
                </label>
                <input
                  type="number"
                  name="phone"
                  pattern="[0-9]*"
                  value={formData.phone}
     
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Alternate Phone<span>:</span>
                </label>
                <input
                  type="number"
                  name="alternatePhone"
                  pattern="[0-9]*"
                  value={formData.alternatePhone}
      
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Communication Address <span>:</span>
                </label>
                <textarea
                  name="communicationAddress"
             
                  value={formData.communicationAddress}
                  // placeholder='Enter Local Address'
                  onChange={handleChange12}
                  required
                ></textarea>
              </div>

              <div class="RegisForm_1">
                <label>
                  Permanent Address <span>:</span>
                </label>
                <textarea
                  name="permanentAddress"
                  // className="txt-ara-lclprntm"
                  value={formData.permanentAddress}
              
                  onChange={handleChange12}
                  required
                ></textarea>
              </div>

              <div class="RegisForm_1">
                <label>
                  Nationality <span>:</span>
                </label>
                <input
                  type="text"
                  name="nationality"
                  value={formData.nationality}
                 
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Emergency Contact <span>:</span>
                </label>
                <input
                  type="tel"
                  name="reference1No"
                  pattern="[0-9]*"
              
                  value={formData.reference1No}
                  onChange={handleChange12}
                  required
                />
              </div>
              <div class="RegisForm_1">
                <label>
                  Name <span>:</span>
                </label>
                <input
                  type="text"
                  name="reference1"
                  value={formData.reference1}
               
                  onChange={handleChange12}
                  required
                />
              </div>

              <div class="RegisForm_1">
                <label>
                  Photo <span>:</span>
                </label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="photo"
                    name="photo"
                    className="hiden-nochse-file"
                    accept="image/*,.pdf"
                    onChange={(e) => handleChange12(e, "photo")}
                    required
                  />
                  <label htmlFor="photo" className="RegisterForm_1_btns choose_file_update">
                    Choose File
                  </label>
                </div>
                <span>or </span>
                <div className="RegisterForm_2">
                  <button
                    onClick={() => setShowFile({ file1: true })}
                    className="RegisterForm_1_btns choose_file_update"
                  >
                    Capture pic
                  </button>

                  {showFile.file1 && (
                    <div
                      className={
                        isSidebarOpen
                          ? "sideopen_showcamera_takepic"
                          : "showcamera_takepic"
                      }
                      onClick={() =>
                        setShowFile({
                          file1: false,
                        })
                      }
                    >
                      <div
                        className={
                          isSidebarOpen
                            ? "sideopen_showcamera_1_takepic1"
                            : "showcamera_1_takepic1"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        {formData3.CapturedImage1 ? ( // Display the captured image
                          <img
                            src={URL.createObjectURL(formData3.CapturedImage1)}
                            alt="captured"
                            className="captured-image11"
                          />
                        ) : (
                          <div className="camera-container">
                            <div className="web_head">
                              <h3>Image</h3>
                            </div>
                            <br></br>
                            <div className="RotateButton_canva">
                              <Webcam
                                audio={false}
                                ref={webcamRef1}
                                screenshotFormat="image/jpeg"
                                mirrored={facingMode === "user"}
                                className="web_cam"
                                videoConstraints={videoConstraints}
                              />
                              {!devices.includes(IsmobileorNot) && (
                                <button onClick={switchCamera}>
                                  <CameraswitchIcon />
                                </button>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="web_btn">
                          {isImageCaptured1 ? (
                            <button
                              onClick={handleRecaptureImage1}
                              className="btncon_add"
                            >
                              Recapture
                            </button>
                          ) : (
                            <button
                              onClick={handleCaptureImage1}
                              className="btncon_add"
                            >
                              Capture
                            </button>
                          )}

                          <button
                            onClick={() =>
                              setShowFile({
                                file1: false,
                              })
                            }
                            className="btncon_add"
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns choose_file_update"
                 
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns choose_file_update"
                  onClick={() => handleCsvupload("Personal")}
                >
                  Upload
                </button>
              </div>
            </div>

          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Roll Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Department <span>:</span>
                </label>
                <select
                  name="department"
                  value={formData.department}
                  onChange={handleChange12}
                  required
                >
                  <option value="">Select</option>
                  {department.map((department) => (
                    <option
                      key={department.Dept_id}
                      value={department.Department_name}
                    >
                      {department.Department_name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="RegisForm_1">
                <label>
                  Designation <span>:</span>
                </label>
                <select
                  name="designation"
                  value={formData.designation}
                  onChange={handleChange12}
                  required
                >
                  <option value="">Select</option>
                  {rollname.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="RegisForm_1">
                <label>
                  Locations <span>:</span>
                </label>
                <select
                  name="locations"
                  id="location"
                  value={formData.locations}
                  onChange={handleChange12}
                >
                  <option value="">Select</option>
                  {location.map((location) => (
                    <option
                      key={location.location_id}
                      value={location.location_name}
                    >
                      {location.location_name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {formData.designation === "THERAPIST" && (
              <div className="RegisFormcon">
                <div class="RegisForm_1">
                  <label>
                    Specialist <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="specialist"
                    value={formData.specialist}

                    onChange={handleChange12}
                    required
                  />
                </div>
              </div>
            )}
            {formData.department === "DOCTOR" && (
              <div className="RegisFormcon">
                <div class="RegisForm_1">
                  <label>
                    Specialist <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="specialist"
                    value={formData.specialist}
          
                    onChange={handleChange12}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Signature <span>:</span>
                  </label>
                  <div className="RegisterForm_2">
                    <input
                      type="file"
                      id="CapturedFile123"
                      name="CapturedFile123"
                      accept="image/*,.pdf"
                      className="hiden-nochse-file"
                      onChange={handleChange12}
                      required
                    />
                    <label
                      htmlFor="CapturedFile123"
                      className="RegisterForm_1_btns"
                    >
                      Choose File
                    </label>
                  </div>
                </div>
              </div>
            )}

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Date of Joining <span>:</span>
                </label>
                <input
                  type="date"
                  name="dateOfJoining"
                  value={formData.dateOfJoining}
                  onChange={handleChange12}
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Reporting Manager <span>:</span>
                </label>
                <input
                  name="manager"
     
                  value={formData.manager}
                  onChange={handleChange12}
                  required
                ></input>
              </div>

              <div className="RegisForm_1">
                <label>
                  Employee Status <span>:</span>
                </label>
                <select
                  name="status"
                  value={formData.status}
                  onChange={handleChange12}
                  required
                >
                  {" "}
                  <option value="">Select</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Sick Leave (.yrs) <span>:</span>
                </label>
                <input
                  type="number"
                  name="sickLeave"
     
                  value={formData.sickLeave}
                  onChange={handleChangeSickLeave}
                  required
                ></input>
              </div>
              <div className="RegisForm_1">
                <label>
                  Casual Leave (.yrs) <span>:</span>
                </label>
                <input
                  type="number"
                  name="casualLeave"
          
                  value={formData.casualLeave}
                  onChange={handleChangeCasualLeave}
                  required
                ></input>
              </div>
              <div className="RegisForm_1">
                <label>
                  Total Leave (.yrs) <span>:</span>
                </label>
                <input
                  name="totalLeave"
              
                  value={formData.totalLeave}
                  // onChange={handleChange12}
                  readOnly
                  required
                ></input>
              </div>
            </div>

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns"
                  style={{ color: "#fff" }}
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleCsvupload("Roll")}
                >
                  Upload
                </button>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Financial Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>Salary Type<span>:</span></label>
                <select
                  name="salaryType"
                  value={formData.salaryType}
                  onChange={handleChange12}
                  onClick={handleSalaryTypeChange}
                  required
                >
                  <option value="">Select</option>
                  <option value="fixed">Fixed Salary</option>
                  <option value="hourly">Hourly Wage</option>
                  <option value="commission">Commission</option>
                  {/* <option value="allowance">Allowance</option> */}
                </select>
              </div>

              <div className="RegisForm_1">
                <label>Pay Scale<span>:</span></label>
                <select
                  name="payScale"
                  value={formData.payScale}
                  onChange={handleChange12}
                  required
                >
                  <option value="">Select</option>
                  <option value="entryLevel">Entry Level</option>
                  <option value="midLevel">Mid Level</option>
                  <option value="seniorLevel">Senior Level</option>
                </select>
              </div>
              {/* <div className="RegisForm_1">
                <label>Leave Type:</label>
                <select
                  name="leave"
                  value={formData.leave}
                  onChange={handleChange12}
                  required
                >
                  <option value="">Select Leave</option>
                  <option value="casualleave">Casual Leave</option>
                  <option value="sickleave">Sick Leave</option>
                </select>
              </div> */}
            </div>
            {/* {formData.leave === "casualleave" ||
            formData.leave === "sickleave" ? (
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>No.of.Days/Year:</label>
                  <input
                    type="text"
                    name="basicPay"
                    placeholder="Enter Basic Pay"
                    value={formData.days}
                    onChange={handleChange12}
                  />
                </div>
              </div>
            ) : null} */}

            {salaryType === "fixed" && (
              <>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>CTC (Per Annum in Lakhs) :</label>
                    <input
                      type="text"
                      name="ctc"
                      placeholder="Enter CTC"
                      value={formData.ctc}
                      onChange={handleChange12}
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label htmlFor="esinumber">
                      ESI Amount (%) <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="esiamount"
                      id="esiamount"
                      placeholder="Enter PAN Number"
                      value={formData.esiamount}
                      onChange={handleChange12}
                      required
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>Travel Allowance (%) <span>:</span></label>
                    <input
                      type="text"
                      name="travel"
                   
                      value={formData.travel}
                      onChange={handleChange12}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>HRA Allowance (%) <span>:</span></label>
                    <input
                      type="text"
                      name="hrallowance"
               
                      value={formData.hrallowance}
                      onChange={handleChange12}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>Medical Allowance (%) <span>:</span></label>
                    <input
                      type="text"
                      name="medical"
                   
                      value={formData.medical}
                      onChange={handleChange12}
                    />
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>PF for Employee (%)<span>:</span></label>
                    <input
                      type="text"
                      name="pfEmployee"
                
                      value={formData.pfEmployee}
                      onChange={handleChange12}
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label>PF for Employeer (%) <span>:</span></label>
                    <input
                      type="text"
                      name="pfEmployeer"
                   
                      value={formData.pfEmployeer}
                      onChange={handleChange12}
                    />
                  </div>
                </div>
              </>
            )}
            {salaryType === "hourly" && (
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>Per Hour <span>:</span></label>
                  <input
                    type="number"
                    name="perHour"
              
                    value={formData.perHour}
                    onChange={handleChange12}
                  />
                </div>
              </div>
            )}

            {salaryType === "commission" && (
              <>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>% of Commission <span>:</span></label>
                    <input
                      type="text"
                      name="commission"
             
                      value={formData.commission}
                      onChange={handleChange12}
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label>Fixed Amount <span>:</span></label>
                    <input
                      type="text"
                      name="fixedamount"
            
                      value={formData.fixedamount}
                      onChange={handleChange12}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>comission amount<span>:</span></label>
                    <input
                      type="text"
                      name="comissionAmount"
                    
                      value={formData.comissionAmount}
                      onChange={handleChange12}
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>Remarks <span>:</span></label>
                    <textarea
                      name="remarks"
                      id="remarks"
                      cols="10"
                      rows="2"
                      value={formData.remarks}
                      onChange={handleChange12}
                    ></textarea>
                  </div>
                </div>
              </>
            )}
            {/* {salaryType === "allowance" && (
              <>
                <div className="RegisFormcon">
                  
                </div>
              </>
            )} */}
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns"
                  style={{ color: "#fff" }}
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleCsvupload("Finance")}
                >
                  Upload
                </button>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel4"}
        onChange={handleChange("panel4")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel4bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Bank Account Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Account Holder Name<span>:</span>
                </label>
                <input
                  type="text"
                  name="accountName"
          
                  value={formData.accountName}
                  onChange={handleChange12}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Account Number<span>:</span>
                </label>
                <input
                  type="number"
                  name="accountNumber"
                  value={formData.accountNumber}
                  onChange={handleChange12}
             
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  Bank Name<span>:</span>
                </label>
                <input
                  type="text"
                  name="bankName"
                  value={formData.bankName}
                  onChange={handleChange12}
              
                  required
                />
              </div>
            </div>

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  Branch<span>:</span>
                </label>
                <input
                  type="text"
                  name="branch"
                  value={formData.branch}
                  onChange={handleChange12}
              
                  required
                />
              </div>

              <div className="RegisForm_1">
                <label>
                  IFSC Code <span>:</span>
                </label>
                <input
                  type="text"
                  name="ifscCode"
                  value={formData.ifscCode}
                  onChange={handleChange12}
           
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label>
                  Pan Number <span>:</span>
                </label>
                <input
                  type="text"
                  name="pannumber"
                  value={formData.pannumber}
                  onChange={handleChange12}
             
                  required
                />
              </div>
            </div>

            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns"
                  style={{ color: "#fff" }}
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleCsvupload("Bank")}
                >
                  Upload
                </button>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
        expanded={expanded === "panel5"}
        onChange={handleChange("panel5")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            EPF Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label htmlFor="epfnumber">
                  EPF Number <span>:</span>
                </label>
                <input
                  type="text"
                  name="epfnumber"
                  id="epfnumber"
                  value={formData.epfnumber}
                  onChange={handleChange12}
                  required
                />
              </div>
              <div className="RegisForm_1">
                <label htmlFor="uannumber">
                  UAN Number <span>:</span>
                </label>
                <input
                  type="text"
                  name="uannumber"
                  id="uannumber"
         
                  value={formData.uannumber}
                  onChange={handleChange12}
                  required
                />
              </div>
            </div>

            <div className="RegisForm_1">
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns"
                  style={{ color: "#fff" }}
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleCsvupload("EPF")}
                >
                  Upload
                </button>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel6"}
        onChange={handleChange("panel6")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel5bh-content"
        >
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Documents Details
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>Resume<span>:</span></label>

                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile1"
                    name="CapturedFile1"
                    accept="image/*,.pdf"
                    onChange={handleChange12}
                    required
                  />
                  <label
                    htmlFor="CapturedFile1"
                    className="RegisterForm_1_btns"
                  >
                    Choose File
                  </label>
                </div>
              </div>
              <div className="RegisForm_1">
                <label>Offer Letter<span>:</span></label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile2"
                    className="hiden-nochse-file"
                    name="CapturedFile2"
                    accept="image/*,.pdf"
                    onChange={handleChange12}
                    required
                  />
                  <label
                    htmlFor="CapturedFile2"
                    className="RegisterForm_1_btns"
                  >
                    Choose File
                  </label>
                </div>
              </div>

              <div className="RegisForm_1">
                <label>Contract<span>:</span></label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile3"
                    name="CapturedFile3"
                    className="hiden-nochse-file"
                    accept="image/*,.pdf"
                    onChange={handleChange12}
                    required
                  />
                  <label
                    htmlFor="CapturedFile3"
                    className="RegisterForm_1_btns"
                  >
                    Choose File
                  </label>
                </div>
              </div>
            </div>
            <div className="RegisFormcon">
              <div className="RegisForm_1">
                <label>Joining Letter<span>:</span></label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile4"
                    name="CapturedFile4"
                    className="hiden-nochse-file"
                    accept="image/*,.pdf"
                    onChange={handleChange12}
                    required
                  />
                  <label
                    htmlFor="CapturedFile4"
                    className="RegisterForm_1_btns"
                  >
                    Choose File
                  </label>
                </div>
              </div>
              <div className="RegisForm_1">
                <label>Others<span>:</span></label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="CapturedFile5"
                    name="CapturedFile5"
                    className="hiden-nochse-file"
                    accept="image/*,.pdf"
                    onChange={handleChange12}
                    required
                  />
                  <label
                    htmlFor="CapturedFile5"
                    className="RegisterForm_1_btns"
                  >
                    Choose File
                  </label>
                </div>
              </div>
            {/* </div> */}

            {/* <div className="RegisForm_1"> */}
              <div className="RegisForm_1">
                <label>
                  {" "}
                  Upload CSV File <span>:</span>{" "}
                </label>
                <input
                  type="file"
                  accept=".xlsx, .xls, .csv"
                  id="Servicechoose"
                  required
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="Servicechoose"
                  className="RegisterForm_1_btns"
                  style={{ color: "#fff" }}
                >
                  Choose File
                </label>
                <button
                  className="RegisterForm_1_btns"
                  onClick={() => handleCsvupload("Documents")}
                >
                  Upload
                </button>
              </div>
            </div>
          </Typography>
        </AccordionDetails>
      </Accordion>

      <div className="Register_btn_con">
        <button className="RegisterForm_1_btns" onClick={handleRegister}>
          {buttonText}
        </button>
        <ToastContainer />
      </div>
    </div>
  );
};

export default EmployeeRegister;
