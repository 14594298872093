import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import './LabMaster.css';
import { DataGrid } from "@mui/x-data-grid";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "#54d854bf",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  #54d854bf !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});



const LabReport = () => {
 
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);


  const [PatientData, setPatientData] = React.useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [openImageView, setopenImageView] = useState(false);
  const [modalContent, setModalContent] = useState('');
  // const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(0);
  // const [selectedRows, setSelectedRows] = React.useState([]);
  const pageSize = 10;
  const showdown = PatientData.length;
  const totalPages = Math.ceil(PatientData.length / 10);



  const lab_Patient_ID = formData.PatientID

  const visitNo = formData.visitNo

  const [Temp_testnamelist, setTemp_testnamelist] = useState([])


  // console.log('rrrr', selectedImage)
  const yourStyles = {
    position: 'absolute',
    inset: '100px',
    border: '1px solid rgb(204, 204, 204)',
    background: 'rgb(97 90 90 / 75%)',
    overflow: 'auto',
    borderRadius: '4px',
    outline: 'none',
    padding: '0px'
  }


  console.log(PatientData.flatMap((ele) => ele.Temp_testnamelist));

  const handleVisibilityClick = (lab_image) => {
    setModalContent(lab_image);
    console.log(modalContent);
    setModalIsOpen(true);
  };


  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
  };



  const [columns] = React.useState([
    { field: "id", headerName: "ID", width: 250 },
    { field: "PatientName", headerName: "Patient Name", width: 350 },
    {
      field: "lab_image",
      headerName: "View",
      width: 250,
      renderCell: (params) => (
        <>
          {isImageOrPDF?(
            <Button className="cell_btn" onClick={() => handleVisibilityClick(params.row.lab_image)}>
              <VisibilityIcon />
            </Button>
          ) : (
            // Render normal text field if lab_image is not a PDF or image
            params.row.lab_image
          )}
        </>
      ),
    },
    { field: "Date", headerName: "Date", width: 300 },
  ]);
  
  // Function to check if lab_image is a PDF or image
  const isImageOrPDF = (labImage) => {
    return labImage.startsWith("application/pdf;") || labImage.startsWith("image/jpeg;") || labImage.startsWith("application/octet-stream;");
  };
  
  



  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/labtest/getfor_doctorworkbenchlab?patientid=${lab_Patient_ID}&visitNo=${visitNo}&location=${userRecord?.location}`)
      .then((response) => {
        console.log(response)
        setPatientData(response.data.map((labData) => ({

          id: labData.PatientID,
          PatientName: labData.lab_patient_Name,
          Date: labData.Date,
          lab_image: labData.lab_image

        }))
        );
        
        setTemp_testnamelist(
          response.data.flatMap((ele) => ele.Temp_testnamelist)
        );
        console.log('wer', response.data)

      })
      .catch((err) => console.log(err));
  }, [lab_Patient_ID]);






  const handlePageChange = (params) => {
    setPage(params.page);
  };

















  return (
    <>
      <div className="form-container22">
        <div className="h_head">
          <h4>Test Preview</h4>
        </div>


        <div className="Selected-table-container RateCard_table">
          <table className="selected-medicine-table2 tablewidth">
            <thead>
              <tr>
                <th id="vital_Twidth">S.No</th>
                <th id="vital_Twidth">Test Name</th>
              </tr>
            </thead>
            <tbody>
              {Temp_testnamelist.map((client, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{client.Test_Name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <br />
        <br />
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={PatientData.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              // onSelectionModelChange={handleSelectionModelChange}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 0 && PatientData.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>

      <Modal isOpen={modalIsOpen} onClose={closeModal} style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent && modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)",
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Lab Image"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "hsl(33, 100%, 50%)",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>



    </>
  )
}
export default LabReport;