import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import '../SupplierMaster/SupplierMaster.css'
import { format } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});



export default function SupplierStock( ) {

  const dispatchvalue  = useDispatch();

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const userRecord = useSelector(state=>state.userRecord?.UserData)


    const navigate = useNavigate();
    const [page1, setPage1] = useState(0);
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [searchQuerypopup, setSearchQuerypopup] = useState('');
    const [Productype, setProductype] = useState('');
    const [summa, setsumma] = useState([])
    const [selectedShow, setSelectedShow] = useState(false);
    const [statustype,setstatustype]=useState('Pending')
    const [Rowdata, setRowdata] = useState([]);
    const [Rowdatapopup, setRowdatapopup] = useState([]);

    const showdown1 = Rowdata.length;
    const pageSize1 = 10;

    

    useEffect(() => {

      axios.get(`http://127.0.0.1:8000/SupplierMaster/get_supplierPay_detials?ProductType=${Productype}&Location=${userRecord?.location}&Status=${statustype}`)
        .then((response) => {
          const data = response.data;
          console.log('out', data);
          setsumma([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            }))
          ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });

    }, [Productype,statustype]);
    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
      const index = params.row.SupplierCode
      axios.get(`http://127.0.0.1:8000/SupplierMaster/get_Full_supplierPay_detials?ProductType=${Productype}&Location=${userRecord?.location}&Status=${statustype}&SupplierCode=${index}`)
        .then((response) => {
          const data = response.data;
          console.log('out', data);
        setSelectedShow(true);
          setRowdata([
            ...data.map((row, ind) => ({
              id: ind + 1,
              ...row,
            }))
          ]);
        })
        .catch((error) => {
            console.error('Error fetching patients data:', error);
        });
        
    };
    const handlemovetopay=(params)=>{
      
      const Supplierpay=params.row
      const currentdate = format(new Date(),'yyyy-MM-dd')
      const datas={
      ProductType:Productype,
      SupplierCode:Supplierpay.SupplierCode,
      SupplierName: Supplierpay.SupplierName,
      Supplier_Bill_Date:Supplierpay.Supplier_Bill_Date,
      GRNInvoice:Supplierpay.GRN_Invoice_No,
      GRNDate:Supplierpay.GRN_Invoice_Date,
      GRNDueDate:Supplierpay.GRN_Due_Date,
      GRNInvoiceAmount:Supplierpay.GRN_Invoice_Amount,
      GRNPaidAmount:Supplierpay.GRN_Paid_Amount,
      GRNBalanceAmount:Supplierpay.GRN_Balance_Amount,
      PaidDate:currentdate,
      }
      dispatchvalue({type : 'Supplierpay' , value :(datas)})
      console.log(datas)
      navigate('/Home/Supplier-Stock-Details')
    }

    

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.SupplierCode.toLowerCase();

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) 
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery,summa]);
    useEffect(() => {

      const lowerCaseQuery = searchQuerypopup.toLowerCase();
      const filteredData = Rowdata.filter((row) => {
          const lowerCaseSupplierName = row.GRN_Invoice_No.toLowerCase();

          return (
              lowerCaseSupplierName.includes(lowerCaseQuery) 
          );
      });

      setRowdatapopup(filteredData);
  }, [searchQuerypopup,Rowdata]);



   

    const handlePageChange1 = (params) => {
        setPage1(params.page);
      };
    const totalPages1 = Math.ceil(Rowdata.length / 10);
   

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'SupplierCode', headerName: 'Supplier Code', width: 200 },
        { field: 'SupplierName', headerName: 'Supplier Name', width: 200 },
        { field: 'TotalAmount', headerName: 'Total Amount', width: 170 },
        { field: 'PaidAmount', headerName: 'Paid Amount', width: 170 },
        { field: 'BalanceAmount', headerName: 'Balance Amount', width: 120 },
        {
            field: 'View',
            headerName: 'View',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <VisibilityIcon />
                    </Button>
                </>
            ),
        },
       
    ];

    const dynamicColumns1 =[
        { field: 'SupplierCode', headerName: 'Supplier Code', width: 150 },
        { field: 'SupplierName', headerName: 'Supplier Name', width: 150 },
        { field: 'GRN_Invoice_No', headerName: 'Invoice No', width: 150 },
        { field: 'GRN_Invoice_Date', headerName: 'Invoice Date', width: 120 },
        { field: 'Supplier_Bill_Date', headerName: 'Supplier Bill Date', width: 120 },
        { field: 'GRN_Due_Date', headerName: 'GRN Due Date', width: 120 },
        { field: 'GRN_Invoice_Amount', headerName: 'Total Amount', width: 120 },      
        { field: 'GRN_Paid_Amount', headerName: 'Paid Amount', width: 120 },      
        { field: 'GRN_Balance_Amount', headerName: 'Balance Amount', width: 120 },        
        { field: 'Location', headerName: 'Location', width: 120 } ,
        {
          field: 'View',
          headerName: 'Action',
          width: 120,
          renderCell: (params) => (
              <>
                  <Button className='cell_btn' onClick={() => handlemovetopay(params)} title='Proceed'>
                      <ArrowForwardIcon />
                  </Button>
              </>
          ),
      },       
    ]

  




    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="Product_Master_h_head">
                    <h3>Supplier Pay List</h3>
                    <div className="Product_Master_div_select">
                        <label htmlFor="Calender"> Product Type :</label>
                        <select className='Product_Master_div_select_opt' value={Productype} onChange={(e)=>{setProductype(e.target.value)}}>
                        <option value=''>Select</option>
                        <option value='Medical'>Medical</option>
                        <option value='Non_Medical'>Non Medical</option>
                        <option value='Assets'>Assets</option>
                        <option value='Stationary'>Stationary</option>
                        <option value='Non_Stationary'>Non Stationary</option>
                        </select>
                    </div>

                 </div>
                    <div className="Supplier_Master_con_1 ">

                        <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Supplier Code :</label>
                            <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Supplier Code' />
                        </div>
                        <div className="Supplier_inputss_forms">
                        <label htmlFor="input">Status :</label>
                        <select 
                            value={statustype}
                            onChange={(e)=>setstatustype(e.target.value)}
                        >
                            <option value='Pending'>Pending</option>
                            <option value='Completed'>Completed</option>
                        </select>
                    </div>
                      
                    </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
            {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>{Productype}List</h3>
                </div>
                <div className="con_1 ">

                <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Invoive No :</label>
                            <input type="text" value={searchQuerypopup} onChange={(e)=>setSearchQuerypopup(e.target.value)} placeholder='Enter the Invoice No' />
                        </div>

                
                </div>
                <div className='IP_grid'>
                  <ThemeProvider theme={theme}>
                    <div className='IP_grid_1'>
                      <DataGrid
                        rows={Rowdatapopup.slice(page1 * pageSize1, (page1 + 1) * pageSize1)} // Display only the current page's data
                        columns={dynamicColumns1} // Use dynamic columns here
                        pageSize={10}
                        initialState={{
                          pagination: {
                            paginationModel: {
                              pageSize: 10,
                            },
                          },
                        }}
                        pageSizeOptions={[10]}
                        onPageChange={handlePageChange1}
                        hideFooterPagination
                        hideFooterSelectedRowCount
                        className='data_grid'
                      />
                      {showdown1 > 0 && Rowdatapopup.length > 10 && (
                        <div className='IP_grid_foot'>
                          <button
                            onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                            disabled={page === 0}
                          >
                            Previous
                          </button>
                          Page {page1 + 1} of {totalPages1}
                          <button
                            onClick={() =>
                              setPage((prevPage) => Math.min(prevPage + 1, totalPages1 - 1))
                            }
                            disabled={page1 === totalPages1 - 1}
                          >
                            Next
                          </button>
                        </div>
                      )}
                    </div>
                  </ThemeProvider>
                  {showdown1 !== 0 && Rowdatapopup.length !== 0 ? (
                    ''
                  ) : (
                    <div className='IP_norecords'>
                      <span>No Records Found</span>
                    </div>
                  )}
                </div>
                <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                    }}

                  >
                    close
                  </button>
                </div>
              </div>


            </div>
          </div>
        )}
                </div>
            </div>
        </>
    );
}
