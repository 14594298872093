import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
// import Webcam from 'react-webcam';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import '../PatientManagement/PatientRegister.css';
import { useNavigate } from "react-router-dom";
import PrintIcon from '@mui/icons-material/Print';
import { DataGrid } from "@mui/x-data-grid";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from 'react-redux';
import Modal from "react-modal";
const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "#54d854bf",
                    textAlign: "Center",
                },
                root: {
                    "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
                    {
                        textAlign: "center",
                        display: "flex !important",
                        justifyContent: "center !important",
                    },
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  #54d854bf !important",
                    display: "flex",
                    justifyContent: "center",
                },
            },
        },
    },
});


const Concern3 = () => {

    const userRecord = useSelector(state => state.userRecord?.UserData)
    const [page, setPage] = useState(0);
    const nursedata = useSelector((state) => state.userRecord?.formData);

    console.log("222222222", nursedata)
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [summa, setsumma] = useState([]);
    const [openImageView, setopenImageView] = useState(false);
    const [modalContent, setModalContent] = useState("");
    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const create = userRecord?.username;
    const [formData, setFormData] = useState({
        treatmentname: '',
        date: format(new Date(), 'yyyy-MM-dd'),
        capturedImage: null,
        ChoosenFile: null,

    });
    const yourStyles = {
        position: "absolute",
        inset: "100px",
        border: "1px solid rgb(204, 204, 204)",
        background: "rgb(97 90 90 / 75%)",
        overflow: "auto",
        borderRadius: "4px",
        outline: "none",
        padding: "0px",
    };

    const navigate = useNavigate();
    // const [showFile, setShowFile] = useState(false);
    // const [isImageCaptured, setIsImageCaptured] = useState(false);
    // const webcamRef = useRef(null);
    const handleVisibilityClick = (ConcernForm) => {
        setModalContent(ConcernForm);
        setopenImageView(true);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setModalContent("");
    };
    const dynamicColumns = [
        { field: "id", headerName: "S.No", width: 150 },
        { field: "patientId", headerName: "PatientID", width: 150 },
        { field: "PatientName", headerName: "Patient Name", width: 200 },
        { field: "visitid", headerName: "VISIT NO", width: 150 },
        { field: "Date", headerName: "Date", width: 150 },
        { field: "location", headerName: "Branch Name", width: 150 },
        {
            field: "ConcernForm",
            headerName: "ConsentForm",
            width: 200,
            renderCell: (params) => (
                <>
                    <Button
                        className="cell_btn"
                        onClick={() => handleVisibilityClick(params.value)}
                    >
                        <VisibilityIcon />
                    </Button>
                </>
            ),
        },
    ];
    const handleInputFileChange = (e) => {
        // Handle file input change if needed
        const file = e.target.files && e.target.files[0]; // Add a null check here
        if (file) {
            setFormData((prev) => ({
                ...prev,
                capturedImage: null,
                ChoosenFile: file
            }));
        }
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    }








    //   const dataURItoBlob = (dataURI) => {
    //     const byteString = atob(dataURI.split(',')[1]);
    //     const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    //     const ab = new ArrayBuffer(byteString.length);
    //     const ia = new Uint8Array(ab);
    //     for (let i = 0; i < byteString.length; i++) {
    //         ia[i] = byteString.charCodeAt(i);
    //     }
    //     return new Blob([ab], { type: mimeString });
    // };
    // const handleCaptureImage = () => {
    //   const imageSrc = webcamRef.current.getScreenshot();
    //   setFormData((prev) => ({
    //     ...prev,
    //     capturedImage: imageSrc ? dataURItoBlob(imageSrc, 'captured_image.jpg') : null,
    //     ChoosenFile:null
    //   }));
    //   setIsImageCaptured(true);
    // };

    // const handleRecaptureImage = () => {
    //   setFormData((prev) => ({
    //     ...prev,
    //     capturedImage: null,
    //   }));
    //   setIsImageCaptured(false);
    // };

    const handlePrint = () => {
        const consentFileInput = document.getElementById("CapturedFile");
        const selectedFile = consentFileInput.files[0];

        if (selectedFile) {
            const fileURL = URL.createObjectURL(selectedFile);
            const newWindow = window.open(fileURL);
            newWindow.print();
        }
    };


    const handleSubmit = () => {
        const form = new FormData()

        const data = {
            patientId: nursedata.PatientID,
            visitNo:nursedata.visitNo,
            patientName: nursedata.PatientName,
            treatmentname: formData.treatmentname,
            date: formData.date,
            capturedImage: formData.ChoosenFile,
            Created_By: create,
            location: userRecord?.location
        }
        
        Object.keys(data).forEach(key => {
            if (data[key] !== null && data[key] !== undefined) {
                form.append(key, data[key]);
            }
        });
        console.log(form)
        axios.post("http://127.0.0.1:5000/appointmentmanagement/insert_concernform", form, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                console.log(response.data)
                successMsg("Saved Successfully")
            })
            .catch((error) => {
                console.log(error)
                errmsg("Error occured")
            })

    };
   
    useEffect(() => {
        const fetchData = () => {
            axios.get(`http://127.0.0.1:5000/appointmentmanagement/get_concernform?location=${userRecord.location}`)
                .then(response => {
                    const data = response.data;
                    setsumma(data.map(row => ({
                        id: row.ConcernFormID,
                        patientId: row.PatientID,
                        visitid: row.VisitID,
                        PatientName: row.PatientName,
                        treatmentname: row.Treatment_Name,
                        Date: row.Date,
                        location: row.location,
                        ConcernForm: row.ConcernForm,
                    })));
    
                    // Set filteredRows to the same data as summa initially
                    setFilteredRows(data.map(row => ({
                        id: row.ConcernFormID,
                        patientId: row.PatientID,
                        visitid: row.VisitID,
                        PatientName: row.PatientName,
                        treatmentname: row.Treatment_Name,
                        Date: row.Date,
                        location: row.location,
                        ConcernForm: row.ConcernForm,
                    })));
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };
    
        fetchData();
    }, []);
    

    

    const successMsg = (msg) => {
        toast.success(`${msg}`, {
            position: "top-center",
            autoClose: 100,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };

    const errmsg = (errorMessage) => {
        toast.error(`${errorMessage}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    }
    return (
        <div className="appointment">
            <div className="h_head">
                <h3>Consent Form</h3>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '100%', padding: '0px 10px', boxSizing: 'border-box' }}>
                {/* Patient Information */}
                <div className="RegisFormcon">


                    <div className="RegisForm_1">
                        <label> Treatment Name <span>:</span> </label>
                        <input
                            type="text"
                            placeholder='Enter Treatment Name '
                            name='treatmentname' // Corrected name attribute
                            value={formData.treatmentname}
                            onChange={handleInputChange} // Use handleInputChange for text input
                        />
                    </div>
                    {/* <div className="RegisForm_1">
          <label htmlFor="Title">Treatment Type <span>:</span></label>
          <input
            name="Title"
            placeholder='Enter Treatment Type'
            onChange={(e) => setFormData({ ...formData, treatmentType: e.target.value })}
          >
          </input>
        </div> */}

                    <div className="RegisForm_1">
                        <label> Date <span>:</span> </label>
                        <input
                            type="date"
                            placeholder='Enter the Date'
                            name='Date'
                            value={formData.date}
                            required
                            readOnly
                        />
                    </div>
                    <div className="RegisForm_1">
                        <label htmlFor="CapturedFile" className='consent_fr_prnt'>Consent Form <button className='print_emrgcy' title='Print' onClick={handlePrint}><PrintIcon /></button>:</label>
                        <div className="file-input-444">
                            <div className="RegisterForm_2">
                                <input
                                    type="file"
                                    id="CapturedFile"
                                    name="CapturedFile"
                                    accept="image/pdf"
                                    onChange={handleInputFileChange}
                                    required
                                    style={{ display: 'none' }}
                                />
                                <label htmlFor="CapturedFile" className="RegisterForm_1_btns">
                                    Choose File
                                </label>
                            </div>


                        </div>
                    </div>

                    {/* <div className="RegisForm_1"  id='hide_div_regg'>

        </div> */}
                </div>



                {/* Submit Button */}


            </div>
            <div className='Register_btn_con' style={{ marginTop: '20px' }}>
                <button onClick={handleSubmit} className='RegisterForm_1_btns'>Submit</button>
            </div>
            <div className="grid_1">
                <ThemeProvider theme={theme}>
                    <div className="grid_1">
                        <DataGrid
                            rows={filteredRows.slice(
                                page * pageSize,
                                (page + 1) * pageSize
                            )} // Display only the current page's data
                            // rows={summa}
                            columns={dynamicColumns} // Use dynamic columns here
                            pageSize={10}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                            }}
                            pageSizeOptions={[10]}
                            onPageChange={handlePageChange}
                            hideFooterPagination
                            hideFooterSelectedRowCount
                            className="data_grid"
                        />
                        {showdown > 0 && filteredRows.length > 10 && (
                            <div className="IP_grid_foot">
                                <button
                                    onClick={() =>
                                        setPage((prevPage) => Math.max(prevPage - 1, 0))
                                    }
                                    disabled={page === 0}
                                >
                                    Previous
                                </button>
                                Page {page + 1} of {totalPages}
                                <button
                                    onClick={() =>
                                        setPage((prevPage) =>
                                            Math.min(prevPage + 1, totalPages - 1)
                                        )
                                    }
                                    disabled={page === totalPages - 1}
                                >
                                    Next
                                </button>
                            </div>
                        )}
                    </div>
                </ThemeProvider>
                {showdown !== 0 && filteredRows.length !== 0 ? (
                    ""
                ) : (
                    <div className="IP_norecords">
                        <span>No Records Found</span>
                    </div>
                )}
            </div>
            <ToastContainer />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={{ content: { ...yourStyles } }}
            >
                <div className="pdf_img_show">
                    {modalContent
                        .toLowerCase()
                        .startsWith("data:application/pdf;base64,") ? (
                        <iframe
                            title="PDF Viewer"
                            src={modalContent}
                            style={{
                                width: "100%",
                                height: "435px",
                                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
                            }}
                        />
                    ) : (
                        <img
                            src={modalContent}
                            alt="Concern Form"
                            style={{
                                width: "80%",
                                height: "75%",
                                marginTop: "20px",
                            }}
                        />
                    )}
                    <div className="jhuhhjh">
                        <Button
                            style={{ color: "white" }}
                            className="clse_pdf_img"
                            onClick={closeModal}
                        >
                            <HighlightOffIcon
                                style={{
                                    fontSize: "40px",
                                    backgroundColor: "#54d854bf",
                                    borderRadius: "40px",
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Concern3;

