import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { DataGrid } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

export default function PatientVisitingDoctor( ) {
  
  const Navigate = useNavigate();

  const dispatchvalue = useDispatch();
  const userRecord = useSelector((state) => state.userRecord?.UserData);
const Location = userRecord?.location

  const [columns] = React.useState([
    { field: "id", headerName: "Patient ID", width: 180 },
    { field: "PatientName", headerName: "Patient Name", width: 180 },
    { field: "phone", headerName: "Phone No", width: 180 },
    { field: "email", headerName: "Email", width: 180 },

    {
      field: "view ",
      headerName: "View",
      width: 100,
      renderCell: (params) => (
        <>
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <VisibilityIcon />
          </Button>
        </>
      ),
     
    },
    {
        field: "action ",
        headerName: "Action",
        width: 100,
        renderCell: (params) => (
            <>
              <Button className='cell_btn' onClick={() => handleDirectProceed(params)}>
                <ArrowForwardIcon />
              </Button>
            </>
          ),
       
      },
     
  ]);


  const [PatientData, setPatientData] = React.useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQuery1, setSearchQuery1] = useState("");
  const [searchQuery2, setSearchQuery2] = useState("");
  const [location, setLocation] = useState('');
  const [locationoptions, setlocationOptions] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [page, setPage] = useState(0);

  const pageSize = 10;
  const showdown = PatientData.length;
  const totalPages = Math.ceil(PatientData.length / 10);

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleList = (params) => {
    const selectedRowId = params.row.id;

    axios
      .get(`http://127.0.0.1:8000/patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(patient => patient.PatientID === selectedRowId);

        axios
          .get(`http://127.0.0.1:8000/patientmanagement/get_communication_address`)
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(patient => patient.PatientID === selectedRowId);

            axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`)
              .then((response) => {
                const data3 = response.data;
                const vital = data3[0];

                axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`)
                  .then((response) => {
                    const data4 = response.data;
                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`)
                      .then((response) => {
                        const data5 = response.data;
                        const PatientPhoto = data5.PatientPhoto;

                        axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_treatment`)
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(row => selectedRowId === data5.PatientID);
                            const history = filteredData.History;

                            axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`)
                              .then((response) => {
                                const data7 = response.data;
                                const EmergencyName = data7.Name;

                                axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`)
                                  .then((response) => {
                                    const data8 = response.data;
                                    const allergies = data8.allergies;

                                    axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`)
                                      .then((response) => {
                                        const data9 = response.data;
                                        const previousvisit = data9.previousvisit;

                                        axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`)
                                          .then((response) => {
                                            const data10 = response.data;
                                            const Next_Appointment = data10.Next_Appointment;

                                            axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`)
                                              .then((response) => {
                                                const data11 = response.data;

                                                axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_allimages_for_patients?selectedRowId=${selectedRowId}`)
                                                  .then((response) => {
                                                    const data12 = response.data;

                                                    const patientAction = (data) =>({
                                                      type: 'forPatientData',
                                                      value: data
                                                    })

                                                    dispatchvalue(patientAction({
                                                        // ...prevInfo,
                                                        ...foundPatient,
                                                        ...patient_address,
                                                        ...vital,
                                                        ...firstimge,
                                                        ...lastimg,
                                                        ...PatientPhoto,
                                                        ...history,
                                                        ...EmergencyName,
                                                        ...allergies,
                                                        ...previousvisit,
                                                        ...Next_Appointment,
                                                        ...data11,
                                                        ...data12,
                                                        // ...data11
                                                        // ...first_image,
                                                        // ...last_image
                                                     })                                              
                                                    )

                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });
    Navigate("/Home/PatientProfile");
  };


  const fetchPatientData = () => {
  fetch(`http://127.0.0.1:8000/VisitingDoctor/get_visitingdoctor_patientlist?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        if (data && Array.isArray(data)) {
          console.log(data)
          const rows = data.map((userdata) => ({
            id: userdata.PatientID,
            PatientName: userdata.PatientName,
            phone: userdata.PatientPhoneNumber,
            email: userdata.Email,
          }));
          setPatientData(rows);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };



  const handleDirectProceed =(params) =>{
    const patientid = params.row.id
    
    axios.get(`http://127.0.0.1:8000/VisitingDoctor/get_Visitingdoctor_info?patientid=${patientid}&location=${Location}`)
    .then((response)=>{
      console.log(response)
      const vistingdoctordata = response.data

      dispatchvalue({ type: "vistingdoctordata", value: vistingdoctordata });

    })
    .catch((error)=>{
      console.log(error)
    })
    
    Navigate('/Home/Visiting-Doctor')

  }


  useEffect(() => {
    fetchPatientData(userRecord?.location);
  }, [userRecord?.location]);

  useEffect(() => {
    fetchlocationOptions();
  }, []);

  const fetchlocationOptions = () => {
    axios
      .get('http://127.0.0.1:8000/usercontrol/getlocationdata')
      .then(response => {
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch(error => {
        console.error('Error fetching Location options:', error);
      });
  };

  const handlelocationchange = (event) => {
    const value = event.target.value.trim();
    setLocation(value);
  };

  const handleSearchChange = (event) => {
    const { id, value } = event.target;

    if (id === 'FirstName') {
      setSearchQuery(value);
    } else if (id === 'PhoneNo') {
      setSearchQuery1(value);
    } else if (id === 'location') {
      setSearchQuery2(value.toLowerCase());
    }
  };


  return (
    <>
      <Helmet>
        <title>Patient List</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h3>Visiting Doctor Patients</h3>
        </div>
        <form>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name :</label>
              <input
                type="text"
                id="FirstName"
                value={searchQuery}
                onChange={handleSearchChange}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No :</label>
              <input
                type="text"
                id="PhoneNo"
                value={searchQuery1}
                onChange={handleSearchChange}
                placeholder="Enter the Phone No"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Location :</label>
              <select
                id="location"
                name="location"
                className="new-custom-input-phone vital_select"
                onChange={handlelocationchange}
                required
              >
                <option>Select</option>
                {locationoptions.map(loc => (
                  <option key={loc.location_id} value={loc.location_name}>
                    {loc.location_name}
                  </option>
                ))}
              </select>
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={PatientData.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && PatientData.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {PatientData.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
}


