import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function VisitingDoctorBilling() {

    const Navigate = useNavigate();

    const visitingdoctorprocedure = useSelector(
        (state) => state.userRecord?.visitingdoctorprocedure
    );
    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const [formData, setFormData] = useState({
        doctorName: "",
        phoneNumber: "",
        email: "",
        location: "",
        specialist: "",
        experience: "",
        dateOfVisit: "",
        duration: "",
        treatmentProcedure: "",
        numberOfSessions: '',
        perCraftCharge: '',
        numberofcraftpersitting: '',
        totalcost: '',
        hospitalPercentage: '',
        doctorPercentage: '',
    });

    console.log(formData)

    // const handlePhoneNumberChange = (e) => {
    //     const inputPhoneNumber = e.target.value;

    //     if (/^\d{0,10}$/.test(inputPhoneNumber)) {
    //         setFormData({ ...formData, phoneNumber: inputPhoneNumber });
    //     } else {
    //         alert("Phone Number must contain 10 digits");
    //     }
    // };



    const handlePerCraftChargeInputChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            perCraftCharge: value,
      

        }));

        // calculateTotalCost();
    };

    const handleDoctorValueChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
     
            doctorPercentage: value,
        }));
    };

    const handleHospitalValueChange = (e) => {
        const value = e.target.value;
        setFormData((prev) => ({
            ...prev,
            hospitalPercentage:value,
   
        }));
    };



    useEffect(() => {   
        const numberOfSessions = parseFloat(formData.numberOfSessions) || 0;
        const numberofcraftpersitting = parseFloat(formData.numberofcraftpersitting) || 0;
        const PerCraftCharge = +formData.perCraftCharge || 0;

        const totalCost = numberOfSessions * numberofcraftpersitting * PerCraftCharge;
        console.log('totalCost :', totalCost)
        setFormData((prev) => ({ ...prev, totalcost: totalCost }));

    }, [formData.numberOfSessions, formData.numberofcraftpersitting, formData.perCraftCharge])



    useEffect(() => {
        setFormData((prev) => ({ ...prev, ...visitingdoctorprocedure || [] }));
    }, [visitingdoctorprocedure]);

    const handleSubmit = (e) => {
        const form = new FormData();
        console.log(userRecord);
        console.log("Form submitted:", form);
        Object.keys(formData).forEach((key) => {
            if (formData[key] !== null && formData[key] !== undefined) {
                form.append(key, formData[key]);
            }
        });

        form.append("branchlocation", userRecord.location);
        form.append("createdby", userRecord.username);

        axios
            .post(
                "http://127.0.0.1:8000/VisitingDoctor/insert_VisitingDoctor_Billing",
                form
            )
            .then((response) => {
                console.log(response.data);
                successMsg("Saved Successfully");
                Navigate('/Home/Visit-Doctor-Billing-List')


            })
            .catch((error) => {
                console.log(error);
                errmsg("Error occurred");
            });

        e.preventDefault();
    };

    const successMsg = (msg) => {
        toast.success(`${msg}`, {
            position: "top-center",
            autoClose: 100,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };

    const errmsg = (errorMessage) => {
        toast.error(`${errorMessage}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            style: { marginTop: "50px" },
        });
    };


    return (
        <>
            <div className="new-patient-registration-form">
                <div className="user_patienthead">
                    <h3>Visiting Doctor Billing</h3>
                </div>
                <div className="new-patient-info-container ">
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Visit Doctor <span> Dr.</span>
                        </label>
                        <select
                            name="doctorName"
                            className="new-custom-input-phone vital_select"
                            value={formData?.doctorName || ''}
                            onChange={handlePerCraftChargeInputChange}
                            required
                        >
                            <option value="" disabled>
                                Select Doctor
                            </option>
                            <option value="BalaChandran"> BalaChandran</option>
                            <option value="Rajesh"> Rajesh</option>
                            <option value="Hemalatha"> Hemalatha</option>
                        </select>
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Phone Number <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="phoneNumber"
                            value={formData?.phoneNumber || ''}
                            placeholder="Enter Phone Number"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Email <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="email"
                            value={formData?.email || ''}
                            placeholder="Enter Email"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Location <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="location"
                            value={formData?.location || ''}
                            placeholder="Enter Location"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Specialist <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="specialist"
                            value={formData?.specialist || ''}
                            placeholder="Enter Specialist"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Experience <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="experience"
                            value={formData?.experience || ''}
                            placeholder="Enter Experience"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Date of Visit <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="dateOfVisit"
                            value={formData?.dateOfVisit || ''}
                            placeholder="Enter Date of Visit"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Duration <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="duration"
                            value={formData?.duration || ''}
                            placeholder="Enter Duration"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Treatment Procedure <span>:</span>
                        </label>
                        <select
                            name="treatmentProcedure"
                            className="new-custom-input-phone vital_select"
                            value={formData?.treatmentProcedure || ''}
                            onChange={handlePerCraftChargeInputChange}
                            required
                        >
                            <option value="">Select Procedure</option>
                            <option value="HT">HT</option>
                            <option value="PRP">PRP</option>
                        </select>
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Sitting / Session <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="numberOfSessions"
                            value={formData?.numberOfSessions || ''}
                            placeholder="Enter Sessions / Crafts"
                            onChange={handlePerCraftChargeInputChange}
                            required
                        />
                    </div>

                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            No.of Craft Per Sitting / Session <span>:</span>
                        </label>
                        <input
                            name="numberofcraftpersitting"
                            placeholder="Enter Procedure Amount"
                            className="new-custom-input-phone vital_select"
                            value={formData?.numberofcraftpersitting || ''}
                            onChange={handlePerCraftChargeInputChange} required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Per Craft Charge <span>:</span>
                        </label>
                        <input
                            name="percraftcharge"
                            placeholder="Enter Per Craft Charge"
                            className="new-custom-input-phone vital_select"
                            value={formData?.perCraftCharge || ''}
                            onChange={handlePerCraftChargeInputChange} required
                        />
                    </div>

                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Total Cost <span>:</span>
                        </label>
                        <input
                            type="number"
                            name="totalcost"
                            value={formData?.totalcost || ''}
                            placeholder="Enter Total cost"
                            readOnly
                        />
                    </div>
                </div>
                <div className="new-patient-info-container ">
                   
                </div>
                <div className="new-patient-info-container ">
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Hospital Value <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="hospitalPercentage"
                            value={formData?.hospitalPercentage || ''}
                            placeholder="Enter Hospital Value"
                            onChange={handleHospitalValueChange}
                            required
                        />
                    </div>
                    <div className="new-custom-form-row incre-inpt-with18">
                        <label className="new-custom-label-title12">
                            Doctor Value <span>:</span>
                        </label>
                        <input
                            type="text"
                            name="doctorPercentage"
                            value={formData?.doctorPercentage || ''}
                            placeholder="Enter Doctor Value"
                            onChange={handleDoctorValueChange}
                            required
                        />
                    </div>
                   
                </div>
                <div className="new-button-container">
                    <button
                        className="btncon_add prs-ad-sub-btn"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        Submit
                    </button>
                    <ToastContainer />
                </div>
            </div>

        </>
    );
}

export default VisitingDoctorBilling;
