import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { addDays, format } from 'date-fns';




function Accounts() {
  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const Editdata = useSelector(state=>state.FinanceStore?.LedgerEditdata)
  
  const navigate = useNavigate();

  const cu_date = format(new Date(), 'yyyy-MM-dd');


  console.log('cu_date',cu_date)

  const [ledgerOpeningdata, setledgerOpeningdata] = useState([]);

  const [filteredRows, setfilteredRows] = useState([]);

  const[Subtitele,setSubtitele]=useState('')

 
  // const [LedgerFormState, setLedgerFormState] = useState({
  //   LedgerName: "",
  //   alias: "",
  //   group: "",
  //   opbal: "",
  //   drCr: "",
  //   BankDetailes:'No',
  //   address: "",
  //   country: "",
  //   state: "",
  //   // typesofDealer: "",
  //   // gstinUin: "",
  //   // adharNumber: "",
  //   Pincode:'',
  //   tin: "",
  //   itPan: "",
  //   ward: "",
  //   email: "",
  //   phoneNumber: "",
  //   whatsappNumber: "",
  //   fax: "",
  //   contactPerson: "",
  //   transport: "",
  //   station: "",
  //   pinCode: "",
  //   distance: "",
  //   cstNumber: "",
  //   lstNumber: "",
  //   serviceTaxNo: "",
  //   lbtNo: "",
  //   ieCode: "",

  //   accountNumber: "",
  //   bankName: "",
  //   branch: "",
  //   ifscCode: "",
  //   panNumber: "",
  // });

  const [LedgerFormState, setLedgerFormState] = useState({
    LedgerName: "",
    alias: "",
    group: "",
    opbal: "",
    drCr: "",
    BankDetailes:'No',
    address: "",
    country: "",
    state: "",
    Pincode:'',
    phoneNumber: "",
    PAN_OR_IT_NO:'',
    accountName:'',
    accountNumber: "",
    bankName: "",
    branch: "",
    ifscCode: "",
    panNumber: "",
  });
  // console.log("setLedgerFormState :", LedgerFormState);

 

  // console.log('Subtitele',Subtitele)
  // console.log('filteredRows',filteredRows)


  useEffect(()=>{

    if(Object.keys(Editdata).length !== 0){

      setLedgerFormState((prev)=>({
        ...prev,
        LedgerName:Editdata.Ledger_Name,
        alias:Editdata.Alias_Name,
        group:Editdata.Group_Name,
        opbal:Editdata.Opening_Balance,
        drCr:Editdata.Depit_or_Credit,
        BankDetailes:Editdata.BankDetailes,
        address:Editdata.Address,
        country:Editdata.Country,
        state:Editdata.State,
        Pincode:Editdata.Pincode,
        phoneNumber:Editdata.PhoneNumber,
        PAN_OR_IT_NO:Editdata.PAN_OR_IT_NO,
        accountName:Editdata.Account_Holder_Name,
        accountNumber:Editdata.Account_Number,
        bankName:Editdata.Bank_Name,
        branch:Editdata.Branch,
        ifscCode:Editdata.IFSC_Code,
        panNumber:Editdata.Pan_No,
      }))

    }

  },[Editdata])

  useEffect(()=>{
    if(LedgerFormState.group !=='' && filteredRows.length !== 0){
      const check = filteredRows.find(
        (ele) => ele.GroupName === LedgerFormState.group && ele.Primary_Group === 'No'
      );
      if (check) {
        setSubtitele(check.Under_Group)
      }else{
        setSubtitele('')
      }
    }
    else{
      setSubtitele('')
    }

  },[LedgerFormState.group,filteredRows])


  useEffect(()=>{
    axios
    .get(
      `http://127.0.0.1:8000/FinanceMagement/Some_get_accountmaster_group`
    )
    .then((response) => {
      console.log(response.data);
      setfilteredRows(response.data)
    })
    .catch((error) => {
      console.log(error);
    });

    Get_ledgerdata()
  },[])

 


  const Get_ledgerdata=()=>{
    axios
    .get(
      `http://127.0.0.1:8000/FinanceMagement/get_Ledger_Entry_For_Diff`
    )
    .then((response) => {
      console.log(response.data);
      setledgerOpeningdata(response.data)
    })
    .catch((error) => {
      console.log(error);
    });
  }

  const Update_ledgerdata = () => {

    const cleardatafun =()=>{

      setLedgerFormState({
        LedgerName: "",
        alias: "",
        group: "",
        opbal: "",
        drCr: "",
        BankDetailes:'No',
        address: "",
        country: "",
        state: "",
        Pincode:'',
        phoneNumber: "",
        PAN_OR_IT_NO:'',
        accountName:'',
        accountNumber: "",
        bankName: "",
        branch: "",
        ifscCode: "",
        panNumber: ""
      })
      setSubtitele('')
    }
    const requiredFields = ['LedgerName', 'group', 'opbal', 'drCr', 'country', 'state'];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === 'Yes';
    
    if (isBankDetailsRequired) {
      requiredFields.push('accountName','accountNumber', 'bankName', 'branch', 'ifscCode', 'panNumber');
    }
  
    const missingFields = requiredFields.filter((field) => !LedgerFormState[field]);
  
    if (missingFields.length === 0) {
      const newData = {
        ...LedgerFormState,        
        S_No:Editdata.S_No,
        cu_date:cu_date,
        CreatedBy: userRecord.username,
        Location: userRecord.location,
      };


      console.log('newData',newData)
  
      axios
        .post(
          "http://127.0.0.1:8000/FinanceMagement/update_Ledger_Entry_details",
          newData
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.Message);
          cleardatafun()
          Get_ledgerdata()
          navigate('/Home/LedgerList')
          
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    
    
    } else {
      alert(`Missing fields: ${missingFields.join(', ')}`);
    }
  };

 
 
 
  const Add_LedgerEntry = () => {

    const cleardatafun =()=>{

      setLedgerFormState({
        LedgerName: "",
        alias: "",
        group: "",
        opbal: "",
        drCr: "",
        BankDetailes:'No',
        address: "",
        country: "",
        state: "",
        Pincode:'',
        phoneNumber: "",
        PAN_OR_IT_NO:'',
        accountName:'',
        accountNumber: "",
        bankName: "",
        branch: "",
        ifscCode: "",
        panNumber: ""
      })
      setSubtitele('')
    }
    const requiredFields = ['LedgerName', 'group', 'opbal', 'drCr', 'country', 'state'];
    const isBankDetailsRequired = LedgerFormState.BankDetailes === 'Yes';
    
    if (isBankDetailsRequired) {
      requiredFields.push('accountName','accountNumber', 'bankName', 'branch', 'ifscCode', 'panNumber');
    }
  
    const missingFields = requiredFields.filter((field) => !LedgerFormState[field]);
  
    if (missingFields.length === 0) {
      const newData = {
        ...LedgerFormState,
        cu_date:cu_date,
        CreatedBy: userRecord.username,
        Location: userRecord.location,
      };


      console.log('newData',newData)
  
      axios
        .post(
          "http://127.0.0.1:8000/FinanceMagement/insert_Ledger_Entry_details",
          newData
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.Message);
          cleardatafun()
          Get_ledgerdata()
        })
        .catch((error) => {
          console.error(error);
          alert("Failed to add data. Please try again.");
        });
    
    
    } else {
      alert(`Missing fields: ${missingFields.join(', ')}`);
    }
  };
  
 
  return (
    <div className="new-patient-registration-form">
      <div className="user_patienthead">
        <h3>Ledger</h3>
      </div>

      <div className="new-patient-info-container">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Name <span>:</span>
          </label>
          <input
            type="text"
            name="LedgerName"
            placeholder="Enter Name"
            required
            value={LedgerFormState.LedgerName}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, LedgerName: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Alias Name<span>:</span>
          </label>
          <input
            type="text"
            name="alias"
            placeholder="Enter Your Alias"
            required
            value={LedgerFormState.alias}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, alias: e.target.value })
            }
          />
        </div>

        <div className="new-custom-form-row incre-inpt-with18">
            <label className="new-custom-label-title12">
              Group <span>:</span>
            </label>
            <input
              type="text"
              name="group"
              placeholder="Enter Your Group"
              list="group_list"
              required
              value={LedgerFormState.group}
              onChange={(e) =>  setLedgerFormState({ ...LedgerFormState, group:e.target.value })}
            />
            <datalist id="group_list">
              {filteredRows.map((item, index) => (
                <option key={index} value={item.GroupName}></option>
              ))}
            </datalist>
            
          </div>
          {Subtitele === ''?'':<span style={{fontSize:'13px',marginTop:'25px'}}>({Subtitele})</span>}



      </div>
      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Op.Bal <span>:</span>
          </label>
          <input
            type="text"
            name="opbal"
            placeholder="Enter Op.Bal"
            required
            value={LedgerFormState.opbal}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, opbal: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Dr/Cr<span>:</span>
          </label>
          <select
            name="drCr"
            className="select_with_dwn"
            required
            value={LedgerFormState.drCr}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, drCr: e.target.value })
            }
          >
            <option value=''>Select</option>
            <option value='Credit'>Credit</option>
            <option value='Debit'>Debit</option>
          </select>
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
           BankDetailes<span>:</span>
          </label>
          <select
            name="BankDetailes"
            className="select_with_dwn"
            required
            value={LedgerFormState.BankDetailes}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, BankDetailes: e.target.value })
            }
          >
            <option value='Yes'>Yes</option>
            <option value='No' >No</option>
          </select>
        </div>
      </div>

      

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Address <span>:</span>
          </label>
          <textarea
            type="text"
            name="address"
            placeholder="Enter your Address"
            className="account_txtarea3"
            required
            value={LedgerFormState.address}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, address: e.target.value })
            }
          />
        </div>

        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            State <span>:</span>
          </label>
          <input
            type="text"
            name="state"
            placeholder="Enter Your State"
            required
            value={LedgerFormState.state}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, state: e.target.value })
            }
          />
        </div>

        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Country <span>:</span>
          </label>
          <input
            type="text"
            name="country"
            placeholder="Enter your Country"
            required
            value={LedgerFormState.country}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, country: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Pincode <span>:</span>
          </label>
          <input
            type="text"
            name="Pincode"
            placeholder="Enter Pincode Number "
            required
            value={LedgerFormState.Pincode}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, Pincode: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Phone Number<span>:</span>
          </label>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter Your Phone Number"
            required
            value={LedgerFormState.phoneNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, phoneNumber: e.target.value })
            }
          />
          </div>
          <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            PAN/IT NO<span>:</span>
          </label>
          <input
            type="text"
            name="PAN_OR_IT_NO"
            placeholder="Enter Your PAN/IT Number"
            required
            value={LedgerFormState.PAN_OR_IT_NO}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, PAN_OR_IT_NO: e.target.value })
            }
          />
          </div>
      </div>

      {/* <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Aadhar Number <span>:</span>
          </label>
          <input
            type="text"
            name="adharNumber"
            placeholder="Enter Aadhar Number "
            required
            value={LedgerFormState.adharNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, adharNumber: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Types of Dealer <span>:</span>
          </label>
          <input
            type="text"
            name="typesofDealer"
            placeholder="Enter Types of Dealer"
            required
            value={LedgerFormState.typesofDealer}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, typesofDealer: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            GSTIN/UIN <span>:</span>
          </label>
          <input
            type="text"
            name="gstinUin"
            placeholder="Enter GSTIN /UIN"
            required
            value={LedgerFormState.gstinUin}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, gstinUin: e.target.value })
            }
          />
        </div>
      </div> */}

      {/* <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            TIN<span>:</span>
          </label>
          <input
            type="text"
            name="tin"
            placeholder="Enter TIN"
            required
            value={LedgerFormState.tin}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, tin: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            IT PAN<span>:</span>
          </label>
          <input
            type="text"
            name="itPan"
            placeholder="Enter IT PAN"
            required
            value={LedgerFormState.itPan}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, itPan: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Ward<span>:</span>
          </label>
          <input
            type="text"
            name="ward"
            placeholder="Enter Ward"
            required
            value={LedgerFormState.ward}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, ward: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Email<span>:</span>
          </label>
          <input
            type="text"
            name="email"
            placeholder="Enter Your Email"
            required
            value={LedgerFormState.email}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, email: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Phone Number<span>:</span>
          </label>
          <input
            type="text"
            name="phoneNumber"
            placeholder="Enter Your Phone Number"
            required
            value={LedgerFormState.phoneNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, phoneNumber: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Whatsapp No<span>:</span>
          </label>
          <input
            type="number"
            name="whatsappNumber"
            placeholder="Enter Your Whatsapp No"
            required
            value={LedgerFormState.whatsappNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, whatsappNumber: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Fax<span>:</span>
          </label>
          <input
            type="text"
            name="fax"
            placeholder="Enter Fax"
            required
            value={LedgerFormState.fax}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, fax: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Contact Person<span>:</span>
          </label>
          <input
            type="text"
            name="contactPerson"
            placeholder="Enter Contact Person"
            required
            value={LedgerFormState.contactPerson}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, contactPerson: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Transport<span>:</span>
          </label>
          <input
            type="text"
            name="transport"
            placeholder="Enter Transport"
            required
            value={LedgerFormState.transport}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, transport: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Station<span>:</span>
          </label>
          <input
            type="text"
            name="station"
            placeholder="Enter Station"
            required
            value={LedgerFormState.station}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, station: e.target.value })
            }
          />
        </div>

        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            PIN Code<span>:</span>
          </label>
          <input
            type="number"
            name="pinCode"
            placeholder="Enter PIN Code"
            required
            value={LedgerFormState.pinCode}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, pinCode: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Distance <span>:</span>
          </label>
          <input
            type="text"
            name="distance"
            placeholder="Enter Distance"
            required
            value={LedgerFormState.distance}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, distance: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            CST Number<span>:</span>
          </label>
          <input
            type="number"
            name="cstNumber"
            placeholder="Enter CST Number"
            required
            value={LedgerFormState.cstNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, cstNumber: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            LST Number<span>:</span>
          </label>
          <input
            type="number"
            name="lstNumber"
            placeholder="Enter LST Number"
            required
            value={LedgerFormState.lstNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, lstNumber: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            LBT No<span>:</span>
          </label>
          <input
            type="number"
            name="lbtNo"
            placeholder="Enter LBT No"
            required
            value={LedgerFormState.lbtNo}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, lbtNo: e.target.value })
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Service Tax No<span>:</span>
          </label>
          <input
            type="number"
            name="serviceTaxNo"
            placeholder="Enter Service Tax No"
            required
            value={LedgerFormState.serviceTaxNo}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, serviceTaxNo: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            IE Code<span>:</span>
          </label>
          <input
            type="number"
            name="ieCode"
            placeholder="Enter IE Code"
            required
            value={LedgerFormState.ieCode}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, ieCode: e.target.value })
            }
          />
        </div>
      </div> */}

      {LedgerFormState.BankDetailes ==='Yes'? <>
      <div className="new-patient-info-container ">
      <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Bank Holder Name <span>:</span>
          </label>
          <input
            type="text"
            name="accountName"
            placeholder="Enter Bank Holder Name"
            required
            value={LedgerFormState.accountName}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, accountName: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Bank Name <span>:</span>
          </label>
          <input
            type="text"
            name="bankName"
            placeholder="Enter Bank Name"
            required
            value={LedgerFormState.bankName}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, bankName: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Account Number <span>:</span>
          </label>
          <input
            type="text"
            name="accountNumber"
            placeholder="Enter Account Number"
            required
            value={LedgerFormState.accountNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, accountNumber: e.target.value })
            }
          />
        </div>
      </div>
      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Branch <span>:</span>
          </label>
          <input
            type="text"
            name="branch"
            placeholder="Enter Branch"
            required
            value={LedgerFormState.branch}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, branch: e.target.value })
            }
          />
        </div>

        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            IFSC Code <span>:</span>
          </label>
          <input
            type="text"
            name="ifscCode"
            placeholder="Enter IFSC Code"
            required
            value={LedgerFormState.ifscCode}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, ifscCode: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Pan Number <span>:</span>
          </label>
          <input
            type="text"
            name="panNumber"
            placeholder="Enter Pan Number"
            required
            value={LedgerFormState.panNumber}
            onChange={(e) =>
              setLedgerFormState({ ...LedgerFormState, panNumber: e.target.value })
            }
          />
        </div>
      </div>
      </> : <></>}

      <div className="new-button-container">
        {Object.keys(Editdata).length !== 0 ?
        <button
        className="btncon_add prs-ad-sub-btn"
        type="button"
        onClick={Update_ledgerdata}
      >
        Update
      </button>
      :
        <button
          className="btncon_add prs-ad-sub-btn"
          type="button"
          onClick={Add_LedgerEntry}
        >
          Save
        </button>}
      </div>

      {ledgerOpeningdata.length !== 0 ?<div style={{ display: "grid", placeItems: "center" }}>
        <h4>Total Opening Balance</h4>
        <div className="Selected-table-container ">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th>Amount</th>
                <th>Credit / Debit</th>
              </tr>
            </thead>
            <tbody>
              {ledgerOpeningdata.map((client, index) => (
                <tr key={index}>
                  <td>{client.Amount}</td>
                  <td>
                      {client.TypeAm}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div> : ''}
    </div>
  );
}

export default Accounts;
