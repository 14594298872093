import React, { useEffect, useState } from "react";
import "./ProductMaster.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
function GeneralMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const selectedProductIndex = useSelector(
    (state) => state.userRecord?.selectedProductIndex
  );

  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  const [formDataProduct, setFormDataProduct] = useState({
    ProductCategory: "Assets",
    ProductType: "",
    ProductQuantity: "",
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    ItemRateWithouttax: "",
    Status: "Active",
    createdby: userRecord?.username,
    Location: userRecord?.location,
  });

  console.log("qa", userRecord);

  useEffect(() => {
    setFormDataProduct((prevData) => ({
      ...prevData,
      ProductType: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    }));
    setIsInactive(false);
  }, [formDataProduct.ProductCategory]);

  useEffect(() => {
    if (selectedProductIndex && selectedProductIndex !== null) {
      if (selectedProductIndex.Productype === "Assets") {
        axios
          .get(
            "http://127.0.0.1:8000/SupplierMaster/get_Assets_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (selectedProductIndex.Productype === "Stationary") {
        axios
          .get(
            "http://127.0.0.1:8000/SupplierMaster/get_Stationary_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (selectedProductIndex.Productype === "Non_Stationary") {
        axios
          .get(
            "http://127.0.0.1:8000/SupplierMaster/get_NONStationary_ProductMaster_data"
          )
          .then((response) => {
            // Handle the successful response here
            console.log(response.data);
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    } else {
      if (formDataProduct.ProductCategory === "Assets") {
        axios
          .get("http://127.0.0.1:8000/SupplierMaster/get_Assets_Product_code")
          .then((response) => {
            console.log("INV:", response.data.nextAssetsProductCode);
            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextAssetsProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (formDataProduct.ProductCategory === "Stationary") {
        axios
          .get(
            "http://127.0.0.1:8000/SupplierMaster/get_Stationary_Product_code"
          )
          .then((response) => {
            console.log("INV:", response.data.nextStationaryProductCode);

            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextStationaryProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (formDataProduct.ProductCategory === "Non_Stationary") {
        axios
          .get(
            "http://127.0.0.1:8000/SupplierMaster/get_NONStationary_Product_code"
          )
          .then((response) => {
            console.log("INV:", response.data.nextNonStationaryProductCode);

            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextNonStationaryProductCode,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [selectedProductIndex, formDataProduct.ProductCategory]);

  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveOrUpdate = () => {
    const newData = { ...formDataProduct };
    let requiredFields;
    if (newData.ProductCategory === "Assets") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    } else if (newData.ProductCategory === "Stationary") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    } else if (newData.ProductCategory === "Non_Stationary") {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    }

    const missingFields = requiredFields.filter(
      (field) => !formDataProduct[field]
    );

    if (missingFields.length === 0) {
      if (selectedProductIndex) {
        axios
          .post(
            `http://127.0.0.1:8000/SupplierMaster/Update_productMaster_All`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            dispatchvalue({ type: "selectedProductIndex", value: null });

            // After successfully updating data, navigate to General_MasterList
            navigate("/Home/General_MasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      } else {
        if (formDataProduct.ProductCategory === "Assets") {
          axios
            .post(
              "http://127.0.0.1:8000/SupplierMaster/insert_Assets_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } else if (formDataProduct.ProductCategory === "Stationary") {
          axios
            .post(
              "http://127.0.0.1:8000/SupplierMaster/insert_Stationary_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        } else if (formDataProduct.ProductCategory === "Non_Stationary") {
          axios
            .post(
              "http://127.0.0.1:8000/SupplierMaster/insert_NONStationary_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/General_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        }
      }
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const clearFormInputs = () => {
    setFormDataProduct({
      ProductCategory: "Medical",
      ProductType: "",
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    });
    setIsInactive(false);
  };
  return (
    <div className="Stoct_Product_master">
      <div className="Stoct_Product_master_head">
        <h3 className="Stoct_Product_master_head_h3">General Master</h3>
      </div>
      <div className="Stoct_Product_master_Container">
        <div className="Stoct_Product_master_form">
          <div className="Stoct_Product_master_form_row">
            <div className="Stoct_Product_master_form_row_div">
              <label
                className="Stoct_Product_master_form_Label"
                htmlFor="ProductType"
              >
                Product Category :
              </label>
              <select
                value={formDataProduct.ProductCategory}
                name="ProductCategory"
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
                disabled={selectedProductIndex}
              >
                <option value="Assets">Assets</option>
                <option value="Stationary">Stationary</option>
                <option value="Non_Stationary">Non Stationary</option>
              </select>
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">
                Item Code :
              </label>
              <input
                type="text"
                name="ItemCode"
                placeholder="Enter Item Code"
                value={formDataProduct.ItemCode}
                required
                readOnly
                className="Stoct_Product_master_form_Input"
              />
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label
                className="Stoct_Product_master_form_Label"
                htmlFor="itemName"
              >
                Item Name :
              </label>
              <input
                type="text"
                name="ItemName"
                placeholder="Enter Item Name"
                value={formDataProduct.ItemName}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
                className="Stoct_Product_master_form_Input"
              />
            </div>

            {formDataProduct.ProductCategory === "Medical" && (
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="generic"
                >
                  Generic Name:
                </label>
                <input
                  type="text"
                  name="GenericName"
                  placeholder="Enter Generic Name"
                  value={formDataProduct.GenericName}
                  onChange={handleInputChange}
                  // readOnly={selectedProductIndex}
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            )}

            {formDataProduct.ProductCategory !== "Medical" &&
              formDataProduct.ProductCategory !== "Assets" && (
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="uom"
                  >
                    Product Type:
                  </label>
                  <input
                    type="text"
                    name="ProductType"
                    placeholder="Enter Product Type"
                    value={formDataProduct.ProductType}
                    onChange={handleInputChange}
                    // readOnly={selectedProductIndex}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              )}

            {formDataProduct.ProductCategory !== "Medical" &&
              formDataProduct.ProductCategory === "Assets" && (
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="uom"
                  >
                    Product Type:
                  </label>
                  {/* <input
                                type="text"
                                name="ProductType"
                                placeholder="Enter Product Type"
                                value={formDataProduct.ProductType}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            /> */}

                  <select
                    value={formDataProduct.ProductType}
                    name="ProductType"
                    onChange={handleInputChange}
                  >
                    <option value="">-Select-</option>
                    <option value="Movable_Assets">Movable Assets</option>
                    <option value="Non_Movable_Assets">
                      Non Movable Assets
                    </option>
                  </select>
                </div>
              )}
          </div>
          <div className="Stoct_Product_master_form_row">
            {formDataProduct.ProductCategory === "Medical" && (
              <>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="strength"
                  >
                    Strength:
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    placeholder="Enter Strength"
                    value={formDataProduct.Strength}
                    onChange={handleInputChange}
                    // readOnly={selectedProductIndex}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="strength"
                  >
                    UOM:
                  </label>
                  <input
                    type="text"
                    name="UOM"
                    placeholder="Enter UOM"
                    value={formDataProduct.UOM}
                    onChange={handleInputChange}
                    // readOnly={selectedProductIndex}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </>
            )}

            {formDataProduct.ProductCategory === "Medical" && (
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="uom"
                >
                  Product Type:
                </label>
                <input
                  type="text"
                  name="ProductType"
                  placeholder="Enter Product Type"
                  value={formDataProduct.ProductType}
                  onChange={handleInputChange}
                  // readOnly={selectedProductIndex}
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            )}

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="uom">
                Product Quantity:
              </label>
              <input
                type="number"
                name="ProductQuantity"
                placeholder="Enter Product Quantity"
                value={formDataProduct.ProductQuantity}
                onChange={handleInputChange}
                // readOnly={selectedProductIndex}
                className="Stoct_Product_master_form_Input"
              />
            </div>

            {formDataProduct.ProductCategory !== "Medical" && (
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="inactive"
                >
                  Inactive:
                  <input
                    type="checkbox"
                    name="inactive"
                    checked={isInactive}
                    onChange={handleInactiveChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </label>
              </div>
            )}
          </div>

          {formDataProduct.ProductCategory === "Medical" && (
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="uom"
                >
                  Selling Rate Without Tax(Per Nos):
                </label>
                <input
                  type="number"
                  name="ItemRateWithouttax"
                  placeholder="Enter Rate"
                  value={formDataProduct.ItemRateWithouttax}
                  onChange={handleInputChange}
                  // readOnly={selectedProductIndex}
                  className="Stoct_Product_master_form_Input"
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="inactive"
                >
                  Inactive:
                  <input
                    type="checkbox"
                    name="inactive"
                    checked={isInactive}
                    onChange={handleInactiveChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </label>
              </div>
            </div>
          )}

          <div className="Stoct_Product_master_form_Save">
            <button
              className=" Stoct_Product_master_form_Save_button"
              onClick={handleSaveOrUpdate}
            >
              {selectedProductIndex ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GeneralMaster;
