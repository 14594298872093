import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';




function AccountGroupMaster() {

  const navigate = useNavigate();


  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const Editdata = useSelector(state=>state.FinanceStore?.GroupEditdata)

  

  const [formState, setFormState] = useState({
    id: "",
    AliasName: "",
    GroupName: "",
    UnderGroup: "",
    NatureOfGroup:'',
    PrimaryKey: "Yes",
    Location: userRecord.location,
    Status: "Active",
  });
  console.log("formState :", formState);
 
  const [datalistundergroup, setdatalistundergroup] = useState([]);

  
  
  
  
  
  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/FinanceMagement/get_undergroup?primarygroup=${formState.PrimaryKey}&name=${formState.GroupName}`
      )
      .then((response) => {
        console.log(response.data);
        setdatalistundergroup(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formState.PrimaryKey]);

  
  useEffect(()=>{

    console.log('Editdata',Editdata)

    if(Object.keys(Editdata).length !== 0){
      setFormState({
        AliasName:Editdata.AliasName,
        GroupName:Editdata.GroupName,
        UnderGroup:Editdata.UnderGroup,
        NatureOfGroup:Editdata.NatureOfGroup,
        PrimaryKey:Editdata.PrimaryKey,
        Location:Editdata.Location,
        Status: Editdata.Status,
        S_No:Editdata.S_No
      })

    }

  },[Editdata])


  useEffect(()=>{

    if(formState.PrimaryKey === 'No' && formState.UnderGroup !==''){

      let NOGrup=0

    }

  },[formState.PrimaryKey,formState.UnderGroup])


 


  const handlecleardata = () => {
    setFormState({
      id: "",
      AliasName: "",
      GroupName: "",
      UnderGroup: "",
      NatureOfGroup:'',
      PrimaryKey: "Yes",
      Location: userRecord.location,
      Status: "Active",
    });
  };

  const add_data = () => {
    console.log(formState);

    const requiredfields = [
      "GroupName",
      "AliasName",
      "PrimaryKey",
      "Status",
    ];
    if(formState.PrimaryKey==='No'){
        requiredfields.push('UnderGroup')
    }else{
        requiredfields.push('NatureOfGroup')
    }
    const existing = requiredfields.filter((field) => !formState[field]);
    if (existing.length === 0) {
      axios
        .post(
          `http://127.0.0.1:8000/FinanceMagement/insert_account_group_master`,
          formState
        )
        .then((response) => {
          console.log(response.data);
          // const data = response.data;
          handlecleardata();
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

  
  const update_data = () => {
    console.log(formState);
    const requiredfields = [
      "GroupName",
      "AliasName",
      "PrimaryKey",
      "Status",
    ];
    if (formState.PrimaryKey === "Yes") {
      requiredfields.push("UnderGroup")
    }else{
      requiredfields.push('NatureOfGroup')
    }
    const existing = requiredfields.filter((field) => !formState[field]);
    if (existing.length === 0) {
      axios
        .post(
          `http://127.0.0.1:8000/FinanceMagement/Update_Accountmaster_group`,
          formState
        )
        .then((response) => {
          console.log(response.data);
          // const data = response.data;
          handlecleardata();
          navigate('/Home/AccountGroupList')

        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      alert(`please fill the required fields ${existing.join(",")}`);
    }
  };

 
 

  return (
    <div className="new-patient-registration-form">
      <div className="user_patienthead">
        <h3>Account Group Master</h3>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Name <span>:</span>
          </label>
          <input
            type="text"
            name="GroupName"
            placeholder="Enter Group Name"
            required
            value={formState.GroupName}
            onChange={(e) =>
              setFormState({ ...formState, GroupName: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Alias Name <span>:</span>
          </label>
          <input
            type="text"
            name="AliasName"
            placeholder="Enter Alias Name"
            required
            value={formState.AliasName}
            onChange={(e) =>
              setFormState({ ...formState, AliasName: e.target.value })
            }
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Primary Group <span>:</span>
          </label>
          <select
            onChange={(e) =>
              setFormState({ ...formState, PrimaryKey: e.target.value })
            }
            value={formState.PrimaryKey}
          >
            <option value="Yes">Yes</option>
            <option value="No">No</option>
          </select>
        </div>
      </div>

      <div className="new-patient-info-container ">
   
      

         <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            UnderGroup <span>:</span>
          </label>
           {formState.PrimaryKey === "Yes" ?<input readOnly/>:
            
            <select
              name="UnderGroup"
              required
              disabled={formState.PrimaryKey === "Yes"}
              value={formState.UnderGroup}
              onChange={(e) =>
                setFormState({ ...formState, UnderGroup: e.target.value })
              }
            >
              <option value=""> Select </option>
              {datalistundergroup.map((option, index) => (
                <option key={index}>{option.Primary_Group}</option>
              ))}
            </select>}
         
        </div> 

        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
          Nature Of Group<span>:</span>
          </label>
            <select
              name="NatureOfGroup"
              required
              value={formState.NatureOfGroup}
              onChange={(e) =>
                setFormState({ ...formState, NatureOfGroup: e.target.value })
              }
            >
              <option value=""> Select </option>
              <option value="Assets">Assets</option>
              <option value="Expenses">Expenses</option>
              <option value="Income">Income</option>
              <option value="Liabilities">Liabilities</option>
            </select>
          
        </div>




        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Location <span>:</span>
          </label>
          <input
            type="text"
            name="ifscCode"
            placeholder="Enter IFSC Code"
            required
            readOnly
            value={formState.Location}
            onChange={(e) => setFormState({ ...formState, ifscCode: e.target.value })}
          />
        </div>

      </div>

      <div className="new-patient-info-container ">

      <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            status <span>:</span>
          </label>
          <select
            onChange={(e) =>
              setFormState({ ...formState, Status: e.target.value })
            }
            value={formState.Status}
          >
            <option value="Active">Active</option>
            <option value="InActive">InActive</option>
          </select>
      </div>
      </div>


      

      <div className="new-button-container">
        <button
          className="btncon_add prs-ad-sub-btn"
          type="button"
          onClick={Object.keys(Editdata).length !== 0 ? update_data : add_data}
        >
          {Object.keys(Editdata).length !== 0 ? "Update" : "Submit"}
        </button>
      </div>
    </div>
  );
}

export default AccountGroupMaster;
