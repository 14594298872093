import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useDispatch, useSelector } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';



const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
export default function IpRegistrationlist( ) {

        const dispatchvalue = useDispatch();

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

    const userRecord = useSelector(state=>state.userRecord?.UserData);




    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([])
    const [selectedShow, setSelectedShow] = useState(false);

    console.log('vcvc',summa)

    useEffect(() => {
        axios.get('http://127.0.0.1:8000/ipregistration/get_All_registration_data')
            .then((response) => {
                const data = response.data;
                console.log('222',data);
                   setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
            })
            .catch((error) => {
                console.error('Error fetching doctor names:', error);
            });
    }, []);
 

    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const index = params.row
        console.log('index:',index)
        dispatchvalue({type:'IpAdmissionEdit',value:index.Booking_Id})
        navigate('/Home/IP-Registration1')
        
        
    };
    const handleRequestMove=(params)=>{
        const index = params.row
        console.log('index:',index)
    }

   

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        console.log('ss',summa)

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.AttenderName.toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) 
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery,summa]);



   

    
    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'Booking_Id', headerName: 'Booking_Id', width: 200 },
        { field: 'AdmissionPurpose', headerName: 'Admission Purpose date', width: 200 },
        { field: 'PatientId', headerName: 'Patient Id', width: 170 },
        { field: 'PatientName', headerName: 'Patient Name', width: 170 },
        { field: 'PatientPhoneNo', headerName: 'Patient PhoneNo', width: 120 },
        { field: 'PrimaryDoctor', headerName: 'Primary Doctor', width: 120 },
        { field: 'Admitdate', headerName: 'Admit date', width: 120 },
        { field: 'AttenderName', headerName: 'Attender Name', width: 120 },
        { field: 'AttenderMobileNo', headerName: 'Attender MobileNo', width: 120 },
        { field: 'PrimaryDisease', headerName: 'Primary Disease', width: 120 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <EditIcon />
                    </Button>
                    <Button className='cell_btn' onClick={() => handleRequestMove(params)}>
                        <ArrowForwardIcon />
                    </Button>
                    
                </>
            ),
        },
        
    ];

  



    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="Product_Master_h_head">
                    <h3>IP Registration List</h3>
                   

                 </div>
                    <div className="Supplier_Master_con_1 ">

                        <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Name :</label>
                            <input type="number" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Supplier Code' />
                        </div>
                        
                      
                    </div>

                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
            {selectedShow && (
          <div
            className={
              isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
            } onClick={() => {
              setSelectedShow(false);
            }}

          >
            <div className="newwProfiles" style={{padding:'10px',boxSizing:'border-box'}} onClick={(e) => e.stopPropagation()}>
              <div className="appointment">
              <div className="calendar_head">
                  <h3>List</h3>
                </div>
                <div className="con_1 ">

                  

                
                </div>
               
                <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                  <button
                    className="closeicon-cs"
                    onClick={() => {
                      setSelectedShow(false);
                    }}

                  >
                    close
                  </button>
                </div>
              </div>


            </div>
          </div>
        )}
                </div>
            </div>
        </>
    );
}



