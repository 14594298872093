import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import "../../TreatmentRecords/Navigation.css";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import IpNurseVitals from "./IpNurseVitals";
import { useNavigate } from "react-router-dom";
import IpIntakeOutput from "./IpIntakeOutput";
import IpDrugAdminister from "./IpDrugAdminister";
import LabReport from "../../TreatmentRecords/LabReport";
import IpLabTest from "./IpLabTest";
import IpLeninChange from "./IpLeninChange";
import TheatreBooking from "../Operationtheatre/TheatreBooking";
import IpWardChange from "./IpWardChange";
import MedicalHistoryForm2 from "./MedicalHistoryForm2";
import MedicalHistoryForm from "./MedicalHistoryForm";


function IpNurseWorkbench() {
    const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

    const dispatchvalue = useDispatch();

    const userRecord = useSelector((state) => state.userRecord?.UserData);

    const IpNurseQueSelectedRow = useSelector((state) => state.InPatients?.IpNurseQueSelectedRow);
    console.log('natha', IpNurseQueSelectedRow);

    const [activeTab, setActiveTab] = useState("vitalForm");
    const [isToggled, setIsToggled] = useState(false);
    const navigate = useNavigate()



    const toggle = () => setIsToggled(!isToggled);

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        closeToggle();
    };

    const closeToggle = () => {
        setIsToggled(false);
    };



    useEffect(() => {
        if (Object.keys(IpNurseQueSelectedRow).length === 0) {
            navigate('/Home/IpNurseQuelist')
        }
    }, [IpNurseQueSelectedRow]);

    return (
        <>




            <div className="new-patient-registration-form">

                <div className="Total_input_container_patientData">
                    <div className="inp_container_all_patientData">
                        <label>Name / Id :</label>
                        <span >{`${IpNurseQueSelectedRow?.PatientName} / ${IpNurseQueSelectedRow?.PatientId}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>BedNo / MRN :</label>
                        <span >{`${IpNurseQueSelectedRow?.BedNo} / ${IpNurseQueSelectedRow?.Booking_Id}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>POA :</label>
                        <span >{`${IpNurseQueSelectedRow?.AdmissionPurpose}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>DOA :</label>
                        <span >{`${IpNurseQueSelectedRow?.Admitdate}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Physician :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDoctor}`} </span >
                    </div>
                    <div className="inp_container_all_patientData">
                        <label>Primary Symptoms :</label>
                        <span >{`${IpNurseQueSelectedRow?.PrimaryDisease}`} </span >
                    </div>
                </div>

                <br />
                <div className="new-patient-registration-form1">
                    <div className="new-navigation">
                        <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'MedicalHistory' || activeTab === 'SurgicalHistory' ? "white" : '' }}>Medical Records</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                                        Medical History
                                    </button>
                                    <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                        Surgical History
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                        Lenin Change
                                    </button>
                                    <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                        OT Booking
                                    </button>
                                    <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                        Ward/Bed Change
                                    </button>
                                    {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>

                        </h2>
                    </div>

                    <div className="new-kit ">
                        <button className="new-tog" onClick={toggle}>
                            {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
                        </button>

                        <div>
                            {isToggled && (
                                <div className="new-navigation-toggle">
                                    <h2>
                            <button onClick={() => handleTabChange("vitalForm")} style={{ color: activeTab === 'vitalForm' ? "white" : '' }}>
                                Vital Form
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'MedicalHistory' || activeTab === 'SurgicalHistory' ? "white" : '' }}>Medical Records</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("MedicalHistory")} style={{ color: activeTab === 'MedicalHistory' ? "white" : '' }}>
                                        Medical History
                                    </button>
                                    <button onClick={() => handleTabChange("SurgicalHistory")} style={{ color: activeTab === 'SurgicalHistory' ? "white" : '' }}>
                                        Surgical History
                                    </button>
                                </div>
                            </div>
                            |
                            <button onClick={() => handleTabChange("Intake/OutPut")} style={{ color: activeTab === 'Intake/OutPut' ? "white" : '' }}>
                                Intake/OutPut
                            </button>
                            |
                            <button onClick={() => handleTabChange("DrugAdministration")} style={{ color: activeTab === 'DrugAdministration' ? "white" : '' }}>
                                Drug Administration
                            </button>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab.includes("Lenin", "OT_Booking", "Ward/Bed", "Nutrition", "CSSD") ? "white" : '' }}>Request</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lenin")} style={{ color: activeTab === 'Lenin' ? "white" : '' }}>
                                        Lenin Change
                                    </button>
                                    <button onClick={() => handleTabChange("OT_Booking")} style={{ color: activeTab === 'OT_Booking' ? "white" : '' }}>
                                        OT Booking
                                    </button>
                                    <button onClick={() => handleTabChange("Ward/Bed")} style={{ color: activeTab === 'Ward/Bed' ? "white" : '' }}>
                                        Ward/Bed Change
                                    </button>
                                    {/* <button onClick={() => handleTabChange("Nutrition")} style={{ color: activeTab === 'Nutrition' ? "white" : '' }}>
                                        Nutrition
                                    </button>
                                    <button onClick={() => handleTabChange("CSSD")} style={{ color: activeTab === 'CSSD' ? "white" : '' }}>
                                        Centralized Sterile Supply Departments
                                    </button> */}
                                </div>
                            </div>
                            |
                            <div class="Lab_dropdown">
                                <button class="Lab_button" style={{ color: activeTab === 'Lab' || activeTab === 'LabReport' ? "white" : '' }}>Lab</button>
                                <div class="Lab_dropdown_content">
                                    <button onClick={() => handleTabChange("Lab")} style={{ color: activeTab === 'Lab' ? "white" : '' }}>
                                        Lab Test
                                    </button>
                                    <button onClick={() => handleTabChange("LabReport")} style={{ color: activeTab === 'LabReport' ? "white" : '' }}>
                                        Lab Test Preview
                                    </button>
                                </div>
                            </div>

                        </h2>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {activeTab === "vitalForm" && <IpNurseVitals />}
            {activeTab === "MedicalHistory" && <MedicalHistoryForm/>}
            {activeTab === "SurgicalHistory" && <MedicalHistoryForm2/>}
            {activeTab === "Intake/OutPut" && <IpIntakeOutput/>}
            {activeTab === "DrugAdministration" && <IpDrugAdminister/>}
            {activeTab === "Lab" && <IpLabTest/>}
            {activeTab === "LabReport" && <LabReport/>}
            {activeTab === "Lenin" && <IpLeninChange/>}
            {activeTab === "OT_Booking" && <TheatreBooking/>}
            {activeTab === "Ward/Bed" && <IpWardChange/>}
        </>
    );
}

export default IpNurseWorkbench;
