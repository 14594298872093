import React, { useEffect, useState } from "react";
import "../ProductMaster/ProductMaster.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";

function PharmachyMaster() {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);


  const selectedProductIndex = useSelector(
    (state) => state.userRecord?.selectedProductIndex
  );


  console.log(selectedProductIndex);

  const [isInactive, setIsInactive] = useState(false);
  const navigate = useNavigate();
  const [formDataProduct, setFormDataProduct] = useState({
    ProductCategory: "MedicalConsumable",
    ProductType: "",
    ProductQuantity: "",
    ItemCode: "",
    ItemName: "",
    GenericName: "",
    Strength: "",
    UOM: "",
    ItemRateWithouttax: "",
    Status: "Active",
    createdby: userRecord?.username,
    Location: userRecord?.location,
  });


  console.log(formDataProduct);
  useEffect(() => {
    setFormDataProduct((prevData) => ({
      ...prevData,
      ProductType: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    }));
    setIsInactive(false);
  }, [formDataProduct.ProductCategory]);

  useEffect(() => {
    if (selectedProductIndex && selectedProductIndex !== null) {
      // if (
      //   selectedProductIndex.Productype === "MedicalConsumable" ||
      //   selectedProductIndex.Productype === "MedicalNonConsumable"
      // ) {
        axios
          .get(
            `http://127.0.0.1:8000/SupplierMaster/get_Medical_ProductMaster_data?Productype=${selectedProductIndex.Productype}`
          )
          .then((response) => {
            const responsedata = response.data;
            const selectedSupplier = responsedata.find(
              (p) => selectedProductIndex.ItemCode === p.ItemCode
            );
            setFormDataProduct((prev) => ({
              ...prev,
              ProductCategory: selectedProductIndex.Productype,
              ...selectedSupplier,
            }));

            if (selectedSupplier.Status !== "Active") {
              setIsInactive(true);
            } else {
              setIsInactive(false);
            }
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      // } else if (selectedProductIndex.ProductCategory === "Non_Medical") {
      //   axios
      //     .get(
      //       "http://127.0.0.1:8000/SupplierMaster/get_NONMedical_ProductMaster_data"
      //     )
      //     .then((response) => {
      //       const responsedata = response.data;
      //       const selectedSupplier = responsedata.find(
      //         (p) => selectedProductIndex.ItemCode === p.ItemCode
      //       );
      //       setFormDataProduct((prev) => ({
      //         ...prev,
      //         ProductCategory: selectedProductIndex.ProductCategory,
      //         ...selectedSupplier,
      //       }));

      //       if (selectedSupplier.Status !== "Active") {
      //         setIsInactive(true);
      //       } else {
      //         setIsInactive(false);
      //       }
      //     })
      //     .catch((error) => {
      //       console.error("Error fetching data:", error);
      //     });
      // }
    } else {
    
        axios
          .get(
            `http://127.0.0.1:8000/SupplierMaster/get_Medical_Product_code?producttype=${formDataProduct.ProductCategory}`
          )
          .then((response) => {
            setFormDataProduct((prevData) => ({
              ...prevData,
              ItemCode: response.data.nextMedicalProductCode,
            }));
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      
    }
  }, [selectedProductIndex, formDataProduct.ProductCategory]);

  const handleInactiveChange = () => {
    setIsInactive(!isInactive);
    setFormDataProduct((prevData) => ({
      ...prevData,
      Status: !isInactive ? "Inactive" : "Active",
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormDataProduct((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSaveOrUpdate = () => {
    console.log(formDataProduct)
    const newData = { ...formDataProduct };
    let requiredFields;

    if (newData.ProductCategory === "MedicalConsumable" || newData.ProductCategory === "MedicalNonConsumable") {
      requiredFields = [
        "ProductCategory",
        "ItemName",
        "ItemCode",
        "GenericName",
        "Strength",
        "UOM",
        "ItemRateWithouttax",
      ];
    } else if (newData.ProductCategory === "NonMedicalConsumable" || newData.ProductCategory === "NonMedicalNonConsumable" || newData.ProductCategory === 'Surgical' ) {
      requiredFields = [
        "ProductCategory",
        "ProductType",
        "ItemName",
        "ItemCode",
      ];
    
    }

    const missingFields = requiredFields.filter(
      (field) => !formDataProduct[field]
    );

    if (missingFields.length === 0) {
      if (selectedProductIndex) {
        axios
          .post(
            `http://127.0.0.1:8000/SupplierMaster/Update_productMaster_All`,
            newData
          )
          .then((response) => {
            console.log(response.data);
            clearFormInputs();
            dispatchvalue({ type: "selectedProductIndex", value: null });

            // After successfully updating data, navigate to Pharmacy_MasterList
            navigate("/Home/Pharmacy_MasterList");
          })
          .catch((error) => {
            console.error("Error post Supplier data:", error);
          });
      } else {
       
          axios
            .post(
              "http://127.0.0.1:8000/SupplierMaster/insert_Medical_ProductMaster_data",
              newData
            )
            .then((response) => {
              console.log(response.data);
              clearFormInputs();
              navigate("/Home/Pharmacy_MasterList");
            })
            .catch((error) => {
              console.error("Error post Supplier data:", error);
            });
        
      }
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const clearFormInputs = () => {
    setFormDataProduct({
      ProductCategory: "MedicalConsumable",
      ProductType: "",
      ItemCode: "",
      ItemName: "",
      GenericName: "",
      Strength: "",
      UOM: "",
      Status: "Active",
    });
    setIsInactive(false);
  };

  return (
    <div className="Stoct_Product_master">
      <div className="Stoct_Product_master_head">
        <h3 className="Stoct_Product_master_head_h3">Pharmacy Master</h3>
      </div>
      <div className="Stoct_Product_master_Container">
        <div className="Stoct_Product_master_form">
          <div className="Stoct_Product_master_form_row">
            <div className="Stoct_Product_master_form_row_div">
              <label
                className="Stoct_Product_master_form_Label"
                htmlFor="ProductType"
              >
                Product Category :
              </label>
              <select
                value={formDataProduct.ProductCategory}
                name="ProductCategory"
                onChange={handleInputChange}
                disabled={selectedProductIndex}
              >
              <option value="MedicalConsumable">Medical Consumable</option>
              <option value="MedicalNonConsumable">
                Medical Non-Consumable
              </option>
              <option value="NonMedicalConsumable">Non MedicalConsumable</option>
              <option value="NonMedicalNonConsumable">Non MedicalNon-Consumable</option>
              <option value="Surgical">Surgical</option>
              </select>
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">
                Item Code :
              </label>
              <input
                type="text"
                name="ItemCode"
                placeholder="Enter Item Code"
                value={formDataProduct.ItemCode}
                required
                readOnly
                className="Stoct_Product_master_form_Input"
              />
            </div>

            <div className="Stoct_Product_master_form_row_div">
              <label
                className="Stoct_Product_master_form_Label"
                htmlFor="itemName"
              >
                Item Name :
              </label>
              <input
                type="text"
                name="ItemName"
                placeholder="Enter Item Name"
                value={formDataProduct.ItemName}
                onChange={handleInputChange}
                className="Stoct_Product_master_form_Input"
              />
            </div>

            {/* {(formDataProduct.ProductCategory === "MedicalConsumable" ||
              formDataProduct.ProductCategory === "MedicalNonConsumable") && ( */}
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="generic"
                >
                  Generic Name:
                </label>
                <input
                  type="text"
                  name="GenericName"
                  placeholder="Enter Generic Name"
                  value={formDataProduct.GenericName}
                  onChange={handleInputChange}
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            {/* )} */}

            {/* {(formDataProduct.ProductCategory !== "MedicalConsumable" ||
              formDataProduct.ProductCategory !== "MedicalNonConsumable") && ( */}
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="uom"
                >
                  Product Type:
                </label>
                <input
                  type="text"
                  name="ProductType"
                  placeholder="Enter Product Type"
                  value={formDataProduct.ProductType}
                  onChange={handleInputChange}
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            {/* )} */}
          </div>

          <div className="Stoct_Product_master_form_row">
            {(formDataProduct.ProductCategory === "MedicalConsumable" ||
              formDataProduct.ProductCategory === "MedicalNonConsumable") && (
              <>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="strength"
                  >
                    Strength:
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    placeholder="Enter Strength"
                    value={formDataProduct.Strength}
                    onChange={handleInputChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="strength"
                  >
                    UOM:
                  </label>
                  <input
                    type="text"
                    name="UOM"
                    placeholder="Enter UOM"
                    value={formDataProduct.UOM}
                    onChange={handleInputChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </>
            )}

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="uom">
                Product Quantity:
              </label>
              <input
                type="number"
                name="ProductQuantity"
                placeholder="Enter Product Quantity"
                value={formDataProduct.ProductQuantity}
                onChange={handleInputChange}
                className="Stoct_Product_master_form_Input"
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="uom"
                >
                  Selling Rate Without Tax(Per Nos):
                </label>
                <input
                  type="number"
                  name="ItemRateWithouttax"
                  placeholder="Enter Rate"
                  value={formDataProduct.ItemRateWithouttax}
                  onChange={handleInputChange}
                  className="Stoct_Product_master_form_Input"
                />
              </div>

            {/* {formDataProduct.ProductCategory !== "MedicalConsumable" &&
              formDataProduct.ProductCategory !== "MedicalNonConsumable" && ( */}
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="inactive"
                  >
                    Inactive:
                    <input
                      type="checkbox"
                      name="inactive"
                      checked={isInactive}
                      onChange={handleInactiveChange}
                      className="Stoct_Product_master_form_Input"
                    />
                  </label>
                </div>
              {/* )} */}
          </div>

          {/* {(formDataProduct.ProductCategory === "MedicalConsumable" ||
            formDataProduct.ProductCategory === "MedicalNonConsumable") && ( */}
            <div className="Stoct_Product_master_form_row">
             

              {/* <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="inactive"
                >
                  Inactive:
                  <input
                    type="checkbox"
                    name="inactive"
                    checked={isInactive}
                    onChange={handleInactiveChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </label>
              </div> */}
            </div>
          {/* )} */}

          <div className="Stoct_Product_master_form_Save">
            <button
              className=" Stoct_Product_master_form_Save_button"
              onClick={handleSaveOrUpdate}
            >
              {selectedProductIndex ? "Update" : "Save"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PharmachyMaster;

