import React, { useState, useEffect } from 'react';

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';

const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeader: {
                    backgroundColor: "hsl(33, 100%, 50%)",
                },
                root: {
                    "& .MuiDataGrid-window": {
                        overflow: "hidden !important",
                    },
                },
                cell: {
                    borderTop: "0px !important",
                    borderBottom: "1px solid  hsl(33, 100%, 50%) !important",
                },
            },
        },
    },
});


const PermissionStatus = ({isSidebarOpen, userRecord}) => {
  console.log('userdata',userRecord);
 
  
    const [columns] = React.useState([

    { field: "employeeid", headerName: "Employee ID", width: 120 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 100 },
    { field: "PermissionDate", headerName: " Date", width: 100 },
    { field: "fromtime", headerName: "From Time", width: 100 },
    { field: 'totime', headerName: 'To Time', width: 80 },
    { field: 'hours', headerName: 'Hours', width: 80 },
    { field: "reason", headerName: "Reason", width: 150 },
        {
            field: "status",
            headerName: "Status",
            width: 150
        },
        {
            field: "rejectstatus",
            headerName: "Reject Reason",
            width: 150
        },
       
    ]);
    const [rows, setRows] = useState([
       
    ]);

    const showdown = rows.length;
  

    // second datagrid2 for permission approval
  
    useEffect(() => {
        fetchLeaveData();
          }, []);
    
      const fetchLeaveData = () => {
        const employeeid = userRecord.EmployeeId;
        fetch(`http://127.0.0.1:8000/HRmanagement/get_permission_register_recent?EmployeeID=${employeeid}&location=${userRecord.location}`)
          .then((response) => response.json())
          .then((data) => {
           console.log(data);
            if (Array.isArray(data)) {
              const Records = data.map((userdata, index) => ({
                id: index + 1,
                Sl_No: userdata.Sl_No,
                employeeid: userdata.EmployeeID,
                employeename: userdata.EmployeeName,
                leaveType: userdata.LeaveType,
                PermissionDate : userdata.PermissionDate,
                fromtime: userdata.fromtime,
                totime: userdata.totime,
                hours : userdata.hours,
                reason: userdata.Reason,
                status : userdata.status,
                rejectstatus : userdata.rejectstatus
              }));
              setRows(Records);
              console.log('record',Records);
            } else {
              console.error("Data is not an array:", data);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      };
      
    
 
    return (
        <div>
            
                

             
                
            
                  
                        <ThemeProvider theme={theme}>
                            <div className=" grid_1" style={{ overflowX: 'auto', maxWidth: '100%' }}>
                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    pageSize={100}
                                    hideFooter={true}
                                    isCellEditable={(params) => params.row.id !== 1}
                                    onEditCellChange={(params) => {
                                        const updatedRows = rows.map((row) => {
                                            if (row.id === params.id) {
                                                return { ...row, [params.field]: params.props.value };
                                            }
                                            return row;
                                        });
                                        setRows(updatedRows);
                                    }}
                                    className=' data_grid'
                                />
                            </div>
                            
                </ThemeProvider>
                {showdown !== 0 && rows.length !== 0 ? (
          ''
        ) : (
          <div className='IP_norecords'>
            <span>No Records Found</span>
          </div>
        )}
            
        </div>
    );
};

export default PermissionStatus;
