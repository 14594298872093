import * as React from "react";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import "../Billing/OverallBilling/GeneralBilling.css";
import { format } from "date-fns";
import { useSelector } from "react-redux";


const Billcancelation = () => {

  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const [ClinicDetials, setClinicDetials] = useState({
    ClinicLogo: null,
    ClinicName: "",
    ClinicGST: "",
    ClinicAddress: "",
    ClinicCity: "",
    ClinicState: "",
    ClinicCode: "",
    ClinicMobileNo: "",
    ClinicLandLineNo: "",
    ClinicMailID: "",
    InvoiceNo: "",
  });
  const [Dname, setDname] = useState("");
  const [FormData, setFormData] = useState({
    PatientID: "",
    PatientName: "",
    PhoneNumber: "",
    Age: "",
    Gender: "",
    City: "",
    State: "",
    Street: "",
    Pincode: "",
  });
  const [initialState, setinitialState] = useState({
    totalItems: 0,
    totalUnits: 0,
    totalAmount: 0,
    totalRefundAmount: 0,
  });
  const [Billing_date, setBilling_date] = useState("");
  const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);

  const [searchQuery, setSearchQuery] = useState({
    BillType: "",
    SearchID: "",
    SearchInvoiceNo: "",
    SearchBillingDate: "",
  });

  const [data, setData] = useState([])

  const [CancelList, setCancelList] = useState([]);

  const [tableData, setTableData] = useState([]);
  const [TableData1, setTableData1] = useState([]);

  const [NameForCancel, SetNameForCancel] = useState([]);
  const [SelectedNameCancel, setSelectedNameCancel] = useState("");
  const [Reason, setReason] = useState({
    reason: "",
    ApprovedBy: "",
    RefundBilledBy: "",
  });
  const [selectedBillType, setSelectedBillType] = useState("");
  // const [selectDoctorList, setselectDoctorList] = useState([]);

  useEffect(() => {
    setReason((prev) => ({
      ...prev,
      RefundBilledBy: userRecord.username,
    }));
  }, [userRecord.username]);
  // ---
  const numberToWords = (number) => {
    let num = parseInt(number.toString().split(".")[0]);
    if (num === 0) {
      return "Zero Rupees Only";
    }

    const units = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
      "Ten",
    ];
    const teens = [
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    const convert = (num) => {
      if (num <= 10 && num !== 0) return units[num];
      if (num < 20) return teens[num - 11];
      if (num < 100)
        return (
          tens[Math.floor(num / 10)] +
          (num % 10 !== 0 ? " " + units[num % 10] : "")
        );
      if (num < 1000)
        return (
          units[Math.floor(num / 100)] +
          " Hundred" +
          (num % 100 !== 0 ? " and " + convert(num % 100) : "")
        );
      if (num < 100000)
        return (
          convert(Math.floor(num / 1000)) +
          " Thousand" +
          (num % 1000 !== 0 ? " and " + convert(num % 1000) : "")
        );
      if (num < 10000000)
        return (
          convert(Math.floor(num / 100000)) +
          " Lakh" +
          (num % 100000 !== 0 ? " and " + convert(num % 100000) : "")
        );
    };

    return convert(num) + " Rupees  Only";
  };

  // --
  useEffect(() => {
    const currentDate = new Date();
    setBilling_date(format(currentDate, "dd-MM-yyyy"));
    // clinic detials
    axios
      .get(
        `http://127.0.0.1:8000/usercontrol/getClinic?location=${userRecord.location}`
      )
      .then((response) => {
        // console.log(response.data)
        const data = response.data[0];
        console.log(data);
        if (data) {
          setClinicDetials((prev) => ({
            ...prev,
            ClinicAddress: data.door_no + "," + data.area + "," + data.street,
            ClinicGST: data.Gst_no,
            ClinicCity: data.city,
            ClinicState: data.state,
            ClinicCode: data.pincode,
            ClinicMobileNo: data.phone_no,
            ClinicLandLineNo: data.landline_no,
            ClinicMailID: data.email,
          }));
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .catch((error) => {
        console.log(error);
      });
    axios
      .get("http://127.0.0.1:8000/usercontrol/getAccountsetting")
      .then((response) => {
        // console.log(response.data.Data)
        const data = response.data;
        setClinicDetials((prev) => ({
          ...prev,
          ClinicName: data.Clinic_Name,
          ClinicLogo: `data:image/png;base64,${data.Clinic_Logo}`,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    if (selectedBillType === "Procedure") {
      axios
        .get(
          `http://127.0.0.1:8000/Bill_Cancellation/get_Bill_Cancel_Procedure_invoice`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setClinicDetials((prev) => ({
            ...prev,
            InvoiceNo: data.nextInvoiceNumber,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedBillType === "Pharmacy") {
      axios
        .get(
          `http://127.0.0.1:8000/Bill_Cancellation/get_Bill_Cancel_Pharmacy_invoice`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setClinicDetials((prev) => ({
            ...prev,
            InvoiceNo: data.nextInvoiceNumber,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (selectedBillType === "DoctorConsultation") {
      axios
        .get(
          `http://127.0.0.1:8000/Bill_Cancellation/get_Bill_Cancel_Procedure_invoice`
        )
        .then((response) => {
          console.log(response.data);
          const data = response.data;
          setClinicDetials((prev) => ({
            ...prev,
            InvoiceNo: data.nextInvoiceNumber,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [selectedBillType]);

  // ---------------------
  const handlechange = (e) => {
    const { name, value } = e.target;

    setSearchQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSearch = () => {
    SetNameForCancel([]);
    setTableData([]);
    setTableData1([]);
    setSelectedBillType("");
    setReason((prev) => ({
      ...prev,
      reason: "",
      ApprovedBy: "",
    }));
    const newSearchQuery = {
      bill_type: searchQuery.BillType,
      patient_id: searchQuery.SearchID ? +searchQuery.SearchID : "Null",
      phone_no: searchQuery.SearchPhoneNo ? +searchQuery.SearchPhoneNo : "Null",
      billing_invoice: searchQuery.SearchInvoiceNo
        ? searchQuery.SearchInvoiceNo
        : "Null",
      bill_date: searchQuery.SearchBillingDate
        ? searchQuery.SearchBillingDate
        : "Null",
    };
    console.table(newSearchQuery);
    if (
      (newSearchQuery.bill_type !== "Null" &&
        newSearchQuery.billing_invoice !== "Null") ||
      (newSearchQuery.bill_type !== "Null" &&
        newSearchQuery.patient_id !== "Null" &&
        newSearchQuery.bill_date !== "Null")
    ) {
      if (
        newSearchQuery.bill_type !== "Null" &&
        newSearchQuery.billing_invoice !== "Null"
      ) {
        axios
          .get(
            `http://127.0.0.1:8000/GeneralBilling/get_All_billing_data?bill_type=${newSearchQuery.bill_type}&invoice_no=${newSearchQuery.billing_invoice}&location=${userRecord.location}`
          )
          .then((response) => {
            const data = response.data.data;
            const visits = {
              VisitID: data[0].visit_id,
              PatientID: data[0].patient_id,
            };
            console.log(data);

            if (data[0].message === "Data Already Exist") {
              SetNameForCancel([]);
              setTableData([]);
              setTableData1([]);
              setCancelList([]);
              setSelectedBillType("");
              setSearchQuery({
                BillType: "",
                SearchID: "",
                SearchInvoiceNo: "",
                SearchBillingDate: "",
              });
              alert("Bill cancelled Already");
            } else {
              if (data[0].ServiceType === "DoctorConsultation") {
                SetNameForCancel(data);
                setSelectedBillType("DoctorConsultation");
              } else if (data[0].ServiceType === "Procedure") {
                setSelectedBillType("Procedure");
                axios
                  .get(
                    `http://127.0.0.1:8000/doctorsworkbench/get_doctorname?patientid=${+visits.PatientID}&visitid=${+visits.VisitID}&location=${userRecord?.location}`
                  )
                  .then((response) => {
                    console.log('response',response)
                    const neww = response.data[0].DoctorName;
                    console.log(neww);
                    const datafor = data.map((p) => ({
                      ...p,
                      TherapistName: p.DoctorName,
                      DoctorName: neww,
                    }));
                    console.log(datafor);
                    SetNameForCancel(datafor);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              } else if (data[0].ServiceType === "Pharmacy") {
                setSelectedBillType("Pharmacy");
                let Discount_Amount_Source = data[0].Discount_Amount_Source;
                if (Discount_Amount_Source === "PharmacyBilling_Items_table") {
                  const newData = data.map((p) => {
                    const discountedAmount =
                      p.discount_type === "Cash"
                        ? (p.BillingAmount * p.Unit - p.discount_amount) /
                          p.Unit
                        : (p.BillingAmount * p.Unit -
                            (p.BillingAmount * p.Unit * p.discount_amount) /
                              100) /
                          p.Unit;
                    console.log(
                      (p.BillingAmount * p.Unit * p.discount_amount) / 100
                    );
                    return {
                      ...p,
                      BillingAmount: discountedAmount,
                    };
                  });
                  console.log(newData);
                  SetNameForCancel(newData);
                } else {
                  const totalAmount = data.reduce((acc, element) => {
                    return acc + element.BillingAmount * element.Unit;
                  }, 0);
                  const DiscountValue =
                    data[0].discount_type === "Cash"
                      ? totalAmount - data[0].discount_amount
                      : totalAmount -
                        (totalAmount * data[0].discount_amount) / 100;
                  const discountvaluePercentage = (
                    DiscountValue / totalAmount
                  ).toFixed(3);

                  const newData = data.map((p) => {
                    const discountedAmount =
                      Math.ceil(p.BillingAmount * discountvaluePercentage) /
                      p.Unit;
                    console.log(
                      Math.ceil(p.BillingAmount * discountvaluePercentage)
                    );

                    return {
                      ...p,
                      BillingAmount: discountedAmount,
                    };
                  });

                  SetNameForCancel(newData);
                }
              }
            }

            setCancelList([]);
          })
          .catch((error) => {
            console.log(error);
          });
      } else if (
        newSearchQuery.bill_type !== "Null" &&
        newSearchQuery.patient_id !== "Null" &&
        newSearchQuery.bill_date !== "Null"
      ) {
        axios
          .get(
            `http://127.0.0.1:8000/GeneralBilling/get_All_billing_invoice_data?bill_type=${newSearchQuery.bill_type}&patient_id=${newSearchQuery.patient_id}&bill_date=${newSearchQuery.bill_date}&location=${userRecord.location}`
          )
          .then((response) => {
            const data = response.data.data;
            console.log(data);
            setCancelList(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      alert("please Enter the fields");
    }
  };
  const handleSearch_1 = (row) => {
    SetNameForCancel([]);
    setTableData([]);
    setTableData1([]);
    setSelectedBillType("");
    setReason((prev) => ({
      ...prev,
      reason: "",
      ApprovedBy: "",
    }));
    // Your logic for handling the search or edit operation goes here
    console.log("Edit button clicked with row:", row);
    axios
      .get(
        `http://127.0.0.1:8000/GeneralBilling/get_All_billing_data?bill_type=${row.Service_Type}&invoice_no=${row.Invoice_NO}&location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data.data;
        console.log(data);
        const visits = {
          VisitID: data[0].visit_id,
          PatientID: data[0].patient_id,
        };
        if (data[0].message === "Data Already Exist") {
          SetNameForCancel([]);
          setTableData([]);
          setTableData1([]);
          setCancelList([]);
          setSelectedBillType("");
          setSearchQuery({
            BillType: "",
            SearchID: "",
            SearchInvoiceNo: "",
            SearchBillingDate: "",
          });

          alert("Bill cancelled Already");
        } else {
          if (data[0].ServiceType === "DoctorConsultation") {
            SetNameForCancel(data);
            setSelectedBillType("DoctorConsultation");
          } else if (data[0].ServiceType === "Procedure") {
            setSelectedBillType("Procedure");
            axios
              .get(
                `http://127.0.0.1:8000/doctorsworkbench/get_doctorname?patientid=${+visits.PatientID}&visitid=${+visits.VisitID}`
              )
              .then((response) => {
                const neww = response.data[0].DoctorName;
                console.log(neww);
                const datafor = data.map((p) => ({
                  ...p,
                  TherapistName: p.DoctorName,
                  DoctorName: neww,
                }));
                console.log(datafor);
                SetNameForCancel(datafor);
              })
              .catch((error) => {
                console.log(error);
              });
          } else if (data[0].ServiceType === "Pharmacy") {
            setSelectedBillType("Pharmacy");
            let Discount_Amount_Source = data[0].Discount_Amount_Source;
            if (Discount_Amount_Source === "PharmacyBilling_Items_table") {
              // console.log('hii')
              const newData = data.map((p) => {
                const discountedAmount =
                  p.discount_type === "Cash"
                    ? (p.BillingAmount * p.Unit - p.discount_amount) / p.Unit
                    : (p.BillingAmount * p.Unit -
                        (p.BillingAmount * p.Unit * p.discount_amount) / 100) /
                      p.Unit;
                console.log(
                  (p.BillingAmount * p.Unit * p.discount_amount) / 100
                );
                return {
                  ...p,
                  BillingAmount: discountedAmount,
                };
              });
              SetNameForCancel(newData);
            } else {
              const totalAmount = data.reduce((acc, element) => {
                return acc + element.BillingAmount * element.Unit;
              }, 0);
              const DiscountValue =
                data[0].discount_type === "Cash"
                  ? totalAmount - data[0].discount_amount
                  : totalAmount - (totalAmount * data[0].discount_amount) / 100;
              const discountvaluePercentage = (
                DiscountValue / totalAmount
              ).toFixed(3);

              const newData = data.map((p) => {
                const discountedAmount =
                  Math.ceil(p.BillingAmount * discountvaluePercentage) / p.Unit;
                console.log(
                  Math.ceil(p.BillingAmount * discountvaluePercentage)
                );

                return {
                  ...p,
                  BillingAmount: discountedAmount,
                };
              });

              SetNameForCancel(newData);
            }
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Function to handle checkbox click
  const handleCheckboxClick = (e, index) => {
    const value = e.target.checked;

    setTableData((prevData) => {
      const newData = [...prevData];
      newData[index].checkboxChecked = value;

      // Check if all checkboxes are checked

      // const allChecked = newData.every((row) => row.checkboxChecked);
      // setSelectAll(allChecked);

      return newData;
    });
  };
  // Function to handle "Select All" checkbox click
  // const handleSelectAllClick = () => {
  //   setSelectAll((prevSelectAll) => !prevSelectAll);
  //   setTableData((prevData) =>
  //     prevData.map((row) => ({
  //       ...row,
  //       checkboxChecked: !selectAll,
  //     }))
  //   );
  // };
  // Call the function to generate initial table data
  useEffect(() => {
    // Assuming SetNameForCancel is an array of objects with a "ServiceName" property
    SetNameForCancel((prevState) =>
      prevState.filter((p) =>
        TableData1.every((row) => row.ServiceName !== p.ServiceName)
      )
    );
  }, [TableData1]);

  const handleClickCancel = () => {
    if (SelectedNameCancel !== "") {
      const selected = NameForCancel.find(
        (p) => p.ServiceName === SelectedNameCancel
      );
      const data = [];

      for (let index = 0; index < selected.sessionCount; index++) {
        let procedureStatus = "";
        let billingStatus = "";

        const paid_not_canceled =
          selected.paidSessionCount -
          (selected.procedureCount + selected.Paid_ProcedureCancellation);
        const non_paid_sessionCount =
          selected.sessionCount - selected.paidSessionCount;
        const nonpaid_notcancel =
          non_paid_sessionCount - selected.NonPaid_ProcedureCancellation;
        console.log(
          paid_not_canceled,
          non_paid_sessionCount,
          nonpaid_notcancel
        );

        if (index < selected.procedureCount) {
          procedureStatus = "completed";
        } else if (
          index <
          selected.procedureCount + selected.Paid_ProcedureCancellation
        ) {
          procedureStatus = "paidcancelled";
        } else if (
          index <
          selected.procedureCount +
            selected.Paid_ProcedureCancellation +
            paid_not_canceled
        ) {
          procedureStatus = "Not Completed";
        } else if (
          index <
          selected.procedureCount +
            selected.Paid_ProcedureCancellation +
            paid_not_canceled +
            selected.NonPaid_ProcedureCancellation
        ) {
          procedureStatus = "non-paidcancelled";
        } else if (
          index <
          selected.procedureCount +
            selected.Paid_ProcedureCancellation +
            paid_not_canceled +
            selected.NonPaid_ProcedureCancellation +
            nonpaid_notcancel
        ) {
          procedureStatus = "Not Completed";
        } else {
          procedureStatus = "Not Completed";
        }

        billingStatus = index < selected.paidSessionCount ? "paid" : "Not paid";

        // Add the current status information to the data array
        data.push({
          ServiceType: selected.ServiceType,
          ServiceName: selected.ServiceName,
          PatientName: selected.PatientName,
          billingStatus,
          BillingAmount: selected.BillingAmount,
          procedureStatus,
          action: "No Action",
          checkboxChecked: index < selected.procedureCount ? true : false,
          VisitID: selected.visit_id,
          PatientID: selected.patient_id,
          billing_Invoice: selected.invoice_no,
          TherapistName: selected.TherapistName,
          DoctorName: selected.DoctorName,
        });
      }

      // Set the data after the loop is complete
      console.log(data);
      setTableData(data);
      setSelectedNameCancel("");
    } else {
      alert("Please Enter the Search Items");
    }
  };

  const handleCancelSave = () => {
    if (selectedBillType === "Procedure") {
      const savetrue = window.confirm("Are you sure you want to save?");
      if (savetrue) {
        if (tableData.length === 0) {
          alert("No data to save. Please enter the search items.");
          return;
        }

        const firstRow = tableData[0];
        const sessionCount = tableData.length;
        const BillingAmount = firstRow.BillingAmount || 0;
        const paidSessionsCount = tableData.filter(
          (row) => row.billingStatus === "paid"
        ).length;
        const completedSessionsCount = tableData.filter(
          (row) => row.procedureStatus === "completed"
        ).length;
        const paidCancelledSessionsCount = tableData.filter(
          (row) =>
            row.checkboxChecked &&
            row.billingStatus === "paid" &&
            row.procedureStatus === "Not Completed"
        ).length;
        const notPaidCancelledSessionsCount = tableData.filter(
          (row) =>
            row.checkboxChecked &&
            row.billingStatus === "Not paid" &&
            row.procedureStatus === "Not Completed"
        ).length;
        const refundAmount = paidCancelledSessionsCount * BillingAmount;

        const newData = {
          ServiceType: firstRow.ServiceType,
          ServiceName: firstRow.ServiceName,
          PatientName: firstRow.PatientName,
          VisitID: firstRow.VisitID,
          PatientID: firstRow.PatientID,
          billing_Invoice: firstRow.billing_Invoice,
          TherapistName: firstRow.TherapistName,
          DoctorName: firstRow.DoctorName,
          sessionCount: sessionCount,
          BillingAmount: BillingAmount,
          paidSessionsCount: paidSessionsCount,
          completedSessionsCount: completedSessionsCount,
          canceledPaidSessionsCount: paidCancelledSessionsCount,
          canceledNotPaidSessionsCount: notPaidCancelledSessionsCount,
          RefundAmount: refundAmount.toFixed(2),
        };
        console.log(newData);

        const existingIndex = TableData1.findIndex(
          (row) => row.ServiceName === newData.ServiceName
        );

        if (existingIndex !== -1) {
          setTableData1((prevData) => {
            const updatedData = [...prevData];
            updatedData[existingIndex] = newData;
            return updatedData;
          });
        } else {
          setTableData1((prevData) => [...prevData, newData]);
        }

        setTableData([]);
      }
    }
  };

  // const handleEdit = (rowData) => {
  //   const sessionCount = rowData.sessionCount;
  //   const paidSessionCount = rowData.paidSessionsCount;
  //   const procedureCount = rowData.completedSessionsCount;
  //   const BillingAmount = rowData.BillingAmount;

  //   const paid_not_canceled = paidSessionCount - (procedureCount + rowData.canceledPaidSessionsCount);
  //   const non_paid_sessionCount = sessionCount - paidSessionCount;
  //   const nonpaid_notcancel = non_paid_sessionCount - rowData.canceledNotPaidSessionsCount;

  //   const data = Array.from({ length: sessionCount }, (_, index) => {
  //     let procedureStatus = '';
  //     let billingStatus = '';

  //     if (index < procedureCount) {
  //       procedureStatus = 'completed';
  //     } else if (index < procedureCount + rowData.canceledPaidSessionsCount) {
  //       procedureStatus = 'paidcancelled';
  //     } else if (index < procedureCount + rowData.canceledPaidSessionsCount + paid_not_canceled) {
  //       procedureStatus = 'Not Completed';
  //     } else if (index < procedureCount + rowData.canceledPaidSessionsCount + paid_not_canceled + rowData.canceledNotPaidSessionsCount) {
  //       procedureStatus = 'non-paidcancelled';
  //     } else if (index < procedureCount + rowData.canceledPaidSessionsCount + paid_not_canceled + rowData.canceledNotPaidSessionsCount + nonpaid_notcancel) {
  //       procedureStatus = 'Not Completed';
  //     } else {
  //       procedureStatus = 'Not Completed';
  //     }

  //     billingStatus = index < paidSessionCount ? 'paid' : 'Not paid';

  //     return {
  //       ServiceType: rowData.ServiceType,
  //       ServiceName: rowData.ServiceName,
  //       VisitID: rowData.VisitID,
  //       PatientID: rowData.PatientID,
  //       billing_Invoice: rowData.billing_Invoice,
  //       TherapistName: rowData.TherapistName,
  //       PatientName: rowData.PatientName,
  //       DoctorName: rowData.DoctorName,
  //       billingStatus,
  //       procedureStatus,
  //       action: 'No Action',
  //       BillingAmount: BillingAmount,
  //       checkboxChecked: index < procedureCount,
  //     };
  //   });

  //   setSelectAll(data.every((row) => row.checkboxChecked));
  //   setTableData(data);
  //   SetNameForCancel((prevState) => prevState.filter((p) => p.ServiceName !== rowData.ServiceName));
  //   setSelectedNameCancel('');
  // };

  function calculation () {
    let totalItems = 0;
    let totalUnits = 0;
    let totalAmount = 0;
    let totalRefundAmount = 0;
    data.forEach((item) => {
      const units = item.Session || 0;
      totalItems += 1;
      totalUnits += units;
      totalAmount += +item.BillingAmount || 0;
      totalRefundAmount += +item.RefundAmount || 0;
    });
    const newState = {
      totalItems,
      totalUnits,
      totalAmount,
      totalRefundAmount,
    };
    setinitialState({
      totalItems: newState.totalItems,
      totalUnits: newState.totalUnits,
      totalAmount: newState.totalAmount.toFixed(2),
      totalRefundAmount: newState.totalRefundAmount.toFixed(2),
    });
  };

  const handleSubmit = (SubmitName) => {
    console.log("SubmitName :", SubmitName);
    console.log("Reason :", Reason);
    if (Reason.ApprovedBy !== "" && Reason.reason !== "") {
      console.table(Reason);
      if (SubmitName === "Procedure") {
        if (TableData1.length !== 0) {
          const data = TableData1.map((p) => ({
            ...p,
            ...Reason,
            location: userRecord?.location,
          }))
          setData(data);
          calculation()
          setDname(data[0].DoctorName);
          axios
            .get(
              `http://127.0.0.1:8000/patientmanagement/get_communication_address_billing/${data[0].PatientID}/${userRecord?.location}`
            )
            .then((response) => {
              const data = response.data[0];
              console.log(data);
              setFormData({
                PatientID: data.PatientID,
                PhoneNumber: data.PhoneNumber,
                PatientName: data.FirstName + " " + data.LastName,
                Age: data.Age,
                Gender: data.Gender,
                City: data.City,
                State: data.State,
                Street: data.Street,
                Pincode: data.Pincode,
              });
            })
            .catch((error) => {
              console.log(error);
            });
          axios
            .post(
              "http://127.0.0.1:8000/Bill_Cancellation/insertBill_Cancel_Procedure",
              data
            )
            .then((response) => {
              console.log(response.data);
              setIsPrintButtonVisible(false);
              setTimeout(() => {
                window.print();
                setIsPrintButtonVisible(true);
                SetNameForCancel([]);
                setTableData([]);
                setTableData1([]);
                setSelectedBillType("");
                setReason((prev) => ({
                  ...prev,
                  reason: "",
                  ApprovedBy: "",
                }));
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });

          console.table(data);
        } else {
          alert("No Data To Refund");
        }
      } else if (SubmitName === "DoctorConsultation") {
        console.log("NameForCancel", NameForCancel);
        const row = NameForCancel.filter((p) => p.checkboxChecked === true);
        console.log("row :", row);
        if (row.length !== 0) {
          const data = row.map((p) => ({
            patient_id: p.patient_id,
            Patient_Name: p.Patient_Name,
            invoice_no: p.invoice_no,
            DoctorName: p.DoctorName,
            visit_id: p.visit_id,
            Amount: parseFloat(p.Amount),
            RefundAmount: parseFloat(p.RefundAmount),
            ...Reason,
            location: userRecord?.location,
          }))
          console.log('data :',data)
          setData(data);
          calculation()

          // ( function  () {
          //   let totalItems = 0;
          //   let totalUnits = 0;
          //   let totalAmount = 0;
          //   let totalRefundAmount = 0;
          //   data.forEach((item) => {
          //     totalItems += 1;
          //     totalUnits += 1;
          //     totalAmount += item.Amount || 0;
          //     totalRefundAmount += item.RefundAmount || 0;
          //   });
          //   const newState = {
          //     totalItems,
          //     totalUnits,
          //     totalAmount,
          //     totalRefundAmount,
          //   };
          //   setinitialState({
          //     totalItems: newState.totalItems,
          //     totalUnits: newState.totalUnits,
          //     totalAmount: newState.totalAmount.toFixed(2),
          //     totalRefundAmount: newState.totalRefundAmount.toFixed(2),
          //   });
          // })();
          setDname(data[0].DoctorName);
          axios
            .get(
              `http://127.0.0.1:8000/patientmanagement/get_communication_address_billing/?patientid=${data[0].PatientID}&location=${userRecord?.location}`
            )
            .then((response) => {
              const data = response.data[0];
              console.log(data);
              setFormData({
                PatientID: data.PatientID,
                PhoneNumber: data.PhoneNumber,
                PatientName: data.FirstName + " " + data.LastName,
                Age: data.Age,
                Gender: data.Gender,
                City: data.City,
                State: data.State,
                Street: data.Street,
                Pincode: data.Pincode,
              });
            })
            .catch((error) => {
              console.log(error);
            });
          axios
            .post(
              "http://127.0.0.1:8000/Bill_Cancellation/insertBill_Cancel_Consultancy",
              data
            )
            .then((response) => {
              console.log(response.data);
              setIsPrintButtonVisible(false);
              setTimeout(() => {
                window.print();
                setIsPrintButtonVisible(true);
                SetNameForCancel([]);
                setTableData([]);
                setTableData1([]);
                setSelectedBillType("");
                setReason((prev) => ({
                  ...prev,
                  reason: "",
                  ApprovedBy: "",
                }));
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
          console.table(data);
        } else {
          alert("No Data To Refund");
        }
      } else if (SubmitName === "Pharmacy") {
        const row = NameForCancel.filter(
          (p) => p.checkboxChecked !== false && p.CancelUnit !== 0
        );
        if (row.length !== 0) {
          const data =
            row && Array.isArray(row)
              ? row.map((p) => ({
                  PatientId: p.PatientId,
                  BilledInvoice: p.invoice_no,
                  PatientName: p.PatientName,
                  DoctorName: p.PhysicianName,
                  VisitID: null,
                  ItemCode: p.ItemCode,
                  ItemName: p.ItemName,
                  BatchNo: p.BatchNo,
                  Unit: p.Unit,
                  BillingAmount: p.BillingAmount,
                  CancelUnit: p.CancelUnit,
                  checkboxChecked: p.checkboxChecked,
                  RefundAmount: +p.CancelUnit * +p.BillingAmount,
                  ...Reason,
                  location: userRecord?.location,
                }))
              : [];
          (function () {
            let totalItems = 0;
            let totalUnits = 0;
            let totalAmount = 0;
            let totalRefundAmount = 0;
            data.forEach((item) => {
              const units = item.CancelUnit || 0;
              totalItems += 1;
              totalUnits += units;
              totalAmount += +item.BillingAmount || 0;
              totalRefundAmount += +item.RefundAmount || 0;
            });
            const newState = {
              totalItems,
              totalUnits,
              totalAmount,
              totalRefundAmount,
            };
            setinitialState({
              totalItems: newState.totalItems,
              totalUnits: newState.totalUnits,
              totalAmount: newState.totalAmount.toFixed(2),
              totalRefundAmount: newState.totalRefundAmount.toFixed(2),
            });
          })();
          setDname(data[0].DoctorName);
          axios
            .get(
              `http://127.0.0.1:8000/patientmanagement/get_communication_address_billing/?patientid=${data[0].PatientID}&location=${userRecord?.location}`
            )
            .then((response) => {
              const data = response.data[0];
              console.log(data);
              setFormData({
                PatientID: data.PatientID,
                PhoneNumber: data.PhoneNumber,
                PatientName: data.FirstName + " " + data.LastName,
                Age: data.Age,
                Gender: data.Gender,
                City: data.City,
                State: data.State,
                Street: data.Street,
                Pincode: data.Pincode,
              });
            })
            .catch((error) => {
              console.log(error);
            });
          axios
            .post(
              "http://127.0.0.1:8000/Bill_Cancellation/insertBill_Cancel_Pharmacy",
              data
            )
            .then((response) => {
              console.log(response.data);
              setIsPrintButtonVisible(false);
              setTimeout(() => {
                window.print();
                setIsPrintButtonVisible(true);
                SetNameForCancel([]);
                setTableData([]);
                setTableData1([]);
                setSelectedBillType("");
                setReason((prev) => ({
                  ...prev,
                  reason: "",
                  ApprovedBy: "",
                }));
              }, 1000);
            })
            .catch((error) => {
              console.log(error);
            });
          console.table(data);
        } else {
          alert("No Data To Refund");
        }
      }
    } else {
      alert("Please Fill the Reason For Cancellation and Approved Person");
    }
  };

  const forPrintData = () => {
    return (
      <div className="billing-invoice">
        <div className="New_billlling_invoice_head">
          <div className="new_billing_logo_con">
            <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
          </div>
          <div className="new_billing_address_1 ">
            <span>{ClinicDetials.ClinicName}</span>
            <div>
              <span>{ClinicDetials.ClinicAddress},</span>
              <span>
                {ClinicDetials.ClinicCity +
                  "," +
                  ClinicDetials.ClinicState +
                  "," +
                  ClinicDetials.ClinicCode}
              </span>
            </div>
            <div>
              <span>{ClinicDetials.ClinicMobileNo + " , "}</span>
              <span>{ClinicDetials.ClinicLandLineNo + " , "}</span>
              <span>{ClinicDetials.ClinicMailID}</span>
            </div>
          </div>
        </div>
        <div
          className="Register_btn_con"
          style={{ color: "hsl(33,100%,50%)", fontWeight: 600 }}
        >
          Bill Cancelation
        </div>
        <div className="new_billing_address">
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Patient Name <span>:</span>
              </label>
              <span>{FormData.PatientName}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Patient ID <span>:</span>
              </label>
              <span>{FormData.PatientID}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Age <span>:</span>
              </label>
              <span>{FormData.Age}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Gender <span>:</span>
              </label>
              <span>{FormData.Gender}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Address <span>:</span>
              </label>
              <span>{FormData.City}</span>
            </div>
          </div>
          <div className="new_billing_address_2">
            <div className="new_billing_div">
              <label>
                Invoice No <span>:</span>
              </label>
              <span>{ClinicDetials.InvoiceNo}</span>
            </div>
            <div className="new_billing_div">
              <label>
                GSTIN No <span>:</span>
              </label>
              <span>{ClinicDetials.ClinicGST}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Physician Name <span>:</span>
              </label>
              <span>{Dname}</span>
            </div>
            <div className="new_billing_div">
              <label>
                Date <span>:</span>
              </label>
              <span>{Billing_date}</span>
            </div>
            <div className="new_billing_div">
              <label>
                {" "}
                Patient Mobile No <span>:</span>
              </label>
              <span>{FormData.PhoneNumber}</span>
            </div>
          </div>
        </div>

        {selectedBillType === "Procedure" && (
          <div className="new_billing_invoice_detials">
            <table>
              <thead>
                <tr>
                  <th>Service Type</th>
                  <th>Service Name</th>
                  <th>Session</th>
                  <th>Paid Session</th>
                  <th>Completed Session</th>
                  <th>Cancelled Paid Session</th>
                  <th>Cancelled Not-Paid Session</th>
                  <th>Billing Amount</th>
                  <th>Refund Amount</th>
                </tr>
              </thead>
              <tbody>
                {TableData1.map((row, index) => (
                  <tr key={index}>
                    <td>{row.ServiceType}</td>
                    <td>{row.ServiceName}</td>
                    <td>{row.sessionCount}</td>
                    <td>{row.paidSessionsCount}</td>
                    <td>{row.completedSessionsCount}</td>
                    <td>{row.canceledPaidSessionsCount}</td>
                    <td>{row.canceledNotPaidSessionsCount}</td>
                    <td>{row.BillingAmount}</td>
                    <td>{row.RefundAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {selectedBillType === "Pharmacy" && (
          <div className="new_billing_invoice_detials">
            <table>
              <thead>
                <tr>
                  <th>Item Code</th>
                  <th>Item Name</th>
                  <th>Batch No</th>
                  <th>Unit</th>
                  <th>Billing Amount</th>
                  <th>Cancel Unit</th>
                  <th>Refund Amount</th>
                </tr>
              </thead>
              <tbody>
                {NameForCancel.map((row, index) => (
                  <tr key={index}>
                    <td>{row.ItemCode}</td>
                    <td>{row.ItemName}</td>
                    <td>{row.BatchNo}</td>
                    <td>{row.Unit}</td>
                    <td>{row.BillingAmount}</td>
                    <td>{row.CancelUnit}</td>
                    <td>{row.CancelUnit * row.BillingAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        {selectedBillType === "DoctorConsultation" && (
          <div className="new_billing_invoice_detials">
            <table>
              <thead>
                <tr>
                  <th>Service Type</th>
                  <th>Doctor Name</th>
                  <th>Fees</th>
                  <th>Refund Amount</th>
                </tr>
              </thead>
              <tbody>
                {NameForCancel.map((row, index) => (
                  <tr key={index}>
                    <td>{row.ServiceType}</td>
                    <td>{row.DoctorName}</td>
                    <td>{row.Amount}</td>
                    <td>{row.RefundAmount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div
          className="new_billing_invoice_detials "
          style={{ paddingBottom: "10px", height: "auto" }}
        >
          <div
            className="total_con_bill_head"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="bill_body" style={{ width: "145px" }}>
              <label>
                {" "}
                Items <span>:</span>
              </label>
              <span>{initialState.totalItems}</span>
            </div>
            <div className="bill_body" style={{ width: "145px" }}>
              <label>
                {" "}
                Unit <span>:</span>
              </label>
              <span>{initialState.totalUnits}</span>
            </div>
            <div className="bill_body" style={{ width: "145px" }}>
              <label>
                {" "}
                Amount <span>:</span>
              </label>
              <span>{initialState.totalAmount}</span>
            </div>
            <div className="bill_body" style={{ width: "145px" }}>
              <label>
                Refund Amount <span>:</span>
              </label>
              <span>{initialState.totalRefundAmount}</span>
            </div>
          </div>
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px",
                }}
              >
                <label>
                  {" "}
                  Amount In Words<span>:</span>
                </label>
                <span style={{ color: "grey" }}>
                  {numberToWords(+initialState.totalRefundAmount)}{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="invoice_detials_total_1 neww_invoicedetials">
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px",
                }}
              >
                <label>
                  {" "}
                  Billed By <span>:</span>
                </label>
                <span>{Reason.RefundBilledBy}</span>
              </div>
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px",
                }}
              >
                <label>
                  {" "}
                  Approved By <span>:</span>
                </label>
                <span>{Reason.ApprovedBy}</span>
              </div>
            </div>
            <div className="total_con_bill">
              <div
                className="bill_body"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: "20px",
                }}
              >
                <label>
                  {" "}
                  Reason <span>:</span>
                </label>
                <span>{Reason.reason}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {isPrintButtonVisible ? (
        <>
          <Helmet>
            <title> List</title>
          </Helmet>
          <div className="appointment">
            <div className="h_head">
              <h4> Billing Cancellation / Refund</h4>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                padding: "10px 10px",
              }}
            >
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label htmlFor="Title">
                    Bill Type <span>:</span>
                  </label>
                  <select
                    name="BillType"
                    value={searchQuery.BillType}
                    onChange={handlechange}
                  >
                    <option value="">Select</option>
                    {/* <option value="Pharmacy">Pharmacy</option> */}
                    <option value="DoctorConsultation">Consultancy</option>
                    <option value="Procedure">Procedure</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Invoice No <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
                    name="SearchInvoiceNo"
           
                    value={searchQuery.SearchInvoiceNo}
                    onChange={handlechange}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Billing Date <span>:</span>{" "}
                  </label>
                  <input
                    type="date"
                    name="SearchBillingDate"
                    value={searchQuery.SearchBillingDate}
                    onChange={handlechange}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    {" "}
                    Patient ID <span>:</span>{" "}
                  </label>
                  <input
                    type="text"
           
                    name="SearchID"
                    value={searchQuery.SearchID}
                    onChange={handlechange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="Register_btn_con">
              <button className="RegisterForm_1_btns" onClick={handleSearch}>
                Search
              </button>
            </div>

            {CancelList.length !== 0 && (
              <div style={{ display: "grid", placeItems: "center" }}>
                <div
                  className="new_billing_invoice_detials"
                  style={{ width: "85%", marginTop: "10px" }}
                >
                  <table>
                    <thead>
                      <tr>
                        <th>Patient Id</th>
                        <th>Invoice No</th>
                        <th>Service Type</th>
                        <th>Billing Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CancelList.map((row, index) => (
                        <tr key={index}>
                          <td>{row.PatientID}</td>
                          <td>{row.Invoice_NO}</td>
                          <td>{row.Service_Type}</td>
                          <td>{row.Billing_date}</td>
                          <td>
                            <button onClick={() => handleSearch_1(row)}>
                              Proceed
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {selectedBillType === "Procedure" && (
              <div style={{ display: "grid", placeItems: "center" }}>
                <div
                  className="h_head"
                  style={{ width: "90%", justifyContent: "space-between" }}
                >
                  <h3> Procedure Fee Cancellation </h3>
                  {/* <label>
              <input type="checkbox" checked={selectAll} onChange={handleSelectAllClick} /> {'  '}
              Select All
            </label> */}
                </div>
                <div
                  className="RegisFormcon RegisFormcon1"
                  style={{ padding: "5px 0px", margin: "10px 0px" }}
                >
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Select Items <span>:</span>
                    </label>
                    <input
                      list="ForListtheItems"
                      placeholder="Select the name"
                      value={SelectedNameCancel}
                      onChange={(e) => setSelectedNameCancel(e.target.value)}
                    />
                    <datalist id="ForListtheItems">
                      {selectedBillType === "Procedure" &&
                        NameForCancel.map((p, index) => (
                          <option key={index} value={p.ServiceName} />
                        ))}
                    </datalist>
                  </div>
                </div>
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={handleClickCancel}
                  >
                    Get
                  </button>
                </div>
                {tableData.length !== 0 && (
                  <>
                    <div
                      className="new_billing_invoice_detials"
                      style={{ width: "85%", marginTop: "10px" }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Service Type</th>
                            <th>Service Name</th>
                            <th>Billing Status</th>
                            <th>Billing Amount</th>
                            <th>Procedure Status</th>
                            <th>Cancel/Refund Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tableData.map((row, index) => (
                            <tr key={index}>
                              <td>{row.ServiceType}</td>
                              <td>{row.ServiceName}</td>
                              <td>{row.billingStatus}</td>
                              <td>{row.BillingAmount}</td>
                              <td>{row.procedureStatus}</td>
                              <td>
                                {row.procedureStatus === "paidcancelled" ||
                                row.procedureStatus === "completed" ||
                                row.procedureStatus === "non-paidcancelled" ? (
                                  "No Action"
                                ) : (
                                  <input
                                    type="checkbox"
                                    checked={row.checkboxChecked}
                                    onChange={(e) =>
                                      handleCheckboxClick(e, index)
                                    }
                                  />
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={handleCancelSave}
                      >
                        Save
                      </button>
                    </div>
                  </>
                )}
                {TableData1.length !== 0 && (
                  <>
                    <div
                      className="new_billing_invoice_detials"
                      style={{ width: "85%", marginTop: "10px" }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Service Type</th>
                            <th>Service Name</th>
                            <th>Session</th>
                            <th>Paid Session</th>
                            <th>Completed Session</th>
                            <th>Cancelled Paid Session</th>
                            <th>Cancelled Not-Paid Session</th>
                            <th>Billing Amount</th>
                            <th>Refund Amount</th>
                            {/* <th>Action</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {TableData1.map((row, index) => (
                            <tr key={index}>
                              <td>{row.ServiceType}</td>
                              <td>{row.ServiceName}</td>
                              <td>{row.sessionCount}</td>
                              <td>{row.paidSessionsCount}</td>
                              <td>{row.completedSessionsCount}</td>
                              <td>{row.canceledPaidSessionsCount}</td>
                              <td>{row.canceledNotPaidSessionsCount}</td>
                              <td>{row.BillingAmount}</td>
                              <td>{row.RefundAmount}</td>
                              {/* <td>
                                <button onClick={() => handleEdit(row)}>Edit</button>
                              </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="RegisFormcon RegisFormcon1"
                      style={{ padding: "5px 0px", margin: "10px 0px" }}
                    >
                      <div className="RegisForm_1">
                        <label>
                          {" "}
                          Reason <span>:</span>
                        </label>
                        <textarea
                          value={Reason.reason}
                          onChange={(e) =>
                            setReason((prev) => ({
                              ...prev,
                              reason: e.target.value,
                            }))
                          }
                        ></textarea>
                      </div>
                      <div className="RegisForm_1">
                        <label>
                          {" "}
                          Approved By <span>:</span>
                        </label>
                        <input
                          type="text"
                          value={Reason.ApprovedBy}
                          onChange={(e) =>
                            setReason((prev) => ({
                              ...prev,
                              ApprovedBy: e.target.value,
                            }))
                          }
                          placeholder="Enter the Approved by"
                        />
                      </div>
                    </div>
                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={() => handleSubmit("Procedure")}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {selectedBillType === "DoctorConsultation" && (
              <div style={{ display: "grid", placeItems: "center" }}>
                <div
                  className="h_head"
                  style={{ width: "90%", justifyContent: "space-between" }}
                >
                  <h3> DoctorConsultation Fee Cancellation </h3>
                </div>
                {NameForCancel.length !== 0 && (
                  <>
                    <div
                      className="new_billing_invoice_detials"
                      style={{
                        width: "75%",
                        marginTop: "10px",
                        borderTop: "none",
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Service Type</th>
                            <th>Doctor Name</th>
                            <th>Fees</th>
                            <th>Refund Amount</th>
                            <th>Cancelation /Refund</th>
                          </tr>
                        </thead>
                        <tbody>
                          {NameForCancel.map((row, index) => (
                            <tr key={index}>
                              <td>{row.ServiceType}</td>
                              <td>{row.DoctorName}</td>
                              <td>{row.Amount}</td>
                              <td>
                                {row.checkboxChecked
                                  ? row.Amount
                                  : row.RefundAmount}
                              </td>
                              <td>
                                <input
                                  type="checkbox"
                                  checked={row.checkboxChecked}
                                  onChange={() =>
                                    SetNameForCancel((prev) =>
                                      prev.map((item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              checkboxChecked:
                                                !item.checkboxChecked,
                                              RefundAmount:
                                                !item.checkboxChecked
                                                  ? item.Amount
                                                  : 0,
                                            }
                                          : item
                                      )
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div
                      className="RegisFormcon RegisFormcon1"
                      style={{ padding: "5px 0px", margin: "10px 0px" }}
                    >
                      <div className="RegisForm_1">
                        <label>
                          {" "}
                          Reason <span>:</span>
                        </label>
                        <textarea
                          value={Reason.reason}
                          onChange={(e) =>
                            setReason((prev) => ({
                              ...prev,
                              reason: e.target.value,
                            }))
                          }
                        ></textarea>
                      </div>
                      <div className="RegisForm_1">
                        <label>
                          {" "}
                          Approved By <span>:</span>
                        </label>
                        <input
                          type="text"
                          value={Reason.ApprovedBy}
                          onChange={(e) =>
                            setReason((prev) => ({
                              ...prev,
                              ApprovedBy: e.target.value,
                            }))
                          }
                          placeholder="Enter the Approved by"
                        />
                      </div>
                    </div>
                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={() => handleSubmit("DoctorConsultation")}
                      >
                        Submit
                      </button>
                    </div>
                  </>
                )}
              </div>
            )}
            {selectedBillType === "Pharmacy" && (
              <div style={{ display: "grid", placeItems: "center" }}>
                <div
                  className="h_head"
                  style={{ width: "90%", justifyContent: "space-between" }}
                >
                  <h3> Pharmacy Fee Cancellation </h3>
                  {/* <label>
              <input type="checkbox" checked={selectAll} onChange={handleSelectAllClick} /> {'  '}
              Select All
            </label> */}
                </div>
                {NameForCancel.length !== 0 && (
                  <div
                    className="new_billing_invoice_detials"
                    style={{ width: "85%", marginTop: "10px" }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Item Code</th>
                          <th>Item Name</th>
                          <th>Batch No</th>
                          <th>Unit</th>
                          <th>Billing Amount</th>
                          <th>Cancel Unit</th>
                          <th>Refund Amount</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {NameForCancel.map((row, index) => (
                          <tr key={index}>
                            <td>{row.ItemCode}</td>
                            <td>{row.ItemName}</td>
                            <td>{row.BatchNo}</td>
                            <td>{row.Unit}</td>
                            <td>{row.BillingAmount}</td>
                            <td>
                              {row.checkboxChecked ? (
                                <input
                                  type="number"
                                  value={row.CancelUnit}
                                  onChange={(e) => {
                                    const value = parseInt(e.target.value) || 0;
                                    if (value <= +row.Unit) {
                                      SetNameForCancel((prev) =>
                                        prev.map((item, i) =>
                                          i === index
                                            ? { ...item, CancelUnit: value }
                                            : item
                                        )
                                      );
                                    } else {
                                      alert(
                                        `Please enter below the Billed Quantity ${row.Unit}`
                                      );
                                    }
                                  }}
                                />
                              ) : (
                                row.CancelUnit
                              )}
                            </td>
                            <td>{row.CancelUnit * row.BillingAmount}</td>
                            <td>
                              <input
                                type="checkbox"
                                checked={row.checkboxChecked}
                                onChange={(e) => {
                                  const value = e.target.checked;
                                  if (value) {
                                    SetNameForCancel((prev) =>
                                      prev.map((item, i) =>
                                        i === index
                                          ? { ...item, checkboxChecked: value }
                                          : item
                                      )
                                    );
                                  } else {
                                    SetNameForCancel((prev) =>
                                      prev.map((item, i) =>
                                        i === index
                                          ? {
                                              ...item,
                                              checkboxChecked: value,
                                              CancelUnit: 0,
                                            }
                                          : item
                                      )
                                    );
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div
                  className="RegisFormcon RegisFormcon1"
                  style={{ padding: "5px 0px", margin: "10px 0px" }}
                >
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Reason <span>:</span>
                    </label>
                    <textarea
                      value={Reason.reason}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          reason: e.target.value,
                        }))
                      }
                    ></textarea>
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      {" "}
                      Approved By <span>:</span>
                    </label>
                    <input
                      type="text"
                      value={Reason.ApprovedBy}
                      onChange={(e) =>
                        setReason((prev) => ({
                          ...prev,
                          ApprovedBy: e.target.value,
                        }))
                      }
                      placeholder="Enter the Approved by"
                    />
                  </div>
                </div>
                <div className="Register_btn_con">
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => handleSubmit("Pharmacy")}
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        forPrintData()
      )}
    </>
  );
};
export default Billcancelation;
