import { React, useState, useRef, useEffect, V, useCallback } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ToastContainer, toast } from "react-toastify";
import RoomsAvail from "../RoomCreate/RoomsAvail";
import axios from "axios";
import HotelIcon from '@mui/icons-material/Hotel';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import SearchIcon from "@mui/icons-material/Search";
import "../../RegistrationForm/Registration.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";

function IpRegistration1() {
  const navigate = useNavigate();
  const dispatchvalue = useDispatch();
  const userRecord = useSelector(state => state.userRecord?.UserData);
  const selectRoom = useSelector(state => state.InPatients?.selectRoom);
  const showroom = useSelector(state => state.InPatients?.showroom);
  const IpAdmissionEdit = useSelector(state => state.InPatients?.IpAdmissionEdit);
  console.log(IpAdmissionEdit)
  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: 'toast-container-over-header',
      style: { marginTop: '50px' },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };
  const errmsg = (errormsg) => {
    toast.error(`${errormsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: '50px' },
    });
  };
  const [PatientIDPhone, setPatientIDPhone] = useState([])
  const [Registerformdata, setRegisterformdata] = useState({
    AdmissionPurpose: '',
    PatientId: '',
    Title: "",
    FirstName: "",
    LastName: "",
    PatientPhoneNo: '',
    Gender: '',
    DOB: '',
    Age: '',
    Nationality: "",
    Referrer: '',
    ReferrerName: '',
    PrimaryDoctor: '',
    Admitdate: '',
    AttenderName: '',
    AttenderMobileNo: '',
    Relation: '',
    PrimaryDisease: '',
    Remarks: '',
    PatientType: "",
    PatientCategory: "",
    RatecardType: "",
  })
  const [RoomManage, setRoomManage] = useState({
    RoomId: '',
    BuildingName: '',
    BlockName: '',
    FloorName: '',
    WardType: '',
    RoomType: '',
    RoomNo: '',
    BedNo: '',
    Package: '',
    IsPackage: 'No',
    PackageDays: '',
    Charge: '',
    Advance: ''
  })
  const [PatientAddress1, setPatientAddress1] = useState({
    Country: '',
    State: '',
    City: '',
    Place: '',
    Street: '',
    PinCode: '',
    Email: ''

  })
  const [EmergencyContact1, setEmergencyContact1] = useState({
    SameAsContactAddress: false,
    EMGCountry: '',
    EMGState: '',
    EMGCity: '',
    EMGPlace: '',
    EMGStreet: '',
    EMGPinCode: '',
    EMGEmail: ''

  })
  const [Insurance1, setInsurance1] = useState({
    InsuranceName: '',
    Scheme: '',
    Plan: '',
    TPA: '',
    CardNo: '',
    AvailedType: '',
    ValidUpto: '',
    EffectDate: '',
    InsuredPerson: '',
    Remarks: '',
    EstimateBill: '',
    PreAuthAmount: '',
    CoPaymentType: 'Percentage',
    InsuranceContri: '',
    PatientContri: '',
    CoPaymentLogic: '',

  })
  const [MedicalLegalCase1, setMedicalLegalCase1] = useState({
    InformedBY: '',
    OfficerName: '',
    PoliceStation: '',
    FIRNo: '',
    CodeStatus: '',
    TypeofAdmission: '',
    AdmittingDiagnosis: '',
  })

  const [expanded, setExpanded] = useState(false);   //for accordian
  const [packagedata, setpackagedata] = useState([])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handlecloseroom = () => {
    dispatchvalue({ type: 'showroom', value: false })
  };
  const handleopenroom = () => {
    dispatchvalue({ type: 'showroom', value: true })
  };
  const handleclear = () => {
    setRegisterformdata((prev) => ({
      AdmissionPurpose: '',
      PatientId: '',
      PatientName: '',
      Title: "",
      FirstName: "",
      LastName: "",
      PatientPhoneNo: '',
      Gender: '',
      DOB: '',
      Age: '',
      Nationality: "",
      Referrer: '',
      PrimaryDoctor: '',
      Admitdate: '',
      AttenderName: '',
      AttenderMobileNo: '',
      Relation: '',
      PrimaryDisease: '',
      Remarks: '',
      PatientType: "",
      PatientCategory: "",
      ClinentName: "",
    }));
    setRoomManage((prev) => ({
      RoomId: '',
      BuildingName: '',
      BlockName: '',
      FloorName: '',
      WardType: '',
      RoomType: '',
      RoomNo: '',
      BedNo: '',
      Package: '',
      IsPackage: 'No',
      PackageDays: '',
      Charge: '',
      Advance: ''
    }));
    setPatientAddress1((prev) => ({
      Country: '',
      State: '',
      City: '',
      Place: '',
      Street: '',
      PinCode: '',
      Email: ''
    }));
    setEmergencyContact1((prev) => ({
      SameAsContactAddress: false,
      EMGCountry: '',
      EMGState: '',
      EMGCity: '',
      EMGPlace: '',
      EMGStreet: '',
      EMGPinCode: '',
      EMGEmail: ''
    }));
    setInsurance1((prev) => ({
      InsuranceName: '',
      Scheme: '',
      Plan: '',
      TPA: '',
      CardNo: '',
      AvailedType: '',
      ValidUpto: '',
      EffectDate: '',
      InsuredPerson: '',
      Remarks: '',
      EstimateBill: '',
      PreAuthAmount: '',
      CoPaymentType: 'Percentage',
      InsuranceContri: '',
      PatientContri: '',
      CoPaymentLogic: '',
    }));
    setMedicalLegalCase1((prev) => ({
      InformedBY: '',
      OfficerName: '',
      PoliceStation: '',
      FIRNo: '',
      CodeStatus: '',
      TypeofAdmission: '',
      AdmittingDiagnosis: '',
    }));
  }

  useEffect(() => {
    if (IpAdmissionEdit) {
      
      axios.get(`http://127.0.0.1:8000/ipregistration/get_data_by_Booking_Id?Booking_Id=${IpAdmissionEdit}`)
        .then((respone) => {
          const data = respone.data
          console.log(data)
          const Inpatient_Register_details = data.Inpatient_Register_details
          const Ip_Room_booking_management = data.Ip_Room_booking_management
          const Inpatient_contact_details = data.Inpatient_contact_details
          const Inpatient_attender_details = data.Inpatient_attender_details
          const Inpatient_Insurance_details = data.Inpatient_Insurance_details
          const inpatient_Medical_Legal_case = data.inpatient_Medical_Legal_case


          setRegisterformdata((prev) => ({
            ...prev,
            ...Inpatient_Register_details
          }));
          axios
        .get(
          `http://127.0.0.1:8000/ipregistration/get_patient_datas_booking?patientid=${Inpatient_Register_details.PatientId}&phonenumber=${Inpatient_Register_details.PatientPhoneNo}`,
        )
        .then((response) => {

          const data = response.data
          // console.log(response.data)
          if (!data) {

            userwarn("Patient ID is not avaiable! Please enter a new Register")
            // navigate('/Register-Patient2');
          } else {
            console.log(response.data);
            setRegisterformdata((prevFormData) => ({
              ...prevFormData,
              PatientId: data.PatientID,
              FirstName: data.FirstName,
              LastName: data.LastName,
              PatientPhoneNo: data.PhoneNumber,
              Gender: data.Gender,
              DOB: data.DateofBirth,
              Age: data.Age,
              Title: data.Title,
              Nationality: data.Nationality,
            }));


          }
        })
        .catch((error) => {
          console.log(error);
        });
          setRoomManage((prev) => ({
            ...prev,
            ...Ip_Room_booking_management
          }));
          setPatientAddress1((prev) => ({
            ...prev,
            ...Inpatient_contact_details
          }));
          setEmergencyContact1((prev) => ({
            ...prev,
            SameAsContactAddress: false,
            ...Inpatient_attender_details
          }));
          setInsurance1((prev) => ({
            ...prev,
            ...Inpatient_Insurance_details
          }));
          setMedicalLegalCase1((prev) => ({
            ...prev,
            ...inpatient_Medical_Legal_case
          }));

        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      handleclear()
    }
  }, [IpAdmissionEdit])
  useEffect(() => {
    const newdate = format(new Date(), 'yyyy-MM-dd')
    setRegisterformdata((prev) => ({
      ...prev,
      Admitdate: newdate
    }))
    axios.get(`http://127.0.0.1:8000/ipregistration/get_All_PatientId_PhoneNo`)
      .then((response) => {
        const data = response.data
        setPatientIDPhone(data)

      })
      .catch((error) => {
        console.log(error)
      })
  }, [])
  // handle change Registerformdata
  const handlechangeRegisterformdata = (e) => {
    const { name, value } = e.target;
    if (name === 'PatientPhoneNo' || name === 'AttenderMobileNo') {
      if (value.length <= 10) {
        setRegisterformdata((prevFormData) => ({
          ...prevFormData,
          [name]: value,
        }));
      }
    } else {
      setRegisterformdata((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

  }
  const handlechangeRoomManage = (e) => {
    const { name, value } = e.target;
    setRoomManage((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(name, value)
    console.log(typeof (value))
  }
  const handlePatientAddressChange = (e) => {
    const { name, value } = e.target;
    setPatientAddress1((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  }
  const handleSameAsContactAddressChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (checked) {
      setEmergencyContact1((prevData) => ({
        ...prevData,
        EMGCountry: PatientAddress1.Country,
        EMGState: PatientAddress1.State,
        EMGCity: PatientAddress1.City,
        EMGPlace: PatientAddress1.Place,
        EMGStreet: PatientAddress1.Street,
        EMGMobile: PatientAddress1.Mobile,
        EMGPinCode: PatientAddress1.PinCode,
        EMGEmail: PatientAddress1.Email,
        [name]: type === 'checkbox' ? checked : value,
      }));
    } else {
      // Reset Emergency Contact fields
      setEmergencyContact1((prevData) => ({
        ...prevData,
        EMGCountry: '',
        EMGState: '',
        EMGCity: '',
        EMGPlace: '',
        EMGStreet: '',
        EMGMobile: '',
        EMGPinCode: '',
        EMGEmail: '',
        [name]: type === 'checkbox' ? checked : value,
      }));
    }
  };
  const handleInsuranceChange = (event) => {

    const { name, value } = event.target;
    setInsurance1((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleMedicalLegalCaseChange = (event) => {

    const { name, value } = event.target;
    setMedicalLegalCase1((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    if (!IpAdmissionEdit) {
      if (selectRoom) {
        if (RoomManage.IsPackage === 'Yes') {

          axios.get(`http://127.0.0.1:8000/usercontrol/get_Package_type_Booking?WardType=${selectRoom?.WardType}&RoomType=${selectRoom?.RoomType}&Location=${selectRoom?.Location}`)
            .then((response) => {
              console.log(response)
              const data = response.data
              setpackagedata(data)
              setRoomManage((prevData) => ({
                ...prevData,
                RoomId: selectRoom?.RoomId,
                BuildingName: selectRoom?.BuildingName,
                BlockName: selectRoom?.BlockName,
                FloorName: selectRoom?.FloorName,
                WardType: selectRoom?.WardType,
                RoomType: selectRoom?.RoomType,
                RoomNo: selectRoom?.RoomNo,
                BedNo: selectRoom?.BedNo,
                Charge: '',
                Package: '',
                PackageDays: ''
              }));
            })
            .catch((error) => {
              console.log(error)
            })

        } else {
          setRoomManage((prevData) => ({
            ...prevData,
            RoomId: selectRoom?.RoomId,
            BuildingName: selectRoom?.BuildingName,
            BlockName: selectRoom?.BlockName,
            FloorName: selectRoom?.FloorName,
            WardType: selectRoom?.WardType,
            RoomType: selectRoom?.RoomType,
            RoomNo: selectRoom?.RoomNo,
            BedNo: selectRoom?.BedNo,
            Charge: selectRoom?.Charge,
            Package: '',
            PackageDays: ''
          }));
        }
      }
    }
  }, [selectRoom, RoomManage.IsPackage, IpAdmissionEdit]);


  useEffect(() => {
    if (!IpAdmissionEdit) {
      const data = packagedata.find((p) => +p.PackageId === +RoomManage.Package)
      if (data) {
        setRoomManage((prevData) => ({
          ...prevData,
          Package: data.PackageId,
          PackageDays: data.Days,
          Charge: data.Charge
        }));
      } else {
        setRoomManage((prevData) => ({
          ...prevData,
          Package: '',
          PackageDays: '',
          Charge: ''
        }));
      }
    }
  }, [RoomManage.Package])
  const handlenavigatePatientRegis = () => {
    navigate("/Home/Register-Patient2");
  };

  const handleSearch = () => {
    const patientID = Registerformdata.PatientId;
    const phone = Registerformdata.PatientPhoneNo;
    if (patientID || phone) {

      axios
        .get(
          `http://127.0.0.1:8000/ipregistration/get_patient_datas_booking?patientid=${patientID}&phonenumber=${phone}`,
        )
        .then((response) => {

          const data = response.data
          // console.log(response.data)
          if (!data) {

            userwarn("Patient ID is not avaiable! Please enter a new Register")
            // navigate('/Register-Patient2');
          } else {
            console.log(response.data);
            setRegisterformdata((prevFormData) => ({
              ...prevFormData,
              PatientId: data.PatientID,
              FirstName: data.FirstName,
              LastName: data.LastName,
              PatientPhoneNo: data.PhoneNumber,
              Gender: data.Gender,
              DOB: data.DateofBirth,
              Age: data.Age,
              Title: data.Title,
              Nationality: data.Nationality,
            }));


          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      userwarn('plesae fill patient id or phone number')
    }
  };

  const handleSubmit = () => {
    const data = {
      Booking_Id: IpAdmissionEdit,
      CreatedBy: userRecord?.username,
      Location: userRecord?.location,
      ...Registerformdata,
      ...RoomManage,
      ...PatientAddress1,
      ...EmergencyContact1,
      ...Insurance1,
      ...MedicalLegalCase1
    }

    axios.post(`http://127.0.0.1:8000/ipregistration/${IpAdmissionEdit ? `update_Ip_Admission` : `insert_Ip_Admission`}`, data)
      .then((response) => {
        console.log(response.data)
        dispatchvalue({ type: 'IpAdmissionEdit', value: null })
        handleclear()

      })
      .catch((error) => {
        console.log(error)
      })
  }
  return (
    <>
      <div className='appointment'>
        <div className='h_head'>
          <h3>Ip Admission</h3>
        </div>
        <Accordion
          expanded={
            expanded !== "panel2" &&
            expanded !== "panel3" &&
            expanded !== "panel4" &&
            expanded !== "panel5"
          }
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1bh-content"
          >
            <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
              Personal Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="RegisFormcon">

                <div className="RegisForm_1">
                  <label htmlFor="AppointmentId">
                    Admission Purpose <span>:</span>
                  </label>
                  <select
                    name="AdmissionPurpose"
                    value={Registerformdata.AdmissionPurpose}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="Emergency">Emergency</option>
                    <option value="Surgery">Surgery</option>
                    <option value="MedicalManagement">Medical Management</option>
                    <option value="MedicalLegalCase">Medical Legal Case</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="PatientID" className='PatientID_label'>
                    Patient ID

                    <span>:</span>
                  </label>
                  <input
                    type="text"
                    id="PatientId"
                    name="PatientId"
                    list="Registerformdata_PatientId"
                    value={Registerformdata.PatientId}
                    placeholder="Enter Patient ID"
                    onChange={handlechangeRegisterformdata}
                    required
                  />
                  <datalist
                    id="Registerformdata_PatientId"
                  >
                    {PatientIDPhone.map((p, index) => (
                      <option key={index} value={p.PatientID}>
                        {p.PatientName}
                      </option>
                    ))}
                  </datalist>

                  <button className="PatientId_SearchIcon" onClick={handleSearch}>
                    <SearchIcon />
                  </button>

                </div>

                <div className="RegisForm_1">
                  <label htmlFor="phone">
                    Phone Number <span>:</span>
                  </label>
                  <input
                    type="number"
                    id="phone"
                    value={Registerformdata.PatientPhoneNo}
                    name="PatientPhoneNo"
                    list="Registerformdata_PatientPhNo"
                    placeholder="Enter your Phone Number"
                    onChange={handlechangeRegisterformdata}
                    required
                  />
                  <datalist
                    id="Registerformdata_PatientPhNo"
                  >
                    {PatientIDPhone.map((p, index) => (
                      <option key={index} value={p.PhoneNumber}>
                        {p.PatientName}
                      </option>
                    ))}
                  </datalist>
                  <button className="PatientId_SearchIcon" onClick={handleSearch}>
                    <SearchIcon />
                  </button>

                </div>


              </div>
              <div className="RegisFormcon">


                <div className="RegisForm_1">
                  <label>
                    Tittle <span>:</span>
                  </label>

                  <select
                    name="Title"
                    value={Registerformdata.Title}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="Mr">Mr</option>
                    <option value="Ms">Ms</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Baby">Baby</option>
                    <option value="TransGender">TransGender</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    First Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="FirstName"
                    placeholder="Enter First Name"
                    value={Registerformdata.FirstName}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Last Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="LastName"
                    placeholder="Enter Last Name"
                    value={Registerformdata.LastName}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
              </div>
              <div className="RegisFormcon">


                <div className="RegisForm_1">
                  <label>
                    Gender <span>:</span>
                  </label>

                  <select
                    name="Gender"
                    value={Registerformdata.Gender}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    Date Of Birth<span>:</span>
                  </label>
                  <input
                    type="date"
                    name="DOB"
                    placeholder="Enter Date Of Birth"
                    value={Registerformdata.DOB}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="AppointmentId">
                    Age <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="Age"
                    placeholder="Enter Age"
                    value={Registerformdata.Age}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
              </div>
              <div className="RegisFormcon">


                <div className="RegisForm_1">
                  <label>
                    Referrer <span>:</span>
                  </label>

                  <select
                    name="Referrer"
                    value={Registerformdata.Referrer}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="Self">Self</option>
                    <option value="Consultant">Consultant</option>
                    <option value="Doctor">Doctor</option>
                    <option value="Lab">Lab</option>
                    <option value="Hospital">Hospital</option>
                    <option value="Blood Bank">Blood Bank</option>
                    <option value="Corporate">Corporate</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    Referrer Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="ReferrerName"
                    placeholder="Enter Referrer Name"
                    value={Registerformdata.ReferrerName}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    PrimaryDoctor<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="PrimaryDoctor"
                    placeholder="Enter PrimaryDoctor"
                    value={Registerformdata.PrimaryDoctor}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
              </div>
              <div className="RegisFormcon">


                <div className="RegisForm_1">
                  <label htmlFor="AppointmentId">
                    Admitdate

                    <span>:</span>
                  </label>
                  <input
                    type="date"
                    name="Admitdate"
                    placeholder="Enter Admitdate"
                    value={Registerformdata.Admitdate}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Attender Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="AttenderName"
                    placeholder="Enter AttenderName"
                    value={Registerformdata.AttenderName}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Attender MobileNo <span>:</span>
                  </label>
                  <input
                    type="number"
                    name="AttenderMobileNo"
                    placeholder="Enter AttenderMobile No"
                    value={Registerformdata.AttenderMobileNo}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
              </div>

              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label htmlFor="AppointmentId">
                    Relation <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="Relation"
                    placeholder="Enter Relation"
                    value={Registerformdata.Relation}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    PrimaryDisease<span>:</span>
                  </label>
                  <input
                    type="text"
                    name="PrimaryDisease"
                    placeholder="Enter Primary Disease"
                    value={Registerformdata.PrimaryDisease}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Remarks <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="Remarks"
                    placeholder="Enter Remarks"
                    value={Registerformdata.Remarks}
                    onChange={handlechangeRegisterformdata}
                  />
                </div>
              </div>
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label htmlFor="AppointmentId">
                    Patient Type <span>:</span>
                  </label>
                  <select
                    name="PatientType"
                    value={Registerformdata.PatientType}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="General">General</option>
                    <option value="VIP">VIP</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    Patient Category<span>:</span>
                  </label>

                  <select
                    name="PatientCategory"
                    value={Registerformdata.PatientCategory}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="General">General</option>
                    <option value="Client">Client</option>
                    <option value="Insurance">Insurance</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label>
                    RatecardType <span>:</span>
                  </label>
                  <select
                    name="RatecardType"
                    value={Registerformdata.RatecardType}
                    onChange={handlechangeRegisterformdata}
                  >
                    <option value="">Select</option>
                    <option value="General">General</option>
                    <option value="Special">Special</option>
                    <option value="VIP">VIP</option>
                    <option value="Client">Client</option>
                    <option value="Insurance">Insurance</option>
                  </select>

                </div>
              </div>

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
              Room Management{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="hotelicn_stylr_4">
                <button onClick={handleopenroom} className="hotel_btn_flx3">
                  <h4>
                    {" "}
                    <HotelIcon />
                  </h4>
                  <h4>Show Rooms</h4>
                </button>
              </div>
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    Building Name <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="BuildingName"
                    placeholder="Enter Building Name"
                    value={RoomManage.BuildingName}
                    onChange={handlechangeRoomManage}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    BlockName <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="BlockName"
                    placeholder="Enter Block Name"
                    value={RoomManage.BlockName}
                    onChange={handlechangeRoomManage}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Floor Name <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="FloorName"
                    placeholder="Enter Floor Name"
                    value={RoomManage.FloorName}
                    onChange={handlechangeRoomManage}
                  />
                </div>
              </div>
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    Ward Type <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="WardType"
                    placeholder="Enter Ward Type"
                    value={RoomManage.WardType}
                    onChange={handlechangeRoomManage}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Room Type <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="RoomType"
                    placeholder="Enter Room Type"
                    value={RoomManage.RoomType}
                    onChange={handlechangeRoomManage}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    Room No <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="RoomNo"
                    placeholder="Enter Room No"
                    value={RoomManage.RoomNo}
                    onChange={handlechangeRoomManage}
                  />
                </div>
              </div>
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label>
                    Bed No <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="BedNo"
                    placeholder="Enter Bed No"
                    value={RoomManage.BedNo}
                    onChange={handlechangeRoomManage}
                  />
                </div>
                <div className="RegisForm_1">
                  <label>
                    IsPackage <span>:</span>
                  </label>
                  <select
                    name="IsPackage"
                    value={RoomManage.IsPackage}
                    onChange={handlechangeRoomManage}
                  >
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>
                {RoomManage.IsPackage === 'Yes' ?
                  <div className="RegisForm_1">
                    <label>
                      Package <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Package"
                      list="package_lists"
                      placeholder="Enter Package"
                      value={RoomManage.Package}
                      onChange={handlechangeRoomManage}
                    />
                    <datalist
                      id="package_lists"
                    >
                      <option value=''>Select</option>
                      {packagedata.map((p) => (
                        <option key={p.PackageId} value={p.PackageId}>

                          {`Days - ${p.Days} , Charge - ${p.Charge}`}
                        </option>
                      ))}
                    </datalist>

                  </div>
                  :
                  <div className="RegisForm_1">
                    <label>
                      Charge <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Charge"
                      readOnly
                      placeholder="Enter Charge"
                      value={RoomManage.Charge}
                      onChange={handlechangeRoomManage}
                    />

                  </div>
                }


              </div>
              <div className="RegisFormcon">
                {RoomManage.IsPackage === 'Yes' &&
                  <>
                    <div className="RegisForm_1">
                      <label>
                        Expected Days <span>:</span>
                      </label>
                      <input
                        type="text"
                        name="PackageDays"
                        readOnly
                        placeholder="Enter PackageDays"
                        value={RoomManage.PackageDays}
                        onChange={handlechangeRoomManage}
                      />
                    </div>
                    <div className="RegisForm_1">
                      <label>
                        Charge <span>:</span>
                      </label>
                      <input
                        type="text"
                        name="Charge"
                        readOnly
                        placeholder="Enter Charge"
                        value={RoomManage.Charge}
                        onChange={handlechangeRoomManage}
                      />

                    </div>
                  </>}
                <div className="RegisForm_1">
                  <label>
                    Advance <span>:</span>
                  </label>
                  <input
                    type="text"
                    name="Advance"
                    placeholder="Enter Advance"
                    value={RoomManage.Advance}
                    onChange={handlechangeRoomManage}
                  />

                </div>
              </div>
              {showroom && (
                <div className="IP_popup_Regis" onClick={handlecloseroom}>
                  <div
                    className="IP_popup_content_regis"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <RoomsAvail />
                  </div>
                </div>
              )}
            </Typography>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={expanded === "panel3"}
          onChange={handleChange("panel3")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2bh-content"
            id="panel1bh-header"
          >
            <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
              Address/Contact{" "}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
            >
              <div className="appointment">
                <div className="">
                  <h3>Contact Address</h3>
                </div>
                <br></br>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Country <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Country"
                      placeholder="Enter Country"
                      value={PatientAddress1.Country}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      State <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="State"
                      placeholder="Enter State"
                      value={PatientAddress1.State}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      City <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="City"
                      placeholder="Enter City"
                      value={PatientAddress1.City}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Place <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Place"
                      placeholder="Enter Place"
                      value={PatientAddress1.Place}
                      onChange={handlePatientAddressChange}
                    />
                  </div>

                  <div className="RegisForm_1">
                    <label>
                      Street <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Street"
                      placeholder="Enter Street"
                      value={PatientAddress1.Street}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Pin Code <span>:</span>
                    </label>
                    <input
                      type="number"
                      name="PinCode"
                      placeholder="Enter Pincode"
                      value={PatientAddress1.PinCode}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                </div>

                <div className="RegisFormcon">

                  <div className="RegisForm_1">
                    <label>
                      Email <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="Email"
                      placeholder="Enter Email"
                      value={PatientAddress1.Email}
                      onChange={handlePatientAddressChange}
                    />
                  </div>
                </div>
              </div>

              <div className="appointment">
                <h3>Attender Contact</h3>
                <div className="same_adress_prsn">
                  <div>
                    <input
                      type="checkbox"
                      name="SameAsContactAddress"
                      checked={EmergencyContact1.SameAsContactAddress}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                  <div>
                    <label htmlFor="checkbox">Same As Contact Address</label>
                  </div>
                </div>


                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Country <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGCountry"
                      placeholder="Enter Country"
                      value={EmergencyContact1.EMGCountry}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      State <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGState"
                      placeholder="Enter State"
                      value={EmergencyContact1.EMGState}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      City <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGCity"
                      placeholder="Enter City"
                      value={EmergencyContact1.EMGCity}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Place <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGPlace"
                      placeholder="Enter Place"
                      value={EmergencyContact1.EMGPlace}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Street <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGStreet"
                      placeholder="Enter Street"
                      value={EmergencyContact1.EMGStreet}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Pin Code <span>:</span>
                    </label>
                    <input
                      type="number"
                      name="EMGPinCode"
                      placeholder="Enter PinCode"
                      value={EmergencyContact1.EMGPinCode}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Email <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EMGEmail"
                      placeholder="Enter Email"
                      value={EmergencyContact1.EMGEmail}
                      onChange={handleSameAsContactAddressChange}
                    />
                  </div>
                </div>
              </div>
            </Typography>
          </AccordionDetails>
        </Accordion>
        {Registerformdata.PatientCategory === 'Insurance' &&
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
                Insurance Forms
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Insurance <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="InsuranceName"
                      placeholder="Enter Insurance"
                      value={Insurance1.InsuranceName}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Insured Person <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="InsuredPerson"
                      placeholder="Enter Insured Person"
                      value={Insurance1.InsuredPerson}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Scheme <span>:</span>
                    </label>
                    <select
                      name="Scheme"
                      placeholder="Enter Scheme"
                      value={Insurance1.Scheme}
                      onChange={handleInsuranceChange}
                    >
                      <option value="">Select</option>
                      <option value="Self">Self</option>
                    </select>
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      TPA <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="TPA"
                      placeholder="Enter TPA"
                      value={Insurance1.TPA}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Card No <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="CardNo"
                      placeholder="Enter Card No"
                      value={Insurance1.CardNo}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Availed Type <span>:</span>
                    </label>
                    <select
                      name="AvailedType"
                      value={Insurance1.AvailedType}
                      onChange={handleInsuranceChange}
                    >
                      <option value="">Select</option>
                      <option value="Self">Self</option>
                      <option value="Dependent">Dependent</option>
                    </select>
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Valid Upto <span>:</span>
                    </label>
                    <input
                      type="date"
                      name="ValidUpto"
                      placeholder="Enter Valid Upto"
                      value={Insurance1.ValidUpto}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Effect Date<span>:</span>
                    </label>
                    <input
                      type="date"
                      name="EffectDate"
                      placeholder="Enter Effect Date"
                      value={Insurance1.EffectDate}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Estimate Bill <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="EstimateBill"
                      placeholder="Enter Estimate Bill"
                      value={Insurance1.EstimateBill}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                </div>

                <div className="RegisFormcon">
                  
                  <div className="RegisForm_1">
                    <label>
                      Plan <span>:</span>
                    </label>
                    <select
                      name="Plan"
                      placeholder="Enter Plan"
                      value={Insurance1.Plan}
                      onChange={handleInsuranceChange}
                    >
                      <option value="">Select</option>
                      <option value="Self">Self</option>
                    </select>
                  </div>
               
                  <div className="RegisForm_1">
                    <label>
                    Pre-Auth Amount <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="PreAuthAmount"
                      placeholder="Enter Pre-Auth Amount"
                      value={Insurance1.PreAuthAmount}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                    CoPayment Type<span>:</span>
                    </label>
                    
                    <select
                      name="CoPaymentType"
                      onChange={handleInsuranceChange}
                      value={Insurance1.CoPaymentType}
                    >
                        <option value='Percentage'>Percentage</option>
                        <option value='Value'>Value</option>
                    </select>
                  </div>
                </div>
                <div className="RegisFormcon">
                 
                  
                  <div className="RegisForm_1">
                    <label>
                    Insurance Contribution <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="InsuranceContri"
                      placeholder="Enter Insurance Contribution"
                      value={Insurance1.EstimateBill}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                    Patient Contribution <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="PatientContri"
                      placeholder="Enter Patiennt Contribution"
                      value={Insurance1.PatientContri}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                    Co-Payment Logic<span>:</span>
                    </label>
                    
                    <select
                      name="CoPaymentLogic"
                      onChange={handleInsuranceChange}
                      value={Insurance1.CoPaymentLogic}
                    >
                        <option value=''>Select</option>
                        <option value='PreAuth'>Pre-Auth Amount</option>
                        <option value='Final'>Final Amount</option>
                    </select>
                  </div>
                </div>
                <div className="RegisFormcon">
                  
                  
                
                  <div className="RegisForm_1">
                    <label>
                      Remarks <span>:</span>
                    </label>
                    <textarea
                      type="text"
                      name="Remarks"
                      placeholder="Enter Remarks"
                      value={Insurance1.Remarks}
                      onChange={handleInsuranceChange}
                    />
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>}

        {Registerformdata.AdmissionPurpose === 'MedicalLegalCase' &&
          <Accordion
            expanded={expanded === "panel5"}
            onChange={handleChange("panel5")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel1bh-header"
            >
              <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
                Medical Legal Case
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
              >
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Informed BY <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="InformedBY"
                      placeholder="Enter Informed BY"
                      value={MedicalLegalCase1.InformedBY}
                      onChange={handleMedicalLegalCaseChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Officer Name <span>:</span>
                    </label>
                    <input
                      type="text"
                      name="OfficerName"
                      placeholder="Enter Officer Name"
                      value={MedicalLegalCase1.OfficerName}
                      onChange={handleMedicalLegalCaseChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Police Station<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="PoliceStation"
                      placeholder="Enter Police Station"
                      value={MedicalLegalCase1.PoliceStation}
                      onChange={handleMedicalLegalCaseChange}
                    />
                  </div>
                </div>
                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      FIR No<span>:</span>
                    </label>
                    <input
                      type="text"
                      name="FIRNo"
                      placeholder="Enter FIR No"
                      value={MedicalLegalCase1.FIRNo}
                      onChange={handleMedicalLegalCaseChange}
                    />
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Code Status <span>:</span>
                    </label>
                    <select
                      name="CodeStatus"
                      placeholder="Enter Code Status"
                      value={MedicalLegalCase1.CodeStatus}
                      onChange={handleMedicalLegalCaseChange}
                    >
                      <option value="">Select</option>
                      <option value="Full">Full</option>
                      <option value="DNR">DNR</option>
                      <option value="DNI">DNI</option>
                    </select>
                  </div>
                  <div className="RegisForm_1">
                    <label>
                      Type of Admit<span>:</span>
                    </label>
                    <select
                      name="TypeofAdmission"
                      placeholder="Enter Type of Admit"
                      value={MedicalLegalCase1.TypeofAdmission}
                      onChange={handleMedicalLegalCaseChange}
                    >
                      <option value="">Select</option>
                      <option value="Non-MLC">Non-MLC</option>
                      <option value="MLC">MLC</option>
                    </select>
                  </div>
                </div>

                <div className="RegisFormcon">
                  <div className="RegisForm_1">
                    <label>
                      Admitting Diagnosis <span>:</span>
                    </label>
                    <textarea
                      type="text"
                      name="AdmittingDiagnosis"
                      placeholder="Enter Admitting Diagnosis"
                      value={MedicalLegalCase1.AdmittingDiagnosis}
                      onChange={handleMedicalLegalCaseChange}
                    />
                  </div>
                </div>
              </Typography>
            </AccordionDetails>
          </Accordion>}
        <ToastContainer />
      </div>
      <div className="button_buton_class">
        <button className="btn-add" onClick={handleSubmit}>
          Save
        </button>
      </div>
    </>
  );
}

export default IpRegistration1;

