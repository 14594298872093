import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import axios from 'axios';
const DigitalExpenses = () => {
    const [userRecord, setUserRecord] = useState([]);
    console.log(userRecord);
  
    useEffect(() => {
      const userRecord = localStorage.getItem("token");
  
      if (userRecord) {
        const decodedToken = (token) => {
          const payloadBase64 = token.split(".")[1];
          const decodedPayload = atob(payloadBase64);
          return JSON.parse(decodedPayload);
        };
        const decodedTokenData = decodedToken(userRecord);
        console.log("decodedTokenData", decodedTokenData);
        setUserRecord(decodedTokenData);
      }
    }, []); // console.log('sss',userRecord)

    const [Billing_date, setBilling_date] = useState(new Date())

    const formattedDate = format(Billing_date, 'yyyy-MM-dd');

    const [Expenses_Invoice_No, setExpenses_Invoice_No] = useState(null)

    const [ClinicDetials, setClinicDetials] = useState({
        ClinicLogo: null,
        ClinicName: '',
        ClinicGST: '',
        ClinicAddress: '',
        ClinicCity: '',
        ClinicState: '',
        ClinicCode: '',
        ClinicMobileNo: '',
        ClinicLandLineNo: '',
        ClinicMailID: '',
    })


    const [Issue_type_list, setIssue_type_list] = useState([])

    const [Expenses_Name_list, setExpenses_Name_list] = useState([])

    const [Issue_To_Name_list, setIssue_To_Name_list] = useState([])

    const [Issue_to_persionID, setIssue_to_persionID] = useState('');

    const [Issue_to_persionName, setIssue_to_persionName] = useState('');

    const [get_total_Amount, setget_total_Amount] = useState('')

    const [view_Amount, setview_Amount] = useState(0)

    const [view_Amount1, setview_Amount1] = useState(0)

    const [view_Amount2, setview_Amount2] = useState(0)
   
    const [Expense_Amount, setExpense_Amount] = useState('')

    const [Reasion_Expense, setReasion_Expense] = useState('')

    const [Issue_to_methods, setIssue_to_methods] = useState('')

    const [get_total_cash,setget_total_cash] = useState('')

    const [get_total_card,setget_total_card] = useState('')

    const [get_expence_data,setget_expence_data] = useState([])

    const [Expense_Type, setExpense_Type] = useState('')

    const [Billpay_method, setBillpay_method] = useState('Cash')

    const [Today_expense_total_amount, setToday_expense_total_amount] = useState('')

    console.log('111', formattedDate)




    


    //   useEffect(() => {
    //     if (!isNaN(Expense_Amount)) {
    //       const newTotalAmount = view_Amount - Expense_Amount;
    //       setview_Amount(newTotalAmount);
    //     }
    //   }, [Expense_Amount]);



    useEffect(() => {
        axios.get(`http://127.0.0.1:8000/usercontrol/getClinic?location=${userRecord.location}`)
            .then((response) => {
                console.log(response.data)
                const data = response.data[0]
                setClinicDetials((prev) => ({
                    if(data){
                        setClinicDetials((prev) => ({
                            ...prev,
                            ClinicAddress: data.door_no + ',' + data.area + "," + data.street,
                            ClinicGST: data.Gst_no,
                            ClinicCity: data.city,
                            ClinicState: data.state,
                            ClinicCode: data.pincode,
                            ClinicMobileNo: data.phone_no,
                            ClinicLandLineNo: data.landline_no,
                            ClinicMailID: data.email,
                        }))
     }
                }))
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get('http://127.0.0.1:8000/usercontrol/getAccountsetting')
            .then((response) => {
                console.log(response.data)
                const data = response.data;
                setClinicDetials((prev) => ({
                    ...prev,
                    ClinicName: data.Clinic_Name,
                    ClinicLogo: (`data:image/png;base64,${data.Clinic_Logo}`)
                }))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [userRecord.location])


    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/Pettycash_link/Get_Issue_to_types_data_By_Active`)
            .then((response) => {
                setIssue_type_list(response.data.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])



    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/Pettycash_link/Get_Expenses_Name_data_method?method=${Issue_to_methods}`)
            .then((response) => {
                setExpenses_Name_list(response.data.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [Issue_to_methods])


    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/usercontrol/getuserregisterID_NAME`)
            .then((response) => {
                setIssue_To_Name_list(response.data)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }, [])



    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/GeneralBilling/get_billing_Amount_bycash?Billing_date=${formattedDate}&location=${userRecord.location}`)
            .then((response) => {

                setget_total_Amount(response.data.net_amount.toFixed(0));
                setget_total_cash(response.data.amount_types[1].Total_Amount);
                setget_total_card(response.data.amount_types[0].Total_Amount);
                console.log(response.data)
                
            })
            .catch((error) => {
                console.error(error);
            });

    }, [formattedDate,userRecord.location])

    const get_expense_total = () => {
        axios.get(`http://127.0.0.1:8000/Pettycash_link/get_Expense_billing_Amount_bycash?Billing_date=${formattedDate}&location=${userRecord.location}`)
            .then((response) => {
                console.log(response.data)
                let T_amount = response.data.net_amount
                setToday_expense_total_amount(T_amount)
            })
            .catch((error) => {
                console.error(error);
            });
    }

    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/Pettycash_link/get_Expense_billing_Amount_bycash?Billing_date=${formattedDate}&location=${userRecord.location}`)
            .then((response) => {
                console.log(response.data)
                let T_amount = response.data.net_amount
                setToday_expense_total_amount(T_amount)
            })
            .catch((error) => {
                console.error(error);
            });




       


    }, [formattedDate, userRecord.location])



    useEffect(() => {

        axios.get(`http://127.0.0.1:8000/Pettycash_link/get_Expense_billing_Amount_by_type?Billing_date=${formattedDate}&location=${userRecord.location}`)
    .then((response) => {
        console.log(response.data)
        const data= response.data.data
        setget_expence_data(data)
    })
    .catch((error) => {
        console.error(error);
    });


       


    }, [formattedDate, userRecord.location])

   
    const get_invoice_num = () => {
        axios.get(`http://127.0.0.1:8000/Pettycash_link/get_ExpensesBilling_table_invoice?location=${userRecord.location}`)
            .then((response) => {
                setExpenses_Invoice_No(response.data.nextInvoiceNumber)
                console.log(response.data)
            })
            .catch((error) => {
                console.error(error);
            });

    }

    useEffect(() => {
        get_invoice_num()
    }, [userRecord.location])


    const handleChange = (e) => {
        const value = e.target.value
     
            if (value <= get_total_Amount) {
                setExpense_Amount(value)
            } else {
                setExpense_Amount(value)
            }
        
    }


    
    useEffect(() => {
        // Initialize new total amounts as the original totals
        let newTotalAmount = get_total_Amount;
        let newTotalCash = get_total_cash;
        let newTotalCard = get_total_card;
    
        // Subtract Today's expense total amount if it's a valid number
        if (!isNaN(Today_expense_total_amount)) {
            newTotalAmount -= Today_expense_total_amount;
        }
    
        // Loop through expenses data and subtract based on payment_type
      if(get_expence_data!= null) 
       get_expence_data.forEach(expense => {
            const expenseAmount = parseFloat(expense.amount);
            if (!isNaN(expenseAmount)) {
                switch(expense.payment_type) {
                    case 'Cash':
                        newTotalCash -= expenseAmount;
                        break;
                    case 'Card':
                    case 'OnlinePayment':
                        case 'Cheque': // Assuming OnlinePayment should be treated the same as Card
                        newTotalCard -= expenseAmount;
                        break;
                    default:
                        // Optionally handle unknown payment types
                        break;
                }
            }
        });    
        // Additionally subtract Expense_Amount based on the selected Billpay_method
        // This section is for any additional expense amount not included in the fetched data
        if (!isNaN(Expense_Amount)) {
            switch(Billpay_method) {
                case 'Cash':
                    newTotalCash -= Expense_Amount;
                    break;
                case 'Card':
                case 'OnlinePayment':
                    newTotalCard -= Expense_Amount;
                    break;
                default:
                    // Handle default case if necessary
                    break;
            }
        }
    
        // Update state with the new calculated amounts
        setview_Amount(newTotalAmount);
        setview_Amount1(newTotalCash);
        setview_Amount2(newTotalCard);
    
    }, [Today_expense_total_amount, get_total_Amount, get_total_cash, get_total_card, Expense_Amount, Billpay_method, get_expence_data, ]);
    
    
    const Save_Expenses_data = () => {

        if (Expense_Type === '' || Expense_Amount === '' || Reasion_Expense === '' 
            ) {

            alert('Enter All Data')

        } else {

            const ClinicName = userRecord.location
            const Issue_From_Id = userRecord.user_id
            const Issue_From_Name = userRecord.username
            const paymenttype = Billpay_method

            axios
                .post('http://127.0.0.1:8000/Pettycash_link/Post_Expensesbill_data', {
                    ClinicName,
                    formattedDate,
                    Expenses_Invoice_No,
                    Issue_From_Id,
                    Issue_From_Name,
                    Issue_to_methods,
                    Issue_to_persionID,
                    Issue_to_persionName,
                    Expense_Type,
                    Billpay_method,
                    Expense_Amount,
                    Reasion_Expense,
                    paymenttype
                })
                .then((res) => {
                    console.log(res.data)
                    setIssue_to_methods('')
                    setIssue_to_persionID('')
                    setIssue_to_persionName('')
                    setExpense_Type('')
                    setExpense_Amount('')
                    setReasion_Expense('')
                    get_invoice_num()
                    setBillpay_method('')
                    get_expense_total()
                    alert('Data Added Successfully');
                })
                .catch((err) => console.log(err));

        }


    }


    console.log('ClinicDetials.ClinicCit',get_expence_data)
    console.log(get_total_cash);
    return (
        <div className='ShiftClosing_over'>
            <div className="ShiftClosing_Container">
                <div className="ShiftClosing_header">
                    <h3>Digital Expenses</h3>
                </div>
                <div className="ShiftClosing_Invoice_container">
                    {/* <div className="ShiftClosing_Invoice">
                    <div>
                        <span className='ShiftClosing_Invoice_span'>
                            <span >Shift closing Invoice : </span>
                            <span>1</span>
                        </span>
                    </div>
                </div> */}
                    <div className="ShiftClosing_Con">
                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor=""> Branch Name: <span>:</span></label>
                                <input
                                    value={userRecord.location}
                                    type="text"
                                    name="Branch_Name"
                                    readOnly
                                />

                            </div>
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Transaction No: <span>:</span></label>
                                <input
                                    name="Transaction_No"
                                    readOnly
                                    value={Expenses_Invoice_No}
                                />
                            </div>
                        </div>
                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Date: <span>:</span></label>
                                <input
                                    type='Date'
                                    name="Billing_date"
                                    value={Billing_date.toISOString().split('T')[0]} // Convert to ISO string and extract the date part
                                    onChange={(e) => setBilling_date(new Date(e.target.value))}
                                    readOnly
                                />
                            </div>

                            <div className="ShiftClosing_Con_column">

                                <label htmlFor="">Issue From <span>:</span></label>
                                <div className="Issue_input">
                                    <input
                                        type="text"
                                        name="Issue_From_id"
                                        value={userRecord.user_id}
                                        readOnly
                                    />
                                    <input
                                        name="Issue_From_name"
                                        className='Issue_input_111'
                                        value={userRecord.username}
                                        readOnly

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Expences Method <span>:</span></label>
                                <select
                                    type="text"
                                    name="Issue_To_type"
                                    value={Issue_to_methods}
                                    onChange={(e) => { setIssue_to_methods(e.target.value) }}
                                >
                                    <option value={''}>-Select-</option>
                                    <option value='DIRECT METHOD'>Direct Method</option>
                                    <option value='INDIRECT METHOD'>Indirect Method</option>
                                </select>
                            </div>

                            {/* <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Issue To: <span>:</span></label>
                                <div className="Issue_input">
                                    <input
                                        type="text"
                                        name="get_Issue_To_id"
                                        placeholder='ID'
                                        list="Issue_To_id"
                                        value={Issue_to_persionID}
                                        onChange={(e) => {
                                            setIssue_to_persionID(e.target.value);
                                            // Add logic to find the corresponding name based on the ID
                                            const selectedPerson = Issue_To_Name_list.find(item => item.user_id === parseInt(e.target.value));
                                            if (selectedPerson) {
                                                setIssue_to_persionName(selectedPerson.username);
                                            } else {
                                                setIssue_to_persionName(''); // Reset the name if ID is not found
                                            }
                                        }}
                                    />

                                    <datalist id="Issue_To_id">
                                        {Issue_To_Name_list.map((item, index) => (
                                            <option key={index} value={item.user_id}></option>
                                        ))}
                                    </datalist>


                                    <input
                                        name="get_Issue_To_name"
                                        className='Issue_input_111' 
                                        placeholder='NAME'
                                        list="Issue_To_name"
                                        value={Issue_to_persionName}
                                        onChange={(e) => {
                                            setIssue_to_persionName(e.target.value)
                                            const selectedPerson = Issue_To_Name_list.find(item => item.username === e.target.value);
                                            if (selectedPerson) {
                                                setIssue_to_persionID(selectedPerson.user_id);
                                            } else {
                                                setIssue_to_persionID(''); // Reset the name if ID is not found
                                            }

                                        }} // Allow manual input for the name
                                    />

                                    <datalist id="Issue_To_name">
                                        {Issue_To_Name_list.map((item, index) => (
                                            <option key={index} value={item.username}></option>
                                        ))}
                                    </datalist>

                                </div>
                            </div> */}
                              {/* <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Expenses Types: <span>:</span></label>
                                <select
                                    type="text"
                                    name="Expenses_Types"
                                    value={Expense_Type}
                                    onChange={(e) => { setExpense_Type(e.target.value) }}
                                >
                                    <option value={''}>-Select-</option>
                                    {Expenses_Name_list.map((ele, ind) => (
                                        <option key={ind} value={ele.Expenses_Name}>
                                            {ele.Expenses_Name}
                                        </option>
                                    ))}
                                </select>
                            </div> */}

                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Expenses Types: <span>:</span></label>
                                <select
                                    type="text"
                                    name="Expenses_Types"
                                    value={Expense_Type}
                                    onChange={(e) => { setExpense_Type(e.target.value) }}
                                >
                                    <option value={''}>-Select-</option>
                                    {Expenses_Name_list.map((ele, ind) => (
                                        <option key={ind} value={ele.Expenses_Name}>
                                            {ele.Expenses_Name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="ShiftClosing_Con_row">

                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Amount <span>:</span></label>
                                <input
                                    type='number'
                                    name="Given_amount"
                                    placeholder='Enter Amount'
                                    value={Expense_Amount}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className='ShiftClosing_Con_column'>
                                            <label>
                                                Payment Method <span>:</span>
                                            </label>
                                            <select name="Discount" value={Billpay_method} onChange={(e) => setBillpay_method(e.target.value)} >
                                                <option value="">Select</option>
                                                {/* <option value="Cash">Cash</option> */}
                                                <option value="Card">Card</option>
                                                <option value="OnlinePayment">Online Payment</option>
                                                <option value="Cheque">Cheque</option>

                                            </select>
                                        </div>
                        </div>

                        <div className="ShiftClosing_Con_row">
                            <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Reason: <span>:</span></label>
                                <textarea name="Reasion_Expense"
                                    className="cashInventory_txt"
                                    placeholder='Only 100 letters'
                                    value={Reasion_Expense}
                                    onChange={(e) => {
                                        const inputText = e.target.value;

                                        if (inputText.length <= 100) {
                                            setReasion_Expense(inputText);
                                        } else {
                                            alert('Maximum allowed wordsss is 100');
                                        }
                                    }}
                                />
                            </div>

                            <div className="ShiftClosing_Con_column Spanspace">
                                <span className='Spanletter'> * Total Balance Amount Is {view_Amount}</span>
                                {/* <span className='Spanletter'> * Balance Amount In Cash{view_Amount1}</span> */}
                                <span className='Spanletter'> * Balance Amount In Recived Bank {view_Amount2}</span>
                            </div>
                        </div>
                        <div >
                            <button className="btn_1" onClick={Save_Expenses_data}>Save</button>
                        </div>
                    </div>
                </div>
                {/* <div className="Shift_button">
                <button>save</button>
                </div> */}
            </div>
        </div>
    )
}

export default DigitalExpenses;

