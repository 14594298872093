import React, { useState, useEffect } from 'react';
// import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { DataGrid } from '@mui/x-data-grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
// import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios'
import { useSelector } from 'react-redux';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const yourStyles = {
  position: 'absolute',
  inset: '100px',
  border: '1px solid rgb(204, 204, 204)',
  background: 'rgb(97 90 90 / 75%)',
  overflow: 'auto',
  borderRadius: '4px',
  outline: 'none',
  padding: '0px'
}

const LeaveMangement = ( ) => {

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const userRecord=useSelector(state=>state.userRecord?.UserData)


  // const [searchFormdata, setSearchFormData]=useState({
  //   employeename:'',
  //   designation :'',
  //   date:''
  // })
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery1, setSearchQuery1] = useState('');
  const [searchQuerypre,setSearchQuerypre] =useState('')
  const [searchQuerypre1,setSearchQuerypre1] =useState('')

  const [rows, setRows] = useState([]);
  const [rows1, setRows1] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [filteredRows1, setFilteredRows1] = useState([]);
  const [showsudden,setshowsudden]=useState(false)
  const showdown = rows.length;
  const showdown1 = rows1.length;
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const [page, setPage] = useState(0);
  // const [page1, setPage1] = useState(0);
  const pageSize = 10;


  console.log('userdata', userRecord);
  const [expanded, setExpanded] = useState("panel1");
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');
  const [reason1, setReason1] = useState('');
  const [selectedRowData, setSelectedRowData] = useState(null)
  const [selectedImage, setSelectedImage] = useState(null);
  // Function to handle changes in the form fields
  const [openModal1, setOpenModal1] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  // const [openImageView, setopenImageView] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
    setOldStatus(params.status);
    setStatus(params.status);


  };
  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReason1(newReason);
  };
  const handleEditClick1 = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
    setOldStatus(params.status);
    setStatus(params.status);

  };


 



  // First Datagrid for other leave types
  const [columns] = React.useState([
    { field: "Sl_No", headerName: "Sl_NO", width: 80 },
    { field: "employeeid", headerName: "Employee ID", width: 120 },
    { field: "employeename", headerName: "Employee Name", width: 100 },
    { field: "leaveType", headerName: "Leave Type", width: 100 },
    { field: "fromdate", headerName: "From Date", width: 100 },
    { field: "todate", headerName: "To Date", width: 100 },
    { field: 'days', headerName: 'Days', width: 80 },
    { field: "reason", headerName: "Reason", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150
    },
    {
      field: "rejectstatus",
      headerName: "Reject Reason",
      width: 150
    },
    {
      field: "medicalcertificate",
      headerName: "View",
      width: 80,
      renderCell: (params) => {
        if (params.row.leaveType === 'sick') {
          return (
            <Button className="cell_btn" onClick={() => handleVisibilityClick(params.row.medicalcertificate)}>
              <VisibilityIcon />
            </Button>
          );
        } else {
          return <span>None</span>;
        }
      },
    },


    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleEditClick(params.row)}>
            <EditIcon />
          </Button>

        </>
      ),
    },
  ]);



  // second datagrid2 for permission approval
  const [columns1] = React.useState([
    { field: "Sl_No", headerName: "Sl_NO", width: 80 },
    { field: "employeeid", headerName: "Employee ID", width: 130 },
    { field: "employeename", headerName: "Employee Name", width: 150 },
    { field: "leaveType", headerName: "Leave Type", width: 130 },
    { field: "fromtime", headerName: "From Time", width: 100 },
    { field: "totime", headerName: "To Time", width: 100 },
    { field: 'hours', headerName: 'Hours', width: 80 },
    { field: "reason", headerName: "Reason", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150
    },
    {
      field: "rejectstatus",
      headerName: "Reject Reason",
      width: 150
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleEditClick1(params.row)}>
            <EditIcon />
          </Button>

        </>
      ),
    },
  ]);

  const [rolename, setRolename] = useState([])
  useEffect(() => {
    axios
      .get(
        'http://127.0.0.1:8000/HRmanagement/getRole_all'
      )
      .then((response) => {
        console.log(response.data);
        setRolename(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  }, [userRecord?.location]);







  useEffect(() => {
    fetchLeaveData();
    fetchPermissionsData();
  }, [showsudden, userRecord]);

  const fetchLeaveData = () => {
    fetch(`http://127.0.0.1:8000/HRmanagement/get_leave_register_submit?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data);
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.designation,
            leaveType: userdata.LeaveType,
            fromdate: userdata.FromDate,
            todate: userdata.ToDate,
            days: userdata.DaysCount,
            reason: userdata.Reason,
            medicalcertificate: userdata.Medical_certificate,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus
          }));
          setRows(Records);
          console.log('record', Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchPermissionsData = () => {
    fetch(`http://127.0.0.1:8000/HRmanagement/get_for_permission_submit?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)

        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            leaveType: userdata.LeaveType,
            fromtime: userdata.fromtime,
            designation: userdata.designation,
            totime: userdata.totime,
            reason: userdata.Reason,
            hours: userdata.hours,
            status: userdata.status,
            rejectstatus: userdata.rejectstatus
          }));
          setRows1(Records);
          console.log(Records)
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  const handleVisibilityClick = (medicalcertificate) => {
    setModalContent(medicalcertificate);
    setModalIsOpen(true);
};

const closeModal = () => {
    setModalIsOpen(false);
    setModalContent('');
};
  // handlesubmit for data get


  const handleSubmit = () => {
    const apiUrl = 'http://127.0.0.1:8000/HRmanagement/update_leave_status_admin';
    const submissionData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      reason: reason1,

    };
    console.log('summa', submissionData)
    axios.post(apiUrl, submissionData)
      .then(response => {
        console.log('Submission response:', response.data);
        alert('Leave status updated successfully');
        setOpenModal(false);
        // window.location.reload();
        setReason1();
        setStatus();
        setshowsudden(!showsudden)
      })
      .catch(error => {
        console.error('Error submitting data:', error);
      });
  };
  // Acordian panel change
  const handleChange1 = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

 
  useEffect(() => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    const lowerCaseQuery1 = searchQuery1.toLowerCase();
  
    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);
  
      if (!searchQuery && !searchQuery1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }
  
      return nameMatches && designationMatches;
    };
  
    const filteredData = rows.filter(filterRow);
 
  
    setFilteredRows(filteredData);

  }, [searchQuery, rows, rows1, searchQuery1]);
  
  

  useEffect(() => {
    const lowerCaseQuerypre = searchQuerypre.toLowerCase();
    const lowerCaseQuerypre1 = searchQuerypre1.toLowerCase();
  
    const filterRow = (row) => {
      const lowerCaseEmployeeName = row.employeename.toLowerCase();
      const lowerCaseDesignation = row.designation.toLowerCase();
      const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuerypre);
      const designationMatches = lowerCaseDesignation.includes(lowerCaseQuerypre1);
  
      if (!searchQuerypre && !searchQuerypre1) {
        // If both search queries are empty, do not filter out any data
        return true;
      }
  
      return nameMatches && designationMatches;
    };
  
    
    const filteredData1 = rows1.filter(filterRow);
  
 
    setFilteredRows1(filteredData1);
  }, [searchQuerypre,rows1, searchQuerypre1]);
  
 







  return (
    <div>



      <Accordion
        expanded={
          expanded !== "panel2"
        }
        onChange={handleChange1("panel1")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">

            Leave Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div className="appointment">


            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">Employee Name :</label>
                <input
                  type="text"
                  id="date"
                  name='employeeName'
                  placeholder='Enter Employee Name'
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">Designation :</label>
                <select
                  name="designation"
                  value={searchQuery1}
                  onChange={(e)=>setSearchQuery1(e.target.value)}
                  className="new-custom-input-phone wei32j"
                  required
                >
                  <option value="select">Select</option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="inp_1">
                <label htmlFor="input">Date :</label>
                <input
                  type="date"
                  id="date"
                  // value={searchFormdata.date}
                  // onChange={handleChange}
                />
              </div> */}


              {/* <button className="btn_1" onClick={handleSearch}>
                <SearchIcon />
              </button> */}
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <div className=" grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={100}
                onSelectionModelChange={handleSelectionModelChange}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" data_grid"
              />
              {showdown > 10 && filteredRows.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {showdown !== 0 && filteredRows.length !== 0 ? (
            ''
          ) : (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}

               <Modal isOpen={modalIsOpen} onRequestClose={closeModal} style={{ content: { ...yourStyles } }}>
            <div className="pdf_img_show">
              {modalContent && modalContent.toLowerCase().startsWith("data:application/pdf;base64,") ? (
                <iframe
                  title="PDF Viewer"
                  src={modalContent}
                  style={{
                    width: "100%",
                    height: "435px",
                    border: "1px solid rgba(0, 0, 0, 0.5)",
                  }}
                />
              ) : (
                <img
                  src={modalContent}
                  alt="Lab Image"
                  style={{
                    width: "80%",
                    height: "75%",
                    marginTop: "20px",
                  }}
                />
              )}
              <div className="jhuhhjh">
                <Button
                  style={{ color: "white" }}
                  className="clse_pdf_img"
                  onClick={closeModal}
                >
                  <HighlightOffIcon
                    style={{
                      fontSize: "40px",
                      backgroundColor: "hsl(33, 100%, 50%)",
                      borderRadius: "40px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </Modal>

        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={expanded === "panel2"}
        onChange={handleChange1("panel2")}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Typography sx={{ width: "45%", flexShrink: 0 }} id="panel1bh-header">
            Permissions Management
          </Typography>
        </AccordionSummary>
        <AccordionDetails>

          <div className="appointment">


            <div className="con_1 ">
              <div className="inp_1">
                <label htmlFor="input">Employee Name :</label>
                <input
                  type="text"
                  id="date"
                  name='employeeName'
                  placeholder='Enter Employee Name'
                  value={searchQuerypre}
                  onChange={(e) => setSearchQuerypre(e.target.value)}
                />
              </div>
              <div className="inp_1">
                <label htmlFor="input">Designation :</label>
                <select
                  name="designation"
                  value={searchQuerypre1}
                  onChange={(e)=>setSearchQuerypre1(e.target.value)}
                  className="new-custom-input-phone wei32j"
                  required
                >
                  <option value="select">Select </option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
              {/* <div className="inp_1">
                <label htmlFor="input">Date :</label>
                <input
                  type="date"
                  id="date"
                  // value={searchFormdata.date}
                  // onChange={handleChange}
                />
              </div> */}


              {/* <button className="btn_1" onClick={handleSearch}>
                <SearchIcon />
              </button> */}
            </div>
          </div>
          <ThemeProvider theme={theme}>
            <div className=" grid_1">
              <DataGrid
                rows={filteredRows1.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns1}
                pageSize={100}
                onSelectionModelChange={handleSelectionModelChange}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className=" data_grid"
              />
              {showdown1 > 10 && filteredRows1.length > 10 && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>


          {showdown1 !== 0 && filteredRows1.length !== 0 ? (
            ''
          ) : (
            <div className='IP_norecords'>
              <span>No Records Found</span>
            </div>
          )}
         
        </AccordionDetails>
      </Accordion>
      {openModal && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>

                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 kshdy1">
                    <label htmlFor="issued">Status<span>:</span></label>
                    <select
                      name="approval"
                      id="approval"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select</option>
                      {oldStatus !== 'Approved' && (
                        <option value="Approved">Approved</option>
                      )}
                      {oldStatus !== 'Not Approved' && (
                        <option value="Not Approved">Not Approved</option>
                      )}
                    </select>
                  </div>
                </div>
                {status === 'Not Approved' && <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 kshdy1">
                    <label htmlFor="reason">Reason <span>:</span></label>
                    <textarea
                      type="text" name="reason" id="reason" placeholder='Enter Reason'
                      value={reason1}
                      onChange={handleReasonChange}
                    />
                  </div>


                </div>
                }


                <div className="Register_btn_con regster_btn_contsai">
                  <button className="RegisterForm_1_btns" onClick={handleSubmit}>Submit</button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}



                
                    {openModal && (
            <div
              className={
                isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
              }
              onClick={() => { setOpenModal(false) }}
            >
              <div className="newwProfiles newwPopupforreason" onClick={(e) => e.stopPropagation()}>

                <div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 kshdy1">
                    <label htmlFor="approval">Status<span>:</span></label>
                    <select
                      name="approval"
                      id="approval"
                      value={status}
                      onChange={handleStatusChange}
                    >
                      <option value="">Select</option>
                      {oldStatus !== 'Approved' && (
                        <option value="Approved">Approved</option>
                      )}
                      {oldStatus !== 'Not Approved' && (
                        <option value="Not Approved">Not Approved</option>
                      )}
                    </select>
                  </div>
                </div>
                {status === 'Not Approved' && (<div className="RegisFormcon leavecon">
                  <div className="RegisForm_1 leaveform_1 kshdy1">
                    <label htmlFor="reason">Reason <span>:</span></label>
                    <textarea
                      type="text" name="reason" id="reason" placeholder='Enter Reason'
                      value={reason1}
                      onChange={handleReasonChange}
                    />
                  </div>
                </div>)
                }

                <div className="Register_btn_con regster_btn_contsai">
                  <button className="RegisterForm_1_btns" onClick={handleSubmit}>Submit</button>
                  <button
                    className="RegisterForm_1_btns"
                    onClick={() => setOpenModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

    </div>
  );
};

export default LeaveMangement;
