import React, { useState, useRef,  useEffect } from "react";
import "./ProPass.css";
import axios from "axios";
import { useSelector } from "react-redux";

const Profile = ( ) => {


  const userRecord = useSelector(state=>state.userRecord?.UserData);

  console.log('ooo',userRecord)

  const [newImageProfile, setNewImageProfile] = useState(null);
  const [newImageCover, setNewImageCover] = useState(null);  
  const [changesMade, setChangesMade] = useState(false);


  const [phoneNo, setPhoneNo] = useState(userRecord?.PhoneNumber);
  const [doorNo, setDoorNo] = useState("");
  const [street, setStreet] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");

const profileData1=()=>{
 
  axios.get("http://127.0.0.1:8000/profile/getuserprofile", {
      params: {
        user_id: userRecord?.user_id,
      },
    })
      .then((response) => {
        if (response.data) {
          const userData = response.data;
          console.log(userRecord?.user_id);
          console.log(userData);
          setPhoneNo(userData.PhoneNumber);
          setDoorNo(userData.Door_No);
          setStreet(userData.Street);
          setArea(userData.Area);
          setCity(userData.City);
          setPincode(userData.PinCode);
          setState(userData.State);
          setCountry(userData.Country);
  
          // Convert base64 strings to Blobs
          const profileBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Profile_image}`
          );
          const coverBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Cover_image}`
          );
  
          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
}

  useEffect(() => {

    axios.get("http://127.0.0.1:8000/profile/getuserprofile", {
      params: {
        user_id: userRecord?.user_id,
      },
    })
      .then((response) => {
        if (response.data) {
          const userData = response.data;
          console.log(userRecord?.user_id);
          console.log(userData);
          setPhoneNo(userData.PhoneNumber);
          setDoorNo(userData.Door_No);
          setStreet(userData.Street);
          setArea(userData.Area);
          setCity(userData.City);
          setPincode(userData.PinCode);
          setState(userData.State);
          setCountry(userData.Country);
  
          // Convert base64 strings to Blobs
          const profileBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Profile_image}`
          );
          const coverBlob = dataURItoBlob(
            `data:image/png;base64,${userData.Cover_image}`
          );
  
          setNewImageProfile(profileBlob);
          setNewImageCover(coverBlob);
        } else {
          console.error("User data not found or empty");
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });

  },[userRecord?.user_id]);
  
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  


  useEffect(() => {
    return () => {
      if (newImageCover) {
        URL.revokeObjectURL(newImageCover);
      }
    };
  }, [newImageCover]);

  useEffect(() => {
    return () => {
      if (newImageProfile) {
        URL.revokeObjectURL(newImageProfile);
      }
    };
  }, [newImageProfile]);




  const onImageChange = (e, setImageFunction) => {
    const file = e.target.files[0];
  
    if (file) {
      const fileReader = new FileReader();
  
      return new Promise((resolve, reject) => {
        fileReader.onload = (event) => { 
          // const dataURL = event.target.result; // Set the result to a variable
  
          // Convert the image file to a Blob
          fileToBlob(file)
            .then((blob) => {
              // Handle the blob as needed (e.g., send it to the server)
              console.log('Blob:', blob);
              setImageFunction(blob);
              resolve();
            })
            .catch((error) => {
              console.error('Error converting image to Blob:', error);
              reject(error);
            });
        };
  
        fileReader.onerror = (error) => {
          console.error('Error reading file:', error);
          reject(error);
        };
  
        fileReader.readAsDataURL(file);
      });
    }
  };
  
  
  const handleFileInputCover = async (event) => {
    try {
      await onImageChange(event, setNewImageCover);
      setChangesMade(true);
    } catch (error) {
      console.error('Error handling cover image:', error);
    }
  };
  
  const handleFileInputProfile = async (event) => {
    try {
      await onImageChange(event, setNewImageProfile);
      setChangesMade(true);
    } catch (error) {
      console.error('Error handling profile image:', error);
    }
  };
  
  const fileToBlob = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const dataURI = reader.result;
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], { type: mimeString });
        resolve(blob);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };
  
  console.log('1im', newImageCover)
  console.log('2im', newImageProfile)

  const handleSaveClick = () => {


    
    if (changesMade) {

     

      
      const newDatatosent = {
        cover_image: newImageCover,
        profile_image: newImageProfile,
        user_id: userRecord?.user_id,
        Phone_No: phoneNo || null,
        Door_No: doorNo.toUpperCase() || null,
        Street: street.toUpperCase() || null,
        Area: area.toUpperCase() || null,
        City: city.toUpperCase() || null,
        Pincode: pincode || null,
        State: state.toUpperCase() || null,
        Country: country.toUpperCase() || null,
        
      };

      console.log('111',newDatatosent)
  
      const formData = new FormData();

      for (const key in newDatatosent) {
        if (newDatatosent.hasOwnProperty(key)) {
          formData.append(key, newDatatosent[key]);
          
        }
      }

      

    
  
      const url = 'http://127.0.0.1:8000/profile/profileinsert';
      axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then((response) => {
          
          console.log(response.data.message);
          alert('Handle success')
          profileData1();
          setChangesMade(false); // Reset changesMade after successful save
        })
        .catch((error) => {
          // Handle error
          console.error('Error uploading images:', error);
        });
    }
  //  ------------------------------------------------------------------------------
  //   // Create an object containing user data
  // const datatoupdate = {
  //   user_id: userRecord?.user_id,
  //   Phone_No: phoneNo || null,
  //   Door_No: doorNo.toUpperCase() || null,
  //   Street: street.toUpperCase() || null,
  //   Area: area.toUpperCase() || null,
  //   City: city.toUpperCase() || null,
  //   Pincode: pincode || null,
  //   State: state.toUpperCase() || null,
  //   Country: country.toUpperCase() || null,
  //   cover_image: newImageCover,
  //   profile_image: newImageProfile,
  // };
  
  // const userData = new FormData();
  //         for (const key in datatoupdate) {
  //           if (datatoupdate.hasOwnProperty(key)) {
  //             userData.append(key, datatoupdate[key]);
  //           }
  //         }
  // // console.log(userData)
  // // Send the user data to the backend
  // const url='http://127.0.0.1:5000/profile/updateprofile';
  // axios.post(url,userData,{
  //   headers: {
  //     'Content-Type': 'multipart/form-data',
  //   }, 
  // }
  // )
  //   .then((response) => {
  //     // Handle success
  //     alert(response.data.message);
  //     setChangesMade(false); // Reset changesMade after successful save
  //   })
  //   .catch((error) => {
  //     // Handle error
  //     console.error("Error saving user data:", error);
  //   });
  
  };










  const fileInputCover = useRef(null);
  const fileInputProfile = useRef(null);

  

  // useEffect(() => {
  //   // Fetch user data based on the user ID
  //   axios.get("http://127.0.0.1:5000/profile/getuserprofile",{
  //     params: {
  //       user_id: userRecord?.user_id
  //     }
  //   })
      
  //     .then((response) => {
  //       if (response.data) { // Check if Data exists
  //         const userData = response.data;
  //         // Map the cover and profile fields to newImageProfile and newImageCover
  //         console.log(userRecord?.user_id)
  //         console.log(userData);
  //         setPhoneNo(userData.PhoneNumber );
  //         setDoorNo(userData.Door_No);
  //         setStreet(userData.Street);
  //         setArea(userData.Area);
  //         setCity(userData.City);
  //         setPincode(userData.PinCode);
  //         setState(userData.State);
  //         setCountry(userData.Country);
  //         setNewImageProfile(`data:image/png;base64,${userData.Profile_image}`);
  //         setNewImageCover(`data:image/png;base64,${userData.Cover_image}`);
  //       } else {
  //         console.error("User data not found or empty");
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching user data:", error);
  //     });
  
   
  //     axios.get("http://127.0.0.1:5000/profile/userprofilelist", {
  //       params: {
  //           user_id: userRecord?.user_id
  //       }
  //   })
  //       .then((response) => {
  //           if (response.data && response.data.length > 0) {
  //               const profile = response.data[0];
  //               console.log(profile);
  //               // Update the state with user data
  //               setTitle(profile.Title);
  //               setFirstName(profile.First_Name);
  //               setLastName(profile.Last_Name);
  //               setEmail(profile.email);
  //               setPhoneNo(profile.PhoneNumber);
                

  //           } else {
  //               console.error("User data not found or empty");
  //           }
  //       })
  //       .catch((error) => {
  //           console.error("Error fetching user data:", error);
  //       });
    
      
  // }, [userRecord?.user_id]);
  

  return (
    <div className="container_pro">
      <div className="head_img_head">
      <div className="head_img">
      {newImageCover instanceof Blob ? (
        <img src={URL.createObjectURL(newImageCover)} alt="Cover" />
      ) : (
        <img src={newImageCover} alt="Cover" />
      )}


      <label className="custom-button-back">
        Choose File
        <input
          type="file"
          accept=".jpg, .jpeg, .png, .pdf"
          onChange={handleFileInputCover}
          className="file-input"
          ref={fileInputCover}
        />
      </label>
            </div>
                <div className="prof_round_head">
                <div className="profile_round">
          {(
            <label className="custom-button-profile">
              Choose File
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                onChange={handleFileInputProfile}
                className="file-input"
                ref={fileInputProfile}
              />
            </label>
          )}
          {newImageProfile instanceof Blob ? (
            <img src={URL.createObjectURL(newImageProfile)} alt="Cover" />
          ) : (
            <img src={newImageProfile} alt="Cover" />
          )}
        </div>

          <div className="user_name">
            <div className="name">
              <div className="rolenam">
                <h4>{userRecord?.username.toUpperCase()}</h4>
                </div>
                <br></br>
               <div className="rolenam"><h6>{userRecord?.role_name.toLowerCase()}</h6></div>
            </div>
            <button className="save123" onClick={handleSaveClick}>
              Save As
            </button>
          </div>
        </div>
      </div>

      <div className="user_items">
        <div className="forms">
        <div className="pack">
            <label className="first">Title:</label>

            <input
              type="text"
              className="inp"
              placeholder="Enter your Title"
              value={userRecord?.Title}
            />
            
          </div>
          <div className="pack firstlog">
            <label className="first">First Name:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your first name"
              value={userRecord?.First_Name} 
            />
          </div>
          <div className="pack">
            <label className="first">Last Name:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your last name"
              value={userRecord?.Last_Name}
            />
          </div>
        </div>

        <div className="forms">
          <div className="pack">
            <label className="first">Email:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your Email"
              value={userRecord?.email}
            />
          </div>
          <div className="pack">
            <label className="first">Phone no:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your Phone No"
              value={phoneNo} onChange={(e) => setPhoneNo(e.target.value)}
            />
          </div>
        </div>

        <div className="forms">
          <div className="pack">
            <label className="first">Door No:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your Door No"
              value={doorNo} onChange={(e) => setDoorNo(e.target.value)}
            />
          </div>
          <div className="pack">
            <label className="first">Street:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your first name"
              value={street} onChange={(e) => setStreet(e.target.value)}
            />
          </div>
          <div className="pack">
            <label className="first">Area:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your Area"
              value={area} onChange={(e) => setArea(e.target.value)}
            />
          </div>
        </div>
        <div className="forms">
          <div className="pack">
            <label className="first">City:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your  City"
              value={city} onChange={(e) => setCity(e.target.value)}
            />
          </div>
          <div className="packlog">
            <label className="first">PinCode:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your PinCode"
              value={pincode} onChange={(e) => setPincode(e.target.value)}
            />
          </div>
        </div>
        <div className="forms">
          <div className="pack">
            <label className="first">State:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your State"
              value={state} onChange={(e) => setState(e.target.value)}
            />
          </div>

          <div className="packlog">
            <label className="first">Country:</label>
            <input
              type="text"
              className="inp"
              placeholder="Enter your Country"
              value={country} onChange={(e) => setCountry(e.target.value)}
            />
          </div>
          <button className="save1" onClick={handleSaveClick} >
            Save As
            </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;