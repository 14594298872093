import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "./IpNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IpDrugAdminister = () => {
  const dataurl = useSelector((state) => state.userRecord?.dataurl);
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
 
  const [Remarks, setRemarks] = useState("");
  const [SOSRemarks, setSOSRemarks] = useState({
    Time:'',
    Date:'',
    Quantity:0,
    Remarks:'',
  });

  const [getdataa, setgetdataa] = useState(false);
  const [postdata, setpostdata] = useState([]);
  const [TabletShow, setTabletShow] = useState(null);
  const [TabletShowSOS, setTabletShowSOS] = useState(null);
  useEffect(() => {
    
      const now = new Date();
      const formattedTime = format(now, "HH:mm");
      const newdate = format(new Date(), "yyyy-MM-dd");
      setSOSRemarks((prev)=>({
        ...prev,
        Time:formattedTime,
        Date:newdate,
        Quantity:1
      }))
 

    // Clean up the interval when the component unmounts
    
  }, []);


 
  useEffect(() => {
    if (IpNurseQueSelectedRow?.Booking_Id &&dataurl) {
      const dateecurrent=format(new Date(),'yyyy-MM-dd')
      axios
      .get(
        `${dataurl}ipregistration/get_Drug_Administration_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&Date=${dateecurrent}`
      )
      .then((response) => {
        const data = response.data.Regular;

        console.log('data',data)
        // Function to convert time to AM/PM format
        // Function to convert time to AM/PM format
        const convertToAMPM = (time) => {
          const numTime = parseInt(time);
          return numTime >= 1 && numTime <= 11
            ? numTime + " AM"
            : numTime === 12
            ? "12 PM"
            : numTime - 12 + " PM";
        };

        // Extract unique times from FrequencyTime arrays and convert them to AM/PM format
        const freqdata = [
          ...new Set(data.flatMap((p) => p.FrequencyIssued.flatMap((r)=>r.FrequencyIssued))),
        ]
          .map((time) => convertToAMPM(time))
          .sort((a, b) => {
            // Extract AM/PM and numerical value from time string
            const [aNum, aPeriod] = a.split(" ");
            const [bNum, bPeriod] = b.split(" ");

            // Compare periods (AM comes before PM)
            if (aPeriod !== bPeriod) {
              return aPeriod.localeCompare(bPeriod);
            }

            // If periods are the same, sort numerically
            return parseInt(aNum) - parseInt(bNum);
          });

        console.log('freqdata', freqdata)
        setTabletShowSOS(response.data.SOS)
        console.log(freqdata,'freqqq')
        setTabletShow({
          frequencyTime: freqdata,
          medicinedata: data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [IpNurseQueSelectedRow, IpNurseQueSelectedRow?.Booking_Id,dataurl,getdataa]);

  
  console.log(TabletShow)

  const handleInputChange = (e, sss, ddd) => {
    const tarval = e.target.checked;
    const ttt =
      ddd.split(" ")[1] === "PM" ? +ddd.split(" ")[0] + 12 : +ddd.split(" ")[0];
    const newrow = {
      ...sss,
      FrequencyIssued: ttt,
    };

    if (tarval) {
      setpostdata((prev) => [...prev, newrow]);
    } else {
      const existdata = postdata.filter((p) => {
        // Check if any key-value pair in p matches the corresponding key-value pair in newrow
        return Object.entries(newrow).some(([key, value]) => p[key] !== value);
      });

      setpostdata(existdata);
    }
    console.log("newrow", newrow);
  };

  console.log("postdata", postdata);

  const handleSubmit = () => {
    if (postdata.length > 0) {
      const formattedTime = format(new Date(), "HH:mm:ss a");
      const formattednewdate = format(new Date(), "yyyy-MM-dd");
      const postrewss = postdata.map((p) => ({
        ...p,
        Duration: p.Duration.split(" ")[0],
        DurationType: p.Duration.split(" ")[1],
        Remarks: p.FrequencyMethod==='Regular'?Remarks :SOSRemarks.Remarks,
        Completed_Date: p.FrequencyMethod==='Regular'?formattednewdate:SOSRemarks.Date,
        Completed_Time: p.FrequencyMethod==='Regular'?formattedTime:SOSRemarks.Time,
      }));
      axios
        .post(
          `${dataurl}ipregistration/insert_Drug_Administration_nurse_frequencywise_datas`,
          postrewss
        )
        .then((response) => {
          console.log(response);
          setTabletShow(null);
          setgetdataa(!getdataa)
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
const handleissusesos=(prams)=>{
  console.log(prams)
}
const formatLabel = (label) => {
  // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
  if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
    return label
      .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
      .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
  } else {
    return label;
  }
};
  return ( 
    <>
      

      <ToastContainer />
      {TabletShow && TabletShow?.medicinedata.length!==0 && (
        <>
         <div className="Add_items_Purchase_Master">
         <span>Regular Medicines</span>
</div>
        <div class="qwertyuio">
          
          <div className="DrugAdministrationTable">
            <table>
              <thead>
                <tr>
                  {TabletShow&& ['PrescribedDate','CurrentDate','DoctorType','DoctorName','MedicineName','FrequencyType'].map((p,index)=>(
                    <th key={p}>{formatLabel(p)}</th>
                  ))}
                  {TabletShow &&
                    TabletShow?.frequencyTime.map((time) => (
                      <th key={time}>{time}</th>
                    ))}
                </tr>
              </thead>
              <tbody>
                {TabletShow &&
                  TabletShow?.medicinedata
                    .map((med) => (
                      <tr key={med.MedicineName}>
                        <td>{med.Date}</td>
                        <td>{med.FrequencyDate}</td>
                        <td>{med.Department}</td>
                        <td>{med.DoctorName}</td>
                        <td>{med.MedicineName}</td>
                        <td>{med.FrequencyType}</td>
                        {TabletShow?.frequencyTime.map((time) => {
                          const newwtime = time.split(" ");
                          let ttt = 0;
                          if (newwtime[1] === "PM") {
                            ttt = +newwtime[0] + 12;
                          } else {
                            ttt = +newwtime[0];
                          }
                          const isChecked = med.FrequencyIssued.some(
                            (f) => +f.FrequencyIssued === ttt
                          );
                          
                          
                          console.log('FrequencyIssued',med.FrequencyIssued)
                         
                          
                          const statusPending = med.FrequencyIssued.some((f) => f.Status === 'Pending' &&  +f.FrequencyIssued === ttt) ;
                          const statusIssued = med.FrequencyIssued.some((f) => f.Status === 'Issued'&& +f.FrequencyIssued === ttt) ;
                          const statusBefore = med.FrequencyIssued.some((f) => f.Status === 'Before'&& +f.FrequencyIssued === ttt) ;
                          const statusAfter = med.FrequencyIssued.some((f) => f.Status === 'After'&& +f.FrequencyIssued === ttt) ;
                          const statusNotIssued = med.FrequencyIssued.some((f) => f.Status === 'NotIssued' &&+f.FrequencyIssued === ttt) ;
                          console.log('statusPending',statusPending)
                          console.log('statusIssued',statusIssued)
                          console.log('statusBefore',statusBefore)
                          console.log('statusAfter',statusAfter)
                          console.log('statusNotIssued',statusNotIssued)

                          return (
                            <td key={time}>
                              {isChecked ? (
                                
                                statusPending ? (
                                  <input
                                  type="checkbox"
                                  onChange={(e) =>
                                    handleInputChange(e, med, time)
                                  }
                                />
                                  
                                ) : (
                                  statusIssued?(
                                    <label className="inputcheckboxcolor_label">
                                    <input
                                      type="checkbox"
                                      checked={isChecked}
                                      readOnly
                                    />
                                    <span
                                      class= "inputcheckboxcolor_Span"
                                    ></span>
                                  </label>
                                  
                                  ):
                                  statusBefore?
                                 (
                                  <label className="inputcheckboxcolor_label">
                                    <input
                                      type="checkbox"
                                      checked={isChecked}
                                      readOnly
                                    />
                                    <span
                                      class="inputcheckboxcolor_Span Span_input_clrr"
                                         
                                    ></span>
                                  </label>
                                 ):
                                 statusNotIssued?
                                 (
                                  <label className="inputcheckboxcolor_label">
                                    <input
                                      type="checkbox"
                                      checked={isChecked}
                                      readOnly
                                    />
                                    <span
                                      class="inputcheckboxcolor_Span Span_input_clrr"
                                         
                                    ></span>
                                  </label>
                                 )
                                 :
                                 (
                                  <label className="inputcheckboxcolor_label">
                                    <input
                                      type="checkbox"
                                      checked={isChecked}
                                      readOnly
                                    />
                                    <span
                                      class="inputcheckboxcolor_Span Span_input_clrr"
                                         
                                    ></span>
                                  </label>
                                 )
                                )
                              ) : (
                                "-"
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="inp_container_all_intakeoutput">
            <label>
              Remarks <span>:</span>
            </label>

            <textarea
              value={Remarks}
              placeholder="Maximum 150 words"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
          <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
            <button className="btn-add" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
        </>
      )}
      {TabletShowSOS && (
        <>
         <div className="Add_items_Purchase_Master">
         <span>SOS Medicines</span>
</div>
        <div class="qwertyuio">
          
          <div className="DrugAdministrationTable">
            <table>
              <thead>
                <tr>
                  {TabletShowSOS&& ['Date','Doctor Type','Doctor Name','Medicine Name'].map((p,index)=>(
                    <th key={p}>{p}</th>
                  ))}
                 <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {TabletShowSOS &&
                  TabletShowSOS
                    .filter((p) => p.Status === "Pending")
                    .map((med) => (
                      <tr key={med.MedicineName}>
                        <td>{med.Date}</td>
                        <td>{med.Department}</td>
                        <td>{med.DoctorName}</td>
                        <td>{med.MedicineName}</td>
                       <td><button onClick={()=>handleissusesos(med)}>issue</button></td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
          <div className="Total_input_container" style={{width:'70%'}}>
          <div className="inp_container_all_intakeoutput">
                <label>
                  Time <span>:</span>
                </label>
                <input
                  type="time"
                  value={SOSRemarks.Time}
                  onChange={(e)=>setSOSRemarks((prev)=>({...prev,Time:e.target.value}))}
                />
                
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Date <span>:</span>
                </label>
                <input
                  type="date"
                  value={SOSRemarks.Date}
                  onChange={(e)=>setSOSRemarks((prev)=>({...prev,Date:e.target.value}))}
                />
                
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Quantity <span>:</span>
                </label>
                <input
                  type="number"
                  onKeyDown={blockInvalidChar}
                  value={SOSRemarks.Quantity}
                  readOnly
                  onChange={(e)=>setSOSRemarks((prev)=>({...prev,Quantity:e.target.value}))}
                />
                
              </div>
          <div className="inp_container_all_intakeoutput">
            <label>
              Remarks <span>:</span>
            </label>

            <textarea
              value={Remarks}
              placeholder="Maximum 150 words"
              onChange={(e) => setRemarks(e.target.value)}
            />
          </div>
          </div>
          <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
            <button className="btn-add" onClick={handleSubmit}>
              Save
            </button>
          </div>
        </div>
        </>
      )}
    </>
  );
};

export default IpDrugAdminister;
