import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";

import SearchIcon from "@mui/icons-material/Search";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import axios from "axios";
import "./RegisReqList.css";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import "./ReqRegis.css";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Modal from "react-modal";
import AppoinmentCalendar from "./AppointmentCalendar";

function CancelAppointmentDialog({
  open,
  onClose,
  onConfirm,
  cancelReason,
  setCancelReason,
}) {
  const handleConfirm = () => {
    // Call the onConfirm callback with the cancellation reason
    onConfirm(cancelReason);
    // Close the dialog
    onClose();
  };

  const handleClose = () => {
    setCancelReason("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Cancel Register Reason</DialogTitle>
      <DialogContent>
        <TextField
          label="Cancellation Reason"
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
        <Button onClick={handleConfirm} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const RequestList = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedId, setselectedId] = useState(null);

  const [cancelReason, setCancelReason] = useState("");

  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const SelectedPatientCalender = useSelector(
    (state) => state.userRecord?.SelectedPatientCalender
  );

  const formRef = React.useRef(null);

  // console.log(cancelReason)

  const navigate = useNavigate();
  const [page, setPage] = React.useState(0);
  const [rows, setRows] = useState([]); // State to hold the data
  const [filteredRows, setfilteredRows] = useState([]); // State to hold the data

  const [appointmentID, setAppointmentID] = useState(null);
  const [doctorNames, setDoctorNames] = useState([]);
  const [isedit, setisedit] = useState(false);

  const create = userRecord?.username;

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const [Requestdata, setRequestdata] = useState({
    AppointmentID: "",
    title: "",
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    date: "",
    appointmentType: "",
    appointmentDate: "",
    appointmentSlot: "",
    appointmentPurpose: "",
    doctorName: "",
    createdBy: create,
    status: "",
    location: userRecord?.location,
  });

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const [openModal2, setOpenModal2] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const yourStyles = {
    position: "absolute",
    inset: "100px",
    border: "1px solid rgb(204, 204, 204)",
    background: "White",
    overflow: "auto",
    borderRadius: "4px",
    outline: "none",
    padding: "0px",
  };



  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
    setOpenModal2(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  // Define the handleAdd function to handle the "Edit" button click
  useEffect(() => {
    console.log(userRecord);
    axios
      .get(
        `http://127.0.0.1:8000/appointmentmanagement/view_appointment_patientrequest?location=${userRecord?.location}`
      )

      .then((response) => {
        console.log("Data from :", response.data);

        const Records = response.data.map((userdata, index) => ({
          id: userdata.appointment_patientrequestID,
          Name: userdata.FirstName + " " + userdata.LastName,
          ...userdata,
        }));
        if (SelectedPatientCalender) {
          const newrecords = Records.filter(
            (p) => SelectedPatientCalender === p.Name
          );
          setRows(newrecords);
        } else {
          setRows(Records);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [SelectedPatientCalender, userRecord?.location]);

  const handleRequestEdit = (params) => {
    const data = params.row;
    const namesss = data.Name.split(" ");
    setisedit(true);
    setRequestdata({
      apointmentrequestid: data.appointment_patientrequestID,
      AppointmentID: data.AppointmentID,
      title: data.Title,
      firstName: namesss[0],
      lastName: namesss[1],
      phone: data.PhoneNumber,
      email: data.Email,
      date: data.AppointmentDate,
      appointmentType: data.AppointmentType,
      appointmentDate: data.AppointmentDate,
      appointmentSlot: data.Slot,
      appointmentPurpose: data.AppointmentPurpose,
      doctorName: data.DoctorName,
      createdBy: data.createdBy,
      status: data.Status,
      location: data.location,
    });
    console.log("row ID:", params.row);
  };

  // Define the handleProceed function to handle the "Proceed" button click
  const handleRequestProceed = (params) => {
    // Extract the 'AppointmentID' and 'FirstName' from the row
    const selectedId = params.row.id;
    const selectedFirstName = params.row.Name.split(" ")[0];
    const selectedPhoneNumber = params.row.PhoneNumber;
    console.log("Selected ID:", selectedId);
    console.log("Selected FirstName:", selectedFirstName);
    console.log("Selected PhoneNumber:", params.row);

    // Make an API request to retrieve data for the selected ID
    const apiUrl = `http://127.0.0.1:8000/appointmentmanagement/view_appointment_patientrequest?location=${userRecord?.location}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        // Now 'data' contains the response from the backend API
        console.log("___All Data:", data);

        // Find and log the data for the selected ID
        const selectedData = data.find(
          (item) => item.AppointmentID === selectedId
        );
        console.log("Selected Data:", selectedData);

        // Make an API request to get the patient ID
        axios
          .get(
            `http://127.0.0.1:8000/appointmentmanagement/get_patient_id?first_name=${selectedFirstName}&phone_number=${selectedPhoneNumber}&location=${userRecord?.location}`
          )
          .then((response) => {
            // Patient ID found
            const patientId = response.data.PatientID;
            const appoitment_id = response.data.NewAppointmentID;
            console.log("appoitmentid -----------------", appoitment_id);
            console.log("Patient ID----------------------------:", patientId);

            // Pass the selected ID and Patient ID to the 'Register' page

            dispatchvalue({
              type: "selectedRequestList",
              value: {
                patientid: patientId,
                selectedid: selectedId,
                appoitment_ID: appoitment_id,
              },
            });
          });
      });
    navigate(`/Home/Register`);
  };

  // const handleRequestForm = () => {
  //   navigate("/Appoinment-Request");
  // };
  const handleclear = () => {
    setRequestdata((prev) => ({
      ...prev,
      AppointmentID: "",
      title: "",
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      date: "",
      appointmentType: "",
      appointmentDate: "",
      appointmentSlot: "",
      appointmentPurpose: "",
      doctorName: "",
      status: "",
    }));
    console.log("State after clear:", Requestdata);
  };
  const handleCancel1 = (params) => {
    const selectedId = params.row.id;
    console.log("Selected ID:", selectedId);
    setselectedId(selectedId);
    setOpenDialog(true);
  };

  const handleCancelAppointment = () => {
    console.log("Cancellation Reason:", cancelReason);
    console.log(selectedId);

    const confirmation = window.confirm(
      "Are you sure you want to cancel the appointment?"
    );
    console.log(confirmation);

    if (confirmation) {
      axios
        .post(
          "http://127.0.0.1:8000/appointmentmanagement/Post_delete_appointment_patientrequest",
          {
            selectedId: selectedId,
            cancelReason: cancelReason,
            location: userRecord?.location,
          }
        )
        .then((response) => {
          console.log(response.data.message);
          alert("successfully deleted");

          fetchRequestData();
        })
        .catch((error) => {
          console.error("Error updating record:", error);
        });
      setCancelReason("");
      setOpenDialog(false);
    } else {
      setCancelReason("");
      setOpenDialog(false);
    }
  };

  const fetchRequestData = () => {
    // Fetch patient data from the backend
    if (userRecord && userRecord?.location) {
      axios
        .get(
          `http://127.0.0.1:8000/appointmentmanagement/view_appointment_patientrequest?location=${userRecord?.location}`
        )

        .then((response) => {
          console.log("Data from backend:", response.data);

          const Records = response.data.map((userdata, index) => ({
            id: userdata.appointment_patientrequestID,
            Name: userdata.FirstName + " " + userdata.LastName,
            ...userdata,
          }));
          if (SelectedPatientCalender) {
            const newrecords = Records.filter(
              (p) => SelectedPatientCalender === p.Name
            );
            setRows(newrecords);
          } else {
            setRows(Records);
          }
        })
        .catch((error) => {
          console.error("Error fetching patient data:", error);
        });
    }
  };

  // Define the columns dynamically
  const dynamicColumns = [
    { field: "AppointmentID", headerName: "Appointment ID", width: 120 },
    { field: "Name", headerName: "Name", width: 100 },
    { field: "PhoneNumber", headerName: "Phone No", width: 110 },
    { field: "AppointmentType", headerName: "Appointment Type", width: 130 },
    { field: "Slot", headerName: "Request Time", width: 110 },
    { field: "DoctorName", headerName: "Doctor Name", width: 110 },
    { field: "AppointmentDate", headerName: "Request Date", width: 110 },
    { field: "location", headerName: "Branch Location", width: 120 },

    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => {
              handleRequestEdit(params);
              scrollToForm();
            }}
          >
            <BorderColorIcon />
          </Button>
          <Button className="cell_btn" onClick={() => handleCancel1(params)}>
            <HighlightOffSharpIcon />
          </Button>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ];

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  useEffect(() => {
    console.log(rows);

    const filteredData =
      rows &&
      rows.filter((row) => {
        const lowerCaseFirstName = row.Name.toLowerCase();
        const lowerCasePhoneNo = row.PhoneNumber.toString().toLowerCase();
        const lowerCaseQuery = PatientFirstName.toLowerCase();
        const lowerCaseQuery1 = PatientPhoneNo.toLowerCase();

        // Check if searchQuery is for FirstName and if it matches
        const matchesFirstName =
          lowerCaseFirstName && lowerCaseFirstName.includes(lowerCaseQuery);

        // Check if searchQuery1 is for PhoneNo and if it matches
        const matchesPhoneNo =
          lowerCasePhoneNo && lowerCasePhoneNo.includes(lowerCaseQuery1);

        // Combine the conditions based on search type
        return (
          (matchesFirstName || !PatientFirstName) &&
          (matchesPhoneNo || !PatientPhoneNo)
        );
      });

    setfilteredRows(filteredData);
  }, [PatientFirstName, PatientPhoneNo, rows]);

  // const handleSearch = (e) => {
  //   e.preventDefault();

  //   fetch(
  //     "http://127.0.0.1:8000/appointmentmanagement/view_appointment_patientrequest"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (Array.isArray(data)) {
  //         // The data is an array of appointment objects
  //         const filteredData = data.filter((appointment) => {
  //           const lowerCaseFirstName = appointment.FirstName.toLowerCase();
  //           const lowerCasePhoneNo = appointment.PhoneNumber.toString(); // Phone numbers should be converted to string

  //           const lowerCaseSearch = PatientFirstName.toLowerCase();
  //           const lowerCasePhoneSearch = PatientPhoneNo.toLowerCase();

  //           // Check if the search query matches the start of either name or phone number,
  //           // and ignore the empty field
  //           return (
  //             (PatientFirstName === "" ||
  //               lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
  //             (PatientPhoneNo === "" ||
  //               lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
  //           );
  //         });

  //         if (filteredData.length === 0) {
  //           alert("No matching appointments found.");
  //           setRows([]);
  //         } else {
  //           const Records = filteredData.map((userdata, index) => ({
  //             id: userdata.appointment_patientrequestID,
  //             appointmentid: userdata.AppointmentID,
  //             Name: userdata.FirstName + " " + userdata.LastName,
  //             phone: userdata.PhoneNumber,
  //             email: userdata.Email,
  //             AppointmentType: userdata.AppointmentType,
  //             Slot: userdata.Slot,
  //             DoctorName: userdata.DoctorName,
  //           }));

  //           setRows(Records);
  //         }
  //       } else {
  //         console.error("Fetched data is not as expected:", data);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching appointment data:", error);
  //     });
  // };

  useEffect(() => {
    // Fetch doctor names
    axios
      .get(
        `http://127.0.0.1:8000/usercontrol/get_doctor_info?location=${userRecord?.location}`
      )
      .then((response) => {
        const data = response.data;
        console.log(data);
        setDoctorNames(data);
      })
      .catch((error) => {
        console.error("Error fetching doctor names:", error);
      });
  }, [userRecord, userRecord?.location]);

  const handleFormSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    const requiredFields = ["firstName", "lastName", "phone"];
    const emptyFields = requiredFields.filter((field) => !Requestdata[field]);

    if (emptyFields.length > 0) {
      // Display an alert for empty fields
      userwarn(
        `Please fill in the following required fields: ${emptyFields.join(
          ", "
        )}`
      );
      return;
    } else {
      // Modify the Requestdata with the appointmentID before submission
      const modifiedRequestdata = {
        ...Requestdata,
        AppointmentID: appointmentID,
        createdBy: userRecord?.username, // Set createdBy dynamically
        location: userRecord?.location,
        date: Requestdata.appointmentDate,
      };

      console.log(modifiedRequestdata);

      axios
        .post(
          "http://127.0.0.1:8000/appointmentmanagement/insert_appointment_patientrequest",
          modifiedRequestdata
        )
        .then((response) => {
          console.log("request : ", response);
          successMsg(response.data.message);

          // After successful submission, fetch the updated data
          fetchRequestData();

          // Reset or clear the form fields
          setRequestdata({
            ...Requestdata,
            AppointmentID: "", // Reset specific fields as needed
            title: "",
            firstName: "",
            lastName: "",
            phone: "",
            email: "",
            date: "",
            appointmentType: "",
            appointmentDate: "",
            appointmentSlot: "",
            appointmentPurpose: "",
            doctorName: "",
            status: "Pending",
          });
        })
        .catch((error) => {
          console.error("Error submitting form:", error);
          errmsg("Error Occurred");
        });
    }
  };

  const handleFormupdate = (event) => {
    event.preventDefault();

    // Check if required fields are empty
    const requiredFields = [
      "title",
      "firstName",
      "lastName",
      "phone",
      "appointmentPurpose",
      "appointmentDate",
      "appointmentType",
      "appointmentDate",
      "appointmentSlot",
      "doctorName",
    ];
    const emptyFields = requiredFields.filter((field) => !Requestdata[field]);

    if (emptyFields.length > 0) {
      // Display an alert for empty fields
      userwarn(
        `Please fill in the following required fields: ${emptyFields.join(
          ", "
        )}`
      );
      return;
    } else {
      console.log(Requestdata);
      axios
        .post(
          "http://127.0.0.1:8000/appointmentmanagement/update_appointment_patientrequest",
          Requestdata
        )
        .then((response) => {
          // Handle the response from the API
          console.log(response.data);
          if (response.data.message === "Successfully Updated") {
            successMsg("updated successfully");
            fetchRequestData();
            setRequestdata({
              ...Requestdata,
              AppointmentID: "", // Reset specific fields as needed
              title: "",
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              date: "",
              appointmentType: "",
              appointmentDate: "",
              appointmentSlot: "",
              appointmentPurpose: "",
              doctorName: "",
              status: "Pending",
            });
            setisedit(false);
          } else {
            alert("Error submitting form data.");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          if (error.response) {
            console.error("Response data:", error.response.data);
          }
          alert("An error occurred while submitting the form data.");
        });
    }
  };

  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/appointmentmanagement/get_max_appointment_id?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const requestNo = response.data.max_appointment_id;
        setAppointmentID(requestNo);
      })
      .catch((error) => {
        console.error("Error fetching max appointment ID:", error);
      });
  }, [userRecord, userRecord?.location]); // empty dependency array to run once on mount

  // const handleCancel = () => {
  //   navigate('/Appoinment-RequestList');
  // };

  const successMsg = (message) => {
    toast.success(`${message}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = (warningMessage) => {
    toast.warn(`${warningMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const errmsg = (errormsg) => {
    toast.error(`${errormsg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <div className="appointment">
        <div className="patient-registration-form" ref={formRef}>
          <div className="h_head" style={{ justifyContent: "space-between" }}>
            <h4>Request No {appointmentID}</h4>
            <Button className="cell_btn" onClick={() => openModal("calendar")}>
              <CalendarMonthIcon />
            </Button>
          </div>
          <br />
          <div>
            <div id="form-table-RequestForm">
              <div className="RegisFormcon">
                <div className="RegisForm_1">
                  <label htmlFor="title">
                    Title<span>:</span>
                  </label>
                  <select
                    id="title"
                    name="title"
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        title: e.target.value,
                      }))
                    }
                    value={Requestdata.title}
                  >
                    <option>Select</option>
                    <option value="Mr">Mr.</option>
                    <option value="Ms">Ms.</option>
                    <option value="Mrs">Mrs.</option>
                    <option value="Others">Others.</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="firstName">
                    First Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        firstName: e.target.value,
                      }))
                    }
                    value={Requestdata.firstName}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="lastName">
                    Last Name<span>:</span>
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    name="lastName"
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        lastName: e.target.value,
                      }))
                    }
                    value={Requestdata.lastName}
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="phone">
                    Phone Number<span>:</span>
                  </label>
                  <input
                    type="number"
                    id="phone"
                    name="phone"
                    value={Requestdata.phone}
                    onChange={(e) => {
                      const inputPhone = e.target.value.slice(0, 10); // Keep only the first 10 characters
                      setRequestdata((prev) => ({
                        ...prev,
                        phone: inputPhone,
                      }));
                    }}
                    maxLength={10} // Limit input to 10 characters
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="email">
                    Email<span>:</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={Requestdata.email}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="date">
                    Date<span>:</span>
                  </label>
                  <input
                    type="date"
                    id="date"
                    name="date"
                    value={Requestdata.appointmentDate}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        appointmentDate: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="appointmentType">
                    Appointment Type<span>:</span>
                  </label>
                  <select
                    id="appointmentType"
                    name="appointmentType"
                    value={Requestdata.appointmentType}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        appointmentType: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value="">Select</option>
                    <option value="Call">Call</option>
                    <option value="WalkIn">Walk In</option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="slot">
                    Request Time<span>:</span>
                  </label>
                  <input
                    type="time"
                    id="appointmentSlot"
                    name="appointmentSlot"
                    value={Requestdata.appointmentSlot}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        appointmentSlot: e.target.value,
                      }))
                    }
                    required
                  />
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="purpose">
                    Visit Purpose<span>:</span>
                  </label>
                  {/* <input type="text" id="appointmentPurpose" name="appointmentPurpose" onChange={handleFormChange}
                    placeholder='Enter your Purpose' required /> */}
                  <select
                    id="appointmentType"
                    name="appointmentPurpose"
                    value={Requestdata.appointmentPurpose}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        appointmentPurpose: e.target.value,
                      }))
                    }
                    required
                  >
                    <option value="">Select</option>
                    <option value="New Consultation">New Consultation</option>
                    <option value="Followup Consultation">
                      Followup Consultation
                    </option>
                    <option value="Followup Procedure">
                      Followup Procedure
                    </option>
                  </select>
                </div>
                <div className="RegisForm_1">
                  <label htmlFor="doctorName">
                    Doctor Name<span>:</span>
                  </label>
                  <select
                    id="doctorName"
                    name="doctorName"
                    value={Requestdata.doctorName}
                    onChange={(e) =>
                      setRequestdata((prev) => ({
                        ...prev,
                        doctorName: e.target.value,
                      }))
                    }
                  >
                    <option>Select</option>
                    {/* Dynamically populate doctor name */}
                    {doctorNames.map((name, index) => (
                      <option key={index} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <br />
          <div className="Register_btn_con">
            <button
              className="RegisterForm_1_btns"
              onClick={isedit ? handleFormupdate : handleFormSubmit}
            >
              {isedit ? "Update" : "Submit"}
            </button>
            <button className="RegisterForm_1_btns" onClick={handleclear}>
              Clear
            </button>
          </div>

          <ToastContainer />
        </div>

        <div className="h_head">
          <h4>Appointment Request List</h4>
        </div>

        <div className="con_1 ">
          <div className="inp_1 req_width">
            <label htmlFor="input">
              First Name<span>:</span>
            </label>
            <input
              type="text"
              id="FirstName"
              value={PatientFirstName}
              onChange={(e) => setPatientFirstName(e.target.value)}
              placeholder="Enter the First Name"
            />
          </div>
          <div className="inp_1 req_width">
            <label htmlFor="input">
              Phone No <span>:</span>
            </label>
            <input
              type="number"
              id="PhoneNo"
              value={PatientPhoneNo}
              onChange={(e) => {
                const inputPhone = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                if (inputPhone.length <= 10) {
                  // Limit to 10 digits
                  setPatientPhoneNo(inputPhone);
                }
              }}
              placeholder="Enter the Phone No"
              maxLength={10} // Limit input to 10 characters
              pattern="[0-9]*" // Allow only numeric input
            />
          </div>
          {/* <button className="btn_1" type="submit">
              <SearchIcon />
            </button> */}
          {/* <button
                className="btn_1"
                type="submit"
                onClick={() => handleRequestForm()}
                title="New Request"
              >
                <LoupeIcon />
              </button> */}
        </div>

        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={filteredRows.slice(page * 10, (page + 1) * 10)} // Display only the current page's data
              columns={dynamicColumns} // Use dynamic columns here
              pageSize={10}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {/* Pagination controls */}
            {filteredRows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {Math.ceil(filteredRows.length / 10)}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(
                        prevPage + 1,
                        Math.ceil(filteredRows.length / 10) - 1
                      )
                    )
                  }
                  disabled={page === Math.ceil(filteredRows.length / 10) - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
      <CancelAppointmentDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        onConfirm={handleCancelAppointment}
        setCancelReason={setCancelReason}
        cancelReason={cancelReason}
      />

{openModal2 && (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        // style={{ content: { ...yourStyles } }}
      >
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => {
            setOpenModal2(false);
          }}
        >
          <div
            className="newwProfiles newwPopupforreason"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              <div className="">
                <AppoinmentCalendar
                  userRecord={userRecord}
                  // modalContent={modalContent}
                />
              </div>
        
              <div className="">
                <Button
                  style={{ color: "white" }}
                  className="clse_pdf_img"
                  onClick={closeModal}
                >
                  <HighlightOffIcon
                    style={{
                      fontSize: "40px",
                      backgroundColor: "var(--ProjectColor)",
                      borderRadius: "40px",
                    }}
                  />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
)}
    </>
  );
};
export default React.memo(RequestList);
