import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import EditNoteIcon from "@mui/icons-material/EditNote";
import SearchIcon from "@mui/icons-material/Search";
import '../PatientManagement/PatientRegister.css';
import { useSelector } from "react-redux";

function NewProcedure( ) {

  const userRecord = useSelector(state=>state.userRecord?.UserData);

  const [proceduredata, setProcedureData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  // const [Patient_list, setPatient_list] = useState([]);
  const [PatientId, setPatientId] = useState("");
  const [PatientData, setPatientData] = useState("");
  const [therapistNames, setTherapistNames] = useState([]);
  const create = userRecord?.username;
  const [regsiter,setregsiter] = useState([]);
  const [visitid,setvisitid] = useState('')
  const [Billing,setBilling] = useState([]);

  console.log(create)

  //   const [ setSelectedPatient_list] = useState({PatientId: ""});

  const initialFormValues = {
    ProcedureName: "",
    Sessions: "",
    Amount: "",
    TotalAmount: "",
    TherapistName: "",
    nextAppointment: "",
  };

  const currentDate = new Date();
  const currentDateString = currentDate.toISOString().split('T')[0];
  console.log(currentDateString);

  const resetInputs = () => {
    setPatientData("");
    setPatientId("");
    setFormValues(initialFormValues);
  };

  const [formValues, setFormValues] = useState(initialFormValues);

    // useEffect(()=>{
    //   axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_visitid_for_directprocedure?selected_patientid=${PatientId}`)
    //   .then((response)=>{
    //     console.log(response.data)
    //   })
    //   .catch((error)=>{
    //     console.log(error)
    //   })
    // },[PatientId])

  const handleAdd = () => {
    if (validateForm()) {
      const newProcedure = {
        PatientID: PatientId,
        ProcedureName: formValues.ProcedureName,
        no_of_session: formValues.Sessions,
        Therapist_Name: formValues.TherapistName,
        nextAppointment: formValues.nextAppointment,
        VisitID: visitid,
        createdBy: create,
        appointmentDate: currentDateString,
        doctorname: 'null',
        Status: 'pending',
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount
      };
      const billing = [{
        PatientID: PatientId,
        PatientName: `${PatientData.FirstName} ${PatientData.LastName}`,
        VisitID: visitid,
        ServiceType: 'Procedure',
        ProcedureName: formValues.ProcedureName,
        Sessions: formValues.Sessions,
        PaidSessions: 0,
        RemainingSessions: 0,
        theraphist_session_completed: 0,
        Amount: formValues.Amount,
        Discount: 0,
        TotalAmount: formValues.TotalAmount,
        Status: 'Pending',
        appointmentDate: currentDateString,

    }];
    
       setBilling(billing)

      const register = {
        PatientID: PatientId,
        FirstName: PatientData.FirstName,
        LastName: PatientData.LastName,
        PhoneNumber: PatientData.PhoneNumber,
        Title: PatientData.Title,
        Email: PatientData.Email,
        created_by: create,
        visitid: visitid
      }

      setregsiter(register)

      if (isEditing) {
        const updatedData = [...proceduredata];
        updatedData[editedIndex] = newProcedure;
        setProcedureData(updatedData);
      } else {
        setProcedureData((prevData) => [...prevData, newProcedure]);
      }

      setFormValues(initialFormValues);
      setIsEditing(false);
      setEditedIndex(null);
    }
  };

  const handleInputChange = (name, value) => {
    setPatientId(value);
  };

  const validateForm = () => {
    return (
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.TherapistName &&
      formValues.nextAppointment
    );
  };

  const handleEdit = (index) => {
    setFormValues(proceduredata[index]);
    setIsEditing(true);
    setEditedIndex(index);
  };

  const Fetch_ONEPatient_data = () => {
    console.log(PatientId);
    axios
      .get(
        `http://127.0.0.1:8000/doctorsworkbench/get_patientdata_for_newprocedure?patientId=${PatientId}`
      )
      .then((response) => {
        console.log(response);
        if (response.data === "None") {
          alert("PatientID Not Available");
          // Clear the inputs
          resetInputs();
        } else {
          setPatientData(response.data[0]);
          setvisitid(response.data[1].visitid)
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  
  useEffect(() => {
    // Fetch therapist names here and update the therapistNames state
    axios
      .get("http://127.0.0.1:8000/doctorsworkbench/get_therapist_name")
      .then((response) => {
        // Assuming the response data is an array of therapist names
        setTherapistNames(response.data);
      })
      .catch((error) => {
        console.error("Error fetching therapist names:", error);
      });
  }, []); // Empty dependency array to ensure this effect runs only once when the component mounts

  const handleSave = () => {
    console.log("Procedure data:", proceduredata);
      
   

      axios.post('http://127.0.0.1:8000/appointmentmanagement/insert_newprocedure_register', regsiter)
      .then((response)=>{
        console.log(response)
      })
      .catch((error)=>{
        console.log(error)
      })

      
    axios
    .post("http://127.0.0.1:8000/doctorsworkbench/insert_procedure" , proceduredata)
    .then((response) => {
      console.log(response);
      alert(response.data.message);
    })
    .catch((error) => {
      console.error("Error saving data:", error);
      // Handle error appropriately, e.g., show an error message to the user
    });

    axios
    .post("http://127.0.0.1:8000/GeneralBilling/insertGeneral_Billing_Data" , Billing)
    .then((response) => {
      console.log(response);
      alert(response.data.message);
    })
    .catch((error) => {
      console.error("Error saving data:", error);
      // Handle error appropriately, e.g., show an error message to the user
    });

    // Reset the form after saving
    resetInputs();
  };

  return (
    <div className="new-patient-registration-form">
      <div className="invoice_Secondpart">
        <h3 style={{ height: "50px" }}>Patient Details:</h3>
        <div className="QuickStock_container_form">
          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="get_PatientId">
              Patient ID:
            </label>
            <div className="input-with-icon">
              <input
                type="text"
                Value={PatientId}
                name="PatientId"
                className="Inventory-2"
                list="get_PatientId"
                onChange={(e) => handleInputChange("PatientId", e.target.value)}
              />

              <button
                className="searching_input_icon3"
                onClick={Fetch_ONEPatient_data}
              >
                <SearchIcon />
              </button>
            </div>
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              Patient Name:
            </label>
            <div className="input-with-icon">
              <input
                type="text"
                placeholder="Enter Patient Name"
                value={
                  (PatientData?.FirstName || "") +
                  " " +
                  (PatientData?.LastName || "")
                }
                name="PatientName"
                className="Inventory-2"
                list="FirstName_PatientId"
                onChange={(e) =>
                  handleInputChange("PatientName", e.target.value)
                } 
              />
              {/* <button
                className="searching_input_icon3"
                onClick={Fetch_ONEPatient_data}
              >
                <SearchIcon />
              </button> */}
            </div>
            {/* 
            <datalist id="FirstName_PatientId">
              {Patient_list.map((item, index) => (
                <option key={item.PatientID} value={item.FirstName}>
                  {item.FirstName}
                </option>
              ))}
            </datalist> */}
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              Patient Age:
            </label>
            <input
              type="number"
              name="PatientAge"
              className="Inventory-2"
              placeholder="Enter Patient Age"
              value={PatientData?.Age || ""}
              readOnly
            />
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              Patient Address:
            </label>
            <input
              name="PatientAddress"
              type="text"
              className="Inventory-2"
              placeholder="Enter Patient Address"
              value={
                (PatientData?.DoorNo || "") +
                " , " +
                (PatientData?.Street || "") +
                "," +
                (PatientData?.Area || "")
              }
              readOnly
            />
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              City:
            </label>
            <input
              name="City"
              type="text"
              className="Inventory-2"
              placeholder="Enter City"
              value={PatientData?.City || ""}
              readOnly
            />
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              State:
            </label>
            <input
              type="text"
              placeholder="Enter State"
              value={PatientData?.State || ""}
              name="State"
              className="Inventory-2"
              readOnly
            />
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              Pincode:
            </label>
            <input
              type="number"
              placeholder="Enter Pincode"
              value={PatientData?.Pincode || ""}
              name="Pincode"
              className="Inventory-2"
              readOnly
            />
          </div>

          <div className="QuickStock_container_div">
            <label className="label_classname" htmlFor="itemCode">
              Phone Number:
            </label>
            <div className="input-with-icon">
              <input
                type="number"
                placeholder="Enter Phone Number"
                value={PatientData?.PhoneNumber || ""}
                name="PhoneNumber"
                className="Inventory-2"
                list="Phone_Number"
                onChange={(e) =>
                  handleInputChange("PhoneNumber", e.target.value)
                }
              />
              {/* <button
                className="searching_input_icon3"
                onClick={Fetch_ONEPatient_data}
              >
                <SearchIcon />
              </button> */}
            </div>
            {/* <datalist id="Phone_Number">
              {Patient_list.map((item, index) => (
                <option key={item.PatientID} value={item.PhoneNumber}>
                  {item.PhoneNumber}
                </option>
              ))}
            </datalist> */}
          </div>
        </div>
      </div>
      <br></br>

      <div className="new-patient-info-container">
        <div className="new-custom-form-row width_procedure">
          <label htmlFor="ProcedureName" className="new-custom-label-title">
            Treatment Procedure:
          </label>
          <select
            id="ProcedureName"
            name="ProcedureName"
            className="new-custom-select-doctor-name"
            value={formValues.ProcedureName}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                ProcedureName: e.target.value,
              }))
            }
          >
            <option value="">Select Procedure</option>
            <option value="botox_injections">Botox Injections</option>
            <option value="dermal_fillers">Dermal Fillers</option>
            <option value="liposuction">Liposuction</option>
            <option value="facelift">Facelift</option>
            <option value="rhinoplasty">Rhinoplasty</option>
          </select>
        </div>

        <div className="new-custom-form-row width_procedure">
          <label htmlFor="DoctorName" className="new-custom-label-title">
            Therapist Name:
          </label>
          <select
            id="DoctorName"
            name="DoctorName"
            className="new-custom-select-doctor-name"
            value={formValues.TherapistName}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                TherapistName: e.target.value,
              }))
            }
          >
            <option value="">Select Therapist</option>
            {therapistNames.map((therapist, index) => (
              <option key={index} value={therapist}>
                {therapist}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="new-patient-info-container">
        <div className="new-custom-form-row width_procedure">
          <label htmlFor="Sessions" className="new-custom-label-title">
            Number of Sessions:
          </label>
          <input
            type="number"
            id="Sessions"
            name="Sessions"
            className="new-custom-select-doctor-input"
            placeholder="Enter No of Sessions"
            value={formValues.Sessions}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                Sessions: e.target.value,
              }))
            }
          />
        </div>
        <div className="new-custom-form-row width_procedure">
          <label htmlFor="Amount" className="new-custom-label-title">
            Procedure Amount:
          </label>
          <input
            type="number"
            id="Amount"
            name="Amount"
            className="new-custom-select-doctor-input"
            placeholder="Enter Procedure Amount"
            value={formValues.Amount}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                Amount: e.target.value,
              }))
            }
          />
        </div>
      </div>

      <div className="new-patient-info-container">
        <div className="new-custom-form-row width_procedure">
          <label htmlFor="Amount" className="new-custom-label-title">
            Total Procedure Amount:
          </label>
          <input
            type="number"
            id="Amount"
            name="TotalAmount"
            className="new-custom-select-doctor-input"
            placeholder="Enter Total Procedure Amount"
            value={formValues.TotalAmount}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                TotalAmount: e.target.value,
              }))
            }
          />
        </div>
        <div className="new-custom-form-row width_procedure">
          <label
            htmlFor="nextAppointment"
            className="new-custom-label-title label_procedure"
          >
            Next Appointment:
          </label>
          <input
            type="date"
            id="nextAppointment"
            name="nextAppointment"
            className="new-custom-select-doctor-input"
            value={formValues.nextAppointment}
            onChange={(e) =>
              setFormValues((prevValues) => ({
                ...prevValues,
                nextAppointment: e.target.value,
              }))
            }
            required
          />
        </div>
      </div>

      <div className="new-button-container">
        <button className="btncon_add" onClick={handleAdd}>
          {isEditing ? "Update" : "Add"}
        </button>
      </div>

      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th id="vital_Twidth">Procedure Name</th>
              <th id="vital_Twidth">Sessions</th>
              <th id="vital_Twidth">Amount</th>
              <th id="vital_Twidth">Total Amount</th>
              <th id="vital_Twidth">Therapist Name</th>
              <th id="vital_Twidth">Next Appointment</th>
              <th id="vital_Twidth">Action</th>
            </tr>
          </thead>
          <tbody>
            {proceduredata.map((data, index) => (
              <tr key={index}>
                <td>{data.ProcedureName}</td>
                <td>{data.no_of_session}</td>
                <td>{data.Amount}</td>
                <td>{data.TotalAmount}</td>
                <td>{data.Therapist_Name}</td>
                <td>{data.nextAppointment}</td>
                <td>
                  <button
                    className="pro-actn-edit"
                    onClick={() => handleEdit(index)}
                  >
                    <EditNoteIcon />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="new-button-container">
        <button className="btncon_add" onClick={handleSave}>
          Save
        </button>
      </div>
    </div>
  );
}
export default NewProcedure;
