import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Edit from '@mui/icons-material/Edit';
import { DataGrid } from '@mui/x-data-grid';
import Button from '@mui/material/Button';



const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#54d854bf",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  #54d854bf !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
function Journalentry () {

  const [isedit,setisedit]=useState(false)

  const [filteredRows, setfilteredRows] = useState([]);


  const [formState, setFormState] = useState({
    id:'',
    VoucherNo:'',
    GSTNature:'',
    CreditType: '',
    FromAccount: '',
    ToAccount:'',
    Credit:0,
    Debit: 0,
    ShortNarrative: '',
  });
  const pageSize = 10;
  const showdown = filteredRows.length;
  const totalPages = Math.ceil(filteredRows.length / 10);
  const [page, setPage] = useState(0);

  const handlePageChange = (params) => {
    setPage(params.page);
  };



const handlecleardata=()=>{
  setFormState({
    id:'',
    VoucherNo:'',
    GSTNature:'',
    CreditType: '',
    FromAccount: '',
    ToAccount:'',
    Credit:0,
    Debit: 0,
    ShortNarrative: '',
  })
}

  const add_data=()=>{
    console.log(formState)
    const requiredfields=[
      'VoucherNo',
      'GSTNature',
      'CreditType',
      'FromAccount',
      'ShortNarrative'
    ]
    if(formState.CreditType==='Credit'){
        requiredfields.push('Credit')
    }else{
        requiredfields.push('Debit')
    }
    const existing=requiredfields.filter((field)=>!formState[field])
    if(existing.length===0){
      setfilteredRows((prev)=>[...prev,formState])
      handlecleardata()

    }else{
      alert(`please fill the required fields ${existing.join(',')}`)
    }
  }

  const update_data=()=>{
    console.log(formState)
    const requiredfields=[
        'VoucherNo',
      'GSTNature',
      'CreditType',
      'FromAccount',
      'ShortNarrative'
    ]
    if(formState.CreditType==='Credit'){
        requiredfields.push('Credit')
    }else{
        requiredfields.push('Debit')
    }
    const existing=requiredfields.filter((field)=>!formState[field])
    if(existing.length===0){
        const datass=[...filteredRows]
            datass[isedit]=formState
            setfilteredRows([...datass])
            handlecleardata()
      }else{
        alert(`please fill the required fields ${existing.join(',')}`)
      }
  }

  const handleeditrow=(rows)=>{
    setFormState({...rows})
    setisedit(rows.id)
  }


  const dynamicColumns = [
    { field: "id", headerName: "S_No", width: 150 },
    { field: "VoucherNo", headerName: "VoucherNo", width: 200 },
    { field: "GSTNature", headerName: "GST Nature", width: 150 },
    { field: "CreditType", headerName: "Credit/Debit", width: 150 },
    { field: "FromAccount", headerName: "From Account ", width: 150 },
    { field: "ToAccount", headerName: "To Account ", width: 150 },
    { field: "Credit", headerName: "Credit ", width: 150 },
    { field: "Debit", headerName: "Debit ", width: 150 },
    { field: "ShortNarrative", headerName: "Short Narrative ", width: 150 },
    {
      field: "Action",
      headerName: "Action",
      width: 200,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleeditrow(params.row)}
          >
            <Edit />
          </Button>
        </>
      ),
    },
  ];
  return (
    <div className='new-patient-registration-form'>
      <div className='user_patienthead'>
        <h3>Journal Master</h3>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
          Voucher No <span>:</span>
          </label>
          <input
            type="text"
            name="VoucherNo"
            placeholder="Enter Voucher No"
            required
            value={formState.VoucherNo}
            onChange={(e) => setFormState({ ...formState, VoucherNo: e.target.value })}
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
          GST Nature <span>:</span>
          </label>
          <input
            type="text"
            name="GSTNature"
            placeholder="Enter GST Nature"
            required
            readOnly={isedit}
            value={formState.GSTNature}
            onChange={(e) => setFormState({ ...formState, GSTNature: e.target.value })}
          />
        </div>
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Credit / Debit <span>:</span>
          </label>
          <select
           onChange={(e)=>setFormState({...formState,CreditType:e.target.value})}
           value={formState.CreditType}
           >
            <option value=''>Select</option>
            <option value='Credit'>Credit</option>
            <option value='Debit'>Debit</option>
           </select>
        </div>
      </div>

      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12" >
          FromAccount <span>:</span>
          </label>
          <input
            type="text"
            name="FromAccount"
            placeholder="Enter From Account"
            required
            readOnly={isedit}
            value={formState.FromAccount}
            onChange={(e) => setFormState({ ...formState, FromAccount: e.target.value })}
           
          />
        </div>
       
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
          To Account <span>:</span>
          </label>
          <input
            type="text"
            name="ToAccount"
            placeholder="Enter To Account"
            required
            readOnly={isedit}
            value={formState.ToAccount}
            onChange={(e) => setFormState({ ...formState, ToAccount: e.target.value })}
          />
        </div>
        
        {formState.CreditType==='Credit'?<div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12">
            Credit <span>:</span>
          </label>
          <input
            type="number"
            name="Credit"
            placeholder="Enter Credit Amount"
            required
            readOnly={isedit}
            value={formState.Credit}
            onChange={(e) => setFormState({ ...formState, Credit: e.target.value })}
          />
        </div>
    :
    <div className="new-custom-form-row incre-inpt-with18">
    <label className="new-custom-label-title12">
      Debit <span>:</span>
    </label>
    <input
      type="number"
      name="Debit"
      placeholder="Enter Credit Amount"
      required
      readOnly={isedit}
      value={formState.Debit}
      onChange={(e) => setFormState({ ...formState, Debit: e.target.value })}
    />
  </div>
    }
      </div>
      <div className="new-patient-info-container ">
        <div className="new-custom-form-row incre-inpt-with18">
          <label className="new-custom-label-title12" >
          FromAccount <span>:</span>
          </label>
          <input
            type="text"
            name="ShortNarrative"
            placeholder="Enter Short Narrative"
            required
            readOnly={isedit}
            value={formState.ShortNarrative}
            onChange={(e) => setFormState({ ...formState, ShortNarrative: e.target.value })}
           
          />
        </div>
        </div>

      <div className="new-button-container">
        <button className="btncon_add prs-ad-sub-btn" type="button" onClick={isedit?update_data:add_data}>
          {isedit?'Update':'Submit'}
        </button>
      </div>
      <div className="grid_1">
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                // rows={summa}
                columns={dynamicColumns} // Use dynamic columns here
                pageSize={10}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > 0 && filteredRows.length > 10 && (
                <div className="IP_grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>
          {showdown !== 0 && filteredRows.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
     
    </div>
  );
}

export default Journalentry;

