import React, { useEffect, useState } from 'react';
import logo from '../assets/logo.png';
import axios from 'axios';
import html2pdf from 'html2pdf.js';
import { useSelector } from 'react-redux';

function PaySlip( ) {

    const userRecord = useSelector(state=>state.userRecord?.UserData)

    const employeedata = useSelector(state=>state.userRecord?.employeedata)

  console.log(userRecord);
  console.log(employeedata);
  const [clinicName, setClinicName] = useState('');
  const [clinicLogo, setClinicLogo] = useState(null);
  const [location, setlocation] = useState('');
  const [date, setdate] = useState('');

  const [formData, setFormData] = useState({
    employeeName: '',
    employeeId: '',
    department: '',
    numberOfPresents: 0,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };



  const handlePrint = async () => {
    try {
      // Get the target element
      const element = document.getElementById('paySlip');
      if (!element) {
        console.error('Target element not found.');
        return;
      }
  
      // Use html2pdf library to generate PDF from the component's content
      const pdfBlob = await html2pdf().from(element).outputPdf();
  
      // Create a FormData object to send the PDF file
      const formData = new FormData();
      formData.append('pdf', new Blob([pdfBlob], { type: 'application/pdf' }), 'paySlip.pdf');
      formData.append('employeeid', employeedata[0]?.EmployeeID);
      formData.append('employeename', employeedata[0]?.EmployeeName);
      formData.append('location', userRecord?.location);
      formData.append('createdby', userRecord?.username);
  
      // Send the PDF file to the backend
      const response = await axios.post('http://127.0.0.1:8000/HRmanagement/insert_EmployeePaySlips', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log(response.data);
      // Handle success (e.g., display a success message)
  
      window.print();
    } catch (error) {
      console.error('Error generating or uploading PDF file:', error);
      // Handle error
    }
  };
  

  useEffect(() => {
    const location = userRecord?.location;
    axios
      .get(`http://127.0.0.1:8000/HRmanagement/getAccountsetting_payslip?location=${location}`)
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          const data = response.data;
          setClinicName(data.Clinic_Name);
          setClinicLogo(`data:image/png;base64,${data.Clinic_Logo}`);
          setlocation(data.location);
          setdate(data.date);
        } else {
          // Handle error if needed
        }
      })
      .catch((error) => console.error('Error fetching data: ', error));
  }, [userRecord]);

    return (
        <>
            <div id="paySlip" className='leaveform'>
                <div className='user_patienthead print-button3'>
                    <h3>Pay Slip</h3>
                </div>
                <body>
                    <div className='paymt-fr-mnth-slp'>
                        <div className='logo-pay-slp'>
                            <img src={clinicLogo} alt='' />
                        </div>
                        <div>
                            <h2>{clinicName} ({location})</h2>
                        </div>
                    </div>
                    <div className='paymt-fr-mnth-slp'>
                        <h3>Pay Slip for the month of ({date})</h3>
                    </div>

                    <table className='jon-flx-the-twoi'>

                        <tbody>
                            <tr>
                                <td>
                                    <label htmlFor='employeeName'>Employee Name:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='employeeName'
                                        name='employeeName'
                                        value={employeedata && employeedata[0]?.EmployeeName || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='pfNumber'>PF Number:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='pfNumber'
                                        name='pfNumber'
                                        value={employeedata && employeedata[4]?.EPFNumber || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor='employeeId'>Employee ID:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='employeeId'
                                        name='employeeId'
                                        value={employeedata && employeedata[0]?.EmployeeID || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='panNumber'>PAN Number:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='panNumber'
                                        name='panNumber'
                                        value={employeedata && employeedata[3]?.PanNumber || ''}
                                        onChange={handleChange}
                                    />
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor='department'>Department:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='department'
                                        name='department'
                                        value={employeedata && employeedata[1]?.Department || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='designation'>Designation:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='designation'
                                        name='designation'
                                        value={employeedata && employeedata[1]?.Designation || ''}
                                        onChange={handleChange}
                                    />
                                </td>


                            </tr>
                            <tr>
                                <td>
                                    <label htmlFor='bankName'>Bank Name:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='bankName'
                                        name='bankName'
                                        value={employeedata && employeedata[3]?.BankName || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='bankAccountNumber'>Bank Account No:</label>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        id='bankAccountNumber'
                                        name='bankAccountNumber'
                                        value={employeedata && employeedata[3]?.AccountNumber || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                            </tr>
                            <tr>
                            <td>
                                    <label htmlFor='numberOfPresents'>Total Working Days:</label>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        id='numberOfPresents'
                                        name='numberOfPresents'
                                        value={employeedata && employeedata[2]?.TotalWorkingDays || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='dateOfJoining'>Date of Joining:</label>
                                </td>
                                <td>
                                    <input
                                        type='text'
                                        id='dateOfJoining'
                                        name='dateOfJoining'
                                        value={employeedata && employeedata[1]?.DateofJoining || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                               


                            </tr>
                            <tr>

                                {/* <td>
                                    <label htmlFor='esiNumber'>ESI Number:</label>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        id='esiNumber'
                                        name='esiNumber'
                                        value={employeedata && employeedata[4]?.ESINumber || ''}
                                        onChange={handleChange}
                                    />
                                </td> */}
                                <td>
                                    <label htmlFor='numberOfPresents'>No of Days absent:</label>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        id='numberOfPresents'
                                        name='numberOfPresents'
                                        value={employeedata && employeedata[2]?.AbsentDays || 0}
                                        onChange={handleChange}
                                    />
                                </td>
                                <td>
                                    <label htmlFor='numberOfPresents'>No of Days Present:</label>
                                </td>
                                <td>
                                    <input
                                        type='number'
                                        id='numberOfPresents'
                                        name='numberOfPresents'
                                        value={employeedata && employeedata[2]?.PresentDays || ''}
                                        onChange={handleChange}
                                    />
                                </td>
                                
                            </tr>
                            {/* <tr>
                            
                            </tr> */}
                        </tbody>
                    </table>



                    <table className='responsive-table909'>
                        <thead>
                            <tr>
                                <th>Earnings</th>
                                <th>Amount</th>
                                <th>Deduction</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Basic Salary</td>
                                <td>{employeedata && employeedata[2]?.Basic_Salary || '0'}</td>
                                <td>PF</td>
                                <td>{employeedata && employeedata[2]?.PF_for_Employee_Amount || '0'}</td>
                            </tr>
                            <tr>
                                <td>HRA</td>
                                <td>{employeedata && employeedata[2]?.HRA_Allowance_Amount || '0'}</td>
                                <td>Esi Amount</td>
                                <td>{employeedata && employeedata[2]?.Esi_Amount || '0'}</td>
                            </tr>
                            <tr>
                                <td>Medical</td>
                                <td>{employeedata && employeedata[2]?.Medical_Allowance_Amount || '0'}</td>
                                <td>Professional Tax</td>
                                <td>{employeedata && employeedata[2]?.professional_tax || '0'}</td>
                            </tr>
                            <tr>
                                <td>Travel</td>
                                <td>{employeedata && employeedata[2]?.Travel_Allowance_Amount || '0'}</td>
                                <td>Loss of Pay</td>
                                <td>{employeedata && employeedata[2]?.lossofpay || '0'}</td>
                            </tr>
                            <tr>
                                <td>Total Earnings</td>
                                <td>{employeedata && employeedata[2]?.TotalEarnings || '0'}</td>
                                <td>Total Deductions</td>
                                <td>{employeedata && employeedata[2]?.totaldeduction || '0'}</td>
                            </tr>

                        </tbody>
                    </table>


                    <div className="signature-section909 ">
                        <div className="signature909">
                            <p>Net Salary :</p>

                            <p>{employeedata && employeedata[2]?.Net_Salary || '0'}</p>

                        </div>
                    </div>
                    <div className="signature-section909">
                        <p className='disclaimer23'>
                            This page is created automatically without a signature.
                        </p>
                    </div>

                    <div className="Register_btn_con">
                        <button className="RegisterForm_1_btns print-button3" onClick={handlePrint}>
                            Print
                        </button>
                    </div>
                </body>
            </div>

        </>
    );
}

export default PaySlip;
