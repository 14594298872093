import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Helmet } from 'react-helmet';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';
import axios from "axios";
import './PharmacyBilling.css';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

 const PharmacyBillingLIst=( )=> {

const dispatchvalue = useDispatch();

    const reduxvalueUsercord = useSelector(state => state)
    const userRecord = reduxvalueUsercord.userRecord?.UserData
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [Prescription_Patient_list, setPrescription_Patient_list] = useState([])

    
    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
   
    const handleRequestEdit = (params) => {
        console.log('============================================',params.row.PatientID)
        const Data = Prescription_Patient_list.find((p)=>p.PatientID === params.row.PatientID)
        dispatchvalue({type : 'Selected_Patient_Pharmacy' , value : ({'PatientID':Data.PatientID,'Patient_Name':Data.Patient_Name})})
        navigate('/Home/Pharmacy-Billing')
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
   
    useEffect(() => {
        axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_prescription?PatientID=${''}&location=${userRecord?.location}`)
            .then((response) => {
                console.log(response.data);
                const Datas = response.data.map((p)=>({
                    ...p,
                    id:p.PatientID
                }));
                console.log(Datas)

                setPrescription_Patient_list(...new Set([ Datas]));
                  
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                // Handle the error, e.g., show an error message to the user
            });
    
    }, [userRecord,userRecord?.location]);
    
    

    console.log(Prescription_Patient_list)
    
    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = Prescription_Patient_list.filter((row) => {
            // Check if row.Patient_Name is defined before calling toLowerCase
            const lowerCaseSupplierName = row.Patient_Name ? row.Patient_Name.toLowerCase() : '';
    
            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });
    
        setFilteredRows(filteredData);
    }, [searchQuery, Prescription_Patient_list]);
    



    const handlePharmacybilling = () => {
        navigate('/Home/Pharmacy-Walkinbilling')
    }


    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'id', headerName: 'Patient Id', width: 100 },
        { field: 'Patient_Name', headerName: 'Patient Name', width: 200 },
        { field: 'AppointmentDate', headerName: 'Appointment Date', width: 200, 
        renderCell: (params) => params.value.slice(5, 16) },
        { field: 'DoctorName', headerName: 'Doctor Name', width: 200 },
        { field: 'Status', headerName: 'Billing Status', width: 150 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <ArrowForwardIcon />
                    </Button>
                </>
            ),
        },
    ];




    return (
        <>
            <Helmet>
                <title>Pharmacy Billing List</title>
            </Helmet>
            <div className="appointment">
                <div className="h_head">
                    <h4>Pharmacy Billing List</h4>
                </div>
                    <div className="con_1 ">

                        <div className="inp_1 Supplier_inputss_forms">
                            <label htmlFor="input">Patient Name <spa>:</spa></label>
                            <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Patient Name' />
                        </div>
                        <button className='btn_1' type='submit'>
                            <SearchIcon />
                        </button>
                        <button className='btn_1' type='submit' onClick={() => handlePharmacybilling()} title='Manual Billing'>
                            <LoupeIcon />
                        </button>
                    </div>
                <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <div className='grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className='data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
export default PharmacyBillingLIst;