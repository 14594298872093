import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import "./PatientQueueList.css";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Tooltip } from "@material-ui/core";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const PatientQueueList = () => {
  const dispatchvalue = useDispatch();

  const userRecord = useSelector((state) => state.userRecord?.UserData);
console.log(userRecord)

const Location = userRecord?.location
const UserName  = userRecord?.username
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  // const [filteredRows,setFilteredRows]=useState([]);
  const [Doctors, setDoctors] = useState([]);
  const [selectedDoctor, setselectedDoctor] = useState("all");
  const [page, setPage] = useState(0);
  const pageSize = 10;
  const showdown = rows.length;
  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [columns, setcolumn] = useState([]);

  const getmethod = useCallback(() => {
    axios
      .get(
        `http://127.0.0.1:8000/doctorsworkbench/get_fortheraphist_queuelist?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response);
        const filteredData = response.data.filter((userdata) => {
          // Check if the doctor matches the selected doctor or if all doctors are selected
          return (
            selectedDoctor === "all" || userdata.DoctorName === selectedDoctor
          );
        });

        const Records = filteredData.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          AppointmentPurpose: userdata.AppointmentPurpose,
          Status: userdata.Status,
          DoctorName: userdata.DoctorName,
          Location: userdata.Location,
          next_appointment_date: userdata.next_appointment_date,
          TotalSession: userdata.TotalSession,
          completedsessions: userdata.completedsessions,
          currentsession: userdata.currentsession,
          Treatment_Procedure: userdata.Treatment_Procedure,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [selectedDoctor, userRecord, userRecord?.location]);





  // useEffect(() => {
  //   axios
  //     .post(
  //       `http://127.0.0.1:8000/doctorsworkbench/insertrequest_fornextappoinment?location=${Location}&createdby=${UserName}`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //     });
  // }, [Location, UserName]);

  useEffect(() => {
    console.log(userRecord);
    getmethod();
  }, [getmethod]); // Add selectedDoctor as a dependency to trigger the effect when the doctor changes

  // console.log(rows)

  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/usercontrol/get_doctor_info?location=${userRecord?.location}`
      )
      .then((response) => {
        setDoctors(response.data);
        setcolumn([
          { field: "PatientName", headerName: "Patient Name", width: 120 },
          {
            field: "DoctorName",
            headerName: "Doctor Name",
            width: 120,
            renderCell: (params) => (
              <>
                {response.data.length > 0 ? (
                  <select
                    value={params.value}
                    onChange={(e) => handledoctorchange(e, params.row)}
                  >
                    {[...response.data, "Arjun"].map((doctor, index) => (
                      <option key={index} value={doctor}>
                        {doctor}
                      </option>
                    ))}
                  </select>
                ) : (
                  <span>Loading...</span>
                )}
              </>
            ),
          },
          {
            field: "AppointmentPurpose",
            headerName: "Appointment Purpose",
            width: 170,
          },
          { field: "Status", headerName: "Status", width: 170 },
          {
            field: "next_appointment_date",
            headerName: "Next Appointment",
            width: 150,
            renderCell: (params) => (
              <Tooltip
              title={
                <>
                  Procedurename: {params.row.Treatment_Procedure}
                  <br />
                  Total Session: {params.row.TotalSession}
                  <br />
                  Completed Session: {params.row.completedsessions}
                  <br />
                  Current Session: {params.row.currentsession}
                </>
              }              arrow
              >
                <span>{params.value}</span>
              </Tooltip>
            ),
          },
          {
            field: "actions ",
            headerName: "View",
            width: 120,
            renderCell: (params) => (
              <>
                <Button className="cell_btn" onClick={() => handleList(params)}>
                  <VisibilityIcon />
                </Button>
              </>
            ),
          },
          {
            field: "Action",
            headerName: "Action",
            width: 100,
            renderCell: (params) => (
              <>
                <Button
                  className="cell_btn"
                  onClick={() => handleRequestProceed(params)}
                >
                  <ArrowForwardIcon />
                </Button>
              </>
            ),
          },
        ]);
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [userRecord, userRecord?.location]);


  const handleRequestProceed = (params) => {
    console.log(params);
    const serialid = params.row.id;
    const location = params.row.Location;
    axios
      .post(
        `http://127.0.0.1:8000/doctorsworkbench/update_status_appointment_register?selectedpatienid=${serialid}&location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        if (response.data) {
          console.log(response.data);
          // Handle success, e.g., navigate to another page
        } else {
          console.error("Error updating record:", response.data.message);
          // Handle error, e.g., show an error message to the user
        }
      })
      .catch((error) => {
        console.error("Error updating record:", error);
      });

    const data = {
      serialid: serialid,
      location: location,
    };
    console.log(data);
    dispatchvalue({ type: "Data", value: data });

    navigate("/Home/Navigation");
  };
  
  const handleList = (params) => {
    // Extract the id from the selected row
    const selectedRowId = params.row.id;
    // Log the selected row id
    console.log("Selected Row id:", selectedRowId);

    axios
      .get(`http://127.0.0.1:8000/patientmanagement/get_personal_info`)
      .then((response) => {
        const data1 = response.data;
        const foundPatient = data1.find(
          (patient) => patient.PatientID === selectedRowId
        );

        console.log(foundPatient);

        // Fetch communication address
        axios
          .get(
            `http://127.0.0.1:8000/patientmanagement/get_communication_address`
          )
          .then((response) => {
            const data2 = response.data;
            const patient_address = data2.find(
              (patient) => patient.PatientID === selectedRowId
            );
            console.log(patient_address);

            axios
              .get(
                `http://127.0.0.1:8000/doctorsworkbench/get_vitalform1?selectedRowId=${selectedRowId}`
              )
              .then((response) => {
                const data3 = response.data;
                // const vital_data = data3.find(patient => patient.PatientID === selectedRowId);
                const vital = data3[0];
                console.log(vital);

                axios
                  .get(
                    `http://127.0.0.1:8000/doctorsworkbench/get_treatment_forprofile?selectedRowId=${selectedRowId}`
                  )
                  .then((response) => {
                    const data4 = response.data;
                    console.log(data4);
                    const firstimge = data4.first_image;
                    const lastimg = data4.last_image;

                    axios
                      .get(
                        `http://127.0.0.1:8000/doctorsworkbench/get_patientphoto?selectedRowId=${selectedRowId}`
                      )
                      .then((response) => {
                        const data5 = response.data;
                        console.log(data5);
                        const PatientPhoto = data5.PatientPhoto;

                        axios
                          .get(
                            `http://127.0.0.1:8000/doctorsworkbench/get_treatment`
                          )
                          .then((response) => {
                            const data6 = response.data;
                            const filteredData = data6.filter(
                              (row) => selectedRowId === data5.PatientID
                            );
                            console.log(data5);
                            const history = filteredData.History;

                            axios
                              .get(
                                `http://127.0.0.1:8000/doctorsworkbench/get_emergencydetails_info1?selectedRowId=${selectedRowId}`
                              )
                              .then((response) => {
                                const data7 = response.data;
                                console.log("/===========", data7);

                                const EmergencyName = data7.Name;
                                console.log(EmergencyName)

                                axios
                                  .get(
                                    `http://127.0.0.1:8000/doctorsworkbench/get_allergiesname_forprofile?selectedRowId=${selectedRowId}`
                                  )
                                  .then((response) => {
                                    const data8 = response.data;
                                    console.log("/===========", data8);

                                    const allergies = data8.allergies;
                                    console.log(allergies)

                                    axios
                                      .get(
                                        `http://127.0.0.1:8000/doctorsworkbench/get_previousvisit?selectedRowId=${selectedRowId}`
                                      )
                                      .then((response) => {
                                        const data9 = response.data;
                                        console.log("/===========", data9);

                                        const previousvisit =
                                          data9.previousvisit;
                                        console.log(previousvisit)

                                        axios
                                          .get(
                                            `http://127.0.0.1:8000/doctorsworkbench/get_next_visit?selectedRowId=${selectedRowId}`
                                          )
                                          .then((response) => {
                                            const data10 = response.data;
                                            console.log("/===========", data10);

                                            const Next_Appointment =
                                              data10.Next_Appointment;
                                            console.log(Next_Appointment);

                                            axios
                                              .get(
                                                `http://127.0.0.1:8000/doctorsworkbench/get_diseasename?selectedRowId=${selectedRowId}`
                                              )
                                              .then((response) => {
                                                const data11 = response.data;
                                                console.log("/===========", data11);

                                                axios
                                                  .get(
                                                    `http://127.0.0.1:8000/doctorsworkbench/get_allimages_for_patients?selectedRowId=${selectedRowId}`
                                                  )
                                                  .then((response) => {
                                                    const data12 =
                                                      response.data;
                                                    console.log(
                                                      "/===========",
                                                      data12
                                                    );

                                                    const patientAction = (data) =>({
                                                      type: 'forPatientData',
                                                      value: data
                                                    })

                                                    dispatchvalue(patientAction({
                                                        // ...prevInfo,
                                                        ...foundPatient,
                                                        ...patient_address,
                                                        ...vital,
                                                        ...firstimge,
                                                        ...lastimg,
                                                        ...PatientPhoto,
                                                        ...history,
                                                        ...EmergencyName,
                                                        ...allergies,
                                                        ...previousvisit,
                                                        ...Next_Appointment,
                                                        ...data11,
                                                        ...data12,
                                                        // ...data11
                                                        // ...first_image,
                                                        // ...last_image
                                                     })                                              
                                                     )
                                                  });
                                              });
                                          });
                                      });
                                  });
                              });
                          });
                      });
                  });
              });
          });
      });

    navigate("/Home/PatientProfile");
  };

  // useEffect(() => {
  //   // Fetch patient IDs
  //   const fetchPatientIDs = async () => {
  //     try {
  //       const response = await axios.get('http://127.0.0.1:8000/doctorsworkbench/get_appoitmentregpatient_ids');
  //       const patientIds = response.data;
  //       console.log(patientIds)
  //       setpatientIDs(patientIds); // Update the state with patient IDs
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };

  //   // Call the fetchPatientIDs function
  //   fetchPatientIDs();
  // }, []);

  // useEffect(() => {

  //   // The empty dependency array [] ensures that this effect runs once when the component mounts

  // }, [patientIDs]);



  console.log("Doctors", Doctors);
  const handledoctorchange = (e, row) => {
    const { value } = e.target;
    console.log(row);
    const confor = window.confirm("Are You Sure ,Want to change the Doctor");
    if (confor) {
      axios
        .post(
          `http://127.0.0.1:8000/appointmentmanagement/Update_doctor_Name?id=${row.id}&DoctorName=${value}&location=${row.Location}`
        )
        .then((response) => {
          console.log(response.data);
          alert(response.data.message);
          getmethod();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    fetch(
      `http://127.0.0.1:8000/appointmentmanagement/get_appointments?location=${userRecord?.location}`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (Array.isArray(data)) {
          // Check if data is an array
          const filteredData = data.filter((user) => {
            const lowerCaseFirstName = user.FirstName.toLowerCase();
            const lowerCasePhoneNo = user.PhoneNumber.toString();

            const lowerCaseSearch = PatientFirstName.toLowerCase();
            const lowerCasePhoneSearch = PatientPhoneNo.toLowerCase();

            // Check if the search query matches the start of the name or phone number,
            // and ignore the empty field
            return (
              (PatientFirstName === "" ||
                lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
              (PatientPhoneNo === "" ||
                lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
            );
          });

          if (filteredData.length === 0) {
            alert("No matching appointments found.");
            setRows([]);
          } else {
            const rows = filteredData.map((appointment) => ({
              id: appointment.PatientID, // Use a unique identifier or fallback to index
              PatientName: appointment.FirstName + appointment.LastName,
              AppointmentPurpose: appointment.AppointmentPurpose,
              Status: appointment.Status,
            }));
            setRows(rows);
          }
        } else {
          console.error("Fetched data is not an array:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  };

  //   const handleSearch = async (e) => {
  //     e.preventDefault();

  //     console.log(PatientFirstName, PatientPhoneNo);

  //     try {
  //       // For first name search
  //       const firstNameResponse = await fetch(http://127.0.0.1:8000/doctorsworkbench/search_appointments?search=${PatientFirstName.toLowerCase()}, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       const firstNameData = await firstNameResponse.json();

  //       // For phone number search
  //       const phoneNoResponse = await fetch(http://127.0.0.1:8000/doctorsworkbench/search_appointments?search=${PatientPhoneNo}, {
  //         method: 'GET',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //       });
  //       const phoneNoData = await phoneNoResponse.json();

  //       updateData(firstNameData, phoneNoData);
  //     } catch (error) {
  //       console.error('Error fetching filtered data:', error);
  //     }
  //   };

  //   const updateData = (firstNameData, phoneNoData) => {
  //     if (firstNameData.success && Array.isArray(firstNameData.appointments_data) &&
  //         phoneNoData.success && Array.isArray(phoneNoData.appointments_data)) {

  //       const firstNameRecords = firstNameData.appointments_data.map((userdata) => ({
  //         id: userdata.SerialNo,
  //         First_Name: userdata.First_Name,
  //         PatientName: userdata.First_Name + ' ' + userdata.Last_Name,
  //         Phone_No: userdata.Phone_No,
  //         DoctorName: userdata.DoctorName,
  //         AppointmentType: userdata.AppointmentType,
  //         Status: userdata.Status,
  //       }));

  //       const phoneNoRecords = phoneNoData.appointments_data.map((userdata) => ({
  //         id: userdata.SerialNo,
  //         First_Name: userdata.First_Name,
  //         PatientName: userdata.First_Name + ' ' + userdata.Last_Name,
  //         Phone_No: userdata.Phone_No,
  //         DoctorName: userdata.DoctorName,
  //         AppointmentType: userdata.AppointmentType,
  //         Status: userdata.Status,
  //       }));

  //       const mergedData = [...firstNameRecords, ...phoneNoRecords];

  //       const uniqueData = Array.from(new Set(mergedData.map((item) => item.id))).map((id) => {
  //         return mergedData.find((item) => item.id === id);
  //       });

  //       console.log(uniqueData);

  //       if (uniqueData.length === 0) {
  //         // alert('No matching users found.');
  //       } else {
  //         setRows(uniqueData);
  //       }
  //     } else {
  //       console.error('Fetched data is not as expected.');
  // }
  // };

  const totalPages = Math.ceil(rows.length / 10);

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h4>Patient Queue List</h4>
          <div className="doctor_select_1 selt-dctr-nse">
            <label htmlFor="names">Doctor<span>:</span></label>
            <select
              name="Doctor Name"
              onChange={(e) => setselectedDoctor(e.target.value)}
              value={selectedDoctor}
            >
              <option value="all">Select</option>
              {Doctors.map((p, index) => (
                <option key={index} value={p}>
                  {p}
                </option>
              ))}
            </select>
          </div>
        </div>
        <form onSubmit={handleSearch}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name <span>:</span></label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No <span>:</span></label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 0 && rows.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
        {rows.length === 0 && (
          <div className="norecords">
            <span>No Records Found</span>
          </div>
        )}
      </div>
    </>
  );
};

export default PatientQueueList;
