import React from "react";
import Logo from "../../../src/assets/logo.png";
import "./MedicalHistoryForm.css";
import { useState } from "react";
import axios from 'axios';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';

function MedicalHistoryForm() {
  const [medicalHistory, setMedicalHistory] = useState({
    // MedicalHistory
    Anemia: false,
    Arthritis: false,
    Asthma: false,
    Cancer: false,
    ChronicObstructivePulmonaryDisease: false,
    ClottingDisorder: false,
    SkinDisease: false,
    CongestiveHeartFailure: false,
    CrohnsDisease: false,
    Depression: false,
    Diabetes: false,
    Emphysema: false,
    EndocrineProblems: false,
    GERD: false,
    Glaucoma: false,
    Hepatitis: false,
    HIVAIDS: false,
    Hypertension: false,
    KidneyDisease: false,
    MyocardialInfarction: false,
    PepticUlcerDisease: false,
    Seizures: false,
    Stroke: false,
    UlcerativeColitis: false,
  });

  const [patientInfo, setPatientInfo] = useState({
    name: "",
    age: "",
    date: new Date().toLocaleDateString(),
    reason: "",
    Description:'',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPatientInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (condition) => {
    setMedicalHistory({
      ...medicalHistory,
      [condition]: !medicalHistory[condition],
    });
  };

  const [socialHistory, setSocialHistory] = useState({
    alcoholUseNever: false,
    alcoholUseOccasionally: false,
    alcoholUseDaily: false,

    tobaccoUseNever: false,
    tobaccoUseOccasionally: false,
    tobaccoUseDaily: false,

    drugsUseNever: false,
    drugsUseOccasionally: false,
    drugsUseDaily: false,
  });

  const handleCheckboxChange2 = (name) => {
    setSocialHistory((prevState) => {
      let updatedState = { ...prevState };

      // Deselect all other checkboxes in the same category
      switch (name) {
        case "alcoholUseNever":
        case "alcoholUseOccasionally":
        case "alcoholUseDaily":
          updatedState = {
            ...prevState,
            alcoholUseNever: false,
            alcoholUseOccasionally: false,
            alcoholUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        case "tobaccoUseNever":
        case "tobaccoUseOccasionally":
        case "tobaccoUseDaily":
          updatedState = {
            ...prevState,
            tobaccoUseNever: false,
            tobaccoUseOccasionally: false,
            tobaccoUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        case "drugsUseNever":
        case "drugsUseOccasionally":
        case "drugsUseDaily":
          updatedState = {
            ...prevState,
            drugsUseNever: false,
            drugsUseOccasionally: false,
            drugsUseDaily: false,
            [name]: !prevState[name],
          };
          break;
        default:
          break;
      }

      return updatedState;
    });
  };

  const [familyHistory, setFamilyHistory] = useState({
    CancerPolyps: false,
    Anemia1: false,
    Diabetes1: false,
    BloodClots1: false,
    HeartDisease1: false,
    Stroke1: false,
    HighBloodPressure1: false,
    AnesthesiaReaction1: false,
    BleedingProblems1: false,
    Hepatitis1: false,
    Other1: false,

   
  });

  const handleCheckboxChange3 = (condition) => {
    setFamilyHistory({
      ...familyHistory,
      [condition]: !familyHistory[condition],
    });
  };

  const [familyHistoryInfo, setFamilyHistoryInfo] = useState({

    FamilyName: " ",
    Age1: "",
    RelationShip1: "",

  });

  const handleInputWomenChange= (e) => {
    const { name, value } = e.target;
    setFamilyHistoryInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }




  // function printPage() {
  //   window.print();
  // }
  const handlePrint = useReactToPrint({
    content: () => document.getElementById('reactprintcontent'),
    onBeforePrint: () => {
        console.log('Before');
    },
    onAfterPrint: async () => {
        console.log('After');
        const printdata = document.getElementById('reactprintcontent');
        console.log('printdata', printdata);
        
        try {
           
            if (printdata) {
                const contentWidth = printdata.offsetWidth;
                const padding = 20; // Adjust the padding as needed
                const pdfWidth = contentWidth + 2 * padding; // Add padding to width
                const pdfHeight = contentWidth * 1.5; // Add padding to height
                const pdf = new jsPDF({
                    unit: 'px',
                    format: [pdfWidth, pdfHeight],
                });
                pdf.html(printdata, {
                    x: padding, // Set x-coordinate for content
                    y: padding, // Set y-coordinate for content
                    callback: () => {
                     
                        const generatedPdfBlob = pdf.output('blob');
                        console.log('generatedPdfBlob',generatedPdfBlob)
                        const data = new FormData
                        // data.append('patientid')
                        data.append('generatedPdfBlob',generatedPdfBlob)
                        
                        axios.post("http://127.0.0.1:8000/ipregistration/insert_pdf_datas", data)
                             .then(response => {
                              // Handle successful response if needed
                               console.log('Print request sent successfully');
                            })
                             .catch(error => {
                               // Handle error if needed
                               console.error('Error sending print request:', error);
                             });
                    },
                });
            } else {
                throw new Error('Unable to get the target element');
            }
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    }
});

  return (
    <>
      <body>
        <div className="Medical_History_container" id="reactprintcontent">
          <div className="Medical_History_logo">
            <img
              src={Logo}
              alt=""
              draggable="false"
              className="Medical_History_logo_img"
            />
            <h3>New Patient Medical History Form</h3>
          </div>

          <div className="form-section5">
            <div className="form-field5">
              <label>
                Patient Name <span>:</span>
              </label>
              <input
                type="text"
                name="name"
                value={patientInfo.name}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field5">
              <label>
                Age <span>:</span>
              </label>
              <input
                type="text"
                name="age"
                value={patientInfo.age}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-field5">
              <label>
                Today's Date <span>:</span>
              </label>
              <input type="text" value={patientInfo.date} readOnly />
            </div>
          </div>

          <div className="form-section5">
            <div className="form-field5">
              <label>
                Reason you are here <span>:</span>
              </label>
              <textarea
                name="reason"
                value={patientInfo.reason}
                onChange={handleInputChange}
              ></textarea>
            </div>
          </div>

          <br></br>
          <div className="form-section5">
            <div className=" dkwjd">
              <h3>Personal Medical History :</h3>
              <h5>
                {" "}
                Have you ever had any of the following conditions? (Check if
                yes)
              </h5>
            </div>
            <br></br>
            <div className="div_ckkkbox_head">
              <div className="div_ckkck_box">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Anemia}
                    onChange={() => handleCheckboxChange("Anemia")}
                  />
                  Anemia
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Arthritis}
                    onChange={() => handleCheckboxChange("Arthritis")}
                  />
                  Arthritis
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Asthma}
                    onChange={() => handleCheckboxChange("Asthma")}
                  />
                  Asthma
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Cancer}
                    onChange={() => handleCheckboxChange("Cancer")}
                  />
                  Cancer
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={
                      medicalHistory["ChronicObstructivePulmonaryDisease"]
                    }
                    onChange={() =>
                      handleCheckboxChange("ChronicObstructivePulmonaryDisease")
                    }
                  />
                  Chronic Obstructive Disease
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["SkinDisease"]}
                    onChange={() => handleCheckboxChange("SkinDisease")}
                  />
                  Skin Disease
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["ClottingDisorder"]}
                    onChange={() => handleCheckboxChange("ClottingDisorder")}
                  />
                  Clotting Disorder
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["CongestiveHeartFailure"]}
                    onChange={() =>
                      handleCheckboxChange("CongestiveHeartFailure")
                    }
                  />
                  Congestive Heart Failure
                </label>
              </div>

              <div className="div_ckkck_box">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["CrohnsDisease"]}
                    onChange={() => handleCheckboxChange("CrohnsDisease")}
                  />
                  Crohn's Disease
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Depression}
                    onChange={() => handleCheckboxChange("Depression")}
                  />
                  Depression
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Diabetes}
                    onChange={() => handleCheckboxChange("Diabetes")}
                  />
                  Diabetes
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Emphysema}
                    onChange={() => handleCheckboxChange("Emphysema")}
                  />
                  Emphysema
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["EndocrineProblems"]}
                    onChange={() => handleCheckboxChange("EndocrineProblems")}
                  />
                  Endocrine Problems
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.GERD}
                    onChange={() => handleCheckboxChange("GERD")}
                  />
                  GERD
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Glaucoma}
                    onChange={() => handleCheckboxChange("Glaucoma")}
                  />
                  Glaucoma
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Hepatitis}
                    onChange={() => handleCheckboxChange("Hepatitis")}
                  />
                  Hepatitis
                </label>
              </div>

              <div className="div_ckkck_box">
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["HIVAIDS"]}
                    onChange={() => handleCheckboxChange("HIVAIDS")}
                  />
                  HIV/AIDS
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Hypertension}
                    onChange={() => handleCheckboxChange("Hypertension")}
                  />
                  Hypertension
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["Kidney Disease"]}
                    onChange={() => handleCheckboxChange("KidneyDisease")}
                  />
                  Kidney Disease
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["MyocardialInfarction"]}
                    onChange={() =>
                      handleCheckboxChange("MyocardialInfarction")
                    }
                  />
                  Myocardial Infarction
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["Peptic Ulcer Disease"]}
                    onChange={() => handleCheckboxChange("PepticUlcerDisease")}
                  />
                  Peptic Ulcer Disease
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Seizures}
                    onChange={() => handleCheckboxChange("Seizures")}
                  />
                  Seizures
                </label>
                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory.Stroke}
                    onChange={() => handleCheckboxChange("Stroke")}
                  />
                  Stroke
                </label>

                <label className="checkbox-label">
                  <input
                    type="checkbox"
                    className="checkbox-input"
                    checked={medicalHistory["UlcerativeColitis"]}
                    onChange={() => handleCheckboxChange("UlcerativeColitis")}
                  />
                  Ulcerative Colitis
                </label>
              </div>
            </div>
          </div>
          <br></br>

          <br></br>
          <div className="form-section5">
            <div className=" dkwjd">
              <h3>Social History :</h3>
              <h5>
                {" "}
                Have you ever had any of the following conditions? (Check if
                yes)
              </h5>
            </div>
            <br></br>
            <div className="div_ckkkbox_head sccx3">
              <div className="div_ckkck_box alcho_tac_drg11">
                <div className="ffdff44">
                  <div>
                    <label className="checkbox-label  alcho_tac_drg">
                      {" "}
                      Alcohol use -{" "}
                    </label>
                  </div>
                  <div className="flx_cjk_labl3">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input ddsfe"
                        checked={socialHistory.alcoholUseNever}
                        onChange={() => {
                          handleCheckboxChange2("alcoholUseNever");
                        }}
                      />
                      Never
                    </label>
                  </div>

                  <div className="flx_cjk_labl3">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input ddsfe"
                        checked={socialHistory.alcoholUseOccasionally}
                        onChange={() => {
                          handleCheckboxChange2("alcoholUseOccasionally");
                        }}
                      />
                      Occasionally
                    </label>
                  </div>

                  <div className="flx_cjk_labl3">
                    <label className="checkbox-label">
                      <input
                        type="checkbox"
                        className="checkbox-input ddsfe"
                        checked={socialHistory.alcoholUseDaily}
                        onChange={() => {
                          handleCheckboxChange2("alcoholUseDaily");
                        }}
                      />
                      Daily
                    </label>
                  </div>
                </div>

                <div className="div_ckkck_box alcho_tac_drg11">
                  <div className="ffdff44">
                    <div>
                      <label className="checkbox-label alcho_tac_drg">
                        {" "}
                        Tobacco use -{" "}
                      </label>
                    </div>
                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.tobaccoUseNever}
                          onChange={() => {
                            handleCheckboxChange2("tobaccoUseNever");
                          }}
                        />
                        Never
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.tobaccoUseOccasionally}
                          onChange={() => {
                            handleCheckboxChange2("tobaccoUseOccasionally");
                          }}
                        />
                        Occasionally
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.tobaccoUseDaily}
                          onChange={() => {
                            handleCheckboxChange2("tobaccoUseDaily");
                          }}
                        />
                        Daily
                      </label>
                    </div>
                  </div>
                </div>

                <div className="div_ckkck_box alcho_tac_drg11">
                  <div className="ffdff44">
                    <div>
                      <label className="checkbox-label alcho_tac_drg">
                        {" "}
                        Drugs use -{" "}
                      </label>
                    </div>
                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.drugsUseNever}
                          onChange={() => {
                            handleCheckboxChange2("drugsUseNever");
                          }}
                        />
                        Never
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.drugsUseOccasionally}
                          onChange={() => {
                            handleCheckboxChange2("drugsUseOccasionally");
                          }}
                        />
                        Occasionally
                      </label>
                    </div>

                    <div className="flx_cjk_labl3">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input ddsfe"
                          checked={socialHistory.drugsUseDaily}
                          onChange={() => {
                            handleCheckboxChange2("drugsUseDaily");
                          }}
                        />
                        Daily
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br></br>
            <div className="form-section5">
              <div className=" dkwjd">
                <h3>Family History :</h3>
                <h5>
                  {" "}
                  Has anyone in your family had any of the following conditions?
                  (Check if yes, and indicate relationship to you)
                </h5>
              </div>
              <br></br>
              <div className="div_ckkkbox_head">
                <div className="div_ckkck_box">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.CancerPolyps}
                      onChange={() => handleCheckboxChange3("CancerPolyps")}
                    />
                    Cancer/Polyps
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.Anemia1}
                      onChange={() => handleCheckboxChange3("Anemia1")}
                    />
                    Anemia
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.Diabetes1}
                      onChange={() => handleCheckboxChange3("Diabetes1")}
                    />
                    Diabetes
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.BloodClots1}
                      onChange={() => handleCheckboxChange3("BloodClots1")}
                    />
                    Blood Clots
                  </label>
                </div>

                <div className="div_ckkck_box">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.HeartDisease1}
                      onChange={() => handleCheckboxChange3("HeartDisease1")}
                    />
                    Heart Disease
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.Stroke1}
                      onChange={() => handleCheckboxChange3("Stroke1")}
                    />
                    Stroke
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.HighBloodPressure1}
                      onChange={() =>
                        handleCheckboxChange3("HighBloodPressure1")
                      }
                    />
                    High Blood Pressure
                  </label>
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.AnesthesiaReaction1}
                      onChange={() =>
                        handleCheckboxChange3("AnesthesiaReaction1")
                      }
                    />
                    Anesthesia Reaction
                  </label>
                </div>

                <div className="div_ckkck_box">
                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.BleedingProblems1}
                      onChange={() =>
                        handleCheckboxChange3("BleedingProblems1")
                      }
                    />
                    Bleeding Problems
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.Hepatitis1}
                      onChange={() => handleCheckboxChange3("Hepatitis1")}
                    />
                    Hepatitis
                  </label>

                  <label className="checkbox-label">
                    <input
                      type="checkbox"
                      className="checkbox-input"
                      checked={familyHistory.Other1}
                      onChange={() => handleCheckboxChange3("Other1")}
                    />
                    Others
                  </label>
                </div>
              </div>
              <br></br>

              <div className="form-section56">
                <label className="form-field56">
                  {" "}
                  Name :
                  <input
                    type="text"
                    name="FamilyName"
                    value={familyHistoryInfo.FamilyName}
                    onChange={ handleInputWomenChange}
                  />
                </label>
                <label className="form-field56">
                  {" "}
                  Age :
                  <input
                    type="number"
                    name="Age1"
                    value={familyHistoryInfo.Age1}
                    onChange= { handleInputWomenChange}
                  />
                </label>
                <label className="form-field56">
                  {" "}
                  Relationship :
                  <input
                    type="text"
                    name="RelationShip1"
                    value={familyHistoryInfo.RelationShip1}
                    onChange = {handleInputWomenChange}
                  />
                </label>
              </div>
              <br></br>
              <div className="form-section5">
            <div className="form-field5"> <label >Description <span>:</span>  </label>
                  <textarea
                     name="Description"
                     value={patientInfo.Description}
                     onChange={handleInputChange} >

                  </textarea>
                
                </div>
              </div>
            </div>
          </div>
          <div className="Register_btn_con">
            {/* <button
              className="RegisterForm_1_btns print-button3"
              onClick={handlePrint}
            >
              Print
            </button> */}
               <button className="RegisterForm_1_btns print-button3" onClick={handlePrint}>Print</button>
          </div>
        </div>
      </body>
    </>
  );
}

export default MedicalHistoryForm;
