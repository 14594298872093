import React, { useEffect, useState } from "react";
import { format } from "date-fns";
import axios from "axios";
import { useSelector } from "react-redux";

const DayClosing = () => {
  const [formData, setFormData] = useState({
    invoiceNo: "",
    date: "",
    issueFromId: "",
    issueFromName: "",
    issueToId: "",
    issueToName: "",
    openingCash: "",
    PharmacycollectedCash: "",
    ProcedurecollectedCash: "",
    ConsultancycollectedCash: "",
    totalCash: "",
    handoverCash: "",
    expenses: "",
    balanceCash: "",
    availableCash: "",
    reason: "",
    Branch_Name: "",
  });
  const [userList, setuserList] = useState([]);

  const reduxvalueUsercord = useSelector((state) => state);
  const userRecord = reduxvalueUsercord.userRecord?.UserData;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "issueToId" || name === "issueToName") {
      if (name === "issueToId") {
        const selectedPerson = userList.find(
          (item) => item.user_id === parseInt(value)
        );
        setFormData({
          ...formData,
          [name]: value,
          issueToName: selectedPerson ? selectedPerson.username : "",
        });
      } else {
        const selectedPerson = userList.find((item) => item.username === value);
        setFormData({
          ...formData,
          [name]: value,
          issueToId: selectedPerson ? selectedPerson.user_id : "",
        });
      }
    } else if (name === "handoverCash") {
      if (+value <= +formData.availableCash) {
        console.log(+formData.availableCash);
        const newval = parseFloat(formData.availableCash) - +value;
        console.log(newval);
        setFormData({
          ...formData,
          [name]: value,
          balanceCash: newval.toFixed(2),
        });
      } else {
        alert(
          `the collected amount is ${formData.availableCash} so please enter below or equal to that`
        );
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };
  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/usercontrol/getuserregisterID_NAME?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        setuserList(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
    setFormData((prev) => ({
      ...prev,
      issueFromId: userRecord?.user_id,
      issueFromName: userRecord?.username,
      date: formattedDate,
      Branch_Name: userRecord?.location,
    }));
    axios
      .get("http://127.0.0.1:8000/Pettycash_link/get_Day_closing_invoice")
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setFormData((prev) => ({
          ...prev,
          invoiceNo: data.nextInvoiceNumber,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        `http://127.0.0.1:8000/Pettycash_link/get_Day_Closing_Data?location=${userRecord?.location}&BillingDate=${formattedDate}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const total =
          data.Opening_Cash +
          data.Pharmacy_Net_Amount +
          data.Procedure_Net_Amount +
          data.Consultancy_Net_Amount;
        const available = +total - data.Expenses_Amount;
        setFormData((prev) => ({
          ...prev,
          openingCash: data.Opening_Cash.toFixed(2),
          expenses: data.Expenses_Amount.toFixed(2),
          PharmacycollectedCash: data.Pharmacy_Net_Amount.toFixed(2),
          ProcedurecollectedCash: data.Procedure_Net_Amount.toFixed(2),
          ConsultancycollectedCash: data.Consultancy_Net_Amount.toFixed(2),
          totalCash: total.toFixed(2),
          availableCash: available.toFixed(2),
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  }, [userRecord?.user_id, userRecord?.username]);

  const getdata = () => {
    const currentDate = new Date();
    const formattedDate = format(currentDate, "yyyy-MM-dd");
    setFormData((prev) => ({
      ...prev,
      issueFromId: userRecord?.user_id,
      issueFromName: userRecord?.username,
      date: formattedDate,
    }));
    axios
      .get(
        `http://127.0.0.1:8000/Pettycash_link/get_Day_closing_invoice?location=${userRecord?.location}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        setFormData((prev) => ({
          ...prev,
          invoiceNo: data.nextInvoiceNumber,
        }));
      })
      .catch((error) => {
        console.error(error);
      });
    axios
      .get(
        `http://127.0.0.1:8000/Pettycash_link/get_Day_Closing_Data?location=${userRecord?.location}&BillingDate=${formattedDate}`
      )
      .then((response) => {
        console.log(response.data);
        const data = response.data;
        const total =
          data.Opening_Cash +
          data.Pharmacy_Net_Amount +
          data.Procedure_Net_Amount +
          data.Consultancy_Net_Amount;
        const available = +total - data.Expenses_Amount;
        setFormData((prev) => ({
          ...prev,
          openingCash: data.Opening_Cash.toFixed(2),
          expenses: data.Expenses_Amount.toFixed(2),
          PharmacycollectedCash: data.Pharmacy_Net_Amount.toFixed(2),
          ProcedurecollectedCash: data.Procedure_Net_Amount.toFixed(2),
          ConsultancycollectedCash: data.Consultancy_Net_Amount.toFixed(2),
          totalCash: total.toFixed(2),
          availableCash: available.toFixed(2),
        }));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {}, []);
  const handleSave = () => {
    console.log(formData);
    const requiredFields = [
      "issueToId",
      "issueToName",
      "handoverCash",
      "reason",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);
    if (emptyFields.length > 0) {
      alert(`The following fields are required: ${emptyFields.join(", ")}`);
      return;
    } else {
      axios
        .post(
          "http://127.0.0.1:8000/Pettycash_link/Post_Day_Closing_Data",
          formData
        )
        .then((response) => {
          console.log(response);
          const data = response.data.message;
          if (data) {
            alert(data);
          }
          setFormData({
            invoiceNo: "",
            date: "",
            issueFromId: "",
            issueFromName: "",
            issueToId: "",
            issueToName: "",
            openingCash: "",
            PharmacycollectedCash: "",
            ConsultancycollectedCash: "",
            ProcedurecollectedCash: "",
            totalCash: "",
            handoverCash: "",
            expenses: "",
            balanceCash: "",
            availableCash: "",
            reason: "",
            Branch_Name: userRecord?.location,
          });
          getdata();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  return (
    <>
      <div className="patient-registration-form">
        <div className="h_head">
          <h4>Day Closing</h4>
        </div>
        <br />

        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="">
              Invoice No <span>:</span>
            </label>
            <input
              type="number"
              readOnly
              name="invoiceNo"
              value={formData.invoiceNo}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Date <span>:</span>
            </label>
            <input
              type="date"
              readOnly
              name="date"
              value={formData.date}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Issue From <span>:</span>
            </label>
            <div className="Issue_input">
              <input
                type="number"
                name="issueFromId"
                className="Issue_input_11123"
                readOnly
                value={formData.issueFromId}
                onChange={handleChange}
              />
              <input
                type="text"
                name="issueFromName"
                readOnly
                className="Issue_input_1112"
                value={formData.issueFromName}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Issue To <span>:</span>
            </label>
            <div className="Issue_input">
              <input
                type="number"
                name="issueToId"
                list="issueToId_1"
                className="Issue_input_11123"
                value={formData.issueToId}
                onChange={handleChange}
              />
              <datalist id="issueToId_1">
                {userList.map((item, index) => (
                  <option key={index} value={item.user_id}></option>
                ))}
              </datalist>
              <input
                type="text"
                name="issueToName"
                list="issueToId_2"
                className="Issue_input_1112"
                value={formData.issueToName}
                onChange={handleChange}
              />
              <datalist id="issueToId_2">
                {userList.map((item, index) => (
                  <option key={index} value={item.username}></option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Opening cash <span>:</span>
            </label>
            <input
              type="number"
              name="openingCash"
              value={formData.openingCash}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Pharmacy Collected Cash <span>:</span>
            </label>
            <input
              type="number"
              name="collectedCash"
              readOnly
              value={formData.PharmacycollectedCash}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Consulation Collected cash <span>:</span>
            </label>
            <input
              type="number"
              name="openingCash"
              readOnly
              value={formData.ConsultancycollectedCash}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Procedure Collected Cash <span>:</span>
            </label>
            <input
              type="number"
              name="collectedCash"
              readOnly
              value={formData.ProcedurecollectedCash}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Total cash <span>:</span>
            </label>
            <input
              type="number"
              name="totalCash"
              value={formData.totalCash}
              readOnly
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Expenses <span>:</span>
            </label>
            <input
              type="number"
              name="expenses"
              readOnly
              value={formData.expenses}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              {" "}
              Available Cash <span>:</span>
            </label>
            <input
              type="number"
              name="availableCash"
              readOnly
              value={formData.availableCash}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              HandOver Cash <span>:</span>
            </label>
            <input
              type="number"
              name="handoverCash"
              value={formData.handoverCash}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Balance <span>:</span>
            </label>
            <input
              type="number"
              name="balanceCash"
              readOnly
              value={formData.balanceCash}
              onChange={handleChange}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="">
              Reason <span>:</span>
            </label>
            <textarea
              name="reason"
              value={formData.reason}
              onChange={handleChange}
           
            />
          </div>
        </div>
        <br />
        <div className="Register_btn_con">
          <button className="RegisterForm_1_btns" onClick={handleSave}>
            save
          </button>
        </div>
      </div>
    </>
  );
};

export default DayClosing;
