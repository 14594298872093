import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import '../PurchaseRaiseMaster/PurchaseRaiseMaster.css'
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";



function IndentRaiseMaster() {


    const userRecord = useSelector(state => state.userRecord?.UserData)

    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [formDataPurchaseInvoice, setFormDataPurchaseInvoice] = useState({
        ProductCategory: 'MedicalConsumable',
        IndentRaiseInvoice: '',
        RequestTo: 'CentralStore',
        RequestFrom: userRecord?.location,
        RequestDate: format(new Date(), 'yyyy-MM-dd'),
        Reason: '',
        Location: userRecord?.location,
        CreatedBy: userRecord?.username,
    })
    const [formDataPurchaseInvoicePrint, setFormDataPurchaseInvoicePrint] = useState(null);
    const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);
    // const [PrintPdf, setPrintPdf] = useState(null);
    const [formDataPurchase, setFormDataPurchase] = useState({
        ItemCode: '',
        ItemName: '',
        GenericName: '',
        Strength: '',
        UOM: '',
        PackIn: '',
        PackQuantity: '',
        OrderQuantity: '',
        ApprovedQuantity: '',
        Status: 'Waiting',
    })

    const [ClinicDetials, setClinicDetials] = useState({
        ClinicLogo: null,
        ClinicName: '',
        ClinicGST: '',
        ClinicAddress: '',
        ClinicCity: '',
        ClinicState: '',
        ClinicCode: '',
        ClinicMobileNo: '',
        ClinicLandLineNo: '',
        ClinicMailID: '',
    });
    const [IndexEdit, setIndexEdit] = useState(null);
    const currentDate = format(new Date(), 'dd-MM-yyyy');

    const [Supplierdetailes, setSupplierdetailes] = useState([])

    const [ItemCodedetailes, setItemCodedetailes] = useState([])



    console.log('asd', formDataPurchaseInvoice)



    useEffect(() => {
        if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' ||
        formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable'  || 
        formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || 
        formDataPurchaseInvoice.ProductCategory === 'NonMedicalNONConsumable'  || 
        formDataPurchaseInvoice.ProductCategory === 'Surgical' ) {
            axios
                .get(`http://127.0.0.1:8000/IndentRaise/get_medical_purchase_Raise_indent_invoice_no?productcategorytype=${formDataPurchaseInvoice.ProductCategory}`)
                .then((response) => {
                    console.log('INV:', response.data);
                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        IndentRaiseInvoice: response.data.nextMedicalIndentRaiseInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get(`http://127.0.0.1:8000/SupplierMaster/get_Medical_ProductMaster_data?Productype=${formDataPurchaseInvoice.ProductCategory}`)
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        // else if (formDataPurchaseInvoice.ProductCategory === 'Non_Medical') {
        //     axios
        //         .get('http://127.0.0.1:8000/IndentRaise/get_non_medical_purchase_Raise_indent_invoice_no')
        //         .then((response) => {
        //             console.log('INV:', response.data.nextNonMedicalIndentRaiseInvoiceNo);

        //             setFormDataPurchaseInvoice((prevData) => ({
        //                 ...prevData,
        //                 IndentRaiseInvoice: response.data.nextNonMedicalIndentRaiseInvoiceNo,
        //             }));
        //         })
        //         .catch((error) => {
        //             // Handle errors here
        //             console.error("Error fetching data:", error);
        //         });

        //     axios
        //         .get('http://127.0.0.1:8000/IndentRaise/get_NONMedical_ProductMaster_data')
        //         .then((response) => {
        //             let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
        //             setItemCodedetailes(A_Status)
        //         })
        //         .catch((error) => {
        //             console.error("Error fetching data:", error);
        //         });
        // }
        else if (formDataPurchaseInvoice.ProductCategory === 'Assets') {
            axios
                .get('http://127.0.0.1:8000/IndentRaise/get_assets_purchase_Raise_indent_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextAssestIndentRaiseInvoiceNo);
                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        IndentRaiseInvoice: response.data.nextAssestIndentRaiseInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_Assets_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'Stationary') {
            axios
                .get('http://127.0.0.1:8000/IndentRaise/get_stationary_purchase_Raise_indent_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextStationaryIndentRaiseInvoiceNo);

                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        IndentRaiseInvoice: response.data.nextStationaryIndentRaiseInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_Stationary_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'Non_Stationary') {
            axios
                .get('http://127.0.0.1:8000/IndentRaise/get_non_stationary_purchase_Raise_indent_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextStationaryIndentRaiseInvoiceNo);

                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        IndentRaiseInvoice: response.data.nextStationaryIndentRaiseInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_NONStationary_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [formDataPurchaseInvoice.ProductCategory])





    useEffect(() => {
        // clinic detials
        axios.get('http://127.0.0.1:8000/usercontrol/getClinic')
            .then((response) => {
                // console.log(response.data)
                const data = response.data[0]
                console.log(data)
                if (data) {
                    setClinicDetials((prev) => ({
                        ...prev,
                        ClinicAddress: data.door_no + ',' + data.area + "," + data.street,
                        ClinicGST: data.Gst_no,
                        ClinicCity: data.city,
                        ClinicState: data.state,
                        ClinicCode: data.pincode,
                        ClinicMobileNo: data.phone_no,
                        ClinicLandLineNo: data.landline_no,
                        ClinicMailID: data.email,
                    }))
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get('http://127.0.0.1:8000/usercontrol/getAccountsetting')
            .then((response) => {
                // console.log(response.data.Data)
                const data = response.data;
                setClinicDetials((prev) => ({
                    ...prev,
                    ClinicName: data.Clinic_Name,
                    ClinicLogo: (`data:image/png;base64,${data.Clinic_Logo}`)
                }))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'ItemCode') {

            const Set_datas = ItemCodedetailes.find((ele) => { return ele.ItemCode === value })
            if (Set_datas) {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemName: Set_datas.ItemName,
                    GenericName: Set_datas.GenericName,
                    Strength: Set_datas.Strength,
                    UOM: Set_datas.UOM,
                    PackIn: Set_datas.ProductType,
                    PackQuantity: Set_datas.ProductQuantity,
                    OrderQuantity: Set_datas.OrderQuantity,
                    ApprovedQuantity: Set_datas.ApprovedQuantity,
                }));
            }
            else {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemName: '',
                    GenericName: '',
                    Strength: '',
                    UOM: '',
                    PackIn: '',
                    PackQuantity: '',
                    OrderQuantity: '',
                    ApprovedQuantity: '',
                }));
            }
        }
        else if (name === 'ItemName') {

            const Set_datas = ItemCodedetailes.find((ele) => { return ele.ItemName === value })

            if (Set_datas) {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemCode: Set_datas.ItemCode,
                    GenericName: Set_datas.GenericName,
                    Strength: Set_datas.Strength,
                    UOM: Set_datas.UOM,
                    PackIn: Set_datas.ProductType,
                    PackQuantity: Set_datas.ProductQuantity,
                    OrderQuantity: Set_datas.OrderQuantity,
                    ApprovedQuantity: Set_datas.ApprovedQuantity,
                }));
            } else {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemCode: '',
                    GenericName: '',
                    Strength: '',
                    UOM: '',
                    PackIn: '',
                    PackQuantity: '',
                    OrderQuantity: '',
                    ApprovedQuantity: '',
                }));
            }
        }
        else {
            if (name === 'OrderQuantity') {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ApprovedQuantity: value

                }));

            } else {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,

                }));

            }
        }

    };

    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        if (name === 'ProductCategory') {
            setFormDataPurchaseInvoice((prevData) => ({
                ...prevData,
                [name]: value,
                Reason: ''

            }));

            handleCancel();
            setCartItems([]);
        } else {
            setFormDataPurchaseInvoice((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleAddToCart = () => {
        const existingItem = cartItems.find((item) => item.ItemCode === formDataPurchase.ItemCode);

        if (existingItem) {
            alert(`Item with code ${formDataPurchase.ItemCode} already exists in the cart.`);
        } else {
            let updatedata;
            let requiredFields;
            if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
            formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' 
            // formDataPurchaseInvoice.ProductCategory === 'Surgical'
            ) {
                updatedata = {
                    ItemCode: formDataPurchase.ItemCode,
                    ItemName: formDataPurchase.ItemName,
                    GenericName: formDataPurchase.GenericName,
                    Strength: formDataPurchase.Strength,
                    UOM: formDataPurchase.UOM,
                    PackIn: formDataPurchase.PackIn,
                    PackQuantity: formDataPurchase.PackQuantity,
                    OrderQuantity: formDataPurchase.OrderQuantity,
                    ApprovedQuantity: formDataPurchase.ApprovedQuantity,
                    Status: formDataPurchase.Status,
                }
                requiredFields = [
                    'ItemCode',
                    'ItemName',
                    'GenericName',
                    'Strength',
                    'UOM',
                    'PackIn',
                    'PackQuantity',
                    'OrderQuantity',
                ]
            } else {
                updatedata = {
                    ItemCode: formDataPurchase.ItemCode,
                    ItemName: formDataPurchase.ItemName,
                    GenericName: formDataPurchase.GenericName,
                    Strength: formDataPurchase.Strength,
                    UOM: formDataPurchase.UOM,
                    PackIn: formDataPurchase.PackIn,
                    PackQuantity: formDataPurchase.PackQuantity,
                    OrderQuantity: formDataPurchase.OrderQuantity,
                    ApprovedQuantity: formDataPurchase.ApprovedQuantity,
                    Status: formDataPurchase.Status,
                }
                requiredFields = [
                    'ItemCode',
                    'ItemName',
                    'PackIn',
                    'PackQuantity',
                    'OrderQuantity',
                ]
            }
            const missingFields = requiredFields.filter((field) => !formDataPurchase[field])
            if (missingFields.length === 0) {
                console.log(updatedata)
                setCartItems((prev) => [...prev, updatedata]);
                handleCancel();
            } else {
                alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
            }
        }


    };

    const handleDeleteCartItem = (itemCode) => {
        setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
    };
    const handleEditCartItem = (itemCode) => {
        const selectedItem = cartItems.find((item) => item.ItemCode === itemCode);
        setIndexEdit(selectedItem)
        console.log(selectedItem.ItemCode)
        if (selectedItem) {
            // Set the form data with the selected item's data
            setFormDataPurchase({
                ItemCode: selectedItem.ItemCode,
                ItemName: selectedItem.ItemName,
                GenericName: selectedItem.GenericName,
                Strength: selectedItem.Strength,
                UOM: selectedItem.UOM,
                PackIn: selectedItem.PackIn,
                PackQuantity: selectedItem.PackQuantity,
                OrderQuantity: selectedItem.OrderQuantity,
                ApprovedQuantity: selectedItem.ApprovedQuantity,
                Status: selectedItem.Status,
            });

            // Remove the selected item from the cart
            //   setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
        }
    };

    const handleUpdateCartItem = () => {
        const updatedItem = {
            ItemCode: formDataPurchase.ItemCode,
            ItemName: formDataPurchase.ItemName,
            GenericName: formDataPurchase.GenericName,
            Strength: formDataPurchase.Strength,
            UOM: formDataPurchase.UOM,
            PackIn: formDataPurchase.PackIn,
            PackQuantity: formDataPurchase.PackQuantity,
            OrderQuantity: formDataPurchase.OrderQuantity,
            ApprovedQuantity: formDataPurchase.ApprovedQuantity,
            Status: formDataPurchase.Status,

        };

        // Check if the updated item has all required fields
        const requiredFields = ['ItemCode', 'ItemName', 'PackIn', 'PackQuantity'];
        const missingFields = requiredFields.filter((field) => !updatedItem[field]);

        if (missingFields.length === 0) {
            // Find the index of the item in the cart with the matching item code
            const indexOfItemToUpdate = cartItems.findIndex((item) => item.ItemCode === formDataPurchase.ItemCode);

            // Update the cart with the modified item
            setCartItems((prev) => {
                const updatedCart = [...prev];
                updatedCart[indexOfItemToUpdate] = updatedItem;
                return updatedCart;
            });

            handleCancel();
            setIndexEdit(null)
        } else {
            alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
        }
    };



    const handleSubmit = () => {

        setFormDataPurchaseInvoicePrint({ ...formDataPurchaseInvoice })
        // setIsPrintButtonVisible(false);
        // setTimeout(async () => {
        //     window.print();
        //     setIsPrintButtonVisible(true);            
        // }, 1000);
        // if (formDataPurchaseInvoice.ProductCategory === 'Medical' || formDataPurchaseInvoice.ProductCategory === 'Non_Medical') {
        const updatedItem = {
            // ProductCategory: formDataPurchaseInvoice.ProductCategory,
            IndentRaiseInvoice: formDataPurchaseInvoice.IndentRaiseInvoice,
            RequestTo: formDataPurchaseInvoice.RequestTo,
            RequestFrom: formDataPurchaseInvoice.RequestFrom,
            Reason: formDataPurchaseInvoice.Reason,
            RequestDate: formDataPurchaseInvoice.RequestDate,
            CreatedBy: formDataPurchaseInvoice.CreatedBy,
            Location: formDataPurchaseInvoice.Location,
            ProductCategory: formDataPurchaseInvoice.ProductCategory,
            cartItems: cartItems /// array
        }

        // };
        console.log(cartItems)
        console.log(updatedItem)

        const requiredFields = ['ProductCategory', 'IndentRaiseInvoice', 'RequestTo', 'RequestFrom', 'Reason'];
        const missingFields = requiredFields.filter((field) => !formDataPurchaseInvoice[field]);

        if (missingFields.length === 0) {
            if (cartItems.length !== 0) {
                if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
                formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' || 
                formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || 
                formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' || 
                formDataPurchaseInvoice.ProductCategory === 'Surgical'
                ) {
                    axios.post('http://127.0.0.1:8000/IndentRaise/insert_medical_purchase_Raise_indent_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Indent_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                // } else if (formDataPurchaseInvoice.ProductCategory === 'Non_Medical') {
                //     axios.post('http://127.0.0.1:8000/IndentRaise/insert_non_medical_purchase_Raise_indent_data', updatedItem)
                //         .then((response) => {
                //             console.log(response.data);
                //             clearFormInputs();
                //             navigate('/Home/Indent_Raise_list');
                //         })
                //         .catch((error) => {
                //             console.error('Error post Supplier data:', error);
                //         });
                } else if (formDataPurchaseInvoice.ProductCategory === 'Assets') {
                    axios.post('http://127.0.0.1:8000/IndentRaise/insert_assets_purchase_Raise_indent_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Indent_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                } else if (formDataPurchaseInvoice.ProductCategory === 'Stationary') {
                    axios.post('http://127.0.0.1:8000/IndentRaise/insert_stationary_purchase_Raise_indent_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Indent_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                } else if (formDataPurchaseInvoice.ProductCategory === 'Non_Stationary') {
                    axios.post('http://127.0.0.1:8000/IndentRaise/insert_non_stationary_purchase_Raise_indent_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Indent_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                }
            } else {
                alert('No Items to Purchase');
            }
        } else {
            alert(`Missing fields: ${missingFields.join(', ')}`);
        }


        console.table(cartItems)
    };

    const handleCancel = () => {
        setFormDataPurchase({
            ItemCode: '',
            ItemName: '',
            GenericName: '',
            Strength: '',
            UOM: '',
            PackIn: '',
            PackQuantity: '',
            OrderQuantity: '',
            ApprovedQuantity: '',
            Status: 'Waiting',
        })
    };

    const clearFormInputs = () => {

        setFormDataPurchaseInvoice((prev) => (
            {
                ...prev,
                ProductCategory: 'MedicalConsumable',
                IndentRaiseInvoice: '',
                RequestTo: '',
                RequestFrom: '',
                Reason: '',
                CreatedBy: 'admin',
            }
        ))

        setFormDataPurchase({
            ItemCode: '',
            ItemName: '',
            GenericName: '',
            Strength: '',
            UOM: '',
            PackIn: '',
            PackQuantity: '',
            OrderQuantity: '',
            ApprovedQuantity: '',
            Status: 'Waiting',
        })


    };

    const forPrintData = () => {
        return (
            <div className="billing-invoice" id="For_print_pdf">
                <div className="New_billlling_invoice_head">
                    <div className="new_billing_logo_con">
                        <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                    </div>
                    <div className="new_billing_address_1 ">
                        <span>{ClinicDetials.ClinicName}</span>
                        <div>

                            <span >{ClinicDetials.ClinicAddress},</span>
                            <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                        </div>
                        <div>
                            <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicMailID}</span>
                        </div>

                    </div>

                </div>
                <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
                    Bill Cancelation
                </div>
                <div className="new_billing_address">
                    <div className="new_billing_address_2">


                        <div className="new_billing_div">
                            <label>Product Type <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.ProductCategory}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Raised From<span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.SupplierCode}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Raised To <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.SupplierName}</span>
                        </div>

                    </div>
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>Invoice No <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.IndentRaiseInvoice}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Date <span>:</span></label>
                            <span>{currentDate}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Reason<span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.Reason}</span>
                        </div>



                    </div>
                </div>

                {
                    (formDataPurchaseInvoicePrint.ProductCategory === "MedicalConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "MedicalNonConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "NonMedicalConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "NonMedicalNonConsumable" ||
                    formDataPurchaseInvoicePrint.ProductCategory === "Surgical") &&(
                        <div className="new_billing_invoice_detials">
                            <table>
                                <thead style={{ backgroundColor: 'hsl(33,100%,50%)' }}>
                                    <tr>
                                        <th>Item Code</th>
                                        <th>Item Name</th>
                                        <th>Generic Name</th>
                                        <th>Strength</th>
                                        <th>UOM</th>
                                        <th>Pack Type</th>
                                        <th>Pack Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {cartItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.ItemCode}</td>
                                            <td>{item.ItemName}</td>
                                            <td>{item.GenericName}</td>
                                            <td>{item.Strength}</td>
                                            <td>{item.UOM}</td>
                                            <td>{item.PackIn}</td>
                                            <td>{item.PackQuantity}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}

                {
                   !(formDataPurchaseInvoicePrint.ProductCategory === "MedicalConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "MedicalNonConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "NonMedicalConsumable" || 
                    formDataPurchaseInvoicePrint.ProductCategory === "NonMedicalNonConsumable" ||
                    formDataPurchaseInvoicePrint.ProductCategory === "Surgical") &&(
                        <div className="new_billing_invoice_detials">
                        <table>
                            <thead>
                                <tr>
                                    <th>Item Code</th>
                                    <th>Item Name</th>
                                    <th>Pack Type</th>
                                    <th>Pack Quantity</th>
                                </tr>
                            </thead>
                            <tbody>

                                {cartItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ItemCode}</td>
                                        <td>{item.ItemName}</td>
                                        <td>{item.PackIn}</td>
                                        <td>{item.PackQuantity}</td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
            

                    )}
                       


            </div>
        );
    }

    return (
        <>
            {isPrintButtonVisible ?
                <div className="Stoct_Product_master">
                    <div className="Stoct_Product_master_head">
                        <h3 className="Stoct_Product_master_head_h3">
                            <ShoppingCartIcon /> Indent Raise
                        </h3>
                    </div>

                    <div className='Stoct_Product_master_Container'>
                        <div className='Stoct_Product_master_form'>
                            <div className="Stoct_Product_master_form_row">
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Product Category :</label>
                                    <select
                                        name='ProductCategory'
                                        value={formDataPurchaseInvoice.ProductCategory}
                                        onChange={handleInputChange1}
                                    >
                                        <option value=''>Select</option>
                                        <option value="MedicalConsumable">Medical Consumable</option>
                                        <option value="MedicalNonConsumable">
                                            Medical Non-Consumable
                                        </option>
                                        <option value="NonMedicalConsumable">Non MedicalConsumable</option>
                                        <option value="NonMedicalNonConsumable">Non MedicalNon-Consumable</option>
                                        <option value="Surgical">Surgical</option>
                                        <option value='Assets'>Assets</option>
                                        <option value='Stationary'>Stationary</option>
                                        <option value='Non_Stationary'>Non Stationary</option>
                                    </select>
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Invoice No :</label>
                                    <input
                                        type="text"
                                        name="IndentRaiseInvoice"
                                        placeholder="Enter Invoice No"
                                        value={formDataPurchaseInvoice.IndentRaiseInvoice}
                                        onChange={handleInputChange1}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>


                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Request From :</label>
                                    <input
                                        type="text"
                                        name="RequestFrom"
                                        placeholder="Enter Supplier Code"
                                        value={formDataPurchaseInvoice.RequestFrom}
                                        onChange={handleInputChange1}
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                </div>



                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Request To :</label>
                                    <input
                                        type="text"
                                        name="RequestTo"
                                        placeholder="Enter Request to"
                                        value={formDataPurchaseInvoice.RequestTo}
                                        onChange={handleInputChange1}
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                </div>

                            </div>
                            <div className="Stoct_Product_master_form_row">

                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Request Date  :</label>
                                    <input
                                        type="date"
                                        name="RequestDate"
                                        placeholder="Enter Invoice No"
                                        value={formDataPurchaseInvoice.RequestDate}
                                        onChange={handleInputChange1}
                                        required
                                        readOnly
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Reason   :</label>
                                    <input
                                        type="text"
                                        name="Reason"
                                        placeholder="Enter Reason "
                                        value={formDataPurchaseInvoice.Reason}
                                        onChange={handleInputChange1}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>




                            </div>



                        </div>
                        <div className="Add_items_Purchase_Master">
                            <span>Add Items</span>
                        </div>
                        <div className='Stoct_Product_master_form'>
                            <div className="Stoct_Product_master_form_row">
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code</label>
                                    <input
                                        type="text"
                                        name="ItemCode"
                                        list="ItemCode_list"
                                        placeholder="Enter Item Code"
                                        value={formDataPurchase.ItemCode}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="ItemCode_list">
                                        {ItemCodedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemCode}></option>
                                        ))}
                                    </datalist>
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Name :</label>
                                    <input
                                        type="text"
                                        name="ItemName"
                                        list="ItemCode_name"
                                        placeholder="Enter Item Name"
                                        value={formDataPurchase.ItemName}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                    <datalist id="ItemCode_name">
                                        {ItemCodedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemName}></option>
                                        ))}
                                    </datalist>
                                </div>
                                {(formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' ||
                                formDataPurchaseInvoice.ProductCategory === 'Surgical'
                                ) &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Generic Name :</label>
                                        <input
                                            type="text"
                                            name="GenericName"
                                            placeholder="Enter Generic Name "
                                            value={formDataPurchase.GenericName}
                                            onChange={handleInputChange}
                                            required
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                }
                                {(formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory === 'Surgical'
                                )&&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Strength :</label>
                                        <input
                                            type="text"
                                            name="Strength"
                                            placeholder="Enter  Strength"
                                            value={formDataPurchase.Strength}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>
                                    
                                }
                                {(formDataPurchaseInvoice.ProductCategory !== 'MedicalConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'MedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalConsumable' && 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalNonConsumable' &&
                                // /formDataPurchaseInvoice.ProductCategory !== 'Surgical'
                                ) &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="strength">Pack Type:</label>
                                        <input
                                            type="text"
                                            name="PackIn"
                                            placeholder="Enter Pack Type"
                                            value={formDataPurchase.PackIn}
                                            onChange={handleInputChange}
                                            // readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>}
                                {/* {(formDataPurchaseInvoice.ProductCategory !== 'MedicalConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'MedicalNonConsumable' && 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalConsumable' && 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalNonConsumable' &&
                                formDataPurchaseInvoice.ProductCategory !== 'Surgical')&&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Pack Quantity :</label>
                                        <input
                                            type="number"
                                            name="PackQuantity"
                                            placeholder="Enter Pack Quantity"
                                            value={formDataPurchase.PackQuantity}
                                            // readOnly
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                } */}

                            </div>
                            {(formDataPurchaseInvoice.ProductCategory !== 'MedicalConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'MedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalConsumable' && 
                                // formDataPurchaseInvoice.ProductCategory !== 'NonMedicalNonConsumable' &&
                                // formDataPurchaseInvoice.ProductCategory !== 'Surgical'
                                )&&
                                <div className="Stoct_Product_master_form_row">
                                     <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Pack Quantity :</label>
                                        <input
                                            type="number"
                                            name="PackQuantity"
                                            placeholder="Enter Pack Quantity"
                                            value={formDataPurchase.PackQuantity}
                                            // readOnly
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Order Quantity :</label>
                                        <input
                                            type="number"
                                            name="OrderQuantity"
                                            placeholder="Enter Order Quantity"
                                            value={formDataPurchase.OrderQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                </div>
                            }
                            {(formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || 
                                // formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' ||
                                // formDataPurchaseInvoice.ProductCategory === 'Surgical'
                                )&&
                                <div className="Stoct_Product_master_form_row">


                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="generic">UOM :</label>
                                        <input
                                            type="text"
                                            name="UOM"
                                            placeholder="Enter UOM"
                                            value={formDataPurchase.UOM}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="strength">Pack Type:</label>
                                        <input
                                            type="text"
                                            name="PackIn"
                                            placeholder="Enter Pack Type"
                                            value={formDataPurchase.PackIn}
                                            onChange={handleInputChange}
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Pack Quantity :</label>
                                        <input
                                            type="number"
                                            name="PackQuantity"
                                            placeholder="Enter Pack Quantity"
                                            value={formDataPurchase.PackQuantity}
                                            onChange={handleInputChange}
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Order Quantity :</label>
                                        <input
                                            type="number"
                                            name="OrderQuantity"
                                            placeholder="Enter Order Quantity"
                                            value={formDataPurchase.OrderQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                </div>
                            }


                            <div className='Stoct_Product_master_form_Save'>

                                <button className="Stoct_Product_master_form_Save_button" onClick={handleCancel}>
                                    Clear
                                </button>
                                {!IndexEdit ?
                                    <button className="Stoct_Product_master_form_Save_button" onClick={handleAddToCart}>
                                        Add
                                    </button>
                                    :
                                    <button className="Stoct_Product_master_form_Save_button" onClick={handleUpdateCartItem}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </div>





                    {cartItems.length !== 0 &&
                        <div className="Stoct_Product_master_form_table_container">
                            <div className="Stoct_Product_master_form_table_container_11">

                                {(formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || 
                                formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory === 'Surgical'
                                ) && <div className="Stoct_Product_master_form_table_container_11">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                <th>Generic Name</th>
                                                <th>Strength</th>
                                                <th>UOM</th>
                                                <th>Pack</th>
                                                <th>Pack Quantity</th>
                                                <th>Order Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.ItemCode}</td>
                                                    <td>{item.ItemName}</td>
                                                    <td>{item.GenericName}</td>
                                                    <td>{item.Strength}</td>
                                                    <td>{item.UOM}</td>
                                                    <td>{item.PackIn}</td>
                                                    <td>{item.PackQuantity}</td>
                                                    <td>{item.OrderQuantity}</td>
                                                    <td>
                                                        <button onClick={() => handleEditCartItem(item.ItemCode)}>edit</button>
                                                        <button onClick={() => handleDeleteCartItem(item.ItemCode)}>delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                }
                                {(formDataPurchaseInvoice.ProductCategory !== 'MedicalConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'MedicalNonConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'NonMedicalConsumable' && 
                                formDataPurchaseInvoice.ProductCategory !== 'NonMedicalNonConsumable' 
                                // formDataPurchaseInvoice.ProductCategory !== 'Surgical'
                                ) && <div className="Stoct_Product_master_form_table_container_11">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                <th>Pack</th>
                                                <th>Pack Quantity</th>
                                                <th>Order Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.ItemCode}</td>
                                                    <td>{item.ItemName}</td>
                                                    <td>{item.PackIn}</td>
                                                    <td>{item.PackQuantity}</td>
                                                    <td>{item.OrderQuantity}</td>
                                                    <td>
                                                        <button onClick={() => handleEditCartItem(item.ItemCode)}>edit</button>
                                                        <button onClick={() => handleDeleteCartItem(item.ItemCode)}>delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>}

                            </div>
                        </div>
                    }
                    {cartItems.length !== 0 &&
                        <div className="button_buton_class">
                            <button className="btn-add" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    }

                </div>
                :
                forPrintData()
            }
        </>
    );
}

export default IndentRaiseMaster;
