import './Pettycash.css';
import AddIcon from '@mui/icons-material/Add';
import { useEffect, useState } from 'react';
import axios from 'axios';





const ExpensesMaster = () => {
  const [userRecord, setUserRecord] = useState([]);
  console.log(userRecord);

  useEffect(() => {
    const userRecord = localStorage.getItem("token");

    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split(".")[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      console.log("decodedTokenData", decodedTokenData);
      setUserRecord(decodedTokenData);
    }
  }, []); // console.log('sss',userRecord)

  const User_name = userRecord.username

  const [Add_Issue_To_Types, setAdd_Issue_To_Types] = useState('')

  const [Issue_type_table, setIssue_type_table] = useState([])


  const [Add_Expenses_Name, setAdd_Expenses_Name] = useState('')

  const [Expenses_Name_table, setExpenses_Name_table] = useState([])

  const [expensemethod, setexpensemethod] = useState('')

  console.log('11', Expenses_Name_table)


  const getdata = () => {
    axios.get(`http://127.0.0.1:8000/Pettycash_link/Get_Issue_to_types_data`)
      .then((response) => {
        setIssue_type_table(response.data.data)
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const getExpensesdata = () => {
    axios.get(`http://127.0.0.1:8000/Pettycash_link/Get_Expenses_Name_data`)
      .then((response) => {
        setExpenses_Name_table(response.data.data)
        console.log(response.data)
      })
      .catch((error) => {
        console.error(error);
      });
  }



  useEffect(() => {
    getdata()
    getExpensesdata()
  }, []);




  const Add_Issue_Types = () => {

    const Samedata = Issue_type_table.find(
      repeat => repeat.Issue_to_types.toLowerCase() === Add_Issue_To_Types.toLowerCase()
    );

    if (Add_Issue_To_Types === '') {
      alert('Enter Issue To Types')
    }
    else if (Samedata) {
      alert('Alredy Enter Data')
    }
    else {
      axios
        .post('http://127.0.0.1:8000/Pettycash_link/Post_Issue_to_types_data', {
          Add_Issue_To_Types,
          User_name
        })
        .then((res) => {
          console.log(res.data)
          getdata()
          setAdd_Issue_To_Types('')
          alert('Data Added Successfully');
        })
        .catch((err) => console.log(err));
    }
  }



  const handleEditClick = (data) => {



    if (data.Status === 'Inactive') {
      const status = 'Active'
      const S_NO = data.S_NO
      Update_status_fun(S_NO, status)
    }
    else {
      const status = 'Inactive'
      const S_NO = data.S_NO
      Update_status_fun(S_NO, status)
    }

  };


  const Update_status_fun = (S_NO, status) => {

    console.log(status)
    axios
      .post('http://127.0.0.1:8000/Pettycash_link/Update_Issue_Status', {
        status,
        User_name,
        S_NO
      })
      .then((res) => {
        console.log(res.data)
        getdata()
        setAdd_Issue_To_Types('')
        alert('Status Update Successfully');
      })
      .catch((err) => console.log(err));
  }


  const Add_Expenses = () => {

    const Samedata = Expenses_Name_table.find(
      repeat => repeat.Expenses_Name.toLowerCase() === Add_Expenses_Name.toLowerCase()
    );

    if (Add_Expenses_Name === '') {
      alert('Enter Issue To Types')
    } else if (Samedata) {
      alert('Alredy Enter Data')
    } else {
      axios
        .post('http://127.0.0.1:8000/Pettycash_link/Post_Expenses_Name_data', {
          Add_Expenses_Name,
          User_name,
          expensemethod
        })
        .then((res) => {
          console.log(res.data)
          getExpensesdata()
          setAdd_Expenses_Name('')
          alert('Data Added Successfully');
        })
        .catch((err) => console.log(err));
    }
  }


  const handleEditExpensesClick = (data) => {

    if (data.Status === 'Inactive') {
      const status = 'Active'
      const S_NO = data.S_NO
      UpdateExpenses_status_fun(S_NO, status)
    }
    else {
      const status = 'Inactive'
      const S_NO = data.S_NO
      UpdateExpenses_status_fun(S_NO, status)
    }
  }


  const UpdateExpenses_status_fun = (S_NO, status) => {

    console.log(status)
    axios
      .post('http://127.0.0.1:8000/Pettycash_link/Update_Expenses_Status', {
        status,
        User_name,
        S_NO
      })
      .then((res) => {
        console.log(res.data)
        getExpensesdata()
        setAdd_Expenses_Name('')
        alert('Status Update Successfully');
      })
      .catch((err) => console.log(err));
  }






  return (
    <div className='ShiftClosing_over'>
      <div className="ShiftClosing_Container">
        <div className="ShiftClosing_header">
          <h4>Expenses Master</h4>
        </div>

       
          <h3 style={{ textAlign: 'center' }}>Add Expenses Types</h3>


              <div className="con_1 ">
              <div className="inp_1">
                  <label htmlFor="input" >Expences Method <span>:</span></label>
                  <select
                  name="department"
                  value={expensemethod}
                  onChange={(e) => setexpensemethod(e.target.value)}
                  className='edcdswx'
                  required
                >
                  <option value="">Select</option>
                  <option value='DIRECT METHOD'>Direct Method</option>
                  <option value='INDIRECT METHOD'>Indirect Method</option>
                  
                </select>

                </div>
                <div className="inp_1">
            <label htmlFor="input" >Expenses Name <span>:</span></label>
            <input
              type="text"
              id="FirstName"
              value={Add_Expenses_Name}
              onChange={(e) => { setAdd_Expenses_Name(e.target.value) }}
              placeholder="Enter Expenses Name"
             
            />
          </div>
                <button className="btn_1" onClick={Add_Expenses}>
                  <AddIcon />
                </button>
              </div>


              <div style={{ width: '100%', display: 'grid', placeItems: 'center' }}>
                <h4>Table</h4>

                <div className="Selected-table-container ">
                  <table className="selected-medicine-table2 ">
                    <thead>
                      <tr>
                        <th >S.No</th>
                        <th>Expenses Method</th>
                        <th >Expences Type</th>
                        <th >Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Expenses_Name_table.map((client, index) => (
                        <tr key={index}>
                          <td>{client.S_NO}</td>
                          <td>{client.Expence_Methods}</td>
                          <td>{client.Expenses_Name}</td>
                          <td>

                            <button onClick={() => handleEditExpensesClick(client)}
                              className='Addnamebtn_pt2'>{client.Status}</button>
                          </td>

                        </tr>
                      ))}
                    </tbody>
                  </table>

                </div>

              </div>

            

      </div>
    </div>
  )

}

export default ExpensesMaster;
