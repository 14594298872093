import React, { useState, useEffect } from "react";
import axios from "axios";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useDispatch, useSelector } from "react-redux";

function Procedure2Pro() {
  const dispatchvalue = useDispatch();

  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  console.log(formData);
  const create = userRecord?.username;
  const [proceduredata, setProcedureData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editedIndex, setEditedIndex] = useState(null);
  const [appointmentdate, setAppointmentdate] = useState("");

  const initialFormValues = {
    ProcedureName: "",
    Sessions: "",
    Amount: "",
    TotalAmount: "",
    nextAppointment: "",
  };

  const [formValues, setFormValues] = useState(initialFormValues);
  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentdate(currentDate);
  }, [appointmentdate, formData]);

  const handleAdd = () => {
    if (validateForm()) {
      const newProcedure = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + " " + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: "Procedure",
        DoctorName: formData.DoctorName,
        appointmentDate: appointmentdate,
        PaidSessions: 0,
        RemainingSessions: +formValues.Sessions,
        theraphist_session_completed: 0,
        Discount: 0,
        Therapist_Name: "None",
        ProcedureName: formValues.ProcedureName,
        Sessions: formValues.Sessions,
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount,
        nextAppointment: formValues.nextAppointment,
        Status: "Pending",
        createdBy: create,
      };

      if (isEditing) {
        const updatedData = [...proceduredata];
        updatedData[editedIndex] = newProcedure;
        setProcedureData(updatedData);
      } else {
        setProcedureData((prevData) => [...prevData, newProcedure]);
      }

      setFormValues(initialFormValues);
      setIsEditing(false);
      setEditedIndex(null);
    }
  };

  const validateForm = () => {
    return (
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.nextAppointment
    );
  };

  const handleEdit = (index) => {
    setFormValues(proceduredata[index]);
    setIsEditing(true);
    setEditedIndex(index);
  };

  const handleSave = () => {
    console.log("Procedure data:", proceduredata);

    // Assuming you want to send proceduredata to the server
    axios
      .post(
        "http://127.0.0.1:8000/doctorsworkbench/insert_procedure",
        proceduredata
      )
      .then((response) => {
        console.log(response);
        alert(response.data.message);
      })
      .catch((error) => {
        console.error("Error saving data:", error);
      });

    // Additional code related to saving other data
    // const Discount = {
    //     newDiscount: ConsultancyDiscount,
    // };
    axios
      .post(
        "http://127.0.0.1:8000/GeneralBilling/insertGeneral_Billing_Data",
        proceduredata
      )
      .then((response) => {
        console.log(response);
        axios
          .post(
            `http://127.0.0.1:8000/GeneralBilling/update_Consultancy_Discount?PatientID=${+formData.PatientID}&VisitID=${+formData.visitNo}&DoctorName=${
              formData.DoctorName
            }`,
            // Discount,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error("Error updating consultancy discount:", error);
          });
      })
      .catch((error) => {
        console.error("Error insert Billing:", error);
      });
  };

  return (
    <div
      className={isSidebarOpen ? "modal_edit_1_pro2 " : "modal_edit_pro2 "}
      onClick={() =>
        dispatchvalue({ type: "isModalShowVisible", value: false })
      }
    >
      <div
        className={
          isSidebarOpen
            ? "modal-content-edit-1_pro2"
            : "modal-content-edit_pro2 "
        }
        onClick={(e) => e.stopPropagation()}
      >
        <div className="new-patient-registration-form">
          <div className="h_head">
            <h4>New Procedure</h4>
          </div>
          <div className="RegisFormcon">
            <div className="RegisForm_1 RegisForm_1234">
              <label htmlFor="ProcedureName">
                Treatment Procedure<span>:</span>
              </label>
              <select
                id="ProcedureName"
                name="ProcedureName"
                className="new-custom-select-doctor-name"
                value={formValues.ProcedureName}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    ProcedureName: e.target.value,
                  }))
                }
              >
                <option value="">Select</option>
                <option value="botox_injections">Botox Injections</option>
                <option value="dermal_fillers">Dermal Fillers</option>
                <option value="liposuction">Liposuction</option>
                <option value="facelift">Facelift</option>
                <option value="rhinoplasty">Rhinoplasty</option>
              </select>
            </div>
            <div className="RegisForm_1 RegisForm_1234">
              <label htmlFor="Sessions">
                Number of Sessions<span>:</span>
              </label>
              <input
                type="number"
                id="Sessions"
                name="Sessions"
                
                value={formValues.Sessions}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    Sessions: e.target.value,
                  }))
                }
              />
            </div>
            <div className="RegisForm_1 RegisForm_1234">
              <label htmlFor="Amount">
                Procedure Amount<span>:</span>
              </label>
              <input
                type="number"
                id="Amount"
                name="Amount"
                value={formValues.Amount}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    Amount: e.target.value,
                  }))
                }
              />
            </div>
            <div className="RegisForm_1 RegisForm_1234">
              <label htmlFor="Amount">
                Total Procedure Amount<span>:</span>
              </label>
              <input
                type="number"
                id="Amount"
                name="TotalAmount"
                value={formValues.TotalAmount}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    TotalAmount: e.target.value,
                  }))
                }
              />
            </div>
            <div className="RegisForm_1 RegisForm_1234">
              <label htmlFor="nextAppointment">
                Next Appointment<span>:</span>
              </label>
              <input
                type="date"
                id="nextAppointment"
                name="nextAppointment"
                value={formValues.nextAppointment}
                onChange={(e) =>
                  setFormValues((prevValues) => ({
                    ...prevValues,
                    nextAppointment: e.target.value,
                  }))
                }
                required
              />
            </div>
          </div>

          <div className="Register_btn_con">
            <button className="RegisterForm_1_btns" onClick={handleAdd}>
              {isEditing ? "Update" : "Add"}
            </button>
          </div>
          <div className="Selected-table-container ">
            <table className="selected-medicine-table2 ">
              <thead>
                <tr>
                  <th id="vital_Twidth">Procedure Name</th>
                  <th id="vital_Twidth">Sessions</th>
                  <th id="vital_Twidth">Amount</th>
                  <th id="vital_Twidth">Total Amount</th>
                  <th id="vital_Twidth">Next Appointment</th>
                  <th id="vital_Twidth">Action</th>
                </tr>
              </thead>
              <tbody>
                {proceduredata.map((data, index) => (
                  <tr key={index}>
                    <td>{data.ProcedureName}</td>
                    <td>{data.Sessions}</td>
                    <td>{data.Amount}</td>
                    <td>{data.TotalAmount}</td>
                    <td>{data.nextAppointment}</td>
                    <td>
                      <button
                        className="pro-actn-edit"
                        onClick={() => handleEdit(index)}
                      >
                        <EditNoteIcon />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="new-button-container">
            <button className="btncon_add" onClick={handleSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Procedure2Pro;
