import React, { useEffect, useState } from 'react';
import '../../Stock/ProductMaster/ProductMaster.css';
import { useNavigate } from "react-router-dom";
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
function RoomMasterCreate( ) {

    const dispatchvalue = useDispatch();

    const userRecord = useSelector(state=>state.userRecord?.UserData)



    
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [isedit, setisedit] = useState(null);
    const [summa, setsumma] = useState([])
    const [update, setupdate] = useState(false)
    const [filteredRows, setFilteredRows] = useState([]);
    const [BuildingName, setBuildingName] = useState([]);
    const [BlockName, setBlockName] = useState([]);
    const [FloorName, setFloorName] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    const [formDataProduct, setFormDataProduct] = useState({
        S_No:'',
       Building:'',
       Block:'',
       Floor:'',
       Ward:'',
       RoomType:'',
       Room:'',
       BedNo:'',
       Charge:'',
       Status:'Active',
       Location:'',
       createdby: userRecord?.username,
    });

    const [formDataRoommaster,setformDataRoommaster]=useState({
        TotalRooms:0,
        CreatedRooms:0,
        AvailableRooms:0,
    })
    const [WardRoom,setWardRoom]=useState([])

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.Building.toLowerCase();
            const lowerCaseSupplierCode = row.Block.toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) ||
                lowerCaseSupplierCode.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
        
    }, [searchQuery,summa]);

    useEffect(()=>{
        axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Master_Data?BookingStatus=All&Status=All&Location=ALL`)
            .then((response)=>{
                console.log(response.data)
                const data=response.data.map((p)=>({
                    ...p,
                    id:p.S_No
                }))
                console.log(data)
                setsumma(data)
            })
            .catch((error)=>{
                console.log(error)
            })
        axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Count_data`)
            .then((response)=>{
                console.log(response.data)
                const data =response.data

                setformDataRoommaster({
                    TotalRooms: data.TotalRooms,
                    CreatedRooms:data.CreatedRooms ,
                    AvailableRooms:data.AvailableRooms
                })
                setBuildingName([...data.BuildingName])
                
            })
            .catch((error)=>{
                console.log(error)
            })
    },[update])



    useEffect(()=>{

        if(formDataProduct.Building!==''){
            axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Block_Name?Building=${formDataProduct.Building}`)
                .then((response)=>{
                    console.log(response.data)
                    
                    setBlockName([...response.data])
                    
                    
                })
                .catch((error)=>{
                    console.log(error)
                })

        }
        
    },[formDataProduct.Building])

    useEffect(()=>{

        if(formDataProduct.Building!==''){
            axios.get(`http://127.0.0.1:8000/ipregistration/get_Room_Floor_Name?Block=${formDataProduct.Block}`)
                .then((response)=>{
                    console.log(response.data)
                    setFloorName([...response.data])
                    
                })
                .catch((error)=>{
                    console.log(error)
                })

        }
    },[formDataProduct.Block])
    useEffect(()=>{
        axios.get(`http://127.0.0.1:8000/usercontrol/get_Room_type`)
      .then((response) => {
        console.log(response)
        setWardRoom(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
    },[])
// Define the columns dynamically
const dynamicColumns = [
    { field: 'id', headerName: 'S_No', width: 150 },
    { field: 'Building', headerName: 'Building Name', width: 150 },
    { field: 'Block', headerName: 'Block Name', width: 150 },
    { field: 'Floor', headerName: 'Floor Name', width: 150 },
    { field: 'Ward', headerName: 'Ward Name', width: 120 },
    { field: 'RoomType', headerName: 'Room Type', width: 120 },
    { field: 'Room', headerName: 'Room No', width: 120 },
    { field: 'BedNo', headerName: 'Bed No', width: 120 },
    { field: 'Charge', headerName: 'Charge', width: 120 },
    { field: 'Location', headerName: 'Location', width: 120 },
    { field: 'Status', headerName: 'Status', width: 120 },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 120,
        renderCell: (params) => (
            <>
                <Button className='cell_btn' onClick={() => handleRequestEdit(params.row)}>
                    <BorderColorIcon />
                </Button>
            </>
        ),
    },
];
   


    const handleInputChange = (event) => {
        const { name, value } = event.target;

        
        if(name ==='Building'){
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Block:'',
                Floor:''
    
            }));
        }else if(name ==='Block'){
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
                Floor:''
    
            }));
        }else{
            setFormDataProduct((prevData) => ({
                ...prevData,
                [name]: value,
    
            }));
        }
    };
   useEffect(()=>{
   const data= WardRoom.find((p)=>p.WardType===formDataProduct.Ward &&p.RoomType===formDataProduct.RoomType)
   console.log(data)
   if(data){
    setFormDataProduct((prev)=>({
        ...prev,
        Charge:data.Charge,
        Location:data.Location
    }))
   }else{
    setFormDataProduct((prev)=>({
        ...prev,
        Charge:'',
        Location:''
    }))
   }
   },[formDataProduct.Ward,formDataProduct.RoomType])
    const handleSaveOrUpdate = () => {
        
    const requiredfields=[
        'Building',
        'Block',
        'Floor',
        'Ward',
        'RoomType',
        'Room',
        'BedNo',
        'Charge',
        'Status',
        'Location',
    ]
    const existing=requiredfields.filter((field)=>!formDataProduct[field])
    if(existing.length===0){
        axios.post(`http://127.0.0.1:8000/ipregistration/${isedit?'update_get_Room_Master_Data':'insert_get_Room_Master_Data'}`,formDataProduct)
            .then((response)=>{
                console.log(response.data)
                clearFormInputs()
                setisedit(null)
                setBuildingName([])
                setBlockName([])
                setFloorName([])
                setupdate(!update)
            })
            .catch((error)=>{
                console.log(error)
            })
    }else{
        alert(`Please fill the required Fields : ${existing.join(',')}`)
    }

};
const handleRequestEdit = (params) => {
    setFormDataProduct({...params})
    setisedit(params)

}
    const clearFormInputs = () => {


        setFormDataProduct({
        Building:'',
        Block:'',
        Floor:'',
        Ward:'',
        RoomType:'',
        Room:'',
        BedNo:'',
        Charge:'',
        Status:'Active',
        createdby: userRecord?.username,
        Location:'',
        });

    };
    return (
        <div className="Stoct_Product_master">
            <div className="Stoct_Product_master_head">
                <h3 className='Stoct_Product_master_head_h3'>Room Master</h3>
            </div>
            <div className='Stoct_Product_master_Container'>
                <div  className='Stoct_Product_master_form'>
                <div className="Stoct_Product_master_form_row">
                    <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Total Rooms :</label>
                            <input
                                type="number"
                                name="AvailableRooms"
                                placeholder="Enter Total Rooms"
                                value={formDataRoommaster.TotalRooms}
                                required
                                readOnly
                                className='Stoct_Product_master_form_Input'
                            />
                            
                        </div>
                 
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="code">Available Rooms :</label>
                            <input
                                type="number"
                                name="AvailableRooms"
                                placeholder="Enter Available Rooms"
                                value={formDataRoommaster.AvailableRooms}
                                required
                                readOnly
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="code">Created Rooms :</label>
                            <input
                                type="number"
                                name="CreatedRooms"
                                placeholder="Enter Created Rooms"
                                value={formDataRoommaster.CreatedRooms}
                                required
                                readOnly
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>

                    </div>
                    <div className="Stoct_Product_master_form_row">
                    <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Building Name :</label>
                            <input
                                type="text"
                                list='BuildingName'
                                name="Building"
                                placeholder="Enter Building Name"
                                value={formDataProduct.Building}
                                required
                                onChange={handleInputChange}
                                className='Stoct_Product_master_form_Input'
                            />
                           <datalist id="BuildingName">
                                        {BuildingName.map((item, index) => (
                                            <option key={index} value={item}></option>
                                        ))}
                            </datalist>
                        </div>
                 
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="code">Block Name :</label>
                            <input
                                type="text"
                                name="Block"
                                list='BlockName'
                                placeholder="Enter Block Name"
                                value={formDataProduct.Block}
                                onChange={handleInputChange}
                                required
                                className='Stoct_Product_master_form_Input'
                            />

                            <datalist id="BlockName">
                                        {BlockName.map((item, index) => (
                                            <option key={index} value={item}></option>
                                        ))}
                            </datalist>
                        </div>

                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Floor Name:</label>
                            <input
                                type="text"
                                name="Floor"
                                list='FloorName'
                                placeholder="Enter Floor"
                                value={formDataProduct.Floor}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            />
                            <datalist id="FloorName">
                                        {FloorName.map((item, index) => (
                                            <option key={index} value={item}></option>
                                        ))}
                            </datalist>
                        </div>
                    
                       
                       <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="generic">Ward Type:</label>
                            
                            <select
                                name="Ward"
                                value={formDataProduct.Ward}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            >
                                <option value=''>select </option>
                               
                                
                {
                  Array.from(new Set(WardRoom.filter(p => p.Status === 'Active').map(p => p.WardType))).map((wardType, index) => (
                    <option key={index} value={wardType}>{wardType}</option>
                  ))
                }
                            </select>
                        </div>

                        
                       
                        

                    </div>
                    <div className="Stoct_Product_master_form_row">
                    
                       
                       
                     
                      
                       <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="strength">Room Type:</label>
                            
                            <select
                                name="RoomType"
                                value={formDataProduct.RoomType}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            >
                                <option value=''>select </option>
                                {
                  Array.from(new Set(WardRoom.filter(p => p.Status === 'Active' && p.WardType === formDataProduct.Ward).map(p => p.RoomType))).map((RoomType, index) => (
                    <option key={index} value={RoomType}>{RoomType}</option>
                  ))
                }
                            </select>
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="strength">Room No:</label>
                            <input
                                type="text"
                                name="Room"
                                placeholder="Enter Room"
                                value={formDataProduct.Room}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                        
                   

                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="uom">Bed No :</label>
                            <input
                                type="text"
                                name="BedNo"
                                placeholder="Enter Bed Count"
                                value={formDataProduct.BedNo}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="uom">Charge:</label>
                            <input
                                type="number"
                                name="Charge"
                                placeholder="Enter Charge per Bed"
                                value={formDataProduct.Charge}
                                onChange={handleInputChange}
                                readOnly
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                       
                        
                    </div>
                    
                    <div className="Stoct_Product_master_form_row">
                    <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="strength">Location:</label>
                            <input
                                type="text"
                                name="Location"
                                placeholder="Enter Location"
                                value={formDataProduct.Location}
                                onChange={handleInputChange}
                                readOnly
                                className='Stoct_Product_master_form_Input'
                            />
                        </div>
                        <div className="Stoct_Product_master_form_row_div">
                            <label className="Stoct_Product_master_form_Label" htmlFor="strength">Status :</label>
                            
                             <select
                                name="Status"
                                value={formDataProduct.Status}
                                onChange={handleInputChange}
                                // readOnly={selectedProductIndex}
                                className='Stoct_Product_master_form_Input'
                            >
                             
                                <option value='Active'>Active </option>
                                <option value='InActive'>InActive </option>
                            </select>
                        </div>
                    </div>
                    
                    
                    <div className='Stoct_Product_master_form_Save'>
                        <button className=' Stoct_Product_master_form_Save_button' onClick={handleSaveOrUpdate} >
                            {isedit ? "Update" : "Save"}
                        </button>
                    </div>
                </div>
            </div>
            <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows ?.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
        </div>
    );
}

export default RoomMasterCreate;
