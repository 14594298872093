import React, { useEffect, useState } from 'react'
import { format } from 'date-fns';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { saveAs } from 'file-saver';

const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: 'var(--ProjectColor)',
  
          },
          root: {
            '& .MuiDataGrid-window': {
              overflow: 'hidden !important',
  
            },
          },
          cell: {
            borderTop: '0px !important',
            borderBottom: '1px solid var(--ProjectColor) !important',
          },
        },
      },
    },
  });

const BalanceSheet = () => {

  

    const currentDate = new Date();
    const formattedDate = format(currentDate,'yyyy-MM-dd');

    console.log(formattedDate)
    

    const [SearchformData, setSearchformData] = useState({
        Location: '',
        DateType: '',
        CurrentDate:'',
        FromDate: '',
        ToDate: '',
    });

    const [Supplierdetailes,setSupplierdetailes]=useState([])
    const [Location,setLocation]=useState([])

    const [page, setPage] = useState(0);
    const [filteredRows, setFilteredRows] = useState([]);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);




    


    const handleChange = (e) => {
        const { name, value } = e.target;
        if(name === 'DateType'){
           if(value === 'Current'){
            setSearchformData({
                ...SearchformData,
                CurrentDate:formattedDate,
                [name]: value
            });
           }else{
            setSearchformData({
                ...SearchformData,
                CurrentDate:'',
                [name]: value
            });
           }
        } 
           
        else{
            setSearchformData({
                ...SearchformData,
                [name]: value
            });
        }
           
        
       
    };
    useEffect(()=>{
        const currentDate = new Date();
        const formattedDate = format(currentDate, 'dd-MM-yyyy');
        setSearchformData((prev)=>({
            ...prev,
            date:formattedDate
        }))
        axios
      .get(`http://127.0.0.1:8000/usercontrol/getlocationdata`)
      .then((response) => {
        const data = response.data.map(p => p.location_name);

        console.log([...data,'CentralStore']);
        setLocation([...data,'CentralStore']);
      })
      .catch((error) => {
        console.log(error);
      });
        
    },[])

    const dynamicColumns = [
        { field: 'id', headerName: 'S.NO', width: 50 },
        { field: 'Location', headerName: 'Branch Name', width: 130 },
        { field: 'Invoice_NO', headerName: 'Invoice NO', width: 130 },
        { field: 'Patient_Name', headerName: 'PATIENT NAME', width: 100 },
        { field: 'Phone_Number', headerName: 'MOBILE NUMBER', width: 100 },
        { field: 'Physician_Name', headerName: 'DOCTOR', width: 150 },
        { field: 'DIAGNOSIS', headerName: 'DIAGNOSIS', width: 150 },
        { field: 'Total_BASE_Amount', headerName: 'GROSS AMOUNT', width: 130 },
        { field: 'Total_Cash_Discount', headerName: 'DISCOUNT', width: 150 },
        { field: 'Total_Net_Amount', headerName: 'NET AMOUNT', width: 130 } ,      
        { field: 'Total_CGST', headerName: 'CGST', width: 130 } ,      
        { field: 'Total_SGST', headerName: 'SGST', width: 130 } , 
        { field: 'Total_GST', headerName: 'GST', width: 130 } ,      
        { field: 'InvoiceValue', headerName: 'INVOICE VALUE', width: 130 },            
        { field: 'Cash', headerName: 'CASH', width: 130 } ,               
        { field: 'Card', headerName: 'CARD', width: 130 } ,   
        { field: 'OnlinePayment', headerName: 'UPI', width: 130 }   
    ];



    const handletoSearch=()=>{
        const params={
            Location: SearchformData.Location,
            DateType: SearchformData.DateType,
            CurrentDate: SearchformData.CurrentDate,
            FromDate: SearchformData.FromDate,
            ToDate: SearchformData.ToDate
        }
        
        let requiredFields=[]
        if(SearchformData.DateType==='Customize'){
            requiredFields=[
                'Location',
                'DateType',
                'FromDate',
                'ToDate',
            ]
        }else{
            requiredFields=[
                'Location',
                'DateType',
            ]
        }
        const existingItem= requiredFields.filter((field)=>!SearchformData[field])
       if(existingItem.length>0){
       
            alert(`Please Fill the RequiredFields : ${existingItem.join(',')} `)
       }else{

        axios.get(`http://127.0.0.1:8000/FinanceMagement/getSalesRegisterDetailsAll`, { params })
        .then((response) => {
            // console.log('111',response.data)
            const A_data=response.data
            const B_data =A_data.map((p,index)=>({
                id:index+1,
                ...p
              }))
            setFilteredRows(B_data)
            console.log('111B_data',B_data)
            // setSearchformData({
            //     Location: '',
            //     DateType: '',
            //     CurrentDate:'',
            //     FromDate: '',
            //     ToDate: '',
            // })
        })
        .catch((error) => {
            console.error(error);
        });

       
       }
       

    }

    const handleExportToExcel = () => {
        if (filteredRows.length !== 0) {
            const columns = [
                { dataKey: 'id', header: 'S.NO'},
                { dataKey: 'Location', header: 'Branch Name'},
                { dataKey: 'GRN_InvoiceNo', header: 'GRN_InvoiceNo'},
                { dataKey: 'GRN_Date', header: 'GRN Date'},
                { dataKey: 'Supplier_Bill_No', header: 'Supplier Bill No'},
                { dataKey: 'Supplier_Bill_Date', header: 'Supplier Bill Date'},
                { dataKey: 'Supplier_Code', header: 'Supplier Code'},
                { dataKey: 'Supplier_Name', header: 'Supplier Name'},
                { dataKey: 'SupplierGST', header: 'Supplier GST No'} ,      
                { dataKey: 'TotalAmountWithoutTax', header: 'TaxableValue'} ,      
                { dataKey: 'SGST 9.0', header: 'CGST 9'} ,      
                { dataKey: 'CGST 9.0', header: 'SGST 9'} ,      
                { dataKey: 'IGST 18.0', header: 'IGST 18'} ,        
                { dataKey: 'SGST 6.0', header: 'CGST 6'} ,      
                { dataKey: 'CGST 6.0', header: 'SGST 6'} ,      
                { dataKey: 'IGST 12.0', header: 'IGST 12'} ,      
                { dataKey: 'SGST 2.5', header: 'CGST 2.5'} ,      
                { dataKey: 'CGST 2.5', header: 'SGST 2.5'} ,      
                { dataKey: 'IGST 5.0', header: 'IGST 5'} ,  
                { dataKey: 'TotalTaxAmount', header: 'Total Tax Amount'} ,
            ];

            const header = columns.map(col => col.header);


            const csv = [
                '\ufeff' + header.join(","), // BOM + header row first
                ...filteredRows.map(row => columns.map(col => row[col.dataKey])),
                '', // Empty row for spacing
               
            ].join("\r\n");

            var data = new Blob([csv], { type: "text/csv" });
            saveAs(data, "PurchaseRegisterReport.csv");
        } else {
            alert('No Data to Save')
        }
    };
 
    



    return (
        <div className='ShiftClosing_over'>
            <div className="ShiftClosing_Container">
                <div className="ShiftClosing_header">
                    <h3>Balance Sheet</h3>
                </div>
                <div className="ShiftClosing_Invoice_container">
                   
                    <div className="ShiftClosing_Con">
                        <div className="ShiftClosing_Con_row">

                        {/* <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Location <span>:</span></label>
                                <select
                                    name='Location'
                                    value={SearchformData.Location}
                                    onChange={handleChange}
                                >
                                    <option value=''>Select Location</option>
                                    {Location.map((p) => (
                                        <option key={p} value={p}>
                                            {p.toUpperCase()}
                                        </option>
                                    ))}
                                </select>

                        </div> */}


                        <div className="ShiftClosing_Con_column">
                            <label htmlFor="">Date Type <span>:</span></label>
                            <select 
                                name="DateType"
                                value={SearchformData.DateType}
                                onChange={handleChange}
                            >
                                <option value="">-Select-</option>
                                <option value="Current">Current Date</option>
                                <option value="Customize">Customize</option>
                            </select>
                        </div>
                            
                             
                           
                        </div>

                        <div className="ShiftClosing_Con_row">
                        
                            {SearchformData.DateType === 'Current' &&
                                <div className="ShiftClosing_Con_column">
                                <label htmlFor="">Current Date <span>:</span></label>
                                <input
                                    type="date"
                                    name="CurrentDate"
                                    value={SearchformData.CurrentDate}
                                    onChange={handleChange}
                                />
                            </div>}
                            {SearchformData.DateType === 'Customize' &&
                            <>
                                <div className="ShiftClosing_Con_column">
                                <label htmlFor="">From Date <span>:</span></label>
                                <input
                                    type="date"
                                    name="FromDate"
                                    value={SearchformData.FromDate}
                                    onChange={handleChange}
                                />
                            </div>
                             <div className="ShiftClosing_Con_column">
                             <label htmlFor="">To Date <span>:</span></label>
                             <input
                                 type="date"
                                 name="ToDate"
                                 value={SearchformData.ToDate}
                                 onChange={handleChange}
                             />
                         </div>
                         </>}
                        </div>                       
                    </div>

                </div>
                <div >
                    <button className="btn_1" onClick={handletoSearch}>Search</button>
                </div>
            </div>
            {/* <div className='grid_1'>
                    <ThemeProvider theme={theme}>
                        <div className='grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className='data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button 
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button 
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
            </div> */}
            {filteredRows.length !== 0 &&
                <div className='PrintExelPdf'>
                    <button onClick={handleExportToExcel}>Save Exel</button>
                </div>
                }
            
             <div className="new_billing_invoice_detials talleybilling" style={{display:'flex'}}>
                <div className='tablecontainer_talley1' style={{width:"50%"}}>
                <table>
                        <thead>
                            <tr>
                                <th>Service Type</th>
                                <th>Amount</th>
                                
                                
                            </tr>
                        </thead>
                        <tbody>

                           
                        </tbody>
                        <tfoot>
                            <tr>
                                <th>Total Amount</th>
                                <th></th>
                            </tr>
                           
                        </tfoot>
                    </table>
</div>
                    
                    <div className="tablecontainer_talley" style={{width:"50%"}}>
                    <table>
                        <thead>
                            <tr>
                                <th>Service Type</th>
                                <th>Amount</th>
                                
                               
                            </tr>
                        </thead>
                        <tbody>

                           
                        </tbody>
                        <tfoot>
                        <tr>
                                <th>Total Amount</th>
                                <th></th>
                            </tr>
                        </tfoot>
                    </table>
                    </div>
              </div>
            
        </div>
    )
}

export default BalanceSheet;
