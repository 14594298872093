import React, { useState, useEffect } from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import './PurchaseRaiseMaster.css'
import axios from "axios";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';



function PurchaseRaiseMaster() {


    const userRecord = useSelector(state => state.userRecord?.UserData)

const [type,settype] = useState('')
    //   const [billingInfo, setBillingInfo] = useState([]);

    const navigate = useNavigate();

    const [cartItems, setCartItems] = useState([]);
    const [formDataPurchaseInvoice, setFormDataPurchaseInvoice] = useState({
        ProductCategory: '',
        PurchaseInvoice: '',
        SupplierCode: '',
        SupplierName: '',
        CreatedBy: userRecord?.username,
        Location: 'CentralStore'
    })

    // const [PrintPdf, setPrintPdf] = useState(null);
    const [formDataPurchase, setFormDataPurchase] = useState({
        ItemCode: '',
        ItemName: '',
        GenericName: '',
        Strength: '',
        UOM: '',
        PackIn: '',
        PackQuantity: '',
        OrderQuantity: '',
        TotalQuantity: '',
        ApprovedQuantity: '',
    })
    console.log(formDataPurchase)
    const [formDataPurchaseInvoicePrint, setFormDataPurchaseInvoicePrint] = useState(null);
    const [isPrintButtonVisible, setIsPrintButtonVisible] = useState(true);


    const [ClinicDetials, setClinicDetials] = useState({
        ClinicLogo: null,
        ClinicName: '',
        ClinicGST: '',
        ClinicAddress: '',
        ClinicCity: '',
        ClinicState: '',
        ClinicCode: '',
        ClinicMobileNo: '',
        ClinicLandLineNo: '',
        ClinicMailID: '',
    });
    const [IndexEdit, setIndexEdit] = useState(null);
    const currentDate = format(new Date(), 'dd-MM-yyyy');

    const [Supplierdetailes, setSupplierdetailes] = useState([])

    const [ItemCodedetailes, setItemCodedetailes] = useState([])



    // console.log('asd',ItemCodedetailes)



    useEffect(() => {
        if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' || formDataPurchaseInvoice.ProductCategory == 'Surgical') {
            axios
            .get(`http://127.0.0.1:8000/SupplierMaster/get_medical_purchase_order_invoice_no?producttype=${formDataPurchaseInvoice.ProductCategory}`)
            .then((response) => {
                    console.log('INV:', response.data.nextMedicalPurchaseOrderInvoiceNo);
                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        PurchaseInvoice: response.data.nextMedicalPurchaseOrderInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
            .get(`http://127.0.0.1:8000/SupplierMaster/get_Medical_ProductMaster_data?Productype=${formDataPurchaseInvoice.ProductCategory}`)
            .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable') {
            axios
                .get(`http://127.0.0.1:8000/SupplierMaster/get_medical_purchase_order_invoice_no?producttype=${formDataPurchaseInvoice.ProductCategory}`)
                .then((response) => {
                    console.log('INV:', response.data.nextMedicalPurchaseOrderInvoiceNo);

                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        PurchaseInvoice: response.data.nextMedicalPurchaseOrderInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

                axios
                .get(`http://127.0.0.1:8000/SupplierMaster/get_Medical_ProductMaster_data?Productype=${formDataPurchaseInvoice.ProductCategory}`)
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'Assets') {
            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_assets_purchase_order_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextAssetsPurchaseOrderInvoiceNo);
                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        PurchaseInvoice: response.data.nextAssetsPurchaseOrderInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_Assets_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'Stationary') {
            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_stationary_purchase_order_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextStationaryPurchaseOrderInvoiceNo);

                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        PurchaseInvoice: response.data.nextStationaryPurchaseOrderInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_Stationary_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
        else if (formDataPurchaseInvoice.ProductCategory === 'Non_Stationary') {
            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_non_stationary_purchase_order_invoice_no')
                .then((response) => {
                    console.log('INV:', response.data.nextNonStationaryPurchaseOrderInvoiceNo);

                    setFormDataPurchaseInvoice((prevData) => ({
                        ...prevData,
                        PurchaseInvoice: response.data.nextNonStationaryPurchaseOrderInvoiceNo,
                    }));
                })
                .catch((error) => {
                    // Handle errors here
                    console.error("Error fetching data:", error);
                });

            axios
                .get('http://127.0.0.1:8000/SupplierMaster/get_NONStationary_ProductMaster_data')
                .then((response) => {
                    console.log('ITEM:', response.data);
                    let A_Status = response.data.filter((ele) => { return ele.Status = "Active" })
                    setItemCodedetailes(A_Status)
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        }
    }, [formDataPurchaseInvoice.ProductCategory])


    useEffect(() => {

        if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable'){
           settype('Medical')
        }else if (formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable'){
            settype('Non_Medical')
        } else if(formDataPurchaseInvoice.ProductCategory){
            settype(formDataPurchaseInvoice.ProductCategory)
        }

        axios.get(`http://127.0.0.1:8000/SupplierMaster/get_supplier_Master_Name?productcategorytype=${type}`)
            .then((response) => {
                const data = response.data.filter((ele) => {
                    return ele.SupplierType === type
                        && ele.Status === "Active"
                })
                console.log('mm', data)
                setSupplierdetailes(data)

            })
            .catch((error) => {
                console.error('Error fetching patients data:', error);
            });

    }, [formDataPurchaseInvoice.ProductCategory,type])


    useEffect(() => {
        // clinic detials
        axios.get('http://127.0.0.1:8000/usercontrol/getClinic')
            .then((response) => {
                // console.log(response.data)
                const data = response.data[0]
                console.log(data)
                if (data) {
                    setClinicDetials((prev) => ({
                        ...prev,
                        ClinicAddress: data.door_no + ',' + data.area + "," + data.street,
                        ClinicGST: data.Gst_no,
                        ClinicCity: data.city,
                        ClinicState: data.state,
                        ClinicCode: data.pincode,
                        ClinicMobileNo: data.phone_no,
                        ClinicLandLineNo: data.landline_no,
                        ClinicMailID: data.email,
                    }))
                }
            })
            .catch((error) => {
                console.log(error)
            })
            .catch((error) => {
                console.log(error)
            })
        axios.get('http://127.0.0.1:8000/usercontrol/getAccountsetting')
            .then((response) => {
                // console.log(response.data.Data)
                const data = response.data;
                setClinicDetials((prev) => ({
                    ...prev,
                    ClinicName: data.Clinic_Name,
                    ClinicLogo: (`data:image/png;base64,${data.Clinic_Logo}`)
                }))
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])




    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'ItemCode') {

            const Set_datas = ItemCodedetailes.find((ele) => { return ele.ItemCode === value })
            if (Set_datas) {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemName: Set_datas.ItemName,
                    GenericName: Set_datas.GenericName,
                    Strength: Set_datas.Strength,
                    UOM: Set_datas.UOM,
                    PackIn: Set_datas.ProductType,
                    PackQuantity: Set_datas.ProductQuantity,
                }));
            }
            else {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemName: '',
                    GenericName: '',
                    Strength: '',
                    UOM: '',
                    PackIn: '',
                    PackQuantity: '',
                }));
            }
        }
        else if (name === 'ItemName') {

            const Set_datas = ItemCodedetailes.find((ele) => { return ele.ItemName === value })

            if (Set_datas) {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemCode: Set_datas.ItemCode,
                    GenericName: Set_datas.GenericName,
                    Strength: Set_datas.Strength,
                    UOM: Set_datas.UOM,
                    PackIn: Set_datas.ProductType,
                    PackQuantity: Set_datas.ProductQuantity,
                }));
            } else {
                setFormDataPurchase((prevData) => ({
                    ...prevData,
                    [name]: value,
                    ItemCode: '',
                    GenericName: '',
                    Strength: '',
                    UOM: '',
                }));
            }
        }
        else {
            const data1 = formDataPurchase.PackQuantity * value

            setFormDataPurchase((prevData) => ({
                ...prevData,
                [name]: value,
                TotalQuantity: data1,
                ApprovedQuantity: data1,

            }));
        }

    };

    const handleInputChange1 = (event) => {
        const { name, value } = event.target;
        if (name === 'ProductCategory') {
            setFormDataPurchaseInvoice((prevData) => ({
                ...prevData,
                [name]: value,
                PurchaseInvoice: '',
                SupplierCode: '',
                SupplierName: '',

            }));

            handleCancel();
            setCartItems([]);
        }
        else if (name === 'SupplierCode') {
            let sup_n = Supplierdetailes.find((ele) => ele.SupplierCode === value);
            if (sup_n) {
                setFormDataPurchaseInvoice((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierName: sup_n.SupplierName,
                }));
            } else {
                setFormDataPurchaseInvoice((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierName: '', // Set default value or handle accordingly
                }));
            }
        }
        else if (name === 'SupplierName') {
            let sup_c = Supplierdetailes.find((ele) => ele.SupplierName === value);
            if (sup_c) {
                setFormDataPurchaseInvoice((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierCode: sup_c.SupplierCode,
                }));
            } else {
                setFormDataPurchaseInvoice((prevData) => ({
                    ...prevData,
                    [name]: value,
                    SupplierCode: '', // Set default value or handle accordingly
                }));
            }
        } else {
            setFormDataPurchaseInvoice((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleAddToCart = () => {
        const existingItem = cartItems.find((item) => item.ItemCode === formDataPurchase.ItemCode);

        if (existingItem) {
            alert(`Item with code ${formDataPurchase.ItemCode} already exists in the cart.`);
        } else {
            let updatedata;
            let requiredFields;
            if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' || 
            formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' || 
            formDataPurchaseInvoice.ProductCategory === 'Surgical') {
                updatedata = {
                    ItemCode: formDataPurchase.ItemCode,
                    ItemName: formDataPurchase.ItemName,
                    GenericName: formDataPurchase.GenericName,
                    Strength: formDataPurchase.Strength,
                    UOM: formDataPurchase.UOM,
                    PackIn: formDataPurchase.PackIn,
                    PackQuantity: formDataPurchase.PackQuantity,
                    OrderQuantity: formDataPurchase.OrderQuantity,
                    TotalQuantity: formDataPurchase.TotalQuantity,
                    ApprovedQuantity: formDataPurchase.ApprovedQuantity,
                }

                if (formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' || formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable'){
                    requiredFields = [
                        'ItemCode',
                        'ItemName',
                        'GenericName',
                        'Strength',
                        'UOM',
                        'PackIn',
                        'PackQuantity',
                        'OrderQuantity',
                        'TotalQuantity'
                    ]
                }else{
                    requiredFields = [
                        'ItemCode',
                        'ItemName',
                        'GenericName',
                        'PackQuantity',
                        'OrderQuantity',
                        'TotalQuantity'
                    ]
                }
                
            } else {
                updatedata = {
                    ItemCode: formDataPurchase.ItemCode,
                    ItemName: formDataPurchase.ItemName,
                    GenericName: 'Null',
                    Strength: 'Null',
                    UOM: 'Null',
                    PackIn: formDataPurchase.PackIn,
                    PackQuantity: formDataPurchase.PackQuantity,
                    OrderQuantity: formDataPurchase.OrderQuantity,
                    TotalQuantity: formDataPurchase.TotalQuantity,
                    ApprovedQuantity: formDataPurchase.ApprovedQuantity,
                }
                requiredFields = [
                    'ItemCode',
                    'ItemName',
                    'PackIn',
                    'PackQuantity',
                    'OrderQuantity',
                    'TotalQuantity'
                ]
            }
            const missingFields = requiredFields.filter((field) => !formDataPurchase[field])
            if (missingFields.length === 0) {
                console.log(updatedata)
                setCartItems((prev) => [...prev, updatedata]);
                handleCancel();
            } else {
                alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
            }
        }


    };

    const handleDeleteCartItem = (itemCode) => {
        setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
    };
    const handleEditCartItem = (itemCode) => {
        const selectedItem = cartItems.find((item) => item.ItemCode === itemCode);
        setIndexEdit(selectedItem)
        console.log(selectedItem.ItemCode)
        if (selectedItem) {
            // Set the form data with the selected item's data
            setFormDataPurchase({
                ItemCode: selectedItem.ItemCode,
                ItemName: selectedItem.ItemName,
                GenericName: selectedItem.GenericName,
                Strength: selectedItem.Strength,
                UOM: selectedItem.UOM,
                PackIn: selectedItem.PackIn,
                PackQuantity: selectedItem.PackQuantity,
                OrderQuantity: selectedItem.OrderQuantity,
                TotalQuantity: selectedItem.TotalQuantity,
            });

            // Remove the selected item from the cart
            //   setCartItems((prev) => prev.filter((item) => item.ItemCode !== itemCode));
        }
    };

    const handleUpdateCartItem = () => {
        const updatedItem = {
            ItemCode: formDataPurchase.ItemCode,
            ItemName: formDataPurchase.ItemName,
            GenericName: formDataPurchase.GenericName,
            Strength: formDataPurchase.Strength,
            UOM: formDataPurchase.UOM,
            PackIn: formDataPurchase.PackIn,
            PackQuantity: formDataPurchase.PackQuantity,
            OrderQuantity: formDataPurchase.OrderQuantity,
            TotalQuantity: formDataPurchase.TotalQuantity,
        };

        // Check if the updated item has all required fields
        const requiredFields = ['ItemCode', 'ItemName', 'PackIn', 'PackQuantity'];
        const missingFields = requiredFields.filter((field) => !updatedItem[field]);

        if (missingFields.length === 0) {
            // Find the index of the item in the cart with the matching item code
            const indexOfItemToUpdate = cartItems.findIndex((item) => item.ItemCode === formDataPurchase.ItemCode);

            // Update the cart with the modified item
            setCartItems((prev) => {
                const updatedCart = [...prev];
                updatedCart[indexOfItemToUpdate] = updatedItem;
                return updatedCart;
            });

            handleCancel();
            setIndexEdit(null)
        } else {
            alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
        }
    };



    const handleSubmit = () => {

        // setFormDataPurchaseInvoicePrint({ ...formDataPurchaseInvoice })
        // setIsPrintButtonVisible(false);
        // setTimeout(async () => {
        //     window.print();
        //     setIsPrintButtonVisible(true);            
        // }, 1000);
        // if (formDataPurchaseInvoice.ProductCategory === 'Medical' || formDataPurchaseInvoice.ProductCategory === 'Non_Medical') {


        const updatedItem = {
            ProductCategory: formDataPurchaseInvoice.ProductCategory,
            PurchaseInvoice: formDataPurchaseInvoice.PurchaseInvoice,
            SupplierCode: formDataPurchaseInvoice.SupplierCode,
            SupplierName: formDataPurchaseInvoice.SupplierName,
            CreatedBy: formDataPurchaseInvoice.CreatedBy,
            Location: formDataPurchaseInvoice.Location,
            Purchaserise_date: format(new Date(), 'yyyy-MM-dd'),
            cartItems: cartItems /// array
        }

        // };
        const requiredFields = ['ProductCategory', 'PurchaseInvoice', 'SupplierCode', 'SupplierName'];
        const missingFields = requiredFields.filter((field) => !formDataPurchaseInvoice[field]);
        console.log(formDataPurchaseInvoice.ProductCategory)
        if (missingFields.length === 0) {
            if (cartItems.length !== 0) {
                if (
                    formDataPurchaseInvoice.ProductCategory === 'MedicalConsumable' ||
                    formDataPurchaseInvoice.ProductCategory === 'MedicalNonConsumable' ||
                    formDataPurchaseInvoice.ProductCategory === 'NonMedicalConsumable' ||
                    formDataPurchaseInvoice.ProductCategory === 'NonMedicalNonConsumable' ||
                    formDataPurchaseInvoice.ProductCategory === 'Surgical'
                  ) {
                    axios
                      .post('http://127.0.0.1:8000/SupplierMaster/insert_medical_purchase_order_data', updatedItem)
                      .then((response) => {
                        console.log(response.data);
                        clearFormInputs();
                        navigate('/Home/Purchase_Raise_list');
                      })
                      .catch((error) => {
                        console.error('Error post Supplier data:', error);
                      });
                  }
                   else if (formDataPurchaseInvoice.ProductCategory === 'Assets') {
                    axios.post('http://127.0.0.1:8000/SupplierMaster/insert_assets_purchase_order_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Purchase_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                } else if (formDataPurchaseInvoice.ProductCategory === 'Stationary') {
                    axios.post('http://127.0.0.1:8000/SupplierMaster/insert_stationary_purchase_order_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Purchase_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                } else if (formDataPurchaseInvoice.ProductCategory === 'Non_Stationary') {
                    axios.post('http://127.0.0.1:8000/SupplierMaster/insert_non_stationary_purchase_order_data', updatedItem)
                        .then((response) => {
                            console.log(response.data);
                            clearFormInputs();
                            navigate('/Home/Purchase_Raise_list');
                        })
                        .catch((error) => {
                            console.error('Error post Supplier data:', error);
                        });
                }
            } else {
                alert('No Items to Purchase');
            }
        } else {
            alert(`Missing fields: ${missingFields.join(', ')}`);
        }


        console.table(cartItems)
    };

    const handleCancel = () => {
        setFormDataPurchase({
            ItemCode: '',
            ItemName: '',
            GenericName: '',
            Strength: '',
            UOM: '',
            PackIn: '',
            PackQuantity: '',
            OrderQuantity: '',
            TotalQuantity: '',
            ApprovedQuantity: '',
        })
    };

    const clearFormInputs = () => {

        setFormDataPurchaseInvoice({
            ...formDataPurchaseInvoice,
            ProductCategory: '',
            PurchaseInvoice: '',
            SupplierCode: '',
            SupplierName: '',
        })

        setFormDataPurchase({
            ItemCode: '',
            ItemName: '',
            GenericName: '',
            Strength: '',
            UOM: '',
            PackIn: '',
            PackQuantity: '',
            OrderQuantity: '',
            TotalQuantity: '',
            ApprovedQuantity: '',
        })


    };

    const forPrintData = () => {
        return (
            <div className="billing-invoice" id="For_print_pdf">
                <div className="New_billlling_invoice_head">
                    <div className="new_billing_logo_con">
                        <img src={ClinicDetials.ClinicLogo} alt="Medical logo" />
                    </div>
                    <div className="new_billing_address_1 ">
                        <span>{ClinicDetials.ClinicName}</span>
                        <div>

                            <span >{ClinicDetials.ClinicAddress},</span>
                            <span >{ClinicDetials.ClinicCity + ',' + ClinicDetials.ClinicState + ',' + ClinicDetials.ClinicCode}</span>
                        </div>
                        <div>
                            <span>{ClinicDetials.ClinicMobileNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicLandLineNo + ' , '}</span>
                            <span>{ClinicDetials.ClinicMailID}</span>
                        </div>

                    </div>

                </div>
                <div className="Register_btn_con" style={{ color: 'hsl(33,100%,50%)', fontWeight: 600 }}>
                    Bill Cancelation
                </div>
                <div className="new_billing_address">
                    <div className="new_billing_address_2">


                        <div className="new_billing_div">
                            <label>Product Type <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.ProductCategory}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Supplier Code <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.SupplierCode}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Supplier Name <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.SupplierName}</span>
                        </div>

                    </div>
                    <div className="new_billing_address_2">
                        <div className="new_billing_div">
                            <label>Invoice No <span>:</span></label>
                            <span>{formDataPurchaseInvoicePrint.PurchaseInvoice}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>Date <span>:</span></label>
                            <span>{currentDate}</span>
                        </div>
                        <div className="new_billing_div">
                            <label>GSTIN No <span>:</span></label>
                            <span>{ClinicDetials.ClinicGST}</span>
                        </div>



                    </div>
                </div>

                {
                    formDataPurchaseInvoicePrint.ProductCategory === "Medical" ?
                        <div className="new_billing_invoice_detials">
                            <table>
                                <thead style={{ backgroundColor: 'hsl(33,100%,50%)' }}>
                                    <tr>
                                        <th>Item Code</th>
                                        <th>Item Name</th>
                                        <th>Generic Name</th>
                                        <th>Strength</th>
                                        <th>UOM</th>
                                        <th>Pack Type</th>
                                        <th>Pack Quantity</th>
                                        <th>Order Quantity</th>
                                        <th>Total Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {cartItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.ItemCode}</td>
                                            <td>{item.ItemName}</td>
                                            <td>{item.GenericName}</td>
                                            <td>{item.Strength}</td>
                                            <td>{item.UOM}</td>
                                            <td>{item.PackIn}</td>
                                            <td>{item.PackQuantity}</td>
                                            <td>{item.OrderQuantity}</td>
                                            <td>{item.TotalQuantity}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        :
                        <div className="new_billing_invoice_detials">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Item Code</th>
                                        <th>Item Name</th>
                                        <th>Pack Type</th>
                                        <th>Pack Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {cartItems.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.ItemCode}</td>
                                            <td>{item.ItemName}</td>
                                            <td>{item.PackIn}</td>
                                            <td>{item.PackQuantity}</td>

                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                }


            </div>
        );
    }

    return (
        <>
            {isPrintButtonVisible ?
                <div className="Stoct_Product_master">
                    <div className="Stoct_Product_master_head">
                        <h3 className="Stoct_Product_master_head_h3">
                            <ShoppingCartIcon /> Purchase Order
                        </h3>
                    </div>

                    <div className='Stoct_Product_master_Container'>
                        <div className='Stoct_Product_master_form'>
                            <div className="Stoct_Product_master_form_row">
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Product Category :</label>
                                    <select
                                        name='ProductCategory'
                                        value={formDataPurchaseInvoice.ProductCategory}
                                        onChange={handleInputChange1}
                                    >
                                        <option value=''>Select</option>
                                        <option value="MedicalConsumable">Medical Consumable</option>
                                        <option value="MedicalNonConsumable">
                                            Medical Non-Consumable
                                        </option>
                                        <option value="NonMedicalConsumable">Non MedicalConsumable</option>
                                        <option value="NonMedicalNonConsumable">Non MedicalNon-Consumable</option>
                                        <option value="Surgical">Surgical</option>
                                        <option value='Assets'>Assets</option>
                                        <option value='Stationary'>Stationary</option>
                                        <option value='Non_Stationary'>Non Stationary</option>
                                    </select>
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Invoice No :</label>
                                    <input
                                        type="text"
                                        name="PurchaseInvoice"
                                        placeholder="Enter Invoice No"
                                        value={formDataPurchaseInvoice.PurchaseInvoice}
                                        onChange={handleInputChange1}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                </div>


                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="code">Supplier Code :</label>
                                    <input
                                        type="text"
                                        name="SupplierCode"
                                        list="SupplierCode_list"
                                        placeholder="Enter Supplier Code"
                                        value={formDataPurchaseInvoice.SupplierCode}
                                        onChange={handleInputChange1}
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="SupplierCode_list">
                                        {Supplierdetailes.map((item, index) => (
                                            <option key={index} value={item.SupplierCode}></option>
                                        ))}
                                    </datalist>
                                </div>



                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Supplier Name :</label>
                                    <input
                                        type="text"
                                        name="SupplierName"
                                        list="SupplierName_list"
                                        placeholder="Enter Supplier Name"
                                        value={formDataPurchaseInvoice.SupplierName}
                                        onChange={handleInputChange1}
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="SupplierName_list">
                                        {Supplierdetailes.map((item, index) => (
                                            <option key={index} value={item.SupplierName}></option>
                                        ))}
                                    </datalist>
                                </div>

                            </div>




                        </div>
                        <div className="Add_items_Purchase_Master">
                            <span>Add Items</span>
                        </div>
                        <div className='Stoct_Product_master_form'>
                            <div className="Stoct_Product_master_form_row">
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Code</label>
                                    <input
                                        type="text"
                                        name="ItemCode"
                                        list="ItemCode_list"
                                        placeholder="Enter Item Code"
                                        value={formDataPurchase.ItemCode}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                        autoComplete="off"
                                    />
                                    <datalist id="ItemCode_list">
                                        {ItemCodedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemCode}></option>
                                        ))}
                                    </datalist>
                                </div>
                                <div className="Stoct_Product_master_form_row_div">
                                    <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Item Name :</label>
                                    <input
                                        type="text"
                                        name="ItemName"
                                        list="ItemCode_name"
                                        placeholder="Enter Item Name"
                                        value={formDataPurchase.ItemName}
                                        onChange={handleInputChange}
                                        required
                                        className='Stoct_Product_master_form_Input'
                                    />
                                    <datalist id="ItemCode_name">
                                        {ItemCodedetailes.map((item, index) => (
                                            <option key={index} value={item.ItemName}></option>
                                        ))}
                                    </datalist>
                                </div>
                                {(formDataPurchaseInvoice.ProductCategory === 'Medical') &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="code">Generic Name :</label>
                                        <input
                                            type="text"
                                            name="GenericName"
                                            placeholder="Enter Generic Name "
                                            value={formDataPurchase.GenericName}
                                            onChange={handleInputChange}
                                            required
                                            readOnly
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>
                                }
                                {(formDataPurchaseInvoice.ProductCategory === 'Medical') &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Strength :</label>
                                        <input
                                            type="text"
                                            name="Strength"
                                            placeholder="Enter  Strength"
                                            value={formDataPurchase.Strength}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>
                                }
                                {(formDataPurchaseInvoice.ProductCategory !== 'Medical') &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="strength">Pack Type:</label>
                                        <input
                                            type="text"
                                            name="PackIn"
                                            placeholder="Enter Pack Type"
                                            value={formDataPurchase.PackIn}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>}
                                {(formDataPurchaseInvoice.ProductCategory !== 'Medical') &&
                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Per {formDataPurchase.PackIn} Quantity :</label>
                                        <input
                                            type="text"
                                            name="PackQuantity"
                                            placeholder="Enter Pack Quantity"
                                            value={formDataPurchase.PackQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>
                                }




                            </div>
                            {(formDataPurchaseInvoice.ProductCategory === 'Medical') &&
                                <>
                                    <div className="Stoct_Product_master_form_row">


                                        <div className="Stoct_Product_master_form_row_div">
                                            <label className="Stoct_Product_master_form_Label" htmlFor="generic">UOM :</label>
                                            <input
                                                type="text"
                                                name="UOM"
                                                placeholder="Enter UOM"
                                                value={formDataPurchase.UOM}
                                                onChange={handleInputChange}
                                                className='Stoct_Product_master_form_Input'
                                                readOnly
                                            />
                                        </div>
                                        <div className="Stoct_Product_master_form_row_div">
                                            <label className="Stoct_Product_master_form_Label" htmlFor="strength">Pack Type:</label>
                                            <input
                                                type="text"
                                                name="PackIn"
                                                placeholder="Enter Pack Type"
                                                value={formDataPurchase.PackIn}
                                                onChange={handleInputChange}
                                                className='Stoct_Product_master_form_Input'
                                                readOnly
                                            />
                                        </div>
                                        <div className="Stoct_Product_master_form_row_div">
                                            <label className="Stoct_Product_master_form_Label" htmlFor="uom">Per {formDataPurchase.PackIn} Quantity :</label>
                                            <input
                                                type="text"
                                                name="PackQuantity"
                                                placeholder="Enter Pack Quantity"
                                                value={formDataPurchase.PackQuantity}
                                                onChange={handleInputChange}
                                                className='Stoct_Product_master_form_Input'
                                                readOnly
                                            />
                                        </div>

                                        <div className="Stoct_Product_master_form_row_div">
                                            <label className="Stoct_Product_master_form_Label" htmlFor="uom">Order Quantity :</label>
                                            <input
                                                type="text"
                                                name="OrderQuantity"
                                                placeholder="Enter Order Quantity"
                                                value={formDataPurchase.OrderQuantity}
                                                onChange={handleInputChange}
                                                className='Stoct_Product_master_form_Input'
                                            />
                                        </div>


                                    </div>

                                    <div className="Stoct_Product_master_form_row">
                                        <div className="Stoct_Product_master_form_row_div">
                                            <label className="Stoct_Product_master_form_Label" htmlFor="uom">Total Quantity :</label>
                                            <input
                                                type="number"
                                                name="TotalQuantity"
                                                placeholder="Enter Total Quantity"
                                                value={formDataPurchase.TotalQuantity}
                                                onChange={handleInputChange}
                                                className='Stoct_Product_master_form_Input'
                                                readOnly
                                            />
                                        </div>
                                    </div>

                                </>

                            }

                            {
                                (formDataPurchaseInvoice.ProductCategory !== 'Medical') && <div className="Stoct_Product_master_form_row">

                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Order Quantity :</label>
                                        <input
                                            type="text"
                                            name="OrderQuantity"
                                            placeholder="Enter Order Quantity"
                                            value={formDataPurchase.OrderQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                        />
                                    </div>


                                    <div className="Stoct_Product_master_form_row_div">
                                        <label className="Stoct_Product_master_form_Label" htmlFor="uom">Total Quantity :</label>
                                        <input
                                            type="number"
                                            name="TotalQuantity"
                                            placeholder="Enter Total Quantity"
                                            value={formDataPurchase.TotalQuantity}
                                            onChange={handleInputChange}
                                            className='Stoct_Product_master_form_Input'
                                            readOnly
                                        />
                                    </div>
                                </div>
                            }


                            <div className='Stoct_Product_master_form_Save'>

                                <button className="Stoct_Product_master_form_Save_button" onClick={handleCancel}>
                                    Clear
                                </button>
                                {!IndexEdit ?
                                    <button className="Stoct_Product_master_form_Save_button" onClick={handleAddToCart}>
                                        Add
                                    </button>
                                    :
                                    <button className="Stoct_Product_master_form_Save_button" onClick={handleUpdateCartItem}>
                                        Update
                                    </button>
                                }
                            </div>
                        </div>
                    </div>





                    {cartItems.length !== 0 &&
                        <div className="Stoct_Product_master_form_table_container">
                            <div className="Stoct_Product_master_form_table_container_11">

                                {formDataPurchaseInvoice.ProductCategory === 'Medical' && <div className="Stoct_Product_master_form_table_container_11">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                <th>Generic Name</th>
                                                <th>Strength</th>
                                                <th>UOM</th>
                                                <th>Pack</th>
                                                <th>Pack Quantity</th>
                                                <th>Order Quantity</th>
                                                <th>Total Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.ItemCode}</td>
                                                    <td>{item.ItemName}</td>
                                                    <td>{item.GenericName}</td>
                                                    <td>{item.Strength}</td>
                                                    <td>{item.UOM}</td>
                                                    <td>{item.PackIn}</td>
                                                    <td>{item.PackQuantity}</td>
                                                    <td>{item.OrderQuantity}</td>
                                                    <td>{item.TotalQuantity}</td>
                                                    <td>
                                                        <button onClick={() => handleEditCartItem(item.ItemCode)}>edit</button>
                                                        <button onClick={() => handleDeleteCartItem(item.ItemCode)}>delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                }
                                {formDataPurchaseInvoice.ProductCategory !== 'Medical' && <div className="Stoct_Product_master_form_table_container_11">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Item Code</th>
                                                <th>Item Name</th>
                                                <th>Pack</th>
                                                <th>Pack Quantity</th>
                                                <th>Order Quantity</th>
                                                <th>Total Quantity</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {cartItems.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.ItemCode}</td>
                                                    <td>{item.ItemName}</td>
                                                    <td>{item.PackIn}</td>
                                                    <td>{item.PackQuantity}</td>
                                                    <td>{item.OrderQuantity}</td>
                                                    <td>{item.TotalQuantity}</td>
                                                    <td>
                                                        <button onClick={() => handleEditCartItem(item.ItemCode)}>edit</button>
                                                        <button onClick={() => handleDeleteCartItem(item.ItemCode)}>delete</button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>}

                            </div>
                        </div>
                    }
                    {cartItems.length !== 0 &&
                        <div className="button_buton_class">
                            <button className="btn-add" onClick={handleSubmit}>
                                Save
                            </button>
                        </div>
                    }

                </div>
                :
                forPrintData()
            }
        </>
    );
}

export default PurchaseRaiseMaster;
