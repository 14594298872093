import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SupplierDetails( ) {

  const  Supplierpay = useSelector(state=>state.userRecord?.Supplierpay)

  const userRecord = useSelector(state=>state.userRecord?.UserData)

  
  const [SupplierForms,setSupplierForms]=useState({
    ProductType:'',
    SupplierCode:'',
    SupplierName:'',
    Supplier_Bill_Date:'',
    GRNInvoice:'',
    GRNDate:'',
    GRNDueDate:'',
    GRNInvoiceAmount:'',
    GRNPaidAmount:'',
    GRNBalanceAmount:'',
    PayableAmount:'',
    Balance:'',
    PaidDate:'',
    PaymentMethod:'',
    PaymentDetials:''
  })
  const navigate=useNavigate();

  useEffect(() => {
    console.log(Supplierpay);
    console.log(!Supplierpay);
  
    if (Supplierpay && Object.keys(Supplierpay).length !== 0) {
      setSupplierForms((prev) => ({
        ...prev,
        ...Supplierpay,
      }));
    }
  }, [Supplierpay]);
  

const handleInputChange = (e) => {
  const { name, value } = e.target;

  if (name === 'PayableAmount') {
    const parsedValue = parseFloat(value) ||0;
    const grnBalanceAmount = parseFloat(SupplierForms.GRNBalanceAmount);

    if (!isNaN(parsedValue) && !isNaN(grnBalanceAmount) && parsedValue <= grnBalanceAmount) {
      setSupplierForms((prev) => ({
        ...prev,
        [name]: parsedValue,
        Balance: (grnBalanceAmount - parsedValue).toFixed(3),
      }));
    } else {
      alert(`Please enter a valid number below or equal to the GRN Balance Amount ${SupplierForms.GRNBalanceAmount}`);
    }
  } else if(name==='PaymentMethod') {
    if(value === 'Cash'){
      setSupplierForms((prev) => ({
        ...prev,
        [name]: value,
        PaymentDetials:'Nill'
      }));
    }else{
      setSupplierForms((prev) => ({
        ...prev,
        [name]: value,
        PaymentDetials:''
      }));
    }
  }else if( name==='PaymentDetials'){
    if(SupplierForms.PaymentMethod===''){
      alert('please select the payment method')
    }else{
      setSupplierForms((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  }
};


  const handleAdd = () => {
    let requiredfields=[
      'PayableAmount',
      'PaidDate',
      'PaymentMethod',
    ]
    if(SupplierForms.PaymentMethod!=='Cash'||SupplierForms.PaymentMethod!==''){
      requiredfields.push('PaymentDetials');
    }
    const missingFields = requiredfields.filter((field) => !SupplierForms[field]); 
   if(SupplierForms.ProductType!=='Cash' &&SupplierForms.SupplierCode!==''){
    if (missingFields.length === 0) {
      const data ={
        ...SupplierForms,
        Location:userRecord?.location,
        CreatedBy:'Admin'
      }
      axios.post(`http://127.0.0.1:8000/SupplierMaster/insert_supplier_pay_By_Date`,data)
        .then((response)=>{
          console.log(response)
          navigate('/Home/Supplier-Stock-Manager')
        })
        .catch((error)=>{
          console.log(error)
        })
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(', ')}`);
    }
   }else{

   }
  };

  return (
    <>
      <Helmet>
        <title>Supplier Details</title>
      </Helmet>
      <div className="h_head">
        <h3>Supplier Details</h3>
      </div>
      <div className='Stoct_Product_master_Container'>
        <div className='Stoct_Product_master_form'>
          <div className="Stoct_Product_master_form_row">
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Product Type :</label>
              <input
                type="text"
                placeholder="Enter Supplier Code"
                value={SupplierForms.ProductType}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Supplier Code :</label>
              <input
                type="text"
                name="SupplierCode"
                placeholder="Enter Supplier Code"
                value={SupplierForms.SupplierCode}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>


            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="code">Supplier Name :</label>
              <input
                type="text"
                name="SupplierName"
                placeholder="Enter Supplier Name"
                value={SupplierForms.SupplierName}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
                autoComplete="off"
              />
            </div>



            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="itemName">Supplier Phone No :</label>
              <input
                type="date"
                name="Supplier_Bill_Date"
                placeholder="Enter Supplier Phone No"
                value={SupplierForms.Supplier_Bill_Date}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
                autoComplete="off"
              />
            </div>

          </div>
          <div className="Stoct_Product_master_form_row">

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Invoice :</label>
              <input
                type="text"
                name="GRNInvoice"
                placeholder="Enter GRN Invoice No"
                value={SupplierForms.GRNInvoice}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Date :</label>
              <input
                type="date"
                name="GRNDate"
                placeholder="Enter GRN Date "
                value={SupplierForms.GRNDate}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Bill Due Date  :</label>
              <input
                type="date"
                name="GRNDueDate"
                placeholder="Enter GRN Due Date "
                value={SupplierForms.GRNDueDate}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Invoice Amount :</label>
              <input
                type="number"
                name="GRNInvoiceAmount"
                placeholder="Enter GRN Invoice Amount "
                value={SupplierForms.GRNInvoiceAmount}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>

          </div>
          <div className="Stoct_Product_master_form_row">

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Paid Amount :</label>
              <input
                type="number"
                name="GRNPaidAmount"
                placeholder="Enter GRN Paid Amount"
                value={SupplierForms.GRNPaidAmount}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">GRN Balance Amount :</label>
              <input
                type="number"
                name="GRNBalanceAmount"
                placeholder="Enter GRN Balance Amount "
                value={SupplierForms.GRNBalanceAmount}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Payable Amount :</label>
              <input
                type="number"
                name="PayableAmount"
                placeholder="Enter Payable Amount "
                value={SupplierForms.PayableAmount}
                onChange={handleInputChange}
                required
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Balance :</label>
              <input
                type="number"
                name="Balance"
                placeholder="Enter BalanceAmount "
                value={SupplierForms.Balance}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>

          </div>
          <div className="Stoct_Product_master_form_row">

            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Paid Date  :</label>
              <input
                type="date"
                name="PaidDate"
                placeholder="Enter PaidDate"
                value={SupplierForms.PaidDate}
                onChange={handleInputChange}
                readOnly
                className='Stoct_Product_master_form_Input'
              />
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Payment Method :</label>
              
              <select
              name='PaymentMethod'
              value={SupplierForms.PaymentMethod}
              onChange={handleInputChange}
              >
                <option value=''>Select</option>
                <option value='Cash'>Cash</option>
                <option value='Check'>Check</option>
                <option value='Online'>Online Transaction</option>
                <option value='Card'>Card</option>
              </select>
            </div>
            <div className="Stoct_Product_master_form_row_div">
              <label className="Stoct_Product_master_form_Label" htmlFor="ProductType">Payment Detials :</label>
              <input
                type="text"
                name="PaymentDetials"
                placeholder="Enter Payment Detials "
                value={SupplierForms.PaymentDetials}
                onChange={handleInputChange}
                required
                readOnly={SupplierForms.PaymentDetials ==='Nill'}
                className='Stoct_Product_master_form_Input'
              />
            </div>

          </div>

        </div>
      </div>
      <div className='Stoct_Product_master_form_Save'>

            <button className="Stoct_Product_master_form_Save_button" onClick={handleAdd}>
                Submit
            </button>
        </div>
     
    </>
  );
}

export default SupplierDetails;
