import React, { useState, useEffect } from "react";
import "./UserRegister.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import Checkbox from "@material-ui/core/Checkbox";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    width: 250,
    border: "none",
  },

  indeterminateColor: {
    color: "#f50057",
  },
  selectedCheckbox: {
    color: "#000", // Set your desired color here
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

const ITEM_HEIGHT = 58;
const ITEM_PADDING_TOP = 1;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "center",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "center",
  },
  variant: "menu",
};

const options = [
  "ClinicMetrics",
  "FrontOffice",
  "Nurse",
  "DoctorWorkbench",
  "Counselor",
  "Therapist",
  "Pharmacy",
  "Cashier",
  "PettyCash",
  "StockManagement",
  "UserControl",
  "HRManagement",
  "Visiting_Doctor",
  "EmployeeRequest",
];

const suboptions =
{
  'ClinicMetrics': [
  ],
  "FrontOffice": ["AppointmentCalendar",
    "AppoinmentRequest",
    "BookingConfimation",
    "OPRegister",
    "ConcernForms",
    "LabReport"
  ]
  ,

  "Nurse": [
    "ConcernForms",
    "PatientQueueList "]
  ,
  "DoctorWorkbench": [
    "PatientList",
    "PatientQueueList"
  ],
  "Counselor": [
    "PatientList",
    "AppointmentCalendar ",
    "PatientQueueList",
    "CounselorQueueList"
  ],
  "Therapist": [
    "PatientList",
    "PatientQueueList"
  ],
  "Pharmacy": [
    "PharmacyBilling",
    "BillCancellation/Refund",
    "BillingHistory",
    "ShiftClosing",
    "DayClosing",
    "Report"
  ],
  "Cashier": [
    "Billing",
    "DueHistory ",
    "BillCancellation",
    "BillingHistory",
    "ShiftClosing",
    "DayClosing",
    "Report "
  ],
  "PettyCash": [
    "ExpenseMaster",
    "CashExpenses",
    "DigitalExpenses",
    "ExpensesReport",
    "HandOverSummary",
    "DayReport"
  ],
  "StockManagement": [
    "QuickStock",
    "Supplierpay",
    "SupplierMaster",
    "ProductMaster",
    "PurchaseMaster",
    "GRN",
    "IndentApprove",
    "GRNApprove",
    "IndentRaise",
    "IndentRecieve",
    "IndentIssue"
  ],
  "UserControl": [
    "RoleManagement ",
    "EmployeeQueueList",
    "UserRegister",
    "RatecardCharges",
    "AccountSettings ",
    "ClinicDetails",
    "UserList",
    "LeaveManagement",
    "AdvanceManagement",
    "VisitDoctorBilling"
  ],
  "HRManagement": [
    "EmployeeRegister",
    "EmployeeList",
    "Attendance",
    "LeaveApproval",
    "AdvanceApproval",
    "PerformanceAppraisal",
    "PerformanceManagement",
    "LeaveManagement",
    "AdvanceManagement",
    "PayRoll"
  ],
  "VisitingDoctor": [
    "VisitingDoctorPatients",
  ],
  "EmployeeRequest": [
    "LeaveManagement",
    "AdvanceManagement"
  ]
}


function UserRegister( ) {

  const foruserregisteremployeedata = useSelector(state=>state.userRecord?.foruserregisteremployeedata)

  const userRecord = useSelector(state=>state.userRecord?.UserData)

  const foredituserregisteremployeedata = useSelector(state=>state.userRecord?.foredituserregisteremployeedata)

  const navigate = useNavigate();

  console.log(userRecord);
  console.log(foruserregisteremployeedata);
  const [roleOptions, setRoleOptions] = useState([]);
  const [locationoptions, setlocationOptions] = useState([]);

  const [formData, setFormData] = useState({
    username: "",
    employeeid: "",
    firstName: "",
    lastName: "",
    Title: "",
    PhoneNo: "",
    email: "",
    password: "",
    roleName: "",
    locationname: "",
    gender: "",
    qualification: "",
  });

  useEffect(() => {
    // Check if foruserregisteremployeedata is not empty
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
      });
    }
  }, [foruserregisteremployeedata]);

  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [availableSubOptions, setAvailableSubOptions] = useState([]);
  const [selectedSubOptions, setSelectedSubOptions] = useState([]);
  const isAllSelected =
    options.length > 0 && selected.length === options.length;

  const isAllSubSelected =
    selectedSubOptions.length === Object.values(suboptions).flat().length;

  const handleChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "all") {
      setSelected(selected.length === options.length ? [] : options);
    } else {
      setSelected(value);
    }
  };

  useEffect(() => {
    fetchRoleOptions();
    fetchlocationOptions();
    fetchUserList();
  }, []);

  useEffect(() => {
    // Check if foruserregisteremployeedata is not empty
    if (foruserregisteremployeedata && foruserregisteremployeedata.length > 0) {
      const employeeData = foruserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
        // ... other fields
      });
    }
  }, [foruserregisteremployeedata]);

  useEffect(() => {
    // Check if foredituserregisteremployeedata is not empty
    if (
      foredituserregisteremployeedata &&
      foredituserregisteremployeedata.length > 0
    ) {
      const employeeData = foredituserregisteremployeedata[0]; // Assuming employee data is at index 0

      // Update the form data state with the fetched data
      setFormData({
        ...formData,
        employeeid: employeeData.EmployeeID,
        username: employeeData.Username,
        firstName: employeeData.EmployeeName,
        lastName: employeeData.FatherName,
        Title: employeeData.Title,
        PhoneNo: employeeData.PhoneNumber,
        email: employeeData.Email,
        roleName: employeeData.Designation,
        locationname: employeeData.Locations,
        gender: employeeData.Gender,
        qualification: employeeData.Qualification,
        // ... other fields
      });
    }
  }, [foredituserregisteremployeedata]);

  useEffect(() => {
    let newSubOptions = [];

    if (selected.length === options.length) {
      Object.keys(suboptions).forEach((option) => {
        newSubOptions = [...newSubOptions, ...suboptions[option]];
      });
      setSelectedSubOptions(newSubOptions);
    } else {
      selected.forEach((selectedOption) => {
        if (suboptions[selectedOption]) {
          newSubOptions = [...newSubOptions, ...suboptions[selectedOption]];
        }
      });
      setAvailableSubOptions(newSubOptions);

      const currentSelectedSubOptions = selectedSubOptions.filter((subOption) =>
        newSubOptions.includes(subOption)
      );
      setSelectedSubOptions(currentSelectedSubOptions);
    }
  }, [selected, suboptions, options.length]);

  const handleSubOptionChange = (event) => {
    const value = event.target.value;

    if (value[value.length - 1] === "All") {
      setSelectedSubOptions(
        selectedSubOptions.length === availableSubOptions.length
          ? []
          : availableSubOptions
      );
    } else {
      setSelectedSubOptions(value);
    }
  };

  useEffect(() => {
    // Fetch role options from the backend and update state
    fetchRoleOptions();
    fetchlocationOptions();
    // Log all user data when the page reloads
    fetchUserList();
  }, []);

 
  const fetchlocationOptions = () => {
    // Fetch role options from the backend
    axios
      .get("http://127.0.0.1:8000/usercontrol/getlocationdata")
      .then((response) => {
        console.log(response);
        const data = response.data;
        if (data) {
          setlocationOptions(data);
        }
      })
      .catch((error) => {
        console.error("Error fetching Location options:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetchUserList()
      .then((usernames) => {
        if (usernames === null) {
          // Handle the case when usernames are null
        } else if (usernames.includes(formData.username)) {
          userwarn();
          setFormData({ ...formData, username: "" });
        } else {
          const datatosend = {
            ...formData,
            selected: selected,
            subselected: selectedSubOptions,
          };
          console.log(datatosend)

          const url = "http://127.0.0.1:8000/usercontrol/insert_userregister";
          axios
            .post(url, datatosend)
            .then((response) => {
              if (response.data.message) {
                
                successMsg(response.data.message)
                setFormData({
                  username: "",
                  employeeid: "",
                  firstName: "",
                  lastName: "",
                  Title: "",
                  PhoneNo: "",
                  email: "",
                  password: "",
                  roleName: "",
                  locationname: "",
                  gender: "",
                  qualification: "",

                });

                fetchRoleOptions();

navigate('/Home/Employee-List')

              } else {
                console.log(response.data);
              }
            })
            .catch((error) => {
              console.error("Error:", error);
            });
        }
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  };

  const fetchUserList = async () => {
    try {
      const response = await axios.get(
        "http://127.0.0.1:8000/usercontrol/userRegistercheck"
      );
      const data = response.data;
      console.log(data);
      return data.usernames; // Return only the 'usernames' array from the response data
    } catch (error) {
      console.error("Error fetching user data:", error);
      return [];
    }
  };

  const fetchRoleOptions = () => {
    // Fetch role options from the backend
    axios
      .get("http://127.0.0.1:8000/usercontrol/getRoleData")
      .then((response) => {
        console.log(response);
        setRoleOptions(response.data);
      })
      .catch((error) => {
        console.error("Error fetching role options:", error);
      });
  };
  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      containerId: "toast-container-over-header",
      style: { marginTop: "50px" },
    });
  };
  const fail = () => {
    toast.warn("User Registration Failed", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const userwarn = () => {
    toast.warn("Username already Exists", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const handleinpchange=(e)=>{
    const {name,value}=e.target
    if(name==='PhoneNo'){
      if(value.length <= 10){
        setFormData((prev)=>({
          ...prev,
          [name]:value
    
        }))
      }
    }else{
    setFormData((prev)=>({
      ...prev,
      [name]:value

    }))
  }}
  
  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h3>User Registration</h3>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
            padding: "0px 10px",
            boxSizing: "border-box",
          }}
        >
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="username">Employee Id<span>:</span></label>
              <input
                type="text"
                id="employeeid"
                name="employeeid"
                value={formData.employeeid}
                onChange={handleinpchange}
                placeholder="Enter your Employee Id"
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="username">Username<span>:</span></label>
              <input
                type="text"
                id="username"
                name="username"
                value={formData.username}
                onChange={handleinpchange}
                placeholder="Enter your username"
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="password">Password<span>:</span></label>
              <input
                type="password"
                id="password"
                name="password"
                value={formData.password}
                onChange={handleinpchange}
                placeholder="Enter your Password"
                required
              />
            </div>
          </div>
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="username">Title<span>:</span></label>
              <input
                name="Title"
                value={formData.Title}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="firstName">First Name<span>:</span></label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                placeholder="Enter your First Name"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="lastName">Last Name<span>:</span></label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleinpchange}
                pattern="[A-Za-z ]+"
                title="Only letters and spaces are allowed"
                placeholder="Enter your Last Name"
                required
              />
            </div>
          </div>

          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="Dateofbirth">Gender<span>:</span></label>
              <input
                type="text"
                value={formData.gender}
                onChange={handleinpchange}
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="PhoneNo">Phone No<span>:</span></label>
              <input
                type="number"
                name="PhoneNo"
                value={formData.PhoneNo}
                onChange={handleinpchange}
                placeholder="Enter your Phone No"
                required
              />
            </div>
            <div className="RegisForm_1">
              <label htmlFor="Qualification">Qualification<span>:</span></label>
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleinpchange}
                placeholder="Enter your Qualification"
                required
              />
            </div>
          </div>
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="email">Email<span>:</span></label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleinpchange}
                placeholder="Enter your Email"
                pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                required
              />
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">Location<span>:</span></label>
              <select
                name="locationname"
                value={formData.locationname}
                onChange={handleinpchange}
              >
                {/* Render an option for each location option */}
                {locationoptions.map((location, index) => (
                  <option key={index} value={location.location_name}>
                    {location.location_name}
                  </option>
                ))}
              </select>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">Role<span>:</span></label>
              <input
                name="role"
                value={formData.roleName}
                onChange={handleinpchange}
              />
            </div>
          </div>
          <div className="RegisFormcon">
            <div className="RegisForm_1">
              <label htmlFor="role">Access:</label>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="mutiple-select-label"
                  multiple
                  placeholder="fffggff"
                  value={selected}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(", ")}
                  MenuProps={MenuProps}
                >
                  <MenuItem
                    value="all"
                    classes={{
                      root: isAllSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          indeterminate: classes.indeterminateColor,
                          checked: classes.selectedCheckbox,
                        }}
                        checked={isAllSelected}
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < options.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>
                  {options.map((option) => (
                    <MenuItem key={option} value={option}>
                      <ListItemIcon>
                        <Checkbox
                          classes={{
                            checked: classes.selectedCheckbox,
                          }}
                          checked={selected.indexOf(option) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="RegisForm_1">
              <label htmlFor="role">Access for Sub:</label>
              <FormControl className={classes.formControl}>
                <Select
                  labelId="mutiple-sub-select-label"
                  multiple
                  value={selectedSubOptions}
                  onChange={handleSubOptionChange}
                  renderValue={(selected) => selected.join(", ")}
                >
                  <MenuItem
                    value="All"
                    classes={{
                      root: isAllSubSelected ? classes.selectedAll : "",
                    }}
                  >
                    <ListItemIcon>
                      <Checkbox
                        classes={{
                          indeterminate: classes.indeterminateColor,
                          checked: classes.selectedCheckbox,
                        }}
                        checked={isAllSubSelected}
                        indeterminate={
                          selectedSubOptions.length > 0 &&
                          selectedSubOptions.length <
                          Object.values(suboptions).flat().length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText
                      classes={{ primary: classes.selectAllText }}
                      primary="Select All"
                    />
                  </MenuItem>

                  {availableSubOptions.map((subOption) => (
                    <MenuItem key={subOption} value={subOption}>
                      <Checkbox
                        checked={selectedSubOptions.includes(subOption)}
                      />
                      <ListItemText primary={subOption} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="RegisForm_1" id="hide_div_regg"></div>
          </div>

          <div className="Register_btn_con" style={{ marginTop: "20px" }}>
            <button onClick={handleSubmit} className="RegisterForm_1_btns">
              Submit
            </button>
          </div>
        </div>
      </div>

      <ToastContainer
        position="top-center"
        containerId="toast-container-over-header"
      />
    </>
  );
}

export default UserRegister;
