import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './SideBar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import {
  faUsers, faHeadset, faStethoscope, faClipboard, faFileMedical, faMoneyBillAlt, faRightFromBracket, faPenNib, faLayerGroup, faUserDoctor,
  faUserNurse, faSackDollar, faUserNinja, faUserTag, faChartBar, faAngleDown, faBuildingColumns,faStore , faSuitcaseMedical ,faThumbsUp ,faIndent , faRegistered
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';

function Sidebar({ }) {



  const [openSubMenu, setOpenSubMenu] = useState(null);
  const [mainAccess, setMainAccess] = useState([]);
  const [subAccess, setSubAccess] = useState([]);
  const sidebarRef = useRef(null);
  const navigate = useNavigate();

  // const handleSidebarToggle = () => {
  //   setSidebarOpen(!isSidebarOpen);
  // };
  const location = useLocation();

  const dispatchvalue  = useDispatch();

  const isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  useEffect(() => {
    dispatchvalue({type:'isSidebarOpen',value:false})
    // Close the sidebar when the route changes
    console.log(location);
    const userRecord = localStorage.getItem("token")
    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      const setAccess1 = (decodedTokenData && decodedTokenData.Access_one.split(',').map(item => item.trim())) || [];
      const setAccess2 = (decodedTokenData && decodedTokenData.Access_two.split(',').map(item => item.trim())) || [];

      setMainAccess(setAccess1);
      setSubAccess(setAccess2);

    };
    // Split the string and then trim each element
  }, [location.pathname]);

  // console.log(subAccess);
  // console.log(mainAccess)
  useEffect(() => {
    // Disable text selection on the entire document
    const disableTextSelection = () => {
      document.body.style.userSelect = 'none';
      document.body.style.MozUserSelect = 'none';
      document.body.style.msUserSelect = 'none';
    };
    disableTextSelection();
    // Enable text selection when the component is unmounted
    return () => {
      document.body.style.userSelect = 'auto';
      document.body.style.MozUserSelect = 'auto';
      document.body.style.msUserSelect = 'auto';
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // This effect runs once when the component mounts


  const handleSubMenuClick = (subMenu) => {
    setOpenSubMenu(openSubMenu === subMenu ? null : subMenu);

  };


  const handleLogoutClick = () => {
    navigate('/')
    localStorage.clear()
  };



  return (
    <nav id="inventory_sidebar" className={isSidebarOpen ? 'inventory_sidebar_open' : ''} ref={sidebarRef}>

      <div className="inv_sidebar_header" onMouseEnter={() => dispatchvalue({type:'isSidebarOpen',value:true})} onMouseLeave={() => dispatchvalue({type:'isSidebarOpen',value:false})}>
        <div className="inv_components_sidebar">

          {mainAccess.includes("ClinicMetrics") && <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} id='font_111'>
            <FontAwesomeIcon icon={faStethoscope} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" onClick={(() => { navigate("/Home/ClinicMetrics") })}>Clinic Metrics</span>}
            {/* {isSidebarOpen && (
              <FontAwesomeIcon
                icon={faAngleDown}
                className={`arrow-icon ${openSubMenu === 'subsidebarmenu' ? 'arrow-rotate' : ''}`}
              />
            )} */}
          </div>}

          {mainAccess.includes("FrontOffice") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu1')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUsers} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Front Office</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu1' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu1' && <div className="subSidebarmenuhover">
              {subAccess.includes("AppoinmentRequest") && <div onClick={(() => { navigate("/Home/Appoinment-RequestList") })}> {isSidebarOpen && <span className="icon-name" >Appoinment Request</span>}</div>}
              {subAccess.includes("BookingConfimation") && <div onClick={(() => { navigate("/Home/Appoinment-RegisterList") })}> {isSidebarOpen && <span className="icon-name" >Appointment Register</span>}</div>}
              {subAccess.includes("Followuplist") && <div onClick={(() => { navigate("/Home/Followuplist") })}>{isSidebarOpen && <span className="icon-name" >Follow-up List</span>}</div>}
              {/* {subAccess.includes("OPRegister") && <div onClick={(() => { navigate("/Home/Register-Patient2"); dispatchvalue({type:'registerdata',value:[]}); dispatchvalue({type:'forpatienteedit',value:[]}) })}>{isSidebarOpen && <span className="icon-name" >OP Register</span>}</div>} */}
              {subAccess.includes("ConcernForms") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Consent Forms</span>}</div>}
              {subAccess.includes("LabReport") && <div onClick={(() => { navigate("/Home/Lab-QueueList") })}>{isSidebarOpen && <span className="icon-name" >Lab Report</span>}</div>}
              {subAccess.includes("patientListUpdate") && <div onClick={(() => { navigate("/Home/Patient-List-update") })}>{isSidebarOpen && <span className="icon-name" >Patient Management</span>}</div>}
              

            </div>}
          </div>}


          {mainAccess.includes("Nurse") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu2')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNurse} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Nurse</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu2' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu2' && <div className="subSidebarmenuhover">
              {/* {subAccess.includes("ConcernForms") && <div onClick={(() => { navigate("/Home/Register_concern_List") })}>{isSidebarOpen && <span className="icon-name" >Concern Forms</span>}</div>} */}
              {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-QueueList3") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("DoctorWorkbench") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu3')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Doctor's WorkBench</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu3' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu3' && <div className="subSidebarmenuhover">
              {subAccess.includes("PatientList") && <div onClick={(() => { navigate("/Home/Patient-List") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>}
              {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-QueueList") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
            </div>}
          </div>}


          {/* {mainAccess.includes("Counselor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faHeadset} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Counselor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover">
              {subAccess.includes("PatientList") && <div onClick={(() => { navigate("/Home/Patient-List2") })}>{isSidebarOpen && <span className="icon-name" >Patient List</span>}</div>}
              {subAccess.includes("AppointmentCalendar") && <div onClick={(() => { navigate("/Home/Appointment-Calendar") })}> {isSidebarOpen && <span className="icon-name" >Appointment Calendar </span>}</div>}
              {subAccess.includes("PatientQueueList") && <div onClick={(() => { navigate("/Home/Treament-CounselorQueueList") })}> {isSidebarOpen && <span className="icon-name" >Patient Queue List </span>}</div>}
              {subAccess.includes("CounselorQueueList") && <div onClick={(() => { navigate("/Home/Treament-CounselorFullList") })}> {isSidebarOpen && <span className="icon-name" >Counselor Queue List</span>}</div>}
            </div>}
          </div>} */}

          {mainAccess.includes("WardManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu4')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserDoctor} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Ward Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu4' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu4' && <div className="subSidebarmenuhover">
                <div onClick={(() => { navigate("/Home/IpRegistrationlist") })}>{isSidebarOpen && <span className="icon-name" >IP Admission List</span>}</div>
                <div onClick={(() => { navigate("/Home/IP-Registration1");dispatchvalue({type:'IpAdmissionEdit',value:null}) })}>{isSidebarOpen && <span className="icon-name" >IP Admission</span>}</div>
                <div onClick={(() => { navigate("/Home/IpNurseQuelist") })}>{isSidebarOpen && <span className="icon-name" >IP Nurse Que List</span>}</div>
                <div onClick={(() => { navigate("/Home/IpDocQuelist") })}>{isSidebarOpen && <span className="icon-name" >IP Doctor Que List</span>}</div>
                <div onClick={(() => { navigate("/Home/Room-Management") })}> {isSidebarOpen && <span className="icon-name" >Room Management</span>}</div>
                {/* <div onClick={(() => { navigate("/Home/Ward-Management") })}> {isSidebarOpen && <span className="icon-name" >Ward Management</span>}</div> */}
                <div onClick={(() => { navigate("/Home/OT-Management") })}> {isSidebarOpen && <span className="icon-name" >OT Management</span>}</div>
            </div>}
</div>}


    


          {mainAccess.includes("Pharmacy") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu6')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faFileMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Pharmacy</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu6' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu6' && <div className="subSidebarmenuhover">
              {subAccess.includes("PharmacyBilling") && <div onClick={(() => { navigate("/Home/Pharmacy_Billing_List") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Billing</span>}</div>}

              {/* <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div> */}
              {subAccess.includes("BillCancellation/Refund") && <div onClick={(() => { navigate("/Home/Pharmacy_Billcancelation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation/Refund</span>}</div>}
              {subAccess.includes("BillingHistory") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>}

            </div>}
          </div>}


          {mainAccess.includes("Cashier") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu7')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faMoneyBillAlt} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Cashier</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu7' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu7' && <div className="subSidebarmenuhover">
              {subAccess.includes("Billing") && <div onClick={(() => { navigate("/Home/Billing-Invoice") })}> {isSidebarOpen && <span className="icon-name" >Billing </span>}</div>}

              {subAccess.includes("DueHistory") && <div onClick={(() => { navigate("/Home/Deu-History-List") })}> {isSidebarOpen && <span className="icon-name" >Due History </span>}</div>}
              {subAccess.includes("BillCancellation") && <div onClick={(() => { navigate("/Home/Bill-Cancellation") })}> {isSidebarOpen && <span className="icon-name" >Bill Cancellation</span>}</div>}
              {subAccess.includes("BillingHistory") && <div onClick={(() => { navigate("/Home/Billing-History") })}>{isSidebarOpen && <span className="icon-name" >Billing History</span>}</div>}

           
            </div>}
          </div>}

          {mainAccess.includes("PettyCash") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu8")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faSackDollar}
                  className="inventory_sidebar_icon"
                />
                {isSidebarOpen && <span className="icon-name">Petty Cash</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${openSubMenu === "subsidebarmenu8" ? "arrow-rotate" : ""
                      }`}
                  />
                )}
              </div>
              {isSidebarOpen && openSubMenu === "subsidebarmenu8" && (
                <div className="subSidebarmenuhover">
                  {subAccess.includes("ExpenseMaster") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Expenses-Master");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Expense Master</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("CashExpenses") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Cash Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DigitalExpenses") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Digital-Expenses");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Digital Expenses </span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("ExpensesReport") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Cash-Expenses-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Expenses Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("HandOverSummary") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Hand-Over-Summary");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">HandOver Summary</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DayReport") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Book-Report");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing Report</span>
                      )}
                    </div>
                  )}
                  {subAccess.includes("DayClosing") && (
                    <div
                      onClick={() => {
                        navigate("/Home/Day-Closing");
                      }}
                    >
                      {" "}
                      {isSidebarOpen && (
                        <span className="icon-name">Day Closing</span>
                      )}
                    </div>
                  )}
                </div>
              )}
            </div>
          )}


{mainAccess.includes("Indent") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu15')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faLayerGroup} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu15' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu15' && <div className="subSidebarmenuhover">
            {subAccess.includes("Indent_Raise_list") && <div onClick={(() => { navigate("/Home/Indent_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Raise </span>}</div>}
            {subAccess.includes("Indent_Recieve_list") && <div onClick={(() => { navigate("/Home/Indent_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve </span>}</div>}
            {subAccess.includes("Indent_Return_list") && <div onClick={(() => { navigate("/Home/Indent_Return_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Return</span>}</div>}

            {subAccess.includes("IndentMovementlist") && <div onClick={(() => { navigate("/Home/IndentMovementlist") })}> {isSidebarOpen && <span className="icon-name" >Indent Move </span>}</div>}

            {subAccess.includes("Quick_Stock_Recieve_List") && <div onClick={(() => { navigate("/Home/Quick_Stock_Recieve_List") })}> {isSidebarOpen && <span className="icon-name" >Quick Stock Recieve</span>}</div>}

            {subAccess.includes("LocationStock_List") && <div onClick={(() => { navigate("/Home/LocationStock_List") })}> {isSidebarOpen && <span className="icon-name" >Location Stock_List </span>}</div>}
            {subAccess.includes("PurchaseReturn_Loc_Master") && <div onClick={(() => { navigate("/Home/PurchaseReturn_Loc_Master") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}

             
            </div>}
          </div>}


          { mainAccess.includes("Central") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu16')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faStore} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Central Store</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu16' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu16' && <div className="subSidebarmenuhover">
             
            {subAccess.includes("Purchase_Raise_list") && <div onClick={(() => { navigate("/Home/Purchase_Raise_list") })}> {isSidebarOpen && <span className="icon-name" >Purchase Master </span>}</div>}
            {subAccess.includes("Purchase_Recieve_list") && <div onClick={(() => { navigate("/Home/Purchase_Recieve_list") })}> {isSidebarOpen && <span className="icon-name" >GRN Recieve</span>}</div>}
            {subAccess.includes("Indent_Issue_list") && <div onClick={(() => { navigate("/Home/Indent_Issue_list") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue </span>}</div>}

            {subAccess.includes("PurchaseReturn_Master") && <div onClick={(() => { navigate("/Home/PurchaseReturn_Master") })}> {isSidebarOpen && <span className="icon-name" >Purchase Return </span>}</div>}
            {subAccess.includes("CentralStock_List") && <div onClick={(() => { navigate("/Home/CentralStock_List") })}> {isSidebarOpen && <span className="icon-name" >Central Stock_List </span>}</div>}
            {subAccess.includes("Supplier-Stock-Manager") && <div onClick={(() => { navigate("/Home/Supplier") })}> {isSidebarOpen && <span className="icon-name" >Supplier pay </span>}</div>}


          
            </div>}
          </div>}
          { mainAccess.includes("Masters") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu17')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faSuitcaseMedical} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Inventory Master</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu17' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu17' && <div className="subSidebarmenuhover">
            
            {subAccess.includes("Supplier_List") && <div onClick={(() => { navigate("/Home/Supplier_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Master </span>}</div>}
            {subAccess.includes("Product_List") && <div onClick={(() => { navigate("/Home/Product_List") })}> {isSidebarOpen && <span className="icon-name" >Product Master </span>}</div>}
            {/* {subAccess.includes("Pharmacy_MasterList") && <div onClick={(() => { navigate("/Home/Pharmacy_MasterList") })}> {isSidebarOpen && <span className="icon-name" >Pharmacy Master </span>}</div>}
            {subAccess.includes("General_MasterList") && <div onClick={(() => { navigate("/Home/General_MasterList") })}> {isSidebarOpen && <span className="icon-name" >General Master </span>}</div>} */}

             
            </div>}
          </div>}

          { mainAccess.includes("CentralApprove") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu18')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faThumbsUp} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">CentralStore Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu18' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu18' && <div className="subSidebarmenuhover">
            
            {subAccess.includes("GRN_appprove_List") && <div onClick={(() => { navigate("/Home/GRN_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >GRN Approve </span>}</div>}
            {subAccess.includes("PurchaseApprove_List") && <div onClick={(() => { navigate("/Home/PurchaseApprove_List") })}> {isSidebarOpen && <span className="icon-name" >Purchase Approve </span>}</div>}
            {subAccess.includes("IndentIssue_appprove_List") && <div onClick={(() => { navigate("/Home/IndentIssue_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Issue Approve </span>}</div>}
            {subAccess.includes("IndentReturnApprove") && <div onClick={(() => { navigate("/Home/IndentReturnApprove") })}> {isSidebarOpen && <span className="icon-name" >Indent Return Approve </span>}</div>}


           
            </div>}
          </div>}

          { mainAccess.includes("IndentApprove") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu19')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faIndent} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Indent Approve</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu19' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu19' && <div className="subSidebarmenuhover">
            
            {subAccess.includes("Indent_appprove_List") && <div onClick={(() => { navigate("/Home/Indent_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Approve </span>}</div>}
            {subAccess.includes("IndentRecieve_appprove_List") && <div onClick={(() => { navigate("/Home/IndentRecieve_appprove_List") })}> {isSidebarOpen && <span className="icon-name" >Indent Recieve Approve </span>}</div>}

           
            </div>}
          </div>}

          { mainAccess.includes("Reports") &&   <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu20')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faRegistered} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Reports</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu20' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu20' && <div className="subSidebarmenuhover">
            
            {subAccess.includes("Supplier_Pay_List") && <div onClick={(() => { navigate("/Home/Supplier_Pay_List") })}> {isSidebarOpen && <span className="icon-name" >Supplier Pay List </span>}</div>}
            {subAccess.includes("PurchaseRegister") && <div onClick={(() => { navigate("/Home/PurchaseRegister") })}> {isSidebarOpen && <span className="icon-name" >Purchase Register </span>}</div>}
            {subAccess.includes("SalesRegister") && <div onClick={(() => { navigate("/Home/SalesRegister") })}> {isSidebarOpen && <span className="icon-name" >Sales Register </span>}</div>}

            
          </div>}
          </div>}


          {mainAccess.includes("HRManagement") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu10')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faPenNib} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">HR Management</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu10' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu10' && <div className="subSidebarmenuhover">
              {subAccess.includes("EmployeeRegister") && <div onClick={(() => { navigate("/Home/Employee-Register") })}>{isSidebarOpen && <span className="icon-name" >Employee Register</span>}</div>}
              {subAccess.includes("EmployeeList") && <div onClick={(() => { navigate("/Home/Employee-List") })}>{isSidebarOpen && <span className="icon-name" >Employee List</span>}</div>}
              {subAccess.includes("Attendance") && <div onClick={(() => { navigate("/Home/Employee-Attendance") })}>{isSidebarOpen && <span className="icon-name" >Attendance</span>}</div>}
              {subAccess.includes("LeaveApproval") && <div onClick={(() => { navigate("/Home/Employee-LeaveApproval") })}> {isSidebarOpen && <span className="icon-name" >Leave Approval </span>}</div>}
              {subAccess.includes("AdvanceApproval") && <div onClick={(() => { navigate("/Home/Employee-AdvanceApproval") })}> {isSidebarOpen && <span className="icon-name" >Advance Approval </span>}</div>}
              {subAccess.includes("PerformanceAppraisal") && <div onClick={(() => { navigate("/Home/Employee-Performance") })}> {isSidebarOpen && <span className="icon-name" >Performance Appraisal </span>}</div>}
              {subAccess.includes("PerformanceManagement") && <div onClick={(() => { navigate("/Home/Employee-PerformanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Performance Management </span>}</div>}

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}

              {subAccess.includes("PayRoll") && <div onClick={(() => { navigate("/Home/Employee-PayRoll") })}> {isSidebarOpen && <span className="icon-name" >Pay Roll </span>}</div>}
              {subAccess.includes("Dutymanagement") && <div onClick={(() => { navigate("/Home/Duty-Management") })}> {isSidebarOpen && <span className="icon-name" >Duty Management</span>}</div>}

              {/* <div onClick={(() => { navigate("/Home/Employee-PaySlip") })}> {isSidebarOpen && <span className="icon-name" >Pay Slip </span>}</div> */}
            </div>}
          </div>
          }
          {mainAccess.includes("VisitingDoctor") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu11')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserNinja} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Visiting Doctor</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu11' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu11' && <div className="subSidebarmenuhover">


              {subAccess.includes("VisitingDoctorPatients") && <div onClick={(() => { navigate("/Home/Patient-Visiting-Doctor") })}> {isSidebarOpen && <span className="icon-name" >Visiting Doctor Patients </span>}</div>}
            </div>}
          </div>}

          {mainAccess.includes("EmployeeRequest") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu12')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faUserTag} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">Employee Request</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu12' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu12' && <div className="subSidebarmenuhover">

              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/Navigation-leave") })}> {isSidebarOpen && <span className="icon-name" >Leave Management</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-LeaveStatus") })}> {isSidebarOpen && <span className="icon-name" >Leave Status </span>}</div> */}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Navigation-Advance") })}>{isSidebarOpen && <span className="icon-name" >Advance Management</span>}</div>}
              {/* <div onClick={(() => { navigate("/Home/Employee-AdvanceStatus") })}> {isSidebarOpen && <span className="icon-name" >Advance Status </span>}</div> */}

              {subAccess.includes("ShiftManagement") && <div onClick={(() => { navigate("/Home/Shift-Details") })}>{isSidebarOpen && <span className="icon-name" >Shift Management</span>}</div>}



            </div>}
          </div>}

          {mainAccess.includes("UserControl") && <div className="Sidebarmenuhover" onClick={() => handleSubMenuClick('subsidebarmenu13')}>
            <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'}>
              <FontAwesomeIcon icon={faChartBar} className='inventory_sidebar_icon' />
              {isSidebarOpen && <span className="icon-name">User Control</span>}
              {isSidebarOpen && (
                <FontAwesomeIcon
                  icon={faAngleDown}
                  className={`arrow-icon ${openSubMenu === 'subsidebarmenu13' ? 'arrow-rotate' : ''}`}
                />
              )}
            </div>
            {isSidebarOpen && openSubMenu === 'subsidebarmenu13' && <div className="subSidebarmenuhover">
            {!subAccess.includes("RoomMaster") && <div onClick={(() => { navigate("/Home/Room_Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master </span>}</div>}
            {!subAccess.includes("RoomMaster") && <div onClick={(() => { navigate("/Home/Frequency_Master") })}>{isSidebarOpen && <span className="icon-name" >Frequency Master </span>}</div>}
              {!subAccess.includes("RoomMaster") && <div onClick={(() => { navigate("/Home/Room_Create_Master") })}>{isSidebarOpen && <span className="icon-name" >Room Master Create </span>}</div>}
              {!subAccess.includes("Ipratecard") && <div onClick={(() => { navigate("/Home/IPratecard") })}>{isSidebarOpen && <span className="icon-name" >IP Ratecard</span>}</div>}
              {subAccess.includes("RoleManagement") && <div onClick={(() => { navigate("/Home/Role-Management") })}>{isSidebarOpen && <span className="icon-name" >Role Management </span>}</div>}
              {subAccess.includes("EmployeeQueueList") && <div onClick={(() => { navigate("/Home/EmployeeQue-List") })}> {isSidebarOpen && <span className="icon-name" >Employee Queue List</span>}</div>}
              {subAccess.includes("UserRegister") && <div onClick={(() => { navigate("/Home/Register-User") })}> {isSidebarOpen && <span className="icon-name" > User Register</span>}</div>}
              {subAccess.includes("RatecardCharges") && <div onClick={(() => { navigate("/Home/Doctor Consulation Charges") })}> {isSidebarOpen && <span className="icon-name" >Ratecard Charges </span>}</div>}
              {subAccess.includes("AccountSettings") && <div onClick={(() => { navigate("/Home/Account Settings") })}> {isSidebarOpen && <span className="icon-name" >Account Settings </span>}</div>}
              {subAccess.includes("ClinicDetails") && <div onClick={(() => { navigate("/Home/Clinic Details") })}> {isSidebarOpen && <span className="icon-name" >Clinic Details </span>}</div>}
              {subAccess.includes("UserList") && <div onClick={(() => { navigate("/Home/User-List") })}> {isSidebarOpen && <span className="icon-name" >User List </span>}</div>}
              {subAccess.includes("LeaveManagement") && <div onClick={(() => { navigate("/Home/Employee-LeaveManage") })}> {isSidebarOpen && <span className="icon-name" >Leave Management </span>}</div>}
              {subAccess.includes("AdvanceManagement") && <div onClick={(() => { navigate("/Home/Employee-AdvanceManagement") })}> {isSidebarOpen && <span className="icon-name" >Advance Management </span>}</div>}
              {subAccess.includes("VisitDoctorBillinglist") && <div onClick={(() => { navigate("/Home/Visit-Doctor-Billing-List") })}> {isSidebarOpen && <span className="icon-name" >Visit Doctor Billing List</span>}</div>}
            </div>}
          </div>
          }





    {mainAccess.includes("Accounts") && (
            <div
              className="Sidebarmenuhover"
              onClick={() => handleSubMenuClick("subsidebarmenu14")}
            >
              <div
                className={
                  isSidebarOpen ? "icon_only icon_tooltip" : "icon_tooltip"
                }
              >
                <FontAwesomeIcon
                  icon={faBuildingColumns}
                  className="inventory_sidebar_icon"
                />
               {isSidebarOpen && <span className="icon-name">Accounts</span>}
                {isSidebarOpen && (
                  <FontAwesomeIcon
                    icon={faAngleDown}
                    className={`arrow-icon ${
                      openSubMenu === "subsidebarmenu14" ? "arrow-rotate" : ""
                    }`}
                  />
                )}
              </div>

              {isSidebarOpen && openSubMenu === "subsidebarmenu14" && (
                
                <div className="subSidebarmenuhover">
                  <div onClick={(() => { navigate("/Home/AccountGroupList") })}>{isSidebarOpen && <span className="icon-name" >Account Group List</span>}</div>

                  {subAccess.includes("Addaccounts") && (
                    <div
                      onClick={() => {
                        navigate("/Home/LedgerList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name"> Ledger Entry </span>
                      )}
                    </div>
                  )}
                  <div onClick={(() => { navigate("/Home/ContraVoucherList") })}>{isSidebarOpen && <span className="icon-name" >Contra Voucher</span>}</div>
                  {subAccess.includes("Paymentvoucher") && (
                    <div
                      onClick={() => {
                        navigate("/Home/PaymentVoucherList");
                      }}
                    >
                      {isSidebarOpen && (
                        <span className="icon-name">Payment Voucher </span>
                      )}
                    </div>
                  )}
                   {subAccess.includes("ReceiptvoucherList") && <div onClick={(() => { navigate("/Home/ReceiptVoucherList") })}>{isSidebarOpen && <span className="icon-name" >Receipt Voucher</span>}</div>}
                  {subAccess.includes("JournalentryList") &&<div onClick={(() => { navigate("/Home/JournalentryList") })}>{isSidebarOpen && <span className="icon-name" >Journal Entry</span>}</div>}
                  {subAccess.includes("DayBook") &&<div onClick={(() => { navigate("/Home/DayBook") })}>{isSidebarOpen && <span className="icon-name" >Day Book</span>}</div>}
                  {subAccess.includes("TrialBalance") &&<div onClick={(() => { navigate("/Home/TrialBalance") })}>{isSidebarOpen && <span className="icon-name" >Trial Balance</span>}</div>}   
                  {subAccess.includes("ProfitandLoss") &&<div onClick={(() => { navigate("/Home/ProfitandLoss") })}>{isSidebarOpen && <span className="icon-name" >Profit & Loss</span>}</div>}
                  {subAccess.includes("CashbookandBankbook") &&<div onClick={(() => { navigate("/Home/CashbookandBankbook") })}>{isSidebarOpen && <span className="icon-name" >Cashbook & Bankbook</span>}</div>}                  
                  {subAccess.includes("BalanceSheet") &&<div onClick={(() => { navigate("/Home/BalanceSheet") })}>{isSidebarOpen && <span className="icon-name" >Balance Sheet</span>}</div>}
                </div>
              )}
            </div>
        )}




          <div className={isSidebarOpen ? 'icon_only icon_tooltip' : 'icon_tooltip'} onClick={handleLogoutClick} id='font_111'>
            <FontAwesomeIcon icon={faRightFromBracket} className='inventory_sidebar_icon' />
            {isSidebarOpen && <span className="icon-name" >Logout</span>}
          </div>

        </div>
      </div>
    </nav >
  );
};

export default Sidebar;
