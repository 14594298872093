import React, { useState } from "react";
// import "./medicalHistory2Form.css";

function MedicalHistoryForm2() {
  const [medicalHistory2, setmedicalHistory2] = useState({
    // Surgical History
    AdrenalGlandSurgery: false,
    Appendectomy: false,
    BariatricSurgery: false,
    BladderSurgery: false,
    BreastSurgery: false,
    CesareanSection: false,
    Cholecystectomy: false,
    ColonSurgery: false,
    CoronaryArteryBypassGraft: false,
    EsophagusSurgery: false,
    GastricBypassSurgery: false,
    HemorrhoidSurgery: false,
    HerniaRepair: false,
    Hysterectomy: false,
    KidneySurgery: false,
    NeckSurgery: false,
    ProstateSurgery: false,
    SmallIntestineSurgery: false,
    SpineSurgery: false,
    StomachSurgery: false,
    ThyroidSurgery: false,
  });

  const handleCheckboxChange = (condition) => {
    setmedicalHistory2({
      ...medicalHistory2,
      [condition]: !medicalHistory2[condition],
    });
  };

  const [patientInfo2, setPatientInfo2] = useState({
    Listnamesdates: "",
    Medications: "",
    Allergies: "",
  });

  const handleInputChange12 = (e) => {
    const { name, value } = e.target;
    setPatientInfo2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [womenInfo2, setWomenInfo2] = useState({
    numberofPregnancies: "",
    numberofDeliveries: "",
    Vaginal: "",
    csections: "",
    Miscarriages: "",
    abortions: "",
  });

  const handleInputboxChange3 = (e) => {
    const { name, value } = e.target;
    setWomenInfo2((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [womenMen, setWomenMen] = useState({
    Monthlyselfexam: false,
    Yearlyphysicianexam: false,
    Lastmammogram: false,

    YearlyGYNexam: false,
    YearlyPAPexam: false,
    Lastmammogram2: false,

    Highsunexposure: false,
    Yearlyskinexam: false,

    Yearlyrectalexam: false,
    YearlyPSAbloodtest: false,

    Yearlyrectalexam2: false,
    Yearlystooltestforblood: false,
    Dateoflastcolonoscopy: "",
  });

  const handleCheckboxChangeWomenMen = (condition) => {
    setWomenMen({
      ...womenMen,
      [condition]: !womenMen[condition],
    });
  };

  const [reviewSystem, setReviewSystem] = useState({
    Nothinginthisgroup: false,

    Weightloss: false,
    LossofAppetite: false,
    Fever: false,
    Chills: false,
    NightSweats: false,
    FaintingSpells: false,

    // Lossofappetite:false,
    // Difficultyswallowing:false,
    // Earlysatiety:false,
    // Heartburn:false,
    // Nausea:false,
    // Vomiting:false,
    // Diarrhea:false,
    // Nausea:false,
    // Nausea:false,
    // Nausea:false,
    // Nausea:false,
    // Nausea:false,

    
  });

  const handleCheckboxGeneralReview = (condition) => {
    setReviewSystem({
      ...reviewSystem,
      [condition]: !reviewSystem[condition],
    });
  };

  return (
    <body>
      <div className="Medical_History_container">
        <div className="form-section5">
          <div className=" dkwjd">
            <h3>Personal Surgical History :</h3>
            <h5>
              {" "}
              Have you ever had any of the following surgeries? (Check if yes)
            </h5>
          </div>
          <br></br>
          <div className="div_ckkkbox_head">
            <div className="div_ckkck_box">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["AdrenalGlandSurgery"]}
                  onChange={() => handleCheckboxChange("AdrenalGlandSurgery")}
                />
                Adrenal Gland Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["Appendectomy"]}
                  onChange={() => handleCheckboxChange("Appendectomy")}
                />
                Appendectomy
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["BariatricSurgery"]}
                  onChange={() => handleCheckboxChange("BariatricSurgery")}
                />
                Bariatric Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["BladderSurgery"]}
                  onChange={() => handleCheckboxChange("BladderSurgery")}
                />
                Bladder Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["BreastSurgery"]}
                  onChange={() => handleCheckboxChange("BreastSurgery")}
                />
                Breast Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["CesareanSection"]}
                  onChange={() => handleCheckboxChange("CesareanSection")}
                />
                Cesarean Section
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["Cholecystectomy"]}
                  onChange={() => handleCheckboxChange("Cholecystectomy")}
                />
                Cholecystectomy
              </label>
            </div>

            <div className="div_ckkck_box">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["ColonSurgery"]}
                  onChange={() => handleCheckboxChange("ColonSurgery")}
                />
                Colon Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["CoronaryArteryBypassGraft"]}
                  onChange={() =>
                    handleCheckboxChange("CoronaryArteryBypassGraft")
                  }
                />
                Coronary Artery Bypass Graft
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["EsophagusSurgery"]}
                  onChange={() => handleCheckboxChange("EsophagusSurgery")}
                />
                Esophagus Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["GastricBypassSurgery"]}
                  onChange={() => handleCheckboxChange("GastricBypassSurgery")}
                />
                Gastric Bypass Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["HemorrhoidSurgery"]}
                  onChange={() => handleCheckboxChange("HemorrhoidSurgery")}
                />
                Hemorrhoid Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["HerniaRepair"]}
                  onChange={() => handleCheckboxChange("HerniaRepair")}
                />
                Hernia Repair
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["Hysterectomy"]}
                  onChange={() => handleCheckboxChange("Hysterectomy")}
                />
                Hysterectomy
              </label>
            </div>
            <div className="div_ckkck_box">
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["KidneySurgery"]}
                  onChange={() => handleCheckboxChange("KidneySurgery")}
                />
                Kidney Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["NeckSurgery"]}
                  onChange={() => handleCheckboxChange("NeckSurgery")}
                />
                Neck Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["ProstateSurgery"]}
                  onChange={() => handleCheckboxChange("ProstateSurgery")}
                />
                Prostate Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["SmallIntestineSurgery"]}
                  onChange={() => handleCheckboxChange("SmallIntestineSurgery")}
                />
                Small Intestine Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["SpineSurgery"]}
                  onChange={() => handleCheckboxChange("SpineSurgery")}
                />
                Spine Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["StomachSurgery"]}
                  onChange={() => handleCheckboxChange("StomachSurgery")}
                />
                Stomach Surgery
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={medicalHistory2["ThyroidSurgery"]}
                  onChange={() => handleCheckboxChange("ThyroidSurgery")}
                />
                Thyroid Surgery
              </label>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>

        <div className="text_fr_surgcl_0">
          <div className="text_fr_surgcl_lbld">
            <div className="iqw7">
              <label> Women - </label>
            </div>
            <div className="oii8u">
              <div className="sdxsxc343">
                <label>Number of pregnancies :</label>
                <input
                  type="text"
                  name="numberofPregnancies"
                  value={womenInfo2.numberofPregnancies}
                  onChange={handleInputboxChange3}
                ></input>
                ,
              </div>
              <div className="sdxsxc343">
                <label>Number of deliveries :</label>
                <input
                  type="text"
                  name="numberofDeliveries"
                  value={womenInfo2.numberofDeliveries}
                  onChange={handleInputboxChange3}
                ></input>
                ,
              </div>
              <div className="sdxsxc343">
                <label>Vaginal :</label>
                <input
                  type="text"
                  name="Vaginal"
                  value={womenInfo2.Vaginal}
                  onChange={handleInputboxChange3}
                ></input>
                ,
              </div>
            </div>
            <div className="oii8u">
              <div className="sdxsxc343">
                <label> C-sections :</label>
                <input
                  type="text"
                  name="csections"
                  value={womenInfo2.csections}
                  onChange={handleInputboxChange3}
                ></input>
                ,
              </div>
              <div className="sdxsxc343">
                <label>Miscarriages :</label>
                <input
                  type="text"
                  name="Miscarriages"
                  value={womenInfo2.Miscarriages}
                  onChange={handleInputboxChange3}
                ></input>
                ,
              </div>
              <div className="sdxsxc343">
                <label>VIPs (abortions) :</label>
                <input
                  type="text"
                  name="abortions"
                  value={womenInfo2.abortions}
                  onChange={handleInputboxChange3}
                ></input>
                .
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="txtare_div_surgical_head">
          <div className="txtare_div_surgical">
            <label>List names and dates of surgeries :</label>
            <textarea
              name="Listnamesdates"
              value={patientInfo2.Listnamesdates}
              onChange={handleInputChange12}
            ></textarea>
          </div>

          <div className="txtare_div_surgical">
            <label>Medications :</label>
            <textarea
              name="Medications"
              value={patientInfo2.Medications}
              onChange={handleInputChange12}
            ></textarea>
          </div>

          <div className="txtare_div_surgical">
            <label>Allergies:</label>
            <textarea
              name="Allergies"
              value={patientInfo2.Allergies}
              onChange={handleInputChange12}
            ></textarea>
          </div>
        </div>
        <br></br>

        <div className="form-section5">
          <div className=" dkwjd">
            <h3>Cancer health habits :</h3>
            <h5> (Select if yes)</h5>
          </div>
          <br></br>

          <div className="women_head_men">
            <div className="div_ckkkbox_head women_chk_box">
              <div>
                <h5>Women</h5>
                <br></br>

                <div className="w99jdid_head">
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Breast :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Monthlyselfexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Monthlyselfexam")
                          }
                        />
                        Monthly self-exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyphysicianexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyphysicianexam")
                          }
                        />
                        Yearly physician-exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Lastmammogram"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Lastmammogram")
                          }
                        />
                        Last mammogram
                      </label>
                    </div>
                  </div>

                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>GYN :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["YearlyGYNexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("YearlyGYNexam")
                          }
                        />
                        Yearly GYN exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["YearlyPAPexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("YearlyPAPexam")
                          }
                        />
                        Yearly PAP exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Lastmammogram2"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Lastmammogram2")
                          }
                        />
                        Last mammogram
                      </label>
                    </div>
                  </div>
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Skin :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Highsunexposure"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Highsunexposure")
                          }
                        />
                        High sun exposure
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyskinexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyskinexam")
                          }
                        />
                        Yearly skin exam
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="div_ckkkbox_head women_chk_box">
              <div>
                <h5>Men</h5>
                <br></br>

                <div className="w99jdid_head">
                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Prostate :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyrectalexam"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyrectalexam")
                          }
                        />
                        Yearly rectal exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["YearlyPSAbloodtest"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("YearlyPSAbloodtest")
                          }
                        />
                        Yearly PSA blood test
                      </label>
                    </div>
                  </div>

                  <div className="div_ckkck_box w99jdid">
                    <div className="weedcvt65_head">
                      <label>Colon :</label>
                    </div>
                    <div className="weedcvt65">
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlyrectalexam2"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen("Yearlyrectalexam2")
                          }
                        />
                        Yearly rectal exam
                      </label>
                      <label className="checkbox-label">
                        <input
                          type="checkbox"
                          className="checkbox-input"
                          checked={womenMen["Yearlystooltestforblood"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen(
                              "Yearlystooltestforblood"
                            )
                          }
                        />
                        Yearly stool test for blood
                      </label>
                      <label className="checkbox-label">
                        {" "}
                        Date of last colonoscopy :
                        <input
                          type="text"
                          className="checkbox-input iojiu7"
                          checked={womenMen["Dateoflastcolonoscopy"]}
                          onChange={() =>
                            handleCheckboxChangeWomenMen(
                              "Dateoflastcolonoscopy"
                            )
                          }
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br></br>

        <div className="form-section5">
          <div className=" dkwjd">
            <h3>Review of Systems :</h3>
            <h5>
              {" "}
              Do you currently have any of the following symptoms or conditions
              (Check if yes)
            </h5>
          </div>
          <br></br>

          <div className="div_ckkkbox_head">
            <div className="div_ckkck_box">
              <div>
                <h5>General :</h5>
                <br></br>
              </div>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["Nothinginthisgroup"]}
                  onChange={() =>
                    handleCheckboxGeneralReview("Nothinginthisgroup")
                  }
                />
                Nothing in this group
              </label>

              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["Weightloss"]}
                  onChange={() => handleCheckboxGeneralReview("Weightloss")}
                />
                Weight loss
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["LossofAppetite"]}
                  onChange={() => handleCheckboxGeneralReview("LossofAppetite")}
                />
                Loss of Appetite
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["Fever"]}
                  onChange={() => handleCheckboxGeneralReview("Fever")}
                />
                Fever
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["Chills"]}
                  onChange={() => handleCheckboxGeneralReview("Chills")}
                />
                Chills
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["NightSweats"]}
                  onChange={() => handleCheckboxGeneralReview("NightSweats")}
                />
                Night Sweats
              </label>
              <label className="checkbox-label">
                <input
                  type="checkbox"
                  className="checkbox-input"
                  checked={reviewSystem["FaintingSpells"]}
                  onChange={() => handleCheckboxGeneralReview("FaintingSpells")}
                />
                Fainting Spells
              </label>
            </div>
          </div>
        </div>
      </div>
    </body>
  );
}

export default MedicalHistoryForm2;

