import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from '@mui/x-data-grid';

import axios from 'axios'
// import SearchIcon from "@mui/icons-material/Search";
// import SearchIcon from "@mui/icons-material/Search";
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

const AddvaceApproval = ( ) => {

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

    const userRecord=useSelector(state=>state.userRecord?.UserData)

  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState('');
  const [oldStatus, setOldStatus] = useState('');
  const [reason, setReason] = useState('');
  const [issuedby,setIssued]= useState('');
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [installment,setInstallment] = useState('')
  const [issuedDate,setIssuedDate] =useState('');
  
  const [showsudden,setshowsudden]=useState(false);
  // const [reject, setReject] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);



  const handleEditClick = (params) => {
    setOpenModal(true);
    setSelectedRowData(params)
    setOldStatus(params.status);
    setStatus(params.status);

  };

  // const showdown = rows.length;
  // const showdown1 = rows1.length;
  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);
  const [selectedRows, setSelectedRows] = React.useState([]);

  const handleSelectionModelChange = (selectionModel) => {
    setSelectedRows(selectionModel);
  };

  const [page, setPage] = useState(0);
  // const [page1, setPage1] = useState(0);
  const pageSize = 10;


 

  const columns = [
    { field: 'Sl_No', headerName: 'Sl No', width: 80 },
    { field: 'employeeid', headerName: 'Employee ID', width: 100 },
    { field: 'employeename', headerName: 'Employee Name', width: 150 },
    { field: 'designation', headerName: 'Designation', width: 130 },
    { field: 'reqdate', headerName: 'Request Date', width: 120 },
    { field: 'reqamount', headerName: 'Request Amount', width: 120 },
    { field: 'reason', headerName: 'Reason', width: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 80,
      renderCell: (params) => (
        <>
          <Button className='cell_btn' onClick={() => handleEditClick(params.row)} >
            <EditIcon />
          </Button>
        </>
      ),
    },
    
  ];


  const [rolename,setRolename] = useState([])
  useEffect(() => {
   axios
     .get(
       'http://127.0.0.1:8000/HRmanagement/getRole_all'
     )
     .then((response) => {
       console.log(response.data);
       setRolename(response.data);
     })
     .catch((error) => {
       console.log(error);
     });


 }, [userRecord?.location]);

  const showdown = rows.length;

  useEffect(() => {
    fetchLeaveData();
  }, []);

  const fetchLeaveData = () => {
    fetch(`http://127.0.0.1:8000/HRmanagement/get_req_advance_register?location=${userRecord?.location}`)
      .then((response) => response.json())
      .then((data) => {
    
        if (Array.isArray(data)) {
          const Records = data.map((userdata, index) => ({
            id: index + 1,
            Sl_No: userdata.Sl_No,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            designation: userdata.Designation,
            reqdate: userdata.RequestDate ? new Date(userdata.RequestDate).toISOString().split('T')[0] : null,
            reqamount: userdata.RequestAmount,
            reason: userdata.Reason,
          }));
          setRows(Records);
          // console.log(Records);
        } else {
          console.error("Data is not an array:", data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleStatusChange = (e) => {
    const selectedStatus = e.target.value;
    setStatus(selectedStatus);
  };

  const handleReasonChange = (e) => {
    const newReason = e.target.value;
    setReason(newReason);
  };
  const handleInstallmentChange = (e) => {
    const newReason = e.target.value;
    setInstallment(newReason);
  };
  
  const handleissuedByChange =(e)=>{
    const issued = e.target.value;
    setIssued(issued)
  }
  const handleissuedDateChange =(e)=>{
    const issueddate = e.target.value
    setIssuedDate(issueddate)
  }
  
  const handleSubmit = () => {
    // Prepare data to be sent to the backend
    const postData = {
      Sl_No: selectedRowData.Sl_No,
      status: status,
      installment: installment,
      rejectReason: reason, // Use the appropriate field name for reject reason
      issuedBy: issuedby,
      issuedDate: issuedDate, // Corrected the key here
    };
  
    // console.log(postData);
  
    // Post data to the backend using Axios
    axios.post('http://127.0.0.1:8000/HRmanagement/update_advance_approval', postData)
      .then((response) => {
        // Handle success
        console.log(response.data);
        alert('Data submitted successfully');
        setOpenModal(false);
        setshowsudden(!showsudden);
        // window.location.reload();
      })
      .catch((error) => {
        // Handle error
        console.error(error);
        alert('Error submitting data');
      });
  };


  const [searchQuery,setsearchQuery]=useState('')
  const [searchQuery1,setsearchQuery1]=useState('')
  //  const showdown = advanceRows.length;
 
   useEffect(() => {
     const lowerCaseQuery = searchQuery.toLowerCase();
     const lowerCaseQuery1 = searchQuery1.toLowerCase();
   
     const filterRow = (row) => {
       const lowerCaseEmployeeName = row.employeename.toLowerCase();
       const lowerCaseDesignation = row.designation.toLowerCase();
       const nameMatches = lowerCaseEmployeeName.includes(lowerCaseQuery);
       const designationMatches = lowerCaseDesignation.includes(lowerCaseQuery1);
   
       if (!searchQuery && !searchQuery1) {
         // If both search queries are empty, do not filter out any data
         return true;
       }
   
       return nameMatches && designationMatches;
     };
   
     const filteredData = rows.filter(filterRow);
  
   
     setFilteredRows(filteredData);
 
   }, [searchQuery, rows, searchQuery1]);
  
  
  

  return (
    <div>
      <div className='leaveform'>
        <div className='user_patienthead'>
          <h3>Advance Approval </h3>
        </div>
      </div>
      <div className="appointment">
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Employee Name :</label>
            <input
              type="text"
              id="date"
              name='employeeName'
              placeholder='Enter Employee Name'
              value={searchQuery}
              onChange={(e)=>setsearchQuery(e.target.value)}
            />
          </div>
          <div className="inp_1">
                <label htmlFor="input">Designation :</label>
                <select
                  name="designation"
                  value={searchQuery1}
                  onChange={(e)=>setsearchQuery1(e.target.value)}
                  className="new-custom-input-phone wei32j"
                  required
                >
                  <option value="select">Select </option>
                  {rolename.map((role) => (
                    <option key={role.role_id} value={role.role_name}>
                      {role.role_name}
                    </option>
                  ))}
                </select>
              </div>
          {/* <div className="inp_1">
            <label htmlFor="input">Date :</label>
            <input
              type="date"
              id="date"
            />
          </div> */}
          {/* <button className="btn_1" >
            <SearchIcon />
          </button> */}
        </div>
      </div>
      <ThemeProvider theme={theme}>
        
          <div className="grid_1 " style={{overflowX: 'auto', maxWidth: '100%' }}>
            <DataGrid
              rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              onSelectionModelChange={handleSelectionModelChange}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
          </div>
          {showdown > 10 && filteredRows.length > 10&& (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
     
      </ThemeProvider>
      {showdown !== 0 && filteredRows.length !== 0 ? (
          ''
        ) : (
          <div className='IP_norecords'>
            <span>No Records Found</span>
          </div>
        )}
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
          onClick={() => { setOpenModal(false) }}
        >
          <div className="newwProfiles newwProfiles1122" onClick={(e) => e.stopPropagation()}>
            <div className="editcont">
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issued">Status<span>:</span></label>
                  <select
                    name="approval"
                    id="approval"
                    value={status}
                    onChange={handleStatusChange}
                  >
                    <option value="">Select</option>
                    {oldStatus !== 'Approved' && (
                      <option value="Approved">Approved</option>
                    )}
                    {oldStatus !== 'Not Approved' && (
                      <option value="Not Approved">Not Approved</option>
                    )}
                  </select>
                </div>
              </div>
              <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="installment">No.of Installment <span>:</span></label>
                  <input
                    type="text" name="installment" id="installment" placeholder='Enter Issuever Name'
                    value={installment}
                    onChange={handleInstallmentChange}
                  />
                </div>
              </div>

            </div>
           
            <div className="editcont">
            <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issuedate">Issued Date <span>:</span></label>
                  <input
                    type="date" name="issuedate" id="issuedate"
                    value={issuedDate}
                    onChange={handleissuedDateChange}
                  />
                </div>
              </div>
            
            <div className="RegisFormcon leavecon1">
                <div className="RegisForm_1 leaveform_11">
                  <label htmlFor="issued">Issued By <span>:</span></label>
                  <input
                    type="text" name="issued" id="issued" placeholder='Enter Issuever Name'
                    value={issuedby}
                    onChange={handleissuedByChange}
                  />
                </div>
              </div>
            </div>
            {status === 'Not Approved' && (
              <div className="RegisFormcon leavecon1" >
              <div className="RegisForm_1 leaveform_11 ">
                <label htmlFor="reason">Reason <span>:</span></label>
                <textarea
                  type="text" name="reason" id="" onChange={handleReasonChange}></textarea>
              </div>
            </div>
            )}
            <div className="Register_btn_con regster_btn_contsai">
              <button className="RegisterForm_1_btns" onClick={handleSubmit}>Submit</button>
              <button
                className="RegisterForm_1_btns"
                onClick={() => setOpenModal(false)}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddvaceApproval;


