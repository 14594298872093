import * as React from "react";
import { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import LoupeIcon from "@mui/icons-material/Loupe";
// import './PatientQueueList.css';
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

export default function CounselorQueueList( ) {

  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const navigate = useNavigate();
  const [rows, setRows] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const pageSize = 10;
  const showdown = rows.length;

  const handleRequestProceed = (params) => {
    console.log(params);
    const serialid = params.row.id;
    localStorage.setItem("PatientID", serialid);
    // axios
    //   .post('http://127.0.0.1:8000/treatmentrecord/update_appointment_reg_process', {
    //     serialid: serialid,
    //   })
    //   .then((response) => {
    //     console.log(response.data.message);
    //   })
    //   .catch((error) => {
    //     console.error('Error updating record:', error);
    //   });
    navigate("/Treament-CounselorProcedure");
  };

  const [columns] = React.useState([
    { field: "PatientName", headerName: "Patient Name", width: 150 },
    { field: "PhoneNumber", headerName: "Phone Number", width: 130 },
    // { field: 'Status', headerName: 'Status', width: 100 },
    {
      field: "consultationstatus",
      headerName: "Dcotor Consultaion Status",
      width: 200,
    },
    { field: "procedurestatus", headerName: "Procedure Status", width: 200 },
    {
      field: "nextapppoinmentdate",
      headerName: "Next Appointment",
      width: 150,
    },
    {
      field: "Action ",
      headerName: "Action",
      width: 80,
      renderCell: (params) => (
        <>
          <Button
            className="cell_btn"
            onClick={() => handleRequestProceed(params)}
          >
            <ArrowForwardIcon />
          </Button>
        </>
      ),
    },
  ]);

  // useEffect(() => {
  //   axios.get('http://127.0.0.1:8000/Counselor/counselor_nextappointment_alert')
  //     .then((response) => {
  //       console.log(response.data);

  //       // Extracting names from the response data
  //       const names = response.data.map(item => item.PatientName).join(', ');

  //       // Displaying all names in the alert message
  //       alert(names);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching data:', error);
  //     });
  // }, []);

  useEffect(() => {
    const location = userRecord?.location;
    fetch(
      `http://127.0.0.1:8000/Counselor/for_counselor_queuelist_pdfemail?location=${location}`
    )
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'data' is an array as per the provided data structure
        console.log(data);
        const Records = data.map((userdata) => ({
          id: userdata.PatientID,
          PatientName: userdata.FirstName + " " + userdata.LastName,
          PhoneNumber: userdata.PhoneNumber,
          // Status: userdata.Status,
          consultationstatus: userdata.ConsultationStatus,
          procedurestatus: userdata.ProcedureStatus,
          nextapppoinmentdate: userdata.Next_appointment_date,
        }));
        setRows(Records);
      })
      .catch((error) => {
        console.error("Error fetching patient data:", error);
      });
  }, [userRecord]);

  columns[4].renderCell = ({ row }) => (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
        color: row.nextapppoinmentdate === "Tomorrow" ? "black" : "black",
        backgroundColor:
          row.nextapppoinmentdate === "Tomorrow"
            ? "hsl(33,100%,80%)"
            : "transparent", // Light green
      }}
    >
      {row.nextapppoinmentdate}
    </div>
  );

  useEffect(() => {
    const createdby = userRecord?.username
    const location = userRecord?.location
    axios.post(`http://127.0.0.1:8000/Counselor/insertrequest_fornextappoinment?location=${location}&createdby=${createdby}`)
      .then((response) => {
        console.log(response.data);

      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, [userRecord]);

  const handlePageChange = (params) => {
    setPage(params.page);
  };
  const totalPages = Math.ceil(rows.length / 10);

  const [PatientFirstName, setPatientFirstName] = useState("");
  const [PatientPhoneNo, setPatientPhoneNo] = useState("");
  const [FirstName, setFirstName] = useState([]);
  const [PhoneNo, setPhoneNo] = useState([]);

  const handleSearch = () => {
    console.log("enter handle search");

    fetch("http://127.0.0.1:8000/appointmentmanagement/get_appointments", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        searchName: PatientFirstName.toLowerCase(),
        searchPhoneNo: PatientPhoneNo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success && Array.isArray(data.appointmentsregData)) {
          const Records = data.appointmentsregData.map((userdata) => ({
            id: userdata.SerialNo,
            First_Name: userdata.First_Name,
            PatientName: userdata.First_Name + " " + userdata.Last_Name,
            PhoneNo: userdata.Phone_No,
            DoctorName: userdata.DoctorName,
            AppointmentType: userdata.AppointmentType,
            Status: userdata.Status,
          }));
          setFirstName(Records);
        } else {
          console.error("Fetched data is not as expected:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });

    fetch(
      "http://127.0.0.1:8000/treatmentrecord/fetch_appointment_reg_data_names",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          searchQuery: PatientPhoneNo,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success && Array.isArray(data.appointmentsregData)) {
          const Records = data.appointmentsregData.map((userdata) => ({
            id: userdata.SerialNo,
            First_Name: userdata.First_Name,
            PatientName: userdata.First_Name + " " + userdata.Last_Name,
            PhoneNo: userdata.Phone_No,
            DoctorName: userdata.DoctorName,
            AppointmentType: userdata.AppointmentType,
            Status: userdata.Status,
          }));
          setPhoneNo(Records);
        } else {
          console.error("Fetched data is not as expected:", data);
        }
      })
      .catch((error) => {
        console.error("Error fetching filtered data:", error);
      });
  };

  const filterRows = (rowData) => {
    const lowerCaseFirstName = (rowData.First_Name || "").toLowerCase();
    const phoneNoString = rowData.PhoneNo.toString() || "";
    const lowerCaseSearchName = PatientFirstName.toLowerCase();
    const lowerCaseSearchPhoneNo = PatientPhoneNo.toString();

    return (
      (PatientFirstName === "" ||
        lowerCaseFirstName.startsWith(lowerCaseSearchName)) &&
      (PatientPhoneNo === "" ||
        phoneNoString.startsWith(lowerCaseSearchPhoneNo))
    );
  };

  const filteredByName = FirstName.filter(filterRows);
  const filteredByPhoneNo = PhoneNo.filter(filterRows);

  const mergedData = [...filteredByName, ...filteredByPhoneNo];

  const uniqueData = Array.from(new Set(mergedData.map((item) => item.id))).map(
    (id) => {
      return mergedData.find((item) => item.id === id);
    }
  );

  console.log(uniqueData);

  if (uniqueData.length === 0) {
    // alert('No matching users found.');
  } else {
    setRows(uniqueData);
  }

  console.log("row inside search", rows);

  //   const handleProcedureForm = () =>{
  //     navigate('/Treament-NewProcedure')
  //   }

  return (
    <>
      <Helmet>
        <title>Patient Queue List</title>
      </Helmet>

      <div className="appointment">
        <div className="h_head">
          <h3>Patient Queue List(C)</h3>
        </div>
        <form onSubmit={handleSearch}>
          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">First Name :</label>
              <input
                type="text"
                id="FirstName"
                value={PatientFirstName}
                onChange={(e) => setPatientFirstName(e.target.value)}
                placeholder="Enter the First Name"
              />
            </div>
            <div className="inp_1">
              <label htmlFor="input">Phone No :</label>
              <input
                type="text"
                id="PhoneNo"
                value={PatientPhoneNo}
                onChange={(e) => setPatientPhoneNo(e.target.value)}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" type="submit">
              <SearchIcon />
            </button>
            {/* <button
              className="btn_1"
              onClick={() => handleProcedureForm()}
              title="New Procedure"
            >
              <LoupeIcon />
            </button> */}
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows.slice(page * pageSize, (page + 1) * pageSize)}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              onPageChange={handlePageChange}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
    </>
  );
}
