import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
import axios from "axios";
// import '../../Components/PatientManagement/PatientRegister.css';
import "../PatientManagement/PatientRegister.css";
import Webcam from "react-webcam";
import { useNavigate } from "react-router-dom";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Procedure2Pro from "./Procedure2Pro";
import CameraswitchIcon from "@mui/icons-material/Cameraswitch";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

// import './Navigation.css';

function Procedure2() {
  const dispatchvalue = useDispatch();

  const isModalShowVisible = useSelector(
    (state) => state.userRecord?.isModalShowVisible
  );

  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);
  const isSidebarOpen = useSelector((state) => state.userRecord?.isSidebarOpen);

  const create = userRecord?.username;
  const Location = userRecord?.location;

  const navigate = useNavigate();
  const [treatmentProcedure, setTreatmentProcedure] = useState("");
  const [ProcedureName, setProcedureName] = useState([]);
  const [nextAppointment, setNextAppointment] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [numOfSessions, setNumOfSessions] = useState("");
  const [numOfSessionsCompleted, setnumOfSessionsCompleted] = useState("");
  const [additionalComments, setAdditionalComments] = useState("");
  const [showCamera3, setShowCamera3] = useState(false);
  const [isCameraImageCaptured3, setIsCameraImageCaptured3] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);

  const [showCamera4, setShowCamera4] = useState(false);
  const [isCameraImageCaptured4, setIsCameraImageCaptured4] = useState(false);
  const [capturedImage1, setCapturedImage1] = useState(null);
  const webcamRef = useRef(null);
  const [currentsession, setcurrentsession] = useState("");
  // const [isModalShowVisible, setModalShowVisible] = useState(false);

  // -------------------------------------
  const [facingMode, setFacingMode] = useState("user");
  const devices = ["iPhone", "iPad", "Android", "Mobile", "Tablet", "desktop"];
  const [IsmobileorNot, setIsmobileorNot] = useState(null);

  useEffect(() => {
    // Use an axios request within useEffect to avoid infinite rendering
    axios
      .get("http://127.0.0.1:8000/patientmanagement/detect_device")
      .then((response) => {
        setIsmobileorNot(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []); // The empty dependency array ensures that this effect runs only once on mount

  const videoConstraints = {
    facingMode: facingMode,
  };

  const switchCamera = () => {
    setFacingMode((prevMode) => (prevMode === "user" ? "environment" : "user"));
  };
  //   --------------------------

  const handleOpenCamera = () => {
    setShowCamera3(true);
  };

  const handleCloseCamera = () => {
    setShowCamera3(false);
  };
  const handleHideCamera = () => {
    setShowCamera3(false);
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("chooser 2 :", imageSrc);
    setCapturedImage(imageSrc);
    setIsCameraImageCaptured3(true);
  };
  const handleRecaptureCameraImage = () => {
    setCapturedImage(null);
    setIsCameraImageCaptured3(false);
  };

  const handleOpenCamera2 = () => {
    setShowCamera4(true);
  };

  const handleCloseCamera2 = () => {
    setShowCamera4(false);
  };
  const handleHideCamera2 = () => {
    setShowCamera4(false);
  };

  const captureImage2 = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    console.log("chooser 2 :", imageSrc);
    setCapturedImage1(imageSrc);
    setIsCameraImageCaptured4(true);
  };
  const handleRecaptureCameraImage2 = () => {
    setCapturedImage1(null);
    setIsCameraImageCaptured4(false);
  };

  const handleOpenModal = () => {
    dispatchvalue({ type: "isModalShowVisible", value: true });
  };

  const handleCloseModal = () => {
    dispatchvalue({ type: "isModalShowVisible", value: false });
  };

  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);
  }, [formData]);

  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/doctorsworkbench/get_current_session?selected_patientid=${formData.PatientID}&location=${Location}&treatmentProcedure=${treatmentProcedure}`
      )
      .then((response) => {
        console.log(response.data);
        setcurrentsession(response.data.Current_Session);
        setnumOfSessionsCompleted(response.data.Completed_Sessions);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [formData.PatientID, treatmentProcedure]);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  useEffect(() => {
    const fetchData = () => {
      axios
        .get(
          `http://127.0.0.1:8000/doctorsworkbench/get_procedurename_theraphist?PatientID=${formData.PatientID}`
        )
        .then((response) => {
          console.log(response.data);
          setProcedureName(response.data);
        })
        .catch((error) => {
          console.log(error);
        });

      const dataToFetch = {
        PatientID: formData.PatientID,
      };

      console.log("treatmentProcedure :", treatmentProcedure);
    };

    fetchData();
  }, [formData.PatientID, treatmentProcedure]);

  useEffect(() => {
    axios
      .get(
        `http://127.0.0.1:8000/doctorsworkbench/get_procedure_bytratment?patientid=${formData.PatientID}&treatmentProcedure=${treatmentProcedure}`
      )
      .then((response) => {
        // setTreatmentProcedure(response.data[0].Treatment_Procedure);
        setNextAppointment(response.data[0].Next_Appointment);
        setNumOfSessions(response.data[0].Number_of_Sessions);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [formData.PatientID, treatmentProcedure]);

  useEffect(() => {
    const fetchData = () => {
      const dataToFetch = {
        PatientID: formData.PatientID,
      };

      console.log("Before Axios.get");

      Axios.get(
        "http://127.0.0.1:8000/doctorsworkbench/get_therapist_procedures",
        { params: dataToFetch }
      )
        .then((response) => {
          console.log("Response:", response);

          if (response && response.data.length > 0) {
            // Data is found, search for the patient ID in the array
            const data = response.data.find(
              (item) => item.PatientID === formData.PatientID
            );

            if (data) {
              console.log(data);
              setnumOfSessionsCompleted(data.Number_of_Sessions_completed);
              setAdditionalComments(data.Additional_Comments);
              setCapturedImage(
                `data:image/jpeg;base64,${data.Patient_theraphy_Photo}`
              );
            } else {
              // Handle case where patient ID is not found in the array
              console.log("Patient ID not found in the response data.");
            }
          } else if (response.data.message === "Data Not Found") {
            // No data found, fields will remain empty
          } else {
            // Handle other response messages or errors
            // You can add more logic as needed
          }
        })
        .catch((error) => {
          // Handle errors, e.g., show an error message
          console.error("Error fetching data:", error);
        });
    };

    fetchData();
  }, [formData.PatientID]);

  const handleShowPreview = () => {
    localStorage.setItem("formData", JSON.stringify(formData));
    window.open(`/Preview`, "_blank");
  };

  const handleSave = () => {
    const dataToSend = {
      AppointmentDate: appointmentDate,
      PatientID: formData.PatientID,
      AppointmentID: formData.SerialNo,
      VisitID: formData.visitNo,
      // TherapistName: formData.TherapistName,
      TreatmentProcedure: treatmentProcedure,
      NextAppointment: nextAppointment,
      CreatedBy: create,
      location: Location,
      NumOfSessions: numOfSessions,
      currentsession: currentsession,
      AdditionalComments: additionalComments,
      beforecapturedImage:
        capturedImage instanceof File
          ? capturedImage
          : dataURItoBlob(capturedImage),
      aftercapturedImage:
        capturedImage1 instanceof File
          ? capturedImage1
          : dataURItoBlob(capturedImage1),
    };
    console.log(dataToSend);

    const BackformData = new FormData();

    for (const key in dataToSend) {
      if (dataToSend.hasOwnProperty(key)) {
        BackformData.append(key, dataToSend[key]);
      }
    }

    axios
      .get(
        `http://127.0.0.1:8000/GeneralBilling/update_theraphycompletedsession_billing?PatientID=${+formData.PatientID}&treatmentProcedure=${treatmentProcedure}&currentsession=${currentsession}`
      )
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });

    const url =
      "http://127.0.0.1:8000/doctorsworkbench/insert_therapist_procedure";

    Axios.post(url, BackformData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        successMsg(response.data.message);
        console.log(response);
      })
      .catch((error) => {
        errmsg("Error saving data:", error);
      });

    const shouldProceed = window.confirm("Do you Complete Procedure?");
    const statuss = shouldProceed ? "Yes" : "No";

    if (statuss === "Yes") {
      // User clicked "OK"
      navigate("/Home/Treament-QueueList2");
    } else {
      // setTreatmentProcedure("");
      // setNextAppointment("");
      // setNumOfSessions("");
      // setnumOfSessionsCompleted("");
      // setcurrentsession("");
      // setCapturedImage1(null);
      // setCapturedImage(null);
    }

    setTimeout(() => {
      // navigate("/Treament-QueueList2");
    }, 1000);
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  return (
    <>
      <Helmet>
        <title>Procedure</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <div className="new-pro-dct">
          <h4> New Procedure</h4>
          <button className="newicon_regis">
            <PersonAddAlt1Icon onClick={handleOpenModal} />
          </button>
        </div>
        <br />
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label htmlFor="newTreatmentProcedure" className="jkji8">Treatment Procedure<span>:</span></label>
            <select
              id="newTreatmentProcedure"
              name="newTreatmentProcedure"
              value={treatmentProcedure}
              onChange={(e) => setTreatmentProcedure(e.target.value)}
            >
              <option value="">Select</option>
              {ProcedureName.map((procedure, index) => (
                <option key={index} value={procedure}>
                  {procedure}
                </option>
              ))}
            </select>
          </div>

          <div className="RegisForm_1">
            <label htmlFor="numOfSessions">No of Sessions<span>:</span></label>
            <input
              type="number"
              id="numOfSessions"
              name="numOfSessions"
              value={numOfSessions}
              onChange={(e) => setNumOfSessions(e.target.value)}
              readOnly
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="currentsession">Current Session<span>:</span></label>
            <input
              type="number"
              id="currentsession"
              name="currentsession"
              value={currentsession}
              onChange={(e) => setNumOfSessions(e.target.value)}
              readOnly
            />
          </div>

          <div className="RegisForm_1">
            <label htmlFor="numOfSessions">No of Sessions Completed<span>:</span></label>
            <input
              type="number"
              id="numOfSessionsCompleted"
              name="numOfSessionsCompleted"
              value={numOfSessionsCompleted}
              onChange={(e) => setnumOfSessionsCompleted(e.target.value)}
              readOnly
            />
          </div>
        

        <div className="RegisForm_1">
          <label htmlFor="patientPhoto">
            Patient Photo Before<span>:</span>
          </label>
          <div className="file-input-444">
                  <div className="RegisterForm_2">
              <button
                onClick={handleOpenCamera}
                className="RegisterForm_1_btns choose_file_update"
              >
                Take Pic
              </button>

              {showCamera3 && (
                <div
                  className={
                    isSidebarOpen ? "sideopen_showcamera" : "showcamera"
                  }
                  onClick={handleHideCamera}
                >
                  <div
                    className={
                      isSidebarOpen ? "sideopen_showcamera_1" : "showcamera_1"
                    }
                    onClick={(e) => e.stopPropagation()}
                  >
                    {isCameraImageCaptured3 ? ( // Display the captured camera image
                      <img
                        src={capturedImage}
                        alt="captured"
                        className="captured-image11"
                      />
                    ) : (
                      <div className="camera-container">
                        <div className="web_head">
                          <h3>Image</h3>
                        </div>
                        <br></br>
                        <div className="RotateButton_canva">
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            mirrored={facingMode === "user"}
                            className="web_cam"
                            videoConstraints={videoConstraints}
                          />
                          {devices.includes(IsmobileorNot) && (
                            <div className="web_cam__1">
                              <button onClick={switchCamera}>
                                <CameraswitchIcon />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="web_btn">
                      {isCameraImageCaptured3 ? ( // Render the Recapture button if a camera image is captured
                        <button
                          onClick={handleRecaptureCameraImage}
                          className="btncon_add"
                        >
                          Recapture
                        </button>
                      ) : (
                        <button onClick={captureImage} className="btncon_add">
                          Capture
                        </button>
                      )}
                      <button
                        onClick={handleCloseCamera}
                        className="btncon_add"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="RegisForm_1">
          <label htmlFor="patientPhoto">Patient Photo After<span>:</span></label>
          <div className="file-input-444 ">
          <div className="RegisterForm_2">
              <button
                onClick={handleOpenCamera2}
                className="RegisterForm_1_btns choose_file_update"
              >
                Take Pic
              </button>

              {showCamera4 && (
                <div
                  className={
                    isSidebarOpen ? "sideopen_showcamera" : "showcamera"
                  }
                  onClick={handleHideCamera2}
                >
                  <div
                    className={
                      isSidebarOpen ? "sideopen_showcamera_1" : "showcamera_1"
                    }
                    onClick={(e) => e.stopPropagation()}
                  >
                    {isCameraImageCaptured4 ? ( // Display the captured camera image
                      <img
                        src={capturedImage1}
                        alt="captured"
                        className="captured-image11"
                      />
                    ) : (
                      <div className="camera-container">
                        <div className="web_head">
                          <h3>Image</h3>
                        </div>
                        <br></br>
                        <div className="RotateButton_canva">
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                            mirrored={facingMode === "user"}
                            className="web_cam"
                            videoConstraints={videoConstraints}
                          />
                          {devices.includes(IsmobileorNot) && (
                            <div className="web_cam__1">
                              <button onClick={switchCamera}>
                                <CameraswitchIcon />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className="web_btn">
                      {isCameraImageCaptured4 ? ( // Render the Recapture button if a camera image is captured
                        <button
                          onClick={handleRecaptureCameraImage2}
                          className="btncon_add"
                        >
                          Recapture
                        </button>
                      ) : (
                        <button onClick={captureImage2} className="RegisterForm_1_btns">
                          Capture
                        </button>
                      )}
                      <button
                        onClick={handleCloseCamera2}
                        className="RegisterForm_1_btns"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

         
        </div>

        <div className="RegisForm_1">
            <label htmlFor="additionalComments">Additional Comments<span>:</span></label>
            <textarea
              id="additionalComments"
              name="additionalComments"
              value={additionalComments}
              onChange={(e) => setAdditionalComments(e.target.value)}
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newNextAppointment">Next Appointment<span>:</span></label>
            <input
              type="date"
              id="newNextAppointment"
              name="newNextAppointment"
              pattern="[A-Za-z ]+"
              title="Only letters and spaces are allowed"
              value={nextAppointment}
              onChange={(e) => setNextAppointment(e.target.value)}
              required
            />
          </div>
          </div>

        <div className="new-button-container">
          <button className="btncon_add" onClick={handleSave}>
            Save
          </button>
          <button className="btncon_add" onClick={handleShowPreview}>
            Preview
          </button>
        </div>
        <ToastContainer />
      </div>

      {isModalShowVisible && <Procedure2Pro />}
    </>
  );
}

export default Procedure2;
