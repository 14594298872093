import React, { useState, useEffect, useCallback } from "react";
import { Helmet } from "react-helmet";
import Axios from "axios";
// import "./Navigation.css";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";

function Vital(props) {
  const formData = props.formData;
  console.log(formData)

  const userRecord = useSelector(state=>state.userRecord?.UserData);

  const create = userRecord?.username;
  const Location = userRecord?.location;

  const [temperature, setTemperature] = useState("");
  const [pulse, setPulse] = useState(""); // Add pulse state
  const [spo2, setSpo2] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [Rr, setRr] = useState("");
  const [sbp, setSBP] = useState(""); // Add sbp state
  const [dbp, setDBP] = useState(""); // Add dbp state
  const [position, setPosition] = useState("");
  const [part, setPart] = useState(""); // Add part state
  const [method, setMethod] = useState(""); // Add method state
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmi, setBMI] = useState("");
  const [hipCircumference, setHipCircumference] = useState("");
  const [waistCircumference, setWaistCircumference] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [selectedVital, setSelectedVital] = useState([]); // Add selectedVital state

  const resetInputFields = () => {
    setTemperature("");
    setPulse("");
    setSpo2("");
    setHeartRate("");
    setRr("");
    setSBP("");
    setDBP("");
    setPosition("");
    setPart("");
    setMethod("");
    setWeight("");
    setHeight("");
    setBMI("");
    setHipCircumference("");
    setWaistCircumference("");
  };
  const fetchData = useCallback(() => {
    const dataToFetch = {
      PatientID: formData.PatientID,
      appointment_patientregisterID: formData.SerialNo,
      visitNo: formData.visitNo,
    };
  
    console.log(dataToFetch);
  
    const PatientID = formData.PatientID;
  
    Axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_vitalformlist?PatientID=${PatientID}&location=${Location}`)
      .then((response) => {
        if (response.data) {
          const data = response.data;
          console.log(data);
          // Filter the data based on PatientID
          setSelectedVital(data);
        } else {
          // Handle the case when no data is found
          setSelectedVital([]); // Set an empty array for no data
        }
      })
      .catch((error) => {
        alert("Error fetching data: " + error);
      });
  }, [formData.PatientID,formData.SerialNo,formData.visitNo]);
  
  useEffect(() => {
    const currentDate = new Date().toISOString().split("T")[0];
    setAppointmentDate(currentDate);

    if (weight && height) {
      const parsedWeight = parseFloat(weight);
      const parsedHeight = parseFloat(height) / 100; // Convert cm to m
      const calculatedBMI = (
        parsedWeight /
        (parsedHeight * parsedHeight)
      ).toFixed(2);
      setBMI(calculatedBMI);
    }
  }, [weight, height]);

  useEffect(() => {
    fetchData(); // Call fetchData immediately when formData or appointmentDate changes
  }, [fetchData]);

  const handleAdd = () => {
    const newMedicineData = {
      PatientID: formData.PatientID,
      appointment_patientregisterID: formData.SerialNo,
      visitNo: formData.visitNo,
      appointmentDate: appointmentDate,
      temperature: temperature,
      pulse: pulse,
      spo2: spo2,
      heartRate: heartRate,
      Rr: Rr,
      sbp: sbp,
      dbp: dbp,
      position: position,
      part: part,
      method: method,
      weight: weight,
      height: height,
      bmi: bmi,
      hipCircumference: hipCircumference,
      waistCircumference: waistCircumference,
      createdBy: create,
      location: Location
    };
    console.log(newMedicineData);
    Axios.post(
      "http://127.0.0.1:8000/doctorsworkbench/insert_vital_form",
      newMedicineData
    )
      .then((response) => {
        console.log(response.data);
        successMsg("Saved Successfully")
        resetInputFields();
        fetchData();
      })
      .catch((error) => {
        errmsg("Error saving data:", error)
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  }



  return (
    <>
      <Helmet>
        <title>Vital</title>
      </Helmet>

      <div className="new-patient-registration-form">
      <div className="RegisFormcon">
                <div className="RegisForm_1">
            <label htmlFor="newTemperature" >
              Temperature <span>:</ span >
            </label>
            <input
              type="text"
              id="newTemperature"
              name="newTemperature"

              onChange={(e) => setTemperature(e.target.value)}
              value={temperature}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newPulse" >
              Pulse <span>:</ span >
            </label>
            <input
              type="text"
              id="newPulse"
              name="newPulse"

              onChange={(e) => setPulse(e.target.value)}
              value={pulse}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newPulse" >
              SPO2 <span>:</ span >
            </label>
            <input
              type="text"
              id="newSPO2"
              name="newSPO2"
        
              onChange={(e) => setSpo2(e.target.value)}
              value={spo2}
              required
            />
          </div>
        </div>
        <div className="new-patient-contact-container">
        <div className="RegisForm_1">
            <label htmlFor="newHeartRate" >
              Heart Rate <span>:</ span >
            </label>
            <input
              type="text"
              id="newHeartRate"
     
              name="newHeartRate"
              onChange={(e) => setHeartRate(e.target.value)}
              value={heartRate}
           
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newRR" >
              RR<span>:</ span >
            </label>
            <input
              type="text"
              id="newRR"
  
              name="newRR"
              onChange={(e) => setRr(e.target.value)}
              value={Rr}
      
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newSBP" >
              SBP <span>:</ span >
            </label>
            <input
              type="text"
              id="newSBP"
              name="newSBP"
              onChange={(e) => setSBP(e.target.value)}
              value={sbp}
  
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newDBP" >
              DBP <span>:</ span >
            </label>
            <input
              type="text"
              id="newDBP"
              name="newDBP"
              onChange={(e) => setDBP(e.target.value)}
              value={dbp}
    
              required
            />
          </div>
        </div>

        <div className="new-patient-contact-container">
        <div className="RegisForm_1">
            <label
              htmlFor="newPosition"
              
            >
              Position <span>:</ span >
            </label>
            <select
              type="text"
              id="newPosition"
    
              name="newPosition"
              onChange={(e) => setPosition(e.target.value)}
              value={position}
   
              required
            >
              <option value="">Select</option>
              <option value="Sitting">Sitting</option>
              <option value="Lying">Lying</option>
              <option value="Standing">Standing</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label
              htmlFor="newPart"
           
            >
              Part <span>:</ span >
            </label>
            <select
              type="text"
              id="newPart"
              name="newPart"

              onChange={(e) => setPart(e.target.value)}
              value={part}

              required
            >
              <option value="">Select</option>
              <option value="Left Arm">Left Arm</option>
              <option value="Right Arm">Right Arm</option>
              <option value="Right Leg">Right Leg</option>
              <option value="Left Leg">Left Leg</option>
            </select>
          </div>
          <div className="RegisForm_1">
            <label
              htmlFor="newMethod"
           
            >
              Method <span>:</ span >
            </label>
            <select
              type="text"
              id="newMethod"
              name="newMethod"
        
              onChange={(e) => setMethod(e.target.value)}
              value={method}

              required
            >
              <option value="">Select</option>
              <option value="Electronic">Electronic</option>
              <option value="Manual">Manual</option>
            </select>
          </div>
        </div>

        <div className="new-patient-contact-container">
        <div className="RegisForm_1">
            <label htmlFor="newWeight" >
              Weight(kg) <span>:</ span >
            </label>
            <input
              type="text"

              id="weight"
              name="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}

              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newHeight" >
              Height(cm) <span>:</ span >
            </label>
            <input
              type="text"
              id="height"
              name="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
 
              required
            />
          </div>
          <div className="RegisForm_1">
            <label htmlFor="newBMI" >
              BMI <span>:</ span >
            </label>
            <input type="text" id="bmi" name="bmi" readOnly value={bmi} />
          </div>
        </div>
        <div className="new-patient-contact-container">
        <div className="RegisForm_1">
            <label
              htmlFor="newHipCircumference"
              
            >
              HC(cm) <span>:</ span >
            </label>
            <input
              type="text"
              id="newHipCircumference"

              name="newHipCircumference"
              onChange={(e) => setHipCircumference(e.target.value)}
              value={hipCircumference}
 
              required
            />
          </div>
          <div className="RegisForm_1">
            <label
              htmlFor="newWaistCircumference"
              
            >
              WC(cm) <span>:</ span >
            </label>
            <input
              type="text"
              id="newWaistCircumference"
              name="newWaistCircumference"
              onChange={(e) => setWaistCircumference(e.target.value)}
              value={waistCircumference}

              required
            />
          </div>
        </div>
        <div className="new-button-container">
          <button className="btncon_add" onClick={handleAdd}>
            Add
          </button>
        </div>

        <div className="for">
          <div className="custom-header">
            <h4>Selected Info</h4>
          </div>

          <div className="Selected-table-container">
            <table className="selected-medicine-table2">
              <thead>
                <tr>
                  <th id="vital_Twidth">Temperature</th>
                  <th id="vital_Twidth">Pulse</th>
                  <th id="vital_Twidth">SPO2</th>
                  <th id="vital_HRwidth">Heart Rate</th>
                  <th id="vital_Twidth">RR</th>
                  <th id="vital_Twidth">SBP</th>
                  <th id="vital_Twidth">DBP</th>
                  <th id="vital_HTwidth">Position</th>
                  <th id="vital_HTwidth">Part</th>
                  <th id="vital_HTwidth">Method</th>
                  <th id="vital_Twidth">Weight(kg)</th>
                  <th id="vital_Twidth">Height(cm)</th>
                  <th id="vital_Twidth">HC(cm)</th>
                  <th id="vital_Twidth">WC(cm)</th>
                </tr>
              </thead>
              <tbody>
  {Array.isArray(selectedVital) && selectedVital.length > 0 ? (
    selectedVital.map((medicine, index) => (
      <tr key={index}>
        <td>{medicine.Temperature}</td>
        <td>{medicine.Pulse}</td>
        <td>{medicine.SPO2}</td>
        <td>{medicine.Heart_Rate}</td>
        <td>{medicine.RR}</td>
        <td>{medicine.SBP}</td>
        <td>{medicine.DBP}</td>
        <td>{medicine.Position}</td>
        <td>{medicine.Part}</td>
        <td>{medicine.Method}</td>
        <td>{medicine.WeightKg}</td>
        <td>{medicine.HeightCm}</td>
        <td>{medicine.HCCm}</td>
        <td>{medicine.WCCm}</td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan="14">No data available</td>
    </tr>
  )}
</tbody>

            </table>
          </div>
        </div>
        <ToastContainer/>
      </div>
    </>
  );
}

export default Vital;
