import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import "../IPNurseflow/IpNurseVitals.css";
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";
import axios from "axios";
import Button from '@mui/material/Button';
import VisibilityIcon from '@mui/icons-material/Visibility';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});
const IpDocDrugAdminis = () => {
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const IpNurseQueSelectedRow = useSelector(
    (state) => state.InPatients?.IpNurseQueSelectedRow
  );
  const [page, setPage] = useState(0);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const [summa, setsumma] = useState([]);
  const [summa1, setsumma1] = useState([]);
  const showdown = summa.length;
  const showdown1 = summa1.length;
  const totalPages = Math.ceil(summa.length / 10);
  const handlePageChange = (params) => {
    if (type === "Intake") {
      setPage(params.page);
    } else {
      setPage1(params.page);
    }
  };
 
  const blockInvalidChar = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
  const [type, setType] = useState("Intake");
  const [AddPriscribtion, setAddPriscribtion] = useState({
    Department: "",
    DoctorName: "",
    GenericName: "",
    MedicineCode: "",
    MedicineName: "",
    Dosage: "",
    Route: "",
    FrequencyMethod: "Regular",
    FrequencyType: "",
    Frequency: "",
    FrequencyTime: "",
    Duration: "",
    DurationType: "days",
    Quantity: "",
    Date: "",
    Time: "",
    Instruction: "",
  });

  const [selectedTimes, setSelectedTimes] = useState([]);
 
  useEffect(() => {
    if(AddPriscribtion.Frequency){
      const frequencytypeval= selectedTimes.find((p)=>p.Frequency===AddPriscribtion.Frequency)
    setAddPriscribtion((prev) => ({
      ...prev,
      FrequencyTime: frequencytypeval?.FrequencyTime,

    }));
    }
  }, [AddPriscribtion.Frequency]);
  const [getdatastate, setgetdatastate] = useState(false);

  const warnmessage = (warnmsg) => {
    toast.warn(`${warnmsg}`, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(name, value);

    
      if(name==='MedicineName'){
        setAddPriscribtion((prev) => ({
          ...prev,
          [name]: value.split(',')[1],
          MedicineCode:value.split(',')[0]
        }));
      }else {
        setAddPriscribtion((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (AddPriscribtion?.Frequency && AddPriscribtion?.Duration && AddPriscribtion?.DurationType) {
      // Split the Frequency string into an array
      const frequency = AddPriscribtion?.Frequency.split('-'); 
      // Calculate the sum of frequencies
      
      const sum = frequency.reduce((acc, val) => acc + parseInt(val), 0);
      
      let durationdata = 0;
      // Adjust durationdata based on the DurationType
      if (AddPriscribtion?.DurationType === 'Weeks') {
        durationdata = sum * 7;
      } else if (AddPriscribtion?.DurationType === 'Months') {
        durationdata = sum * 30;
      } else {
        durationdata = sum * 1;
      }
  
      // Calculate Quantity based on durationdata and Duration
      const quantydata = durationdata * AddPriscribtion.Duration;
    //   alert(quantydata)
      // Update the state with the new Quantity value
      setAddPriscribtion(prev => ({
        ...prev,
        Quantity: quantydata
      }));
    }
  }, [AddPriscribtion?.Frequency, AddPriscribtion?.Duration, AddPriscribtion?.DurationType]);
  
  const handlecleardata = () => {
    setAddPriscribtion((prev) => ({
      ...prev,
      Department: "",
      DoctorName: "",
      GenericName: "",
      MedicineCode: "",
      MedicineName: "",
      Dosage: "",
      Route: "",
      FrequencyMethod:'Regular',
      FrequencyType:'',
      Frequency: "",
      FrequencyTime: "",
      Duration: "",
      DurationType: "days",
      Quantity: "",
      Instruction: "",
    }));
   
    
  };
  useEffect(()=>{
    axios.get(`http://127.0.0.1:8000/ipregistration/get_frequency_masters`)
    .then((response)=>{
      const data =response.data.map((p,index)=>({
        ...p,
        id:p.Frequency_Id
      }))
      setSelectedTimes(data)
      console.log(data)
    })
    .catch((error)=>{
      console.log(error)
    })
  },[])
  useEffect(() => {
    const intervalId = setInterval(() => {
      const now = new Date();
      const formattedTime = format(now, "hh:mm:ss a");
      const newdate = format(new Date(), "yyyy-MM-dd");
      setAddPriscribtion((prev) => ({
        ...prev,
        Time: formattedTime,
        Date: newdate,
      }));
      
    }, 1000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

    const handlefullview=(params)=>{
      const row=params.row
      axios.get(`http://127.0.0.1:8000/ipregistration/get_Drug_Administration_datas?Booking_Id=${IpNurseQueSelectedRow?.Booking_Id}&Date=${row.Date}&DoctorName=${row.DoctorName}&Department=${row.Department}`)
        .then((response)=>{
          console.table(response.data)
          
        })
        .catch((error)=>{
          console.log(error)
        })
    }
  const handleChange = (event) => {
    setType(event.target.value);
  };
  const formatLabel = (label) => {
    // Check if the label contains both uppercase and lowercase letters, and doesn't contain numbers
    if (/[a-z]/.test(label) && /[A-Z]/.test(label) && !/\d/.test(label)) {
      return label
        .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between lowercase and uppercase letters
        .replace(/^./, (str) => str.toUpperCase()); // Capitalize first letter
    } else {
      return label;
    }
  };
  function getTextWidth(text) {
    // Create a dummy element to measure text width
    const dummyElement = document.createElement("span");
    dummyElement.textContent = text;
    dummyElement.style.visibility = "hidden";
    dummyElement.style.whiteSpace = "nowrap";
    document.body.appendChild(dummyElement);

    // Get the width of the text
    const width = dummyElement.offsetWidth;

    // Remove the dummy element
    document.body.removeChild(dummyElement);

    return width;
  }
  const dynamicColumns = [
    {
      field: "id",
      headerName: "S_No",
      width: 40,
    },
    ...Object.keys( AddPriscribtion ).map(
      (labelname, index) => {
        const formattedLabel = formatLabel(labelname);
        const labelWidth = getTextWidth(formattedLabel);

        return {
          field: labelname,
          headerName: formattedLabel,
          width:['Instruction','Date','Time'].find((f)=>f===labelname) ? labelWidth + 100 : labelWidth + 30,
          valueGetter: (params) => {
            const value = params.row[labelname];
            return value ? value : '-';
          }
        };
      }
    ),
  ];

  

  const handlesubmitdata = () => {
    let requiredfields=[];
    if(AddPriscribtion.FrequencyMethod==='Regular'){
      requiredfields = [
        ...Object.keys(AddPriscribtion),
      ].filter((key) => key !== "Instruction");
    }else{
      requiredfields = [
        ...Object.keys(AddPriscribtion),
      ].filter((key) => !["Instruction","FrequencyType","Frequency","FrequencyTime","Duration","DurationType"].includes(key) );
    }
     
 
    const exist = requiredfields.filter((field) =>
      !AddPriscribtion[field] 
    );

    if (exist.length > 0) {
      warnmessage(`The following fields are required: ${exist.join(", ")}`);
    } else {
      const indxx=summa.length+1
      const senddata = {
        ...AddPriscribtion,
        Location: userRecord?.location,
        CapturedBy: userRecord?.username,
        Booking_Id: IpNurseQueSelectedRow?.Booking_Id,
        id: indxx, // Use the length of the array as the index
      };
      setsumma(prevSumma => [...prevSumma, senddata]);  
      setgetdatastate(!getdatastate);
      handlecleardata();    
    }
  };
  const submitalldata=()=>{
  if(summa.length>0){
    axios
    .post(
      `http://127.0.0.1:8000/ipregistration/insert_Drug_Administration_datas`,
      summa
    )
    .then((response) => {
      console.log(response);
      setsumma([])
    })
    .catch((error) => {
      console.log(error);
    });
  }
  }

  return (
    <>
      <div className="Supplier_Master_Container">
        <div style={{ width: "100%", display: "grid", placeItems: "center" }}>
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
          >
            <ToggleButton
              value="Intake"
              style={{
                height: "30px",
                width: "180px",
                backgroundColor:
                  type === "Intake"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
              }}
              className="togglebutton_container"
            >
              Add Drugs
            </ToggleButton>
            <ToggleButton
              value="Output"
              style={{
                backgroundColor:
                  type === "Output"
                    ? "var(--selectbackgroundcolor)"
                    : "inherit",
                width: "180px",
                height: "30px",
              }}
              className="togglebutton_container"
            >
              Issue Drugs
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        {type === "Intake" && (
          <>
            <div className="Total_input_container">
              <div className="inp_container_all_intakeoutput">
                <label>
                  Department <span>:</span>
                </label>

                <select
                  name="Department"
                  value={AddPriscribtion.Department}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  <option value="Primary">Primary</option>
                  <option value="Duty">Duty</option>
                  <option value="Special">Special</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Doctor Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="DoctorName"
                  list="IpDoctorNameList"
                  value={AddPriscribtion.DoctorName}
                  onChange={handleInputChange}
                />
                <datalist id="IpDoctorNameList">
                  {["Ram", "Ragu", "Raja"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Generic Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="GenericName"
                  list="IpGenericNameList"
                  value={AddPriscribtion.GenericName}
                  onChange={handleInputChange}
                />
                <datalist id="IpGenericNameList">
                  {["Paracetomal", "Cerizine"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Medicine Name <span>:</span>
                </label>

                <input
                  type="text"
                  name="MedicineName"
                  list="IpMedicineNameList"
                  value={AddPriscribtion.MedicineName}
                  onChange={handleInputChange}
                />
                <datalist id="IpMedicineNameList">
                  {['111,Dolo','112,Calpol'].map((row, index) => (
                    <option key={index} value={row} >{row.split(',')[0]}</option>
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Dose <span>:</span>
                </label>

                <input
                  name="Dosage"
                  type="text"
                  list="IpDosageList"
                  value={AddPriscribtion.Dosage}
                  onChange={handleInputChange}
                />

                <datalist id="IpDosageList">
                  {["650 Mg", "250 mg"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Route <span>:</span>
                </label>

                <input
                  name="Route"
                  type="text"
                  list="IpRouteList"
                  value={AddPriscribtion.Route}
                  onChange={handleInputChange}
                />

                <datalist id="IpRouteList">
                  {["Tablet"].map((row, index) => (
                    <option key={index} value={row} />
                  ))}
                </datalist>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Freq Method <span>:</span>
                </label>

                <select
                  name="FrequencyMethod"
                  value={AddPriscribtion.FrequencyMethod}
                  onChange={handleInputChange}
                >
                <option value="Regular">Regular</option>
                <option value="SOS">SOS</option>
                 
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Frequency Type <span>:</span>
                </label>

                <select
                  name="FrequencyType"
                  value={AddPriscribtion.FrequencyType}
                  disabled={AddPriscribtion.FrequencyMethod==='SOS'}
                  onChange={handleInputChange}
                >
                <option value="">Select</option>
                <option value="BeforeFood">BeforeFood</option>
                <option value="AfterFood">AfterFood</option>
                 
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Frequency <span>:</span>
                </label>

                <select
                  name="Frequency"
                  value={AddPriscribtion.Frequency}
                  disabled={AddPriscribtion.FrequencyMethod==='SOS'}
                  onChange={handleInputChange}
                >
                  <option value="">Select</option>
                  {selectedTimes.filter((p)=>p.FrequencyType===AddPriscribtion.FrequencyType).map((fields) => (
                    <option value={fields.Frequency} key={fields}>
                      {fields.Frequency}
                    </option>
                  ))}
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                Frequency Time <span>:</span>
                </label>

                <input
                  name="FrequencyTime"
                  type="text"
                  readOnly
                  disabled={AddPriscribtion.FrequencyMethod==='SOS'}
                  value={AddPriscribtion.FrequencyTime}
                  onChange={handleInputChange}
                />
                </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Duration <span>:</span>
                </label>

                <input
                  name="Duration"
                  type="number"
                  onKeyDown={blockInvalidChar}
                  style={{ width: "20px" }}
                  disabled={AddPriscribtion.FrequencyMethod==='SOS'}
                  value={AddPriscribtion.Duration}
                  onChange={handleInputChange}
                />
                <select
                  name="DurationType"
                  style={{ width: "110px" }}
                  value={AddPriscribtion.DurationType}
                  onChange={handleInputChange}
                  disabled={AddPriscribtion.FrequencyMethod==='SOS'}
                >
                  <option value="days">days</option>
                  <option value="Weeks">weeks</option>
                  <option value="Months">Months</option>
                </select>
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Quantity <span>:</span>
                </label>

                <input
                  name="Quantity"
                  type="number"
                  onKeyDown={blockInvalidChar}
                  value={AddPriscribtion.Quantity}
                  readOnly={AddPriscribtion.FrequencyMethod==='Regular'}
                  onChange={handleInputChange}
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Date <span>:</span>
                </label>

                <input
                  name="Date"
                  type="text"
                  value={AddPriscribtion.Date}
                  disabled
                />
              </div>
              <div className="inp_container_all_intakeoutput">
                <label>
                  Time <span>:</span>
                </label>

                <input
                  name="Time"
                  type="text"
                  value={AddPriscribtion.Time}
                  disabled
                />
              </div>

              <div className="inp_container_all_intakeoutput">
                <label>
                  Instructions <span>:</span>
                </label>

                <textarea
                  name="Instruction"
                  value={AddPriscribtion.Instruction}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            
            <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
          <button className="btn-add" onClick={handlesubmitdata}>
            Add
          </button>
        </div>
          </>

        ) 
        }
        

        {
    summa.length!==0 && <>
    <div className="IP_grid">
      <ThemeProvider theme={theme}>
        <div className="IP_grid_1">
          <DataGrid
            rows={summa.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
            columns={dynamicColumns} // Use dynamic columns here
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10]}
            onPageChange={handlePageChange}
            hideFooterPagination
            hideFooterSelectedRowCount
            className="Ip_data_grid"
          />
          {showdown > 0 && summa.length > 10 && (
            <div className="IP_grid_foot">
              <button
                onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                disabled={page === 0}
              >
                Previous
              </button>
              Page {page + 1} of {totalPages}
              <button
                onClick={() => setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))}
                disabled={page === totalPages - 1}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </ThemeProvider>
      {showdown !== 0 && summa.length !== 0 ? null : (
        <div className="IP_norecords">
          <span>No Records Found</span>
        </div>
      )}
    </div>
     <div style={{ display: "grid", placeItems: "center", width: "100%" }}>
     <button className="btn-add" onClick={submitalldata}>
       Submit
     </button>
   </div>
   </>
  
}

      </div>
     
      <ToastContainer />
    </>
  );
};

export default IpDocDrugAdminis;
