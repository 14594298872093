import React, { useState, useEffect } from 'react';
// import "./TreatmentComponent.css";
// import imageConversion from 'image-conversion';


import Axios from 'axios';

import Canva2 from './Canva2';
function Treatment2(props) {
      const getformData = props.formData;
      const isnewSidebarOpen = props.isSidebarOpen;
      const create = props.userRecord.username;
      const Location = props.userRecord.location;


      const [showcamera, setshowcamera] = useState(false);
      const [orignalImagecanva, setoriginalImagecanva] = useState(null);
      const [mergedImagecanva, setmergedImagecanva] = useState(null);


      const [historyValue, setHistoryValue] = useState('');
      const [examinationValue, setExaminationValue] = useState('');
      const [diagnosisValue, setDiagnosisValue] = useState('');
      const [appointmentDate, setAppointmentDate] = useState('');

      useEffect(() => {
            const currentDate = new Date().toISOString().split('T')[0];
            setAppointmentDate(currentDate);
        
            // const constraints = {
            //   appointmentDate: appointmentDate,
            //   PatientID: formData.PatientID,
            //   // AppointmentID: formData.SerialNo,
            //   visitNo: formData.visitNo,
            // };
            const patientid = getformData.PatientID
            const visitNo = getformData.visitNo
        
            Axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_treatment?patientid=${patientid}&visitid=${visitNo}&location=${Location}`)
              .then((response) => {
                console.log("fetched", response.data);
        
                if (response.data && response.data.length > 0) {
                  const matchedData = response.data.find(item => item.PatientID === getformData.PatientID);
                  console.log(matchedData)
                  if (matchedData) {
                    setHistoryValue(matchedData.History);
                    setExaminationValue(matchedData.Examination);
                    setDiagnosisValue(matchedData.Diagnosis);
                    setoriginalImagecanva(`data:image/jpeg;base64,${(matchedData.Original_Image)}`);
                    setmergedImagecanva(`data:image/jpeg;base64,${(matchedData.Anotated_Medical_Image)}`);
                  } else {
                    console.log("No data found for PatientID:", getformData.PatientID);
                  }
        
                } else {
                  console.log("Error or empty response:", response);
        
                }
        
        
              })
              .catch((error) => {
                console.error(error);
              });
          }, [appointmentDate, getformData]);
        
      const handleShowcamera = () => {
            setshowcamera(true)
      }
      const handlehidecamera = () => {
            setshowcamera(false)
      }

      const handleSave = () => {
            // Create an object with the data to send to the backend
            const dataToSend = {
                  history: historyValue,
                  examination: examinationValue,
                  diagnosis: diagnosisValue,
                  PatientID: getformData.PatientID,
                  appointmentDate: appointmentDate,
                  AppointmentID: getformData.SerialNo,
                  visitNo: getformData.visitNo,
                  createdBy: create,
                  location: Location,
                  original_image: orignalImagecanva,
                  annotated_medical_image: mergedImagecanva
                  // Add other data as needed
            };
            
            console.log(dataToSend)

            // If no image is selected, send the rest of the data without an image
            Axios.post('http://127.0.0.1:8000/doctorsworkbench/insert_treatment', dataToSend)
                  .then((response) => {
                        // Handle the response from the server, e.g., show a success message
                        alert(response.data.message);
                  })
                  .catch((error) => {
                        // Handle errors, e.g., show an error message
                        console.error(error);
                  });

      };





      return (
            <>
                  <div className="treatment_total_container">
                        <div className="treatment_container">
                              <div className="treatcon_1">
                                    <div className="treatcon_body">
                                          <div className="treatcon_body_1">
                                                <label htmlFor="history">History <span>:</span></label>
                                                <textarea
                                                      id="history"
                                                      name="history"
                                                      cols="25"
                                                      rows="3"
                                                      value={historyValue}
                                                      onChange={(e) => setHistoryValue(e.target.value)}
                                                ></textarea>
                                          </div>
                                          <div className="treatcon_body_1">
                                                <label htmlFor="examination">Examination <span>:</span></label>
                                                <textarea
                                                      id="examination"
                                                      name="examination"
                                                      cols="25"
                                                      rows="3"
                                                      value={examinationValue}
                                                      onChange={(e) => setExaminationValue(e.target.value)}
                                                ></textarea>
                                          </div>
                                          <div className="treatcon_body_1">
                                                <label htmlFor="diagnosis">Diagnosis <span>:</span></label>
                                                <textarea
                                                      id="diagnosis"
                                                      name="diagnosis"
                                                      cols="25"
                                                      rows="3"
                                                      value={diagnosisValue}
                                                      onChange={(e) => setDiagnosisValue(e.target.value)}
                                                ></textarea>
                                          </div>
                                    </div>
                              </div>
                              {orignalImagecanva && <div className="treatcon_2">
                                    <div className="treatcon_image">
                                          <div className="treatcon_image_1">
                                                <img src={orignalImagecanva} alt="" />
                                          </div>
                                          <div className="treatcon_label">
                                                <label htmlFor="name">Original Image</label>
                                          </div>

                                    </div>
                                    <div className="treatcon_image">
                                          <div className="treatcon_image_1">
                                                <img src={mergedImagecanva} alt="" />
                                          </div>
                                          <div className="treatcon_label">
                                                <label htmlFor="name">Anotated Medical Image</label>
                                          </div>

                                    </div>
                              </div>}
                        </div>
                        <div className="treatment_buttons">
                              <button onClick={handleSave}>save</button>
                              <button onClick={handleShowcamera}>capture</button>
                        </div>
                  </div>
                  {showcamera &&
                        <div className={isnewSidebarOpen ?"sideopen_showcamera": "showcamera" } onClick={handlehidecamera}>
                              <div  className={isnewSidebarOpen ? "sideopen_showcamera_1" : "showcamera_1"} onClick={(e) => e.stopPropagation()}>
                                    <Canva2 setoriginalImagecanva={setoriginalImagecanva} setmergedImagecanva={setmergedImagecanva} setshowcamera={setshowcamera} />
                              </div>
                        </div>
                  }
            </>

      );
}

export default Treatment2;
