import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { DataGrid } from '@mui/x-data-grid';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Axios from 'axios';
import './RoleManagement.css';
import { IconButton } from '@mui/material';
import TreatmentProEdit from './TreatmentProEdit';
import { useSelector } from 'react-redux';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: 'Center',
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
            textAlign: 'center',
            display: 'flex !important',
            justifyContent: 'center !important'
          },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: 'flex',
          justifyContent: 'center'
        },
      },
    },
  },
});

function TreatmentPro( ) {


 const isModalOpen = useSelector(state=>state.userRecord?.isModalOpen)

  const userRecord = useSelector(state=>state.userRecord?.UserData)

  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen)

  const [ProcedureName, setProcedureName] = useState('');
  const [cost, setCost] = useState('');
  const [ProcedureData, setProcedureData] = useState([]);
  const [columns] = useState([
    { field: 'ProcedureID', headerName: 'Procedure ID', width: 250 },
    { field: 'ProcedureName', headerName: 'Name', width: 350 },
    {
      field: 'ProcedureCost',
      headerName: 'Cost',
      width: 300,
      renderCell: (params) => (
        <div>
          {params.value}
          <IconButton className='tempt_edit' onClick={() => handleOpenModal(params.row)}>
            <EditNoteIcon />
          </IconButton>
        </div>
      ),
    },
  ]);

  const create = userRecord?.username;

  const [page, setPage] = useState(0);
  const [newproceduredata, setNewProcedureData] = useState([]);
  const pageSize = 10;

  const handlePageChange = (params) => {
    setPage(params.page);
  };

  const totalPages = Math.ceil(ProcedureData.length / 10);

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ProcedureName: ProcedureName,
      ProcedureCost: cost,
      Created_By: create,
    };

    Axios.post('http://127.0.0.1:8000/usercontrol/insert_procedure', data)
      .then((response) => {
        console.log('Data successfully sent:', response.data);
        setProcedureName('');
        setCost('');
        fetchProcedureData();
      })
      .catch((error) => {
        console.error('Error sending data:', error);
      });
  };

  useEffect(() => {
    fetchProcedureData();
  }, []);

  const fetchProcedureData = () => {
    Axios.get('http://127.0.0.1:8000/usercontrol/get_procedure')
      .then((response) => {
        const mappedData = response.data.map((row) => ({
          ProcedureID: row.ProcedureID,
          ProcedureName: row.ProcedureName,
          ProcedureCost: row.ProcedureCost,
        }));
        setProcedureData(mappedData);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const handleOpenModal = (params) => {
    isModalOpen();
    setNewProcedureData(params);
  };

  const handleUpdateProcedureData = (updatedData) => {
    const updatedIndex = ProcedureData.findIndex((p) => p.ProcedureID === updatedData.ProcedureID);

    if (updatedIndex !== -1) {
      const updatedProcedureData = [...ProcedureData];
      updatedProcedureData[updatedIndex] = updatedData;
      setProcedureData(updatedProcedureData);
    } else {
      console.error("No matching data found for ProcedureID:", updatedData.ProcedureID);
    }
  };

  return (
    <>
      <Helmet>
        <title>Treatment Procedure</title>
      </Helmet>
      <div className="appointment">
        <div className="h_head">
          <h3>Treatment Procedure</h3>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="con_1">
            <div className="inp_1">
              <label htmlFor="procedureName">Procedure Name:</label>
              <input
                type="text"
                placeholder="Enter the Procedure Name"
                id="procedureName"
                name="procedureName"
                value={ProcedureName}
                onChange={(e) => setProcedureName(e.target.value)}
                required
              />
            </div>
            <div className="inp_1">
              <label htmlFor="procedureCost">Cost:</label>
              <input
                type="text"
                placeholder="Enter the Cost"
                id="procedureCost"
                name="procedureCost"
                value={cost}
                onChange={(e) => setCost(e.target.value)}
                required
              />
            </div>
            <button className="btn_1" type="submit">
              Add
            </button>
          </div>
        </form>
        <ThemeProvider theme={theme}>
          <div className="grid_1">
            {ProcedureData.length > 0 ? (
              <DataGrid
                rows={ProcedureData.slice(page * pageSize, (page + 1) * pageSize)}
                columns={columns}
                pageSize={100}
                getRowId={(row) => row.ProcedureID}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 10,
                    },
                  },
                }}
                pageSizeOptions={[10]}
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
            ) : (
              <p>No data available.</p>
            )}
            {ProcedureData.length > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </ThemeProvider>
      </div>
      {isModalOpen && userRecord && (
        <TreatmentProEdit
          onClose={isModalOpen}
          isSidebarOpen={isSidebarOpen}
          newproceduredata={newproceduredata}
          updateProcedureData={handleUpdateProcedureData}
        />
      )}
    </>
  );
}

export default TreatmentPro;
