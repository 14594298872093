const initstate={
    GroupEditdata:{},
    LedgerEditdata:{}

};

const FinanceStore=(state=initstate,action)=>
{
    switch(action.type)
    {
        case 'Groupdata':
            return{...state,GroupEditdata:action.value}

        case 'Ledgerdata':
            return{...state,LedgerEditdata:action.value}

        
        
        default:
            return state;
    }
}

export default FinanceStore;