import React, { useState, useEffect } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
// import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const TheatreBooking = () => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [pagesview, setpagesview] = useState(false);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (pagesview) {
      setValue(1);
    }
  }, [pagesview]);

  //   const handleChange = (event, newValue) => {
  //     setValue(newValue);
  //   };

  const [pasentdetailearr, setpasentdetailearr] = useState([]);

  const [Theaternamelist, setTheaternamelist] = useState([]);

  const [Doctordetailearr, setDoctordetailearr] = useState([]);

  const [singlepasentdetaileobj, setpasentdetaileobj] = useState({});

  const [SearchIPno, setSearchIPno] = useState("");

  const [Opdate, setOpdate] = useState("");

  const [Optimeinput, setOptime] = useState("");

  const [Theatername, setTheatername] = useState("");

  const [SelecetSpecialization, setSelecetSpecialization] = useState("");

  const [Filterspecial, setFilterspecial] = useState([]);

  const [SelecetDoctorename, setSelecetDoctorename] = useState("");

  const [FilterspecialDoctorename, setFilterspecialDoctorename] = useState([]);

  const [Oprationstarttime, setOprationstarttime] = useState("");

  const [OprationDurationhours, setOprationDurationhours] = useState("");

  const [Employeedataarr, setEmployeedataarr] = useState([]);

  console.log("Employeedataarr", Employeedataarr);

  const [SelectSurgeon, setSelectSurgeon] = useState("");

  const [Surgeonstemparr, setSurgeonstemparr] = useState([]);

  const [SelectAssSurgeon, setSelectAssSurgeon] = useState("");

  const [AssSurgeontemparr, setAssSurgeontemparr] = useState([]);

  const [SelectAnaesthetician, setSelectAnaesthetician] = useState("");

  const [Anaestheticiantemparr, setAnaestheticiantemparr] = useState([]);

  const [SelectTechnician, setSelectTechnician] = useState("");

  const [Techniciantemparr, setTechniciantemparr] = useState([]);

  const [SelectNurse, setSelectNurse] = useState("");

  const [Nursetemparr, setNursetemparr] = useState([]);

  const [OPmedicineArr, setOPmedicineArr] = useState([]);

  const [FilterOPmedicineArr, setFilterOPmedicineArr] = useState([]);



  // ----------------------------------------------


  const [selectCSSDKit,setselectCSSDKit] =useState('')

  const [CSSDKitlist,setCSSDKitlist] =useState([])
  
  
 

  const [Medicine_Type, SetMedicine_Type] = useState("");

  const [TabletORSyringedata, setTabletORSyringedata] = useState("");

  const [AnaesthetistDose, setAnaesthetistDose] = useState("");

  const [Anaesth_Requ_data_arr, setAnaesth_Requ_data_arr] = useState([]);

  const [Edit_btn_condition, setEdit_btn_condition] = useState(false);

  const [Pre_Opration_notes, setPre_Opration_notes] = useState("");

  const [Post_Opration_notes, setPost_Opration_notes] = useState("");

  const [OT_Notes_input, setOT_Notes_input] = useState("");

  const [OT_Remarks_input, setOT_Remarks_input] = useState("");

  const [type, setType] = useState("pre_con");

  useEffect(() => {
    axios
      .get("http://localhost:5000/getallinputdetailes/getpassionatedetailes")
      .then((response) => {
        console.log(response.data);
        setpasentdetailearr(response.data);
      })

      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:5000/getallinputdetailes/getoptheaternamelist")
      .then((response) => setTheaternamelist(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:5000/getallinputdetailes/getdoctorsdetaileslist")
      .then((response) => setDoctordetailearr(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("http://localhost:5000/getallinputdetailes/getemployeesdetaileslist")
      .then((response) => setEmployeedataarr(response.data))
      .catch((err) => console.log(err));
  }, []);

  // -------------------------------------------SQL 4tables----------------------------------------------------------------------------

  const [
    Theatre_Booking_personal_information,
    setTheatre_Booking_personal_information,
  ] = useState([]);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/OT_management_link/get_Theatre_Booking_personal_information"
      )
      .then((response) =>
        setTheatre_Booking_personal_information(response.data)
      )
      .catch((err) => console.log(err));
  }, []);

  console.log("pppppppp", Theatre_Booking_personal_information);

  const [Theatre_Booking_Doctors_Details, setTheatre_Booking_Doctors_Details] =
    useState([]);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/OT_management_link/get_Theatre_Booking_Doctors_Details"
      )
      .then((response) => setTheatre_Booking_Doctors_Details(response.data))
      .catch((err) => console.log(err));
  }, []);

  console.log("dddddddd", Theatre_Booking_Doctors_Details);

  const [
    Theatre_Booking_Anaesthetist_Details,
    setTheatre_Booking_Anaesthetist_Details,
  ] = useState([]);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/OT_management_link/get_Theatre_Booking_Anaesthetist_Details"
      )
      .then((response) =>
        setTheatre_Booking_Anaesthetist_Details(response.data)
      )
      .catch((err) => console.log(err));
  }, []);

  console.log("AAAAAAA", Theatre_Booking_Anaesthetist_Details);

  const [Theatre_Booking_Other_Details, setTheatre_Booking_Other_Details] =
    useState([]);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/OT_management_link/get_Theatre_Booking_Other_Details"
      )
      .then((response) => setTheatre_Booking_Other_Details(response.data))
      .catch((err) => console.log(err));
  }, []);

  //  console.log("oooooooo",Theatre_Booking_Other_Details)

  // -------------------------------------------4tables----------------------------------------------------------------------------

  const OP_med_filter = () => {
    if (Medicine_Type === "--Select--") {
      alert("Select Medicine Type");
    }

    if (Medicine_Type === "Tablet") {
      let OPtempmad = OPmedicineArr.filter((ele) => {
        return ele.category === "Tablet";
      });
      setFilterOPmedicineArr(OPtempmad);
    }
    if (Medicine_Type === "Syringe") {
      let OPtempmad = OPmedicineArr.filter((ele) => {
        return ele.category === "Syringe";
      });
      setFilterOPmedicineArr(OPtempmad);
    }
  };

  useEffect(() => {
    OP_med_filter();
  }, [Medicine_Type]);

  useEffect(() => {
    axios
      .get(
        "http://localhost:5000/getallinputdetailes/get_anaesthetist_requirements_list"
      )
      .then((response) => setOPmedicineArr(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setFilterspecial([
      ...new Set(Doctordetailearr.map((item) => item.Specialization)),
    ]);
  }, [SelecetSpecialization]);

  useEffect(() => {
    setFilterspecialDoctorename(
      Doctordetailearr.filter(
        (item) => item.Specialization === SelecetSpecialization
      )
    );
  }, [SelecetDoctorename]);

  const getdata_OP_pasent = () => {
    if (SearchIPno === "") {
      alert("Enter IP No");
    } else {
      let Srchipno = parseInt(SearchIPno);
      let tamp_obj = pasentdetailearr?.find((ele) => {
        return +ele.IP_NO === +Srchipno;
      });
      console.log(pasentdetailearr);
      console.log(tamp_obj);
      setpasentdetaileobj(tamp_obj);
    }
  };

  const SubmitSurgionname = () => {
    if (SelectSurgeon === "") {
      alert("please enter the Surgeonname");
    } else if (Surgeonstemparr.some((e) => e.Name === SelectSurgeon)) {
      alert("You are alredy enter the Surgeonname");
      setSelectSurgeon("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.surgeons.some((surgeon) =>
          surgeon.Name.includes(SelectSurgeon)
        );
      })
    ) {
      alert("Surgeon was busy");
      setSelectSurgeon("");
    } else {
      let emp_id = Employeedataarr.filter((e) => e.Name === SelectSurgeon).find(
        (e) => e
      )?.id;

      setSurgeonstemparr([
        ...Surgeonstemparr,
        { Emp_id: emp_id, Name: SelectSurgeon },
      ]);
      setSelectSurgeon("");
    }
  };

  const DeleteSurgeonsname = (Name) => {
    let Balancearr = Surgeonstemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setSurgeonstemparr(Balancearr);
    setSelectSurgeon("");
  };

  const SubmitAssSurgionname = () => {
    if (SelectAssSurgeon === "") {
      alert("please enter the Ass.Surgenname");
    } else if (AssSurgeontemparr.some((e) => e.Name === SelectAssSurgeon)) {
      alert("You are alredy enter the Ass.Surgenname");
      setSelectAssSurgeon("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Assistant_Surgeon.some((Assistant_Surgeon) =>
          Assistant_Surgeon.Name.includes(SelectAssSurgeon)
        );
      })
    ) {
      alert("Assistant_Surgeon was busy");
      setSelectAssSurgeon("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectAssSurgeon
      ).find((e) => e)?.id;

      setAssSurgeontemparr([
        ...AssSurgeontemparr,
        { Emp_id: emp_id, Name: SelectAssSurgeon },
      ]);
      setSelectAssSurgeon("");
    }
  };

  const DeleteAssSurgeonsname = (Name) => {
    let Balancearr = AssSurgeontemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setAssSurgeontemparr(Balancearr);
    setSelectAssSurgeon("");
  };

  const SubmitAnaestheticianname = () => {
    if (SelectAnaesthetician === "") {
      alert("please enter the Anaesthetician");
    } else if (
      Anaestheticiantemparr.some((e) => e.Name === SelectAnaesthetician)
    ) {
      alert("You are alredy enter the Anaesthetician");
      setSelectAnaesthetician("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Anaesthetician.some((Anaesthetician) =>
          Anaesthetician.Name.includes(SelectAnaesthetician)
        );
      })
    ) {
      alert("Anaesthetician was busy");
      setSelectAnaesthetician("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectAnaesthetician
      ).find((e) => e)?.id;

      setAnaestheticiantemparr([
        ...Anaestheticiantemparr,
        { Emp_id: emp_id, Name: SelectAnaesthetician },
      ]);
      setSelectAnaesthetician("");
    }
  };

  const DeleteAnaestheticianname = (Name) => {
    let Balancearr = Anaestheticiantemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setAnaestheticiantemparr(Balancearr);
    setSelectAnaesthetician("");
  };

  const SubmitTechnicianname = () => {
    if (SelectTechnician === "") {
      alert("please enter the Technician");
    } else if (Techniciantemparr.some((e) => e.Name === SelectTechnician)) {
      alert("You are alredy enter the Technician");
      setSelectTechnician("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Technician.some((Technician) =>
          Technician.Name.includes(SelectTechnician)
        );
      })
    ) {
      alert("Technician was busy");
      setSelectTechnician("");
    } else {
      let emp_id = Employeedataarr.filter(
        (e) => e.Name === SelectTechnician
      ).find((e) => e)?.id;

      setTechniciantemparr([
        ...Techniciantemparr,
        { Emp_id: emp_id, Name: SelectTechnician },
      ]);
      setSelectTechnician("");
    }
  };

  const DeleteTechnicianname = (Name) => {
    let Balancearr = Techniciantemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setTechniciantemparr(Balancearr);
    setSelectTechnician("");
  };

  const SubmitNursename = () => {
    if (SelectNurse === "") {
      alert("please enter the Nurse");
    } else if (Nursetemparr.some((e) => e.Name === SelectNurse)) {
      alert("You are alredy enter the Nurse");
      setSelectNurse("");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      }) &&
      Theatre_Booking_Doctors_Details.some((ele) => {
        return ele.Nurse.some((Nurse) => Nurse.Name.includes(SelectNurse));
      })
    ) {
      alert("Nurse was busy");
      setSelectNurse("");
    } else {
      let emp_id = Employeedataarr.filter((e) => e.Name === SelectNurse).find(
        (e) => e
      )?.id;

      setNursetemparr([...Nursetemparr, { Emp_id: emp_id, Name: SelectNurse }]);
      setSelectNurse("");
    }
  };

  const DeleteNursename = (Name) => {
    let Balancearr = Nursetemparr.filter((ele) => {
      return ele.Name !== Name;
    });
    setNursetemparr(Balancearr);
    setSelectNurse("");
  };

  const SubmitAnaesthetist_Requirements = () => {
    if (Medicine_Type === "") {
      alert("please enter the Medicine Type");
    }
    if (TabletORSyringedata === "") {
      alert("please enter the Tablet / Syringe Name");
    }
    if (AnaesthetistDose === "") {
      alert("please enter the Dose");
    } else if (
      Anaesth_Requ_data_arr.some(
        (e) =>
          e.MedicineType === Medicine_Type &&
          e.TabletORSyringe_Name === TabletORSyringedata
      )
    ) {
      alert("You are alredy enter the Tablet OR Syringe");
      SetMedicine_Type("");
      setTabletORSyringedata("");
      setAnaesthetistDose("");
    } else {
      setAnaesth_Requ_data_arr([
        ...Anaesth_Requ_data_arr,
        {
          MedicineType: Medicine_Type,
          TabletORSyringe_Name: TabletORSyringedata,
          Dose: AnaesthetistDose,
        },
      ]);
      SetMedicine_Type("");
      setTabletORSyringedata("");
      setAnaesthetistDose("");
      setFilterOPmedicineArr([]);
    }
  };

  const Delete_Anaesthetist_Req = (Name) => {
    console.log(Name);

    let Balancearr = Anaesth_Requ_data_arr.filter((ele) => {
      return ele.TabletORSyringe_Name !== Name;
    });
    setAnaesth_Requ_data_arr(Balancearr);
    setSelectAssSurgeon("");
    SetMedicine_Type("");
    setTabletORSyringedata("");
    setAnaesthetistDose("");
  };




  // ---------------------------------------------------------------

  const SubmitCSSDKit_Requirements = () => {
    if (selectCSSDKit === "") {
      alert("Please enter the CSSD Kit");
    } else if (CSSDKitlist.some((e) => e.Name === SelectNurse)) {
      alert("You have already entered the Nurse");
      setselectCSSDKit("");
    } else {
      setCSSDKitlist([...CSSDKitlist, { Emp_id:CSSDKitlist.length+1, Name: selectCSSDKit }]);
      setselectCSSDKit("");
    }
  };

  const DeleteCSSDKit = (Name) => {
    let Balancearr = CSSDKitlist.filter((ele) => {
      return ele.Name !== Name;
    });
    setCSSDKitlist(Balancearr);
    setselectCSSDKit("");
  };




  useEffect(() => {
    if (
      !!singlepasentdetaileobj &&
      Theatre_Booking_personal_information.some((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      })
    ) {
      // alert("Alredy OP Booked")

      let OP_p_detaile = Theatre_Booking_personal_information.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      let OP_D_detaile = Theatre_Booking_Doctors_Details.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      let OP_A_detaile = Theatre_Booking_Anaesthetist_Details.filter((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });
      console.log("yyy", OP_A_detaile);

      let OP_O_detaile = Theatre_Booking_Other_Details.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      });

      setOpdate(OP_p_detaile.OPDate);
      setOptime(OP_p_detaile.OPtime);
      setTheatername(OP_p_detaile.Theatre_Name);
      setSelecetSpecialization(OP_p_detaile.Specialization);
      setSelecetDoctorename(OP_p_detaile.Doctor_Name);
      setOprationstarttime(OP_p_detaile.Start_Time);
      setOprationDurationhours(OP_p_detaile.Duration);
      setSurgeonstemparr(OP_D_detaile.surgeons);
      setAssSurgeontemparr(OP_D_detaile.Assistant_Surgeon);
      setAnaestheticiantemparr(OP_D_detaile.Anaesthetician);
      setTechniciantemparr(OP_D_detaile.Technician);
      setNursetemparr(OP_D_detaile.Nurse);
      setAnaesth_Requ_data_arr(OP_A_detaile);
      setPre_Opration_notes(OP_O_detaile.Pre_opration_detailes);
      setPost_Opration_notes(OP_O_detaile.Post_opration_detailes);
      setOT_Notes_input(OP_O_detaile.OT_Notes);
      setOT_Remarks_input(OP_O_detaile.Remarks);

      setEdit_btn_condition(true);
    }
  }, [singlepasentdetaileobj]);

  const Theater_Doctor_det_submit_fun = () => {
    const inputTime = Optimeinput;
    const hoursToAdd = parseInt(OprationDurationhours);
    console.log(inputTime);
    console.log(hoursToAdd);

    const timeParts = inputTime.split(":");
    const dateObj = new Date();
    dateObj.setHours(Number(timeParts[0]));
    dateObj.setMinutes(Number(timeParts[1]));

    // Add hours
    dateObj.setHours(dateObj.getHours() + hoursToAdd);

    // Format the result back to a string
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const End_time = `${hours}:${minutes}`;

    if (
      Theatre_Booking_personal_information.some((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      })
    ) {
      alert("Alredy OP Booked");
    }

    if (
      SearchIPno === "" ||
      Object.keys(singlepasentdetaileobj).length === 0 ||
      Opdate === "" ||
      Optimeinput === "" ||
      Theatername === "" ||
      SelecetSpecialization === "" ||
      SelecetDoctorename === "" ||
      Oprationstarttime === "" ||
      OprationDurationhours === "" ||
      Surgeonstemparr.length === 0 ||
      AssSurgeontemparr.length === 0 ||
      Anaestheticiantemparr.length === 0 ||
      Techniciantemparr.length === 0 ||
      Nursetemparr.length === 0
    ) {
      alert("Please Enter All data");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Theatre_Name === Theatername
        );
      })
    ) {
      alert("Theater Alredy Booked");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Doctor_Name === SelecetDoctorename
        );
      })
    ) {
      alert("Doctor Alredy Booked");
    } else {
      let HSI_NO = singlepasentdetaileobj?.HSI_NO;
      let PatientName = singlepasentdetaileobj?.PatientName;
      let patientType = singlepasentdetaileobj?.patientType;
      let PatientGender = singlepasentdetaileobj?.PatientGender;
      let PatientWardno = singlepasentdetaileobj?.PatientWardno;

      axios
        .post(
          "http://localhost:5000/OT_management_link/Theatre_Booking_personal_information",
          {
            SearchIPno,
            HSI_NO,
            Opdate,
            Optimeinput,
            PatientName,
            patientType,
            PatientGender,
            PatientWardno,
            Theatername,
            SelecetSpecialization,
            SelecetDoctorename,
            Oprationstarttime,
            OprationDurationhours,
            End_time,
          }
        )
        .then((response) => {
          // setTheatername("")
          // setSelecetSpecialization("")
          // setSelecetDoctorename("")
          // setOprationstarttime("")
          // setOprationDurationhours("")
          // setOpdate("")
          // setOptime("")
          // setSearchIPno("")
          // setpasentdetaileobj({
          //     "id":"",
          //     "IP_NO":"",
          //     "HSI_NO":"",
          //     "PatientName": "",
          //     "PatientGender": "",
          //     "PatientWardno":"",
          //     "patientType":""
          // })
          console.log(response);
          alert("Data successfully Submit");
        })
        .catch((error) => console.log(error));

      axios
        .post(
          "http://localhost:5000/OT_management_link/Theatre_Booking_Doctors_Details",
          {
            SearchIPno,
            Surgeonstemparr,
            AssSurgeontemparr,
            Anaestheticiantemparr,
            Techniciantemparr,
            Nursetemparr,
          }
        )
        .then((response) => {
          // setSurgeonstemparr([])
          // setAssSurgeontemparr([])
          // setAnaestheticiantemparr([])
          // setTechniciantemparr([])
          // setNursetemparr([])
          console.log(response);
          alert("Data successfully Submit");
          setpagesview(!pagesview);
        })
        .catch((error) => console.log(error));
    }
  };

  const Theater_Doctor_det_Update_fun = () => {
    const inputTime = Optimeinput;
    const hoursToAdd = parseInt(OprationDurationhours);
    // console.log(inputTime)
    // console.log(hoursToAdd)

    const timeParts = inputTime.split(":");
    const dateObj = new Date();
    dateObj.setHours(Number(timeParts[0]));
    dateObj.setMinutes(Number(timeParts[1]));

    // Add hours
    dateObj.setHours(dateObj.getHours() + hoursToAdd);

    // Format the result back to a string
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");

    const End_time = `${hours}:${minutes}`;

    if (
      SearchIPno === "" ||
      Object.keys(singlepasentdetaileobj).length === 0 ||
      Opdate === "" ||
      Optimeinput === "" ||
      Theatername === "" ||
      SelecetSpecialization === "" ||
      SelecetDoctorename === "" ||
      Oprationstarttime === "" ||
      OprationDurationhours === "" ||
      Surgeonstemparr.length === 0 ||
      AssSurgeontemparr.length === 0 ||
      Anaestheticiantemparr.length === 0 ||
      Techniciantemparr.length === 0 ||
      Nursetemparr.length === 0
    ) {
      alert("Please Enter All data");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.IP_NO === SearchIPno &&
          ele.OPDate === Opdate &&
          ele.Theatre_Name === Theatername &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2)
        );
      })
    ) {
      alert("Theater Alredy Booked");
    } else if (
      Theatre_Booking_personal_information.some((ele) => {
        return (
          ele.IP_NO === SearchIPno &&
          ele.OPDate === Opdate &&
          ele.OPtime.slice(0, 2) <= Optimeinput.slice(0, 2) &&
          ele.Endtime.slice(0, 2) >= Optimeinput.slice(0, 2) &&
          ele.Doctor_Name === SelecetDoctorename
        );
      })
    ) {
      alert("Doctor Alredy Booked");
    } else {
      let OP_Theatre_ID = Theatre_Booking_personal_information.find((ele) => {
        return ele.IP_NO === parseInt(SearchIPno);
      }).OP_Theatre_ID;

      let HSI_NO = singlepasentdetaileobj?.HSI_NO;
      let PatientName = singlepasentdetaileobj?.PatientName;
      let patientType = singlepasentdetaileobj?.patientType;
      let PatientGender = singlepasentdetaileobj?.PatientGender;
      let PatientWardno = singlepasentdetaileobj?.PatientWardno;

      axios
        .post(
          `http://localhost:5000/OT_management_link/Theatre_Booking_personal_information_update/${OP_Theatre_ID}`,
          {
            SearchIPno,
            HSI_NO,
            Opdate,
            Optimeinput,
            PatientName,
            patientType,
            PatientGender,
            PatientWardno,
            Theatername,
            SelecetSpecialization,
            SelecetDoctorename,
            Oprationstarttime,
            OprationDurationhours,
            End_time,
          }
        )
        .then((response) => {
          // setTheatername("")
          // setSelecetSpecialization("")
          // setSelecetDoctorename("")
          // setOprationstarttime("")
          // setOprationDurationhours("")
          // // setSearchIPno("")
          // setOpdate("")
          // setOptime("")
          // setpasentdetaileobj({
          //     "id":"",
          //     "IP_NO":"",
          //     "HSI_NO":"",
          //     "PatientName": "",
          //     "PatientGender": "",
          //     "PatientWardno":"",
          //     "patientType":""
          // })

          console.log(response);
          alert("Data successfully Updated");
        })
        .catch((error) => console.log(error));

      axios
        .post(
          `http://localhost:5000/OT_management_link/Theatre_Booking_Doctors_Details_update/${OP_Theatre_ID}`,
          {
            SearchIPno,
            Surgeonstemparr,
            AssSurgeontemparr,
            Anaestheticiantemparr,
            Techniciantemparr,
            Nursetemparr,
          }
        )
        .then((response) => {
          // setSurgeonstemparr([])
          // setAssSurgeontemparr([])
          // setAnaestheticiantemparr([])
          // setTechniciantemparr([])
          // setNursetemparr([])
          console.log(response);
          alert("Data successfully Updated");
        })
        .catch((error) => console.log(error));

      setpagesview(!pagesview);
    }
  };

  const OT_after_OP_submit = () => {
    axios
      .post(
        "http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details",
        {
          SearchIPno,
          Anaesth_Requ_data_arr,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        "http://localhost:5000/OT_management_link/Theatre_Booking_Other_Details",
        {
          SearchIPno,
          Pre_Opration_notes,
          Post_Opration_notes,
          OT_Notes_input,
          OT_Remarks_input,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
        setpagesview(!pagesview);
        setValue(0);
        setTheatername("");
        setSelecetSpecialization("");
        setSelecetDoctorename("");
        setOprationstarttime("");
        setOprationDurationhours("");
        setSearchIPno("");
        setOpdate("");
        setOptime("");
        setpasentdetaileobj({
          id: "",
          IP_NO: "",
          HSI_NO: "",
          PatientName: "",
          PatientGender: "",
          PatientWardno: "",
          patientType: "",
        });

        setSurgeonstemparr([]);
        setAssSurgeontemparr([]);
        setAnaestheticiantemparr([]);
        setTechniciantemparr([]);
        setNursetemparr([]);
        setAnaesth_Requ_data_arr([]);
        setPre_Opration_notes("");
        setPost_Opration_notes("");
        OT_Notes_input("");
        OT_Remarks_input("");
      })
      .catch((error) => console.log(error));
  };

  const OT_after_OP_update = () => {
    // let get_anaesthetist_id=Theatre_Booking_Anaesthetist_Details.filter((ele)=>{return ele.IP_NO===parseInt(SearchIPno)}).map((e)=> e.Theatre_Booking_Anaesthetist_ID)

    // console.log("kkkkk",get_anaesthetist_id)

    let OP_Theatre_ID = Theatre_Booking_personal_information.find((ele) => {
      return ele.IP_NO === parseInt(SearchIPno);
    }).OP_Theatre_ID;

    axios
      .delete(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details_update/${OP_Theatre_ID}`
      )
      .then((response) => {
        console.log(response);
        alert("Delete");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Anaesthetist_Details_update/${OP_Theatre_ID}`,
        {
          SearchIPno,
          OP_Theatre_ID,
          Anaesth_Requ_data_arr,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Data successfully Submit");
      })
      .catch((error) => console.log(error));

    axios
      .post(
        `http://localhost:5000/OT_management_link/Theatre_Booking_Other_Details_update/${OP_Theatre_ID}`,
        {
          SearchIPno,
          Pre_Opration_notes,
          Post_Opration_notes,
          OT_Notes_input,
          OT_Remarks_input,
        }
      )
      .then((response) => {
        console.log(response);
        alert("Ddddddd");
        setpagesview(!pagesview);
        setValue(0);
        setTheatername("");
        setSelecetSpecialization("");
        setSelecetDoctorename("");
        setOprationstarttime("");
        setOprationDurationhours("");
        setSearchIPno("");
        setOpdate("");
        setOptime("");
        setpasentdetaileobj({
          id: "",
          IP_NO: "",
          HSI_NO: "",
          PatientName: "",
          PatientGender: "",
          PatientWardno: "",
          patientType: "",
        });

        setSurgeonstemparr([]);
        setAssSurgeontemparr([]);
        setAnaestheticiantemparr([]);
        setTechniciantemparr([]);
        setNursetemparr([]);
        setAnaesth_Requ_data_arr([]);
        setPre_Opration_notes("");
        setPost_Opration_notes("");
        OT_Notes_input("");
        OT_Remarks_input("");
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div className="appointment">
        <div className="h_head">
          <h3>Theatre Booking</h3>
        </div>
        <div className="Theatre_booking_con">
          <div className="Theatre_booking_con_form">
            <div className="Theatre_booking_con_1">
              <div className="Theatre_booking_form">
                <div className="Theatre_booking_form_row">
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      IP No<span>:</span>
                    </label>
                    <input
                      type="text"
                      value={SearchIPno}
                      placeholder="Enter IP No"
                      onChange={(e) => {
                        setSearchIPno(e.target.value);
                      }}
                    />
                    <div
                      className="Ip_No_searchicon"
                      onClick={getdata_OP_pasent}
                    >
                      <SearchIcon />
                    </div>
                  </div>

                  <div className="Theatre_booking_form_row_div">
                    <label>
                      HIS No<span>:</span>
                    </label>
                    <input
                      value={singlepasentdetaileobj?.HSI_NO}
                      type="text"
                      placeholder="Enter HIS No"
                      readOnly
                    />
                  </div>
                </div>
                <div className="Theatre_booking_form_row">
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Patient Name<span>:</span>
                    </label>
                    <input
                      value={singlepasentdetaileobj?.PatientName}
                      type="text"
                      placeholder="Enter Patient Name"
                      readOnly
                    />
                  </div>
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Patient Type<span>:</span>
                    </label>
                    <input
                      value={singlepasentdetaileobj?.patientType}
                      type="text"
                      placeholder="Enter Patient Type"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="Theatre_booking_con_1">
              <div className="Theatre_booking_form">
                <div className="Theatre_booking_form_row">
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Date<span>:</span>
                    </label>
                    <input
                      type="date"
                      value={Opdate}
                      onChange={(e) => {
                        setOpdate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Time<span>:</span>
                    </label>
                    <input
                      type="time"
                      value={Optimeinput}
                      placeholder="Enter Time"
                      onChange={(e) => {
                        setOptime(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="Theatre_booking_form_row">
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Sex / Age<span>:</span>
                    </label>
                    <input
                      value={singlepasentdetaileobj?.PatientGender}
                      type="text"
                      placeholder="Enter Sex / Age"
                      readOnly
                    />
                  </div>
                  <div className="Theatre_booking_form_row_div">
                    <label>
                      Ward / Bed No<span>:</span>
                    </label>
                    <input
                      value={singlepasentdetaileobj?.PatientWardno}
                      type="text"
                      placeholder="Enter Ward/ Bed No"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div>
            <Accordion
              expanded={
                expanded !== "panel2" &&
                expanded !== "panel3" &&
                expanded !== "panel4" &&
                expanded !== "panel5"
              }
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Theatre & Doctors Details
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <div className="Theatre_booking_form">
                    <div className="theatre_Hosp_detaile_Top_container">
                      <div className="theatre_Hosp_detaile_container">
                        <div className="Theatre_Hos_Row_width">
                          <label>
                            Theatre Name<span>:</span>
                          </label>
                          <input
                            list="TheaterList"
                            value={Theatername}
                            onChange={(e) => {
                              setTheatername(e.target.value);
                            }}
                            className="Big_input_box"
                          />
                          <datalist id="TheaterList">
                            {Theaternamelist.map((ele) => (
                              <option key={ele?.id} value={ele.Name} />
                            ))}
                          </datalist>
                        </div>

                        <div className="Theatre_Hos_Row_width">
                          <label>
                            Specialization<span>:</span>
                          </label>
                          <input
                            list="SpecializationList"
                            value={SelecetSpecialization}
                            onChange={(e) => {
                              setSelecetSpecialization(e.target.value);
                            }}
                            className="Big_input_box"
                          />
                          <datalist id="SpecializationList">
                            {Filterspecial.map((ele) => (
                              <option value={ele} />
                            ))}
                          </datalist>
                        </div>
                      </div>

                      <div className="theatre_Hosp_detaile_container">
                        <div className="Theatre_Hos_Row_width">
                          <label>
                            Doctor Name<span>:</span>
                          </label>
                          <input
                            list="DoctorsnameList"
                            value={SelecetDoctorename}
                            onChange={(e) => {
                              setSelecetDoctorename(e.target.value);
                            }}
                            className="Big_input_box"
                          />
                          <datalist id="DoctorsnameList">
                            {FilterspecialDoctorename.map((ele) => (
                              <option value={ele.Name} />
                            ))}
                          </datalist>
                        </div>
                        <div className="Theatre_Hos_Row_width">
                          <label>
                            Start Time<span>:</span>
                          </label>
                          <input
                            type="time"
                            className="Start_time_box"
                            value={Oprationstarttime}
                            onChange={(e) => {
                              setOprationstarttime(e.target.value);
                            }}
                          />
                        </div>
                        <div className="Theatre_Hos_Row_width">
                          <label>
                            Duration<span>:</span>
                          </label>
                          <input
                            type="number"
                            className="Small_input_box wew21"
                            value={OprationDurationhours}
                            onChange={(e) => {
                              setOprationDurationhours(e.target.value);
                            }}
                            min="1"
                          />{" "}
                          <span className="Durationletter"> *Only hours</span>
                        </div>
                      </div>

                      <div className="theatre_Hosp_detaile_container"></div>

                      <>
                        <h3>Doctors Details</h3>
                        <br></br>
                        <div className="theatre_Hosp_detaile_container">
                          <div className="Theatre_Hos_Row_width">
                            <label>
                              Surgeon<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="SurgeonnameList"
                              value={SelectSurgeon}
                              onChange={(e) => {
                                setSelectSurgeon(e.target.value);
                              }}
                            />
                            <datalist id="SurgeonnameList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Surgeon";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist>
                            <button
                              className="Addnamebtn"
                              onClick={SubmitSurgionname}
                            >
                              +
                            </button>
                          </div>

                          <div className="Theatre_Hos_Row_width">
                            <label>
                              Ass.Surgeon<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="Ass_SurgeonnameList"
                              value={SelectAssSurgeon}
                              onChange={(e) => {
                                setSelectAssSurgeon(e.target.value);
                              }}
                            />
                            <datalist id="Ass_SurgeonnameList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Ass_Surgeon";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist>
                            <button
                              className="Addnamebtn"
                              onClick={SubmitAssSurgionname}
                            >
                              +
                            </button>
                          </div>
                          <div className="Theatre_Hos_Row_width">
                            <label>
                              Anaesthetician<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="AnaestheticianList"
                              value={SelectAnaesthetician}
                              onChange={(e) => {
                                setSelectAnaesthetician(e.target.value);
                              }}
                            />
                            <datalist id="AnaestheticianList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Anaesthetician";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist>
                            <button
                              className="Addnamebtn"
                              onClick={SubmitAnaestheticianname}
                            >
                              +
                            </button>
                          </div>
                        </div>

                        <div className="theatre_Hosp_detaile_container">
                          <div className="Theatre_Hos_Row_width">
                            <label>
                              Technician<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="TechnicianList"
                              value={SelectTechnician}
                              onChange={(e) => {
                                setSelectTechnician(e.target.value);
                              }}
                            />
                            <datalist id="TechnicianList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Technician";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist>
                            <button
                              className="Addnamebtn"
                              onClick={SubmitTechnicianname}
                            >
                              +
                            </button>
                          </div>

                          <div className="Theatre_Hos_Row_width">
                            <label>
                              Nurse<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="NurseList"
                              value={SelectNurse}
                              onChange={(e) => {
                                setSelectNurse(e.target.value);
                              }}
                            />
                            <datalist id="NurseList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Nurse";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist>
                            <button
                              className="Addnamebtn"
                              onClick={SubmitNursename}
                            >
                              +
                            </button>
                          </div>

                          {/* --------------------------------- */}

                          <div className="Theatre_Hos_Row_width">
                            <label>
                              CSSD Kit<span>:</span>
                            </label>
                            <input
                              type="text"
                              className="Big_input_box"
                              list="CSSDKit"
                              value={selectCSSDKit}
                              onChange={(e) => {
                                setselectCSSDKit(e.target.value);
                              }}
                            />

                            {/* <datalist id="NurseList">
                              {Employeedataarr.filter((e) => {
                                return e.category === "Nurse";
                              }).map((ele) => (
                                <option key={ele?.id} value={ele.Name} />
                              ))}
                            </datalist> */}

                            <button
                              className="Addnamebtn"
                              onClick={SubmitCSSDKit_Requirements}
                            >
                              +
                            </button>
                          </div>



                        </div>

                        <div className="Register_btn_con">
                          {Edit_btn_condition === true ? (
                            <button
                              className="RegisterForm_1_btns"
                              onClick={Theater_Doctor_det_Update_fun}
                            >
                              Update
                            </button>
                          ) : (
                            <button
                              className="RegisterForm_1_btns"
                              onClick={Theater_Doctor_det_submit_fun}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </>
                      <>

                        <div className="Doc_detaile_Tables_place">
                          {Surgeonstemparr.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>Surgeons</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {Surgeonstemparr && Surgeonstemparr.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteSurgeonsname(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}

                          {AssSurgeontemparr.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>Ass.Surgeons</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {AssSurgeontemparr &&
                                  AssSurgeontemparr.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteAssSurgeonsname(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}
                        </div>

                        <div className="Doc_detaile_Tables_place">
                          {Anaestheticiantemparr.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>Anaesthetician</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {Anaestheticiantemparr &&
                                  Anaestheticiantemparr.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteAnaestheticianname(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}

                          {Techniciantemparr.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>Technician</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {Techniciantemparr &&
                                  Techniciantemparr.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteTechnicianname(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}
                        </div>

                        <div className="Doc_detaile_Tables_place">
                          {Nursetemparr.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>Nurse</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {Nursetemparr &&
                                  Nursetemparr.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteNursename(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}



                        {CSSDKitlist.length === 0 ? (
                            ""
                          ) : (
                            <div className="Doc_names_table">
                              <h4>CSSD Kit</h4>
                              <table>
                                <tr>
                                  <th>Name</th>
                                  <th>Action</th>
                                </tr>
                                {CSSDKitlist &&
                                  CSSDKitlist.map((ele, ind) => (
                                    <tr key={"mytbl" + ind}>
                                      <td>{ele.Name}</td>
                                      <td>
                                        <button
                                          className="Table_edit_btn"
                                          onClick={() => {
                                            DeleteCSSDKit(ele.Name);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </div>
                          )}
                        </div>


                      </>
                    </div>
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Anaesthetist Requirement
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <>
                    <div className="theatre_Hosp_detaile_container">
                      <div className="Theatre_Hos_Row_width">
                        <label htmlFor="Medtype">
                          Medicine Type<span>:</span>
                        </label>
                        <select
                          id="Medtype"
                          name="Medtype"
                          value={Medicine_Type}
                          onChange={(e) => {
                            SetMedicine_Type(e.target.value);
                          }}
                          className="Anaesthetist_Select_tag"
                        >
                          <option value="">Select</option>
                          <option value="Tablet">Tablet</option>
                          <option value="Syringe">Syringe</option>
                        </select>
                      </div>

                      <div className="Theatre_Hos_Row_width">
                        <label htmlFor="experience">
                          Tablet / Syringe<span>:</span>
                        </label>
                        <input
                          type="text"
                          className="Big_input_box_anasthetist"
                          list="Tablet_Syringe_list"
                          value={TabletORSyringedata}
                          onChange={(e) => {
                            setTabletORSyringedata(e.target.value);
                          }}
                        />
                        <datalist id="Tablet_Syringe_list">
                          {FilterOPmedicineArr.map((ele) => (
                            <option key={ele?.id} value={ele.Name} />
                          ))}
                        </datalist>
                      </div>
                      <div className="Theatre_Hos_Row_width">
                        <label htmlFor="certification">
                          Dose<span>:</span>
                        </label>
                        <input
                          type="text"
                          className="Dose_input_anasthetist"
                          value={AnaesthetistDose}
                          onChange={(e) => {
                            setAnaesthetistDose(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div className="Register_btn_con">
                      <button
                        className="RegisterForm_1_btns"
                        onClick={SubmitAnaesthetist_Requirements}
                      >
                        Add
                      </button>
                    </div>

                    {Anaesth_Requ_data_arr.length === 0 ? (
                      ""
                    ) : (
                      <div className="Anaesthetist_detaile_table_place">
                        <div className="Anaesthetist_detaile_table">
                          <h4>Anaesthetist Requirements</h4>
                          <table>
                            <tr>
                              <th>Medicine Type</th>
                              <th>Name</th>
                              <th>Dose</th>
                              <th>Action</th>
                            </tr>
                            {Anaesth_Requ_data_arr &&
                              Anaesth_Requ_data_arr.map((ele, ind) => (
                                <tr key={"mytbl" + ind}>
                                  <td>{ele.MedicineType}</td>
                                  <td>{ele.TabletORSyringe_Name}</td>
                                  <td>{ele.Dose}</td>
                                  <td>
                                    <button
                                      className="Table_edit_btn"
                                      onClick={() => {
                                        Delete_Anaesthetist_Req(
                                          ele.TabletORSyringe_Name
                                        );
                                      }}
                                    >
                                      <DeleteIcon />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </table>
                        </div>
                      </div>
                    )}
                  </>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Patient Conditions
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <>
                    <div className="Patiant_condition_full_page">
                      <div className="Patiant_condition_check_btn">
                        <ToggleButtonGroup exclusive aria-label="Platform">
                          <ToggleButton
                            onClick={() => {
                              setType("pre_con");
                            }}
                            style={{
                              width: "140px",
                              height: "40px",
                              backgroundColor:
                                type === "pre_con"
                                  ? "var(--selectbackgroundcolor)"
                                  : "inherit",
                            }}
                          >
                            <span style={{ whiteSpace: "nowrap" }}>
                              Pre Operation
                            </span>
                          </ToggleButton>
                          <ToggleButton
                            onClick={() => {
                              setType("post_con");
                            }}
                            style={{
                              backgroundColor:
                                type === "post_con"
                                  ? "var(--selectbackgroundcolor)"
                                  : "inherit",
                              width: "140px",
                              height: "40px",
                            }}
                          >
                            <span style={{ whiteSpace: "nowrap" }}>
                              Post Operation
                            </span>
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </div>
                      <br></br>
                      <div className="Pre_Post_Op_detaile_palce">
                        {type === "pre_con" ? (
                          <div>
                            <textarea
                              value={Pre_Opration_notes}
                              onChange={(e) => {
                                setPre_Opration_notes(e.target.value);
                              }}
                              placeholder="Enter Pre Operation"
                            />
                          </div>
                        ) : (
                          <div>
                            <textarea
                              value={Post_Opration_notes}
                              onChange={(e) => {
                                setPost_Opration_notes(e.target.value);
                              }}
                              placeholder="Enter Post Operation"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  OT Notes
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <div className="OT_notes_place">
                    <textarea
                      value={OT_Notes_input}
                      onChange={(e) => {
                        setOT_Notes_input(e.target.value);
                      }}
                      placeholder="Enter OT Notes"
                    />
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChange("panel5")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{ width: "45%", flexShrink: 0 }}
                  id="panel1bh-header"
                >
                  Remarks
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography
                  sx={{ display: "flex", flexDirection: "column", gap: "10px" }}
                >
                  <div className="OT_notes_place">
                    <textarea
                      value={OT_Remarks_input}
                      onChange={(e) => {
                        setOT_Remarks_input(e.target.value);
                      }}
                      placeholder="Enter Remarks"
                    />
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
        <div className="Register_btn_con">
          {Edit_btn_condition === true ? (
            <button
              className="RegisterForm_1_btns"
              onClick={OT_after_OP_update}
            >
              Update
            </button>
          ) : (
            <button
              className="RegisterForm_1_btns"
              onClick={OT_after_OP_submit}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </>
  );
};

export default TheatreBooking;
