import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useSelector } from "react-redux";

function VisitingDoctor1() {
  const formData = useSelector((state) => state.userRecord?.workbenchformData);
  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const [formDetails, setformDetails] = useState({
    doctorName: "",
    phoneNumber: "",
    email: "",
    location: "",
    specialist: "",
    experience: "",
    // dateOfVisit: '',
  });

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value;

    if (/^\d{0,10}$/.test(inputPhoneNumber)) {
      setformDetails({ ...formDetails, phoneNumber: inputPhoneNumber });
    } else {
      alert("Phone Number must contain 10 digits");
    }
  };

  const handleSubmit = (e) => {
    const form = new FormData();

    Object.keys(formDetails).forEach((key) => {
      if (formDetails[key] !== null && formDetails[key] !== undefined) {
        form.append(key, formDetails[key]);
        form.append("branchlocation", userRecord?.location);
        form.append("createdby", userRecord?.username);
        form.append("patientID", formData.PatientID);
        form.append("patientName", formData.firstName + "" + formData.lastName);
        form.append("visitid", formData.visitNo);
      }
    });

    e.preventDefault();
    console.log("Form submitted:", formData);

    axios
      .post(
        "http://127.0.0.1:8000/VisitingDoctor/insert_VisitingDoctor_Info",
        form
      )
      .then((response) => {
        console.log(response.data);
        successMsg("Saved Successfully");
      })
      .catch((error) => {
        console.log(error);
        errmsg("Error occured");
      });
  };

  const successMsg = (msg) => {
    toast.success(`${msg}`, {
      position: "top-center",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };

  const errmsg = (errorMessage) => {
    toast.error(`${errorMessage}`, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { marginTop: "50px" },
    });
  };
  return (
    <>
      <div className="new-patient-registration-form">
        <div className="RegisFormcon">
          <div className="RegisForm_1">
            <label>
              Visit Doctor <span> Dr.</span>
            </label>
            <select
              name="doctorName"
              value={formDetails.doctorName}
              onChange={(e) =>
                setformDetails({ ...formDetails, doctorName: e.target.value })
              }
              required
            >
              <option value="">Select</option>
              <option value="BalaChandran"> BalaChandran</option>
              <option value="Rajesh"> Rajesh</option>
              <option value="Hemalatha"> Hemalatha</option>
            </select>
          </div>

          <div className="RegisForm_1">
            <label>
              Phone Number <span>:</span>
            </label>
            <input
              type="text"
              name="phoneNumber"
              value={formDetails.phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Email <span>:</span>
            </label>
            <input
              type="text"
              name="email"
              value={formDetails.email}

              onChange={(e) =>
                setformDetails({ ...formDetails, email: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Location <span>:</span>
            </label>
            <input
              type="text"
              name="location"
              value={formDetails.location}
              onChange={(e) =>
                setformDetails({ ...formDetails, location: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
               <label>
              Specialist <span>:</span>
            </label>
            <input
              type="text"
              name="specialist"
              value={formDetails.specialist}
              onChange={(e) =>
                setformDetails({ ...formDetails, specialist: e.target.value })
              }
              required
            />
          </div>
          <div className="RegisForm_1">
            <label>
              Experience <span>:</span>
            </label>
            <input
              type="text"
              name="experience"
              value={formDetails.experience}
              onChange={(e) =>
                setformDetails({ ...formDetails, experience: e.target.value })
              }
              required
            />
          </div>
        </div>
       
      </div>
      <div className="new-button-container">
        <button
          className="btncon_add prs-ad-sub-btn"
          type="submit"
          onClick={handleSubmit}
        >
          Submit
        </button>
        <ToastContainer />
      </div>
    </>
  );
}

export default VisitingDoctor1;
