import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Header.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { FaLocationDot } from "react-icons/fa6";
import profileImg from '../assets/profileimg.jpeg';
import { useDispatch, useSelector } from 'react-redux';

function Header( ) {
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

const dispatchvalue = useDispatch();

const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )

console.log(isSidebarOpen);

const  toggled = useSelector(state=>state.userRecord?.toggled )

  const [userRecord, setUserRecord] = useState(null)
  const [ClinicDetails, setClinicDetails] = useState([]);
  const [selectedOption, setSelectedOption] = useState('logged');
  const [profiletogg, setprofiletogg] = useState(false);
  const [loctntogg, setloctntogg] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [newImageProfile, setNewImageProfile] = useState(null);



  useEffect(() => {
    const userRecord = localStorage.getItem("token")

    if (userRecord) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(userRecord);
      console.log('decodedTokenData', decodedTokenData)
      setUserRecord(decodedTokenData)
    }

  }, [])


  const handleprofiletoggle = () => {
    setprofiletogg(!profiletogg);
    setDropdownVisible(!isDropdownVisible);

  };

  const handleloctntoggle = () => {
    setloctntogg(!loctntogg);
  };

  const handleSidebarToggle = () => {
    dispatchvalue({ type: 'isSidebarOpen', value: !isSidebarOpen });
    dispatchvalue({ type: 'toggled', value: !toggled });
  };
  


  const handleProfile = (event) => {
    const value = event.target.value;

    if (value === 'Profile') {
      navigate('/Home/Profile');
      setSelectedOption('Profile'); // Update the selected option after navigation
      setDropdownVisible(false);

    } else if (value === 'PasswordChange') {
      navigate('/Home/PasswordChange');

      setSelectedOption('PasswordChange'); // Update the selected option after navigation
      setDropdownVisible(false);
    } else {
      event.preventDefault();
    }
  };

  useEffect(() => {
    // Move side effects to useEffect

    axios
      .get(`http://127.0.0.1:8000/usercontrol/getAccountsetting`)
      .then((response) => {
        if (response.data) {
          const firstClinic = response.data;
          
          setClinicDetails(firstClinic); // Access the "Data" key
        } else {
          console.error('No record found');
        }
      })
      .catch((error) => console.error('Error fetching data'));

  }, []);

  // Empty dependency array for componentDidMount effect
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  useEffect(() => {
    if (userRecord) {
      axios.get("http://127.0.0.1:8000/profile/getuserprofile", {
        params: {
          user_id: userRecord?.user_id,
        },
      })
        .then((response) => {
          if (response.data && response.data.Profile_image) {
            const userData = response.data;
            console.log(userRecord?.user_id);
            setNewImageProfile(
              `data:${userData?.contentType};base64,${userData?.Profile_image}`
            );
          } else {
            console.log("User data or Profile_image not found or empty");
            // If user data or Profile_image is empty, set the default profile image
            setNewImageProfile(profileImg);
          }
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
          // If there's an error, set the default profile image
          setNewImageProfile(profileImg);
        });
    } else {
      // If userRecord is null, set the default profile image
      setNewImageProfile(profileImg);
    }
  }, [userRecord]);
  
  
  // Check if the current location matches the path for each option and update the selected option accordingly
  useEffect(() => {
    const path = location.pathname;
    if (path === '/Home/Profile') {
      setSelectedOption('Profile');
      setDropdownVisible(false);
    } else if (path === '/Home/PasswordChange') {
      setSelectedOption('PasswordChange');
      setDropdownVisible(false);
    } else {
      setSelectedOption('logged');
    }
  }, [location.pathname]);




  return (
    <>
      <div className='header_container_sidebar'>
        <div className="header mrgn_logo" >

          <div className='icn-tle-cntrfx'>

            <h1 className="header_name" >
              {ClinicDetails.clinicName}
            </h1>

            {ClinicDetails.clinicLogo && (
              <img
                src={`data:${ClinicDetails.contentType};base64,${ClinicDetails.clinicLogo}`}
                alt={ClinicDetails.clinicName}
                className="logosmc"
              />
            )}
          </div>

          <div className='prfl-lctn-adjs'>
            <div className='flx-lct-icn4'>

              <div><FaLocationDot className="loc_icon" /></div>
              <div> <h5 className='loc_input'>{userRecord?.location}</h5></div>

            </div>

            <div className="select-container">
              <img
                onClick={handleprofiletoggle}
                style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer',marginTop:'5px' }}
                src={newImageProfile || profileImg}
                alt="Profile"
              />



              {isDropdownVisible && (
                <div className="dropdown-container">
                  <div className="select-container">
                    <div className="select-style" value={selectedOption} onChange={handleProfile}>
                      <div value="logged" className='eifjidfj'>
                        {userRecord ? (
                          <><span>{userRecord?.username} ( {userRecord?.role_name} )</span></>
                        ) : (
                          <>Not logged in</>
                        )}
                      </div>
                      <br></br>
                      <div className="selectrole" value="Profile" onClick={(() => { navigate("/Home/Profile") })}>Profile</div>
                      <div className="selectrole" value="PasswordChange" onClick={(() => { navigate("/Home/PasswordChange") })}>Password Change</div>
                    </div>
                  </div>
                </div>
              )}
            </div>

          </div>
        </div>
      </div>


      <div className='header_container_sidebar1'>
        <div className="headersmc" >
          <IconButton
            onClick={handleSidebarToggle}
            color="inherit"
            aria-label={toggled ? 'Close menu' : 'Open menu'}
          >
            {toggled ? <CloseIcon /> : <MenuIcon />}
          </IconButton>


          <div  >
            {ClinicDetails.Clinic_Logo && (
              <img
              src={`data:${ClinicDetails.contentType};base64,${ClinicDetails.clinicLogo}`}
              alt={ClinicDetails.clinicName}
              className="logosmc"
            />
            )}

          </div>

          <div className='flx-lct-icn4'>


            <div className="header_logo_toggle" >
              <div className='prfl-lctn-adjs'>

                <div><FaLocationDot className="loc_icon" onClick={handleloctntoggle} /></div>
                {loctntogg &&
                  <div className='toggled_box_header'>
                    <div> <h5 className='loc_input'>{userRecord?.location}</h5></div>
                  </div>}

                <img
                  onClick={handleprofiletoggle}
                  style={{ width: '40px', height: '40px', borderRadius: '50%', cursor: 'pointer'}}
                  src={newImageProfile || profileImg}
                  alt="Profile"
                />




                {profiletogg &&
                  <div className='toggled_box_header'>
                    <div className="">
                      {userRecord ? (
                        <span>{userRecord?.username} ( {userRecord?.role_name} )</span>
                      ) : (
                        <span>Not logged in</span>
                      )}
                    </div>
                    <div className="toggled_box_header_txt" onClick={(() => { navigate("/Home/Profile") })}>
                      Profile
                    </div>
                    <div className="toggled_box_header_txt" onClick={(() => { navigate("/Home/PasswordChange") })}>
                      Password Change
                    </div>
                  </div>

                }
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  )
}

export default Header;
