import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import LoupeIcon from '@mui/icons-material/Loupe';
import EditIcon from '@mui/icons-material/Edit';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';




const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "#54d854bf",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  #54d854bf !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  
export default function LedgerList() {

    const dispatchvalue = useDispatch()

    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([])

    console.log('vcvc',summa)

    useEffect(() => {

        
           axios.get(`http://127.0.0.1:8000/FinanceMagement/get_Ledger_Entry_details`)
           .then((response) => {
               const data = response.data.LedgerEntries
               console.log('data',data)

               setsumma( [
                ...data.map((row, ind) => ({
                    id: ind + 1,
                    ...row,
                }))
            ]);
           })
           .catch((error) => {
               console.error('Error fetching patients data:', error);
           });
      
       
   },[]);


    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const index = params.row
        console.log('index:',index) 
        dispatchvalue({ type: 'Ledgerdata', value: index })
        navigate('/Home/Add_Accounts')

        
    };

    useEffect(() => {
        console.log('ss',summa)

        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.Ledger_Name.toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery,summa]);



    const handleRequestForm = () => {

        navigate('/Home/Add_Accounts')
        dispatchvalue({ type: 'Ledgerdata', value: {} })

    }

    
   

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'id', headerName: 'S.No', width: 200 },
        { field: 'date', headerName: 'Date', width: 200 },
        { field: 'Ledger_Name', headerName: 'Name', width: 200 },
        { field: 'Group_Name', headerName: 'Unter Group', width: 200 },
        { field: 'Opening_Balance', headerName: 'Amount', width: 170 },
        { field: 'Depit_or_Credit', headerName: 'Depit Or Credit', width: 170 },
        {
            field: 'Action',
            headerName: 'Action',
            width: 120,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <EditIcon/>
                    </Button>
                </>
            ),
        },
    ];

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };



    return (
        <>
            
            <div className="Supplier_Master_Container">
                
                <div className="Product_Master_h_head">
                    <h3>Ledger List</h3>
                 </div>
                <div className="Supplier_Master_con_1 "> 
                <div className="Supplier_inputss_forms">
                <label htmlFor="input">Name :</label>
                <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Name' />
                </div>                      

                <button className='Supplier_Master_btn_1' type='submit' onClick={() => handleRequestForm()} title='New Ledger Entry'>
                    <LoupeIcon />
                </button>
                </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
