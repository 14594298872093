import * as React from 'react';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from "axios";
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import LoupeIcon from '@mui/icons-material/Loupe';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import './SupplierMaster.css'
import VisibilityIcon from "@mui/icons-material/Visibility";
import Modal from 'react-modal';
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';



const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          columnHeader: {
            backgroundColor: "var(--ProjectColor)",
            textAlign: 'Center',
          },
          root: {
            "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer": {
              textAlign: 'center',
              display: 'flex !important',
              justifyContent: 'center !important'
            },
            "& .MuiDataGrid-window": {
              overflow: "hidden !important",
            },
          },
          cell: {
            borderTop: "0px !important",
            borderBottom: "1px solid  var(--ProjectColor) !important",
            display: 'flex',
            justifyContent: 'center'
          },
        },
      },
    },
  });
  

export default function SupplierList() {


  const dispatchvalue = useDispatch();

  const reduxvalueUsercord=useSelector(state=>state)
  const userRecord =reduxvalueUsercord.userRecord?.UserData



    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [summa, setsumma] = useState([])


    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const yourStyles={
      position: 'absolute',
      inset: '100px',
      border: '1px solid rgb(204, 204, 204)',
      background: 'rgb(97 90 90 / 75%)',
      overflow: 'auto',
      borderRadius: '4px',
      outline: 'none',
      padding: '0px'
    }



    const successMsg = (message) => {
      toast.success(`${message}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        containerId: 'toast-container-over-header',
        style: { marginTop: '50px' },
      });
    };
    const userwarn = (warningMessage) => {
      toast.warn(`${warningMessage}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: { marginTop: '50px' },
      });
    };
    const errmsg = (errormsg) => {
      toast.error(`${errormsg}`, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        style: { marginTop: '50px' },
      });
    };
  

    useEffect(() => {
        axios.get('http://127.0.0.1:8000/SupplierMaster/get_supplier_Master_List')
        .then((response) => {
            const data =response.data
            console.log('mm',data)
            setsumma(
                data.map((row) => ({
                    id: row.SupplierCode,
                    ...row,
                }))
            )
        })
        .catch((error) => {
          console.error('Error fetching patients data:', error);
        });
        
    }, []);
    const [filteredRows, setFilteredRows] = useState([]);
    const pageSize = 10;
    const showdown = filteredRows.length;
    const totalPages = Math.ceil(filteredRows.length / 10);
    const handlePageChange = (params) => {
        setPage(params.page);
    };
    // Define the handleAdd function to handle the "Edit" button click

    const handleRequestEdit = (params) => {
        const index = params.id;

        dispatchvalue({type :'selectedSupplierIndex' ,value : (index)})
        navigate('/Home/Supplier_Master');
    };
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        const filteredData = summa.filter((row) => {
            const lowerCaseSupplierName = row.SupplierName.toLowerCase();
            const lowerCaseSupplierType = row.SupplierType.toLowerCase();
            const lowerCaseSupplierCode = row.SupplierCode.toString().toLowerCase(); // Convert to string for case-insensitive search

            return (
                lowerCaseSupplierName.includes(lowerCaseQuery) ||
                lowerCaseSupplierCode.includes(lowerCaseQuery) ||
                lowerCaseSupplierType.includes(lowerCaseQuery)
            );
        });

        setFilteredRows(filteredData);
    }, [searchQuery,summa]);



    const handleRequestForm = () => {

        navigate('/Home/Supplier_Master')
        dispatchvalue({type: 'selectedSupplierIndex', value :(null)})
    }

    const handleVisibilityClick = (ConsentForm) => {
      console.log('ConsentForm', ConsentForm);
  
      if (ConsentForm === 'data:image/jpg;base64,' && 'data:application/octet-stream;base64') {
          alert('No Data');
      } else {
          setModalContent(ConsentForm);
          setModalIsOpen(true);
      }
  };
  

      const closeModal = () => {
        setModalIsOpen(false);
        setModalContent('');
      };

    // Define the columns dynamically
    const dynamicColumns = [
        { field: 'SupplierCode', headerName: 'Supplier Code', width: 130 },
        { field: 'SupplierName', headerName: 'Supplier Name', width: 150 },
        { field: 'SupplierCategory', headerName: 'Supplier Category', width: 150 },
        { field: 'SupplierType', headerName: 'SupplierType', width: 150 },
        { field: 'phoneno', headerName: 'Phone No', width: 100 },
        { field: 'city', headerName: 'City', width: 150 },
        {
            field: "ConcernForm",
            headerName: "ConcernForm",
            width: 100,
            renderCell: (params) => (
              <>
                <Button
                  className="cell_btn"
                  onClick={() => handleVisibilityClick(params.value)}
                >
                  <VisibilityIcon />
                </Button>
              </>
            ),
          },

        { field: 'Status', headerName: 'Status', width: 100 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 100,
            renderCell: (params) => (
                <>
                    <Button className='cell_btn' onClick={() => handleRequestEdit(params)}>
                        <BorderColorIcon />
                    </Button>
                </>
            ),
        },
    ];




    return (
        <>
            
            <div className="Supplier_Master_Container">
                <div className="Supplier_Master_h_head">
                    <h3>Supplier List</h3>
                </div>
                    <div className="Supplier_Master_con_1 ">

                        <div className="Supplier_inputss_forms">
                            <label htmlFor="input">Supplier Code :</label>
                            <input type="text" value={searchQuery} onChange={handleSearchChange} placeholder='Enter the Supplier Code' />
                        </div>
                        <button className='Supplier_Master_btn_1' type='submit'>
                            <SearchIcon />
                        </button>
                        <button className='Supplier_Master_btn_1' type='submit' onClick={() => handleRequestForm()} title='New Supplier'>
                            <LoupeIcon />
                        </button>
                    </div>
                <div className='IP_grid'>
                    <ThemeProvider theme={theme}>
                        <div className='IP_grid_1'>
                            <DataGrid
                                rows={filteredRows.slice(page * pageSize, (page + 1) * pageSize)} // Display only the current page's data
                                columns={dynamicColumns} // Use dynamic columns here
                                pageSize={10}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                pageSizeOptions={[10]}
                                onPageChange={handlePageChange}
                                hideFooterPagination
                                hideFooterSelectedRowCount
                                className=' Ip_data_grid'
                            />
                            {showdown > 0 && filteredRows.length > 10 && (
                                <div className='IP_grid_foot'>
                                    <button
                                        onClick={() => setPage((prevPage) => Math.max(prevPage - 1, 0))}
                                        disabled={page === 0}
                                    >
                                        Previous
                                    </button>
                                    Page {page + 1} of {totalPages}
                                    <button
                                        onClick={() =>
                                            setPage((prevPage) => Math.min(prevPage + 1, totalPages - 1))
                                        }
                                        disabled={page === totalPages - 1}
                                    >
                                        Next
                                    </button>
                                </div>
                            )}
                        </div>
                    </ThemeProvider>
                    {showdown !== 0 && filteredRows.length !== 0 ? (
                        ''
                    ) : (
                        <div className='IP_norecords'>
                            <span>No Records Found</span>
                        </div>
                    )}
                </div>
                <Modal isOpen={modalIsOpen} onRequestClose={closeModal}style={{ content: { ...yourStyles } }}>
        <div className="pdf_img_show">
          {modalContent.toString().toLowerCase().startsWith("data:application/pdf;base64,") ? (
            <iframe
              title="PDF Viewer"
              src={modalContent}
              style={{
                width: "100%",
                height: "435px",
                border: "1px solid rgba(0, 0, 0, 0.5)", // Black border with reduced opacity
              }}
            />
          ) : (
            <img
              src={modalContent}
              alt="Concern Form"
              style={{
                width: "80%",
                height: "75%",
                marginTop: "20px",
              }}
            />
          )}
          <div className="jhuhhjh">
            <Button
              style={{ color: "white" }}
              className="clse_pdf_img"
              onClick={closeModal}
            >
              <HighlightOffIcon
                style={{
                  fontSize: "40px",
                  backgroundColor: "#54d854bf",
                  borderRadius: "40px",
                }}
              />
            </Button>
          </div>
        </div>
      </Modal>
            </div>
        </>
    );
}
