import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
// import { saveAs } from 'file-saver';
import Button from "@mui/material/Button";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
          textAlign: "Center",
        },
        root: {
          "& .MuiDataGrid-root .MuiDataGrid-columnHeader, .MuiDataGrid-columnHeaderTitleContainer":
            {
              textAlign: "center",
              display: "flex !important",
              justifyContent: "center !important",
            },
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
          display: "flex",
          justifyContent: "center",
        },
      },
    },
  },
});

const IndentReturnMaster = () => {
  const IndentReturnListData = useSelector( (state) => state.userRecord?.IndentReturnListData);

  const userRecord = useSelector((state) => state.userRecord?.UserData);

  const navigate = useNavigate();

  const [datalist, setdatalist] = useState([]);

  const cu_date = format(new Date(), "yyyy-MM-dd");

  // console.log('vathuruchu',IndentReturnListData)

  const [isedit, setisedit] = useState(null);

  const [Selecteditemdata, setSelecteditemdata] = useState([]);

  const [ItemCodedetailes, setItemCodedetailes] = useState([]);

  const [OverallPurchaseRecieveFormData, setOverallPurchaseRecieveFormData] =
    useState({
      Indent_Return_Invoice_NO: "",
      Indent_Return_Date: cu_date,
      ProductCategory: "",
      Reason: "",
      CreatedBy: userRecord?.username,
      Location: userRecord?.location,
      RecievedBy: "",
      Finel_NetAmount: "",
      Return_From: userRecord?.location,
      Return_To: "CentralStore",
    });

  const [PurchaseRecieveFormData, setPurchaseRecieveFormData] = useState({
    Item_Code: "",
    Item_Name: "",
    Generic_Name: "",
    Strength: "",
    UOM: "",
    Pack_type: "",
    Pack_Quantity: "",
    Return_Quantity: "",
    Return_TotalQuantity: "",
    HSNCode: "",
    BatchNo: "",
    ManufactureDate: "",
    ExpiryDate: "",
    MRP: "",
    Tax_Percentage: "",
    Selling_Rate: "",
    Taxable_Selling_Rate: "",
    TotalAmount: "",
  });

  console.log("qqq", OverallPurchaseRecieveFormData);

  const [PurchaseRecieveFormData1, setPurchaseRecieveFormData1] = useState({
    Item_Code: "",
    Item_Name: "",
    Pack_type: "",
    Pack_Quantity: "",
    Return_Quantity: "",
    Return_TotalQuantity: "",
    MRP: "",
    TotalAmount: "",
  });

  const [datalist1, setdatalist1] = useState([]);
  const [page1, setPage1] = useState(0);
  const pageSize = 10;
  const showdown1 = datalist1.length;
  const totalPages = Math.ceil(datalist.length / 10);
  const totalPages1 = Math.ceil(datalist1.length / 10);

  console.log("cu_date:", datalist1);

  useEffect(() => {
    console.log("vathuruchu", IndentReturnListData);

    if (
      IndentReturnListData &&
      Object.keys(IndentReturnListData).length !== 0
    ) {
      setOverallPurchaseRecieveFormData((prevData) => ({
        ...prevData,
        Indent_Return_Invoice_NO: IndentReturnListData.IndentReturn_InvoiceNo,
        Indent_Return_Date: IndentReturnListData.Indent_Return_Date,
        ProductCategory: IndentReturnListData.Product_Category,
        Reason: IndentReturnListData.Reason,
        CreatedBy: IndentReturnListData.Issue_By,
        Location: IndentReturnListData.Location,
        RecievedBy: "",
        Finel_NetAmount: IndentReturnListData.Finel_NetAmount,
        Return_From: IndentReturnListData.Return_From,
        Return_To: IndentReturnListData.Return_To,
      }));

      const datal = IndentReturnListData.items;

      const b_data = datal.map((p, index) => {
        const { HSN_Code, Batch_No, MRP_Per_Quantity, ...rest } = p;

        return {
          id: index + 1,
          ...rest,
          HSNCode: HSN_Code,
          BatchNo: Batch_No, // Include Pack_Quantity in b_data
          MRP: MRP_Per_Quantity,
        };
      });

      setdatalist1(b_data);
    } else {
      if (OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "Surgical") {
        axios
          .get(
            `http://127.0.0.1:8000/IndentRaise/get_Medical_Indent_Return_InvoiceNo?productcategorytype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Return_Invoice_NO: response.data.nextInvoiceNo,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (OverallPurchaseRecieveFormData.ProductCategory === "Assets") {
        axios
          .get(
            "http://127.0.0.1:8000/IndentRaise/get_Assets_Indent_Return_InvoiceNo"
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Return_Invoice_NO: response.data.nextInvoiceNo,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (
        OverallPurchaseRecieveFormData.ProductCategory === "Stationary"
      ) {
        axios
          .get(
            "http://127.0.0.1:8000/IndentRaise/get_Stationary_Indent_Return_InvoiceNo"
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Return_Invoice_NO: response.data.nextInvoiceNo,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      } else if (
        OverallPurchaseRecieveFormData.ProductCategory === "Non_Stationary"
      ) {
        axios
          .get(
            "http://127.0.0.1:8000/IndentRaise/get_Non_Stationary_Indent_Return_InvoiceNo"
          )
          .then((response) => {
            console.log("INV:", response.data.nextInvoiceNo);
            setOverallPurchaseRecieveFormData((prevData) => ({
              ...prevData,
              Indent_Return_Invoice_NO: response.data.nextInvoiceNo,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [OverallPurchaseRecieveFormData.ProductCategory, IndentReturnListData]);

  useEffect(() => {
    if  (OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "Surgical")  {
      axios
        .get(
          `http://127.0.0.1:8000/SupplierMaster/get_Medical_ProductMaster_data?Productype=${OverallPurchaseRecieveFormData.ProductCategory}`
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          let A_Status = response.data.filter((ele) => {
            return (ele.Status = "Active");
          });
          setItemCodedetailes(A_Status);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (OverallPurchaseRecieveFormData.ProductCategory === "Assets") {
      axios
        .get(
          "http://127.0.0.1:8000/SupplierMaster/get_Assets_ProductMaster_data"
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          let A_Status = response.data.filter((ele) => {
            return (ele.Status = "Active");
          });
          setItemCodedetailes(A_Status);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (
      OverallPurchaseRecieveFormData.ProductCategory === "Stationary"
    ) {
      axios
        .get(
          "http://127.0.0.1:8000/SupplierMaster/get_Stationary_ProductMaster_data"
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          let A_Status = response.data.filter((ele) => {
            return (ele.Status = "Active");
          });
          setItemCodedetailes(A_Status);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else if (
      OverallPurchaseRecieveFormData.ProductCategory === "Non_Stationary"
    ) {
      axios
        .get(
          "http://127.0.0.1:8000/SupplierMaster/get_NONStationary_ProductMaster_data"
        )
        .then((response) => {
          console.log("ITEM:", response.data);
          let A_Status = response.data.filter((ele) => {
            return (ele.Status = "Active");
          });
          setItemCodedetailes(A_Status);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [OverallPurchaseRecieveFormData.ProductCategory]);

  useEffect(() => {
    if (
      PurchaseRecieveFormData.Item_Code ||
      PurchaseRecieveFormData1.Item_Code
    ) {
      if (OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalNonConsumable" ||
      OverallPurchaseRecieveFormData.ProductCategory === "Surgical")  {
        axios
          .get(
            `http://127.0.0.1:8000/quickstockreceive/get_medical_stock_information_location?Item_Code=${PurchaseRecieveFormData.Item_Code}&productcategorytype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("wrt:", response.data);
            setSelecteditemdata(response.data);
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }

      if (OverallPurchaseRecieveFormData.ProductCategory !== "MedicalConsumable" &&
      OverallPurchaseRecieveFormData.ProductCategory !== "MedicalNonConsumable" &&
      OverallPurchaseRecieveFormData.ProductCategory !== "NonMedicalConsumable" &&
      OverallPurchaseRecieveFormData.ProductCategory !== "NonMedicalNonConsumable" &&
      OverallPurchaseRecieveFormData.ProductCategory !== "Surgical")  {
        axios
          .get(
            `http://127.0.0.1:8000/quickstockreceive/getAssets_Stock_information_location?Item_Code=${PurchaseRecieveFormData1.Item_Code}&Productype=${OverallPurchaseRecieveFormData.ProductCategory}`
          )
          .then((response) => {
            console.log("wrt:", response.data);
            let d_data = response.data.stock_information[0];
            console.log("d_data", d_data);

            setPurchaseRecieveFormData1((prev) => ({
              ...prev,
              // MRP:d_data.MRP_Per_Quantity,
              MRP: 100,
            }));
          })
          .catch((error) => {
            // Handle errors here
            console.error("Error fetching data:", error);
          });
      }
    }
  }, [
    PurchaseRecieveFormData.Item_Code,
    PurchaseRecieveFormData1.Item_Code,
    OverallPurchaseRecieveFormData.ProductCategory,
  ]);

  useEffect(() => {
    if (datalist1.length !== 0) {
      // console.log('datalist1:',datalist1)

      let G_Total = 0;

      datalist1.map((ele, ind) => {
        return (G_Total += +ele.TotalAmount);
      });

      console.log("G_Total:", G_Total);

      setOverallPurchaseRecieveFormData((prev) => ({
        ...prev,
        Finel_NetAmount: G_Total,
      }));
    }
  }, [datalist1]);

  const dynamicColumns1 = () => {
    if (OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable") {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        { field: "Strength", headerName: "Strength", width: 120 },
        { field: "UOM", headerName: "UOM", width: 100 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 170 },
        {
          field: "Return_TotalQuantity",
          headerName: "Return TotalQuantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Taxable Selling Rate",
          width: 170,
        },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } if (OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "Surgical") {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Generic_Name", headerName: "Generic Name", width: 150 },
        {
          field: "ManufactureDate",
          headerName: "Manufacture Date",
          width: 170,
        },
        { field: "ExpiryDate", headerName: "Expiry Date", width: 150 },
        { field: "HSNCode", headerName: "HSN Code", width: 170 },
        { field: "BatchNo", headerName: "Batch No", width: 170 },
        { field: "Pack_type", headerName: "Pack Type", width: 150 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 170 },
        {
          field: "Return_TotalQuantity",
          headerName: "Return TotalQuantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 150 },
        { field: "Tax_Percentage", headerName: "Tax Percentage", width: 170 },
        {
          field: "Taxable_Selling_Rate",
          headerName: "Taxable Selling Rate",
          width: 170,
        },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEdit1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    } 
    else {
      return [
        { field: "Item_Code", headerName: "Item Code", width: 200 },
        { field: "Item_Name", headerName: "Item Name", width: 200 },
        { field: "Pack_type", headerName: "Pack Type", width: 170 },
        { field: "Return_Quantity", headerName: "Return Quantity", width: 170 },
        {
          field: "Return_TotalQuantity",
          headerName: "Return TotalQuantity",
          width: 170,
        },
        { field: "MRP", headerName: "MRP", width: 170 },
        { field: "TotalAmount", headerName: "Total Amount", width: 170 },
        {
          field: "actions",
          headerName: "Actions",
          width: 120,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleRequestEditNon1(params.row)}
              >
                <EditIcon />
              </Button>
            </>
          ),
        },
      ];
    }
  };

  const handlePageChange1 = (params) => {
    setPage1(params.page);
  };

  const handlechangeReturnto = (e) => {
    const { name, value } = e.target;

    setOverallPurchaseRecieveFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInputFormChange = (e) => {
    const { name, value } = e.target;

    if (name === "Item_Code") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemCode === value;
      });

      if (Set_datas) {
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Name: Set_datas.ItemName,
          Generic_Name: Set_datas.GenericName,
          Strength: Set_datas.Strength,
          UOM: Set_datas.UOM,
          Pack_type: Set_datas.ProductType,
          Pack_Quantity: Set_datas.ProductQuantity,
        }));
      } else {
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Name: "",
          Generic_Name: "",
          Strength: "",
          UOM: "",
          Pack_type: "",
          Pack_Quantity: "",
        }));
      }
    } else if (name === "Item_Name") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemName === value;
      });

      if (Set_datas) {
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Code: Set_datas.ItemCode,
          Generic_Name: Set_datas.GenericName,
          Strength: Set_datas.Strength,
          UOM: Set_datas.UOM,
          Pack_type: Set_datas.ProductType,
          Pack_Quantity: Set_datas.ProductQuantity,
        }));
      } else {
        setPurchaseRecieveFormData((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Code: "",
          Generic_Name: "",
          Strength: "",
          UOM: "",
          Pack_type: "",
          Pack_Quantity: "",
        }));
      }
    } else if (name === "BatchNo") {
      const S_data = Selecteditemdata.find((ele) => {
        return ele.Batch_No === value;
      });
      console.log("S_data:", S_data);
      if (S_data) {
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          HSNCode: S_data.HSN_Code,
          ManufactureDate: S_data.ManufactureDate,
          ExpiryDate: S_data.ExpiryDate,
          MRP: S_data.MRP_Per_Quantity,
          Selling_Rate: S_data.Selling_Rate,
          Tax_Percentage: S_data.Tax_Percentage,
          Taxable_Selling_Rate: (+S_data.Taxable_Selling_Rate).toFixed(2),
          GRN_InvoiceNo: S_data.GRN_InvoiceNo,
        }));
      } else {
        setPurchaseRecieveFormData((prev) => ({
          ...prev,
          [name]: value,
          HSNCode: "",
          ManufactureDate: "",
          ExpiryDate: "",
          MRP: "",
          Selling_Rate: "",
          Tax_Percentage: "",
          Taxable_Selling_Rate: "",
        }));
      }
    } else if (name === "Return_Quantity") {
      const S_data = Selecteditemdata[0];
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
        Return_TotalQuantity: value * PurchaseRecieveFormData.Pack_Quantity,
        MRP: S_data.MRP_Per_Quantity,
      }));
    } else {
      setPurchaseRecieveFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    const T_Amount =
      PurchaseRecieveFormData.Taxable_Selling_Rate *
      PurchaseRecieveFormData.Return_TotalQuantity;

    setPurchaseRecieveFormData((prev) => ({
      ...prev,
      TotalAmount: T_Amount.toFixed(2),
    }));
  }, [
    PurchaseRecieveFormData.Return_TotalQuantity,
    PurchaseRecieveFormData.Taxable_Selling_Rate,
  ]);

  const handleInputFormNonChange = (e) => {
    const { name, value } = e.target;

    if (name === "Item_Code") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemCode === value;
      });

      if (Set_datas) {
        setPurchaseRecieveFormData1((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Name: Set_datas.ItemName,
          Pack_type: Set_datas.ProductType,
          Pack_Quantity: Set_datas.ProductQuantity,
        }));
      } else {
        setPurchaseRecieveFormData1((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Name: "",
          Pack_type: "",
          Pack_Quantity: "",
        }));
      }
    } else if (name === "Item_Name") {
      const Set_datas = ItemCodedetailes.find((ele) => {
        return ele.ItemName === value;
      });

      if (Set_datas) {
        setPurchaseRecieveFormData1((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Code: Set_datas.ItemCode,
          Pack_type: Set_datas.ProductType,
          Pack_Quantity: Set_datas.ProductQuantity,
        }));
      } else {
        setPurchaseRecieveFormData1((prevData) => ({
          ...prevData,
          [name]: value,
          Item_Code: "",
          Pack_type: "",
          Pack_Quantity: "",
        }));
      }
    } else if (name === "Return_Quantity") {
      setPurchaseRecieveFormData1((prev) => ({
        ...prev,
        [name]: value,
        Return_TotalQuantity: value * PurchaseRecieveFormData1.Pack_Quantity,
        TotalAmount:
          value *
          PurchaseRecieveFormData1.Pack_Quantity *
          PurchaseRecieveFormData1.MRP,
      }));
    } else {
      setPurchaseRecieveFormData1((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleRequestEdit = (params) => {
    setPurchaseRecieveFormData({
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Generic_Name: params.Generic_Name,
      Strength: params.Strength,
      UOM: params.UOM,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Return_Quantity: "",
      Return_TotalQuantity: "",
      HSNCode: "",
      BatchNo: "",
      ManufactureDate: "",
      ExpiryDate: "",
      MRP: "",
      Tax_Percentage: "",
      Selling_Rate: "",
      Taxable_Selling_Rate: "",
      TotalAmount: "",
    });
  };

  const handleRequestEdit1 = (params) => {
    setPurchaseRecieveFormData({ ...params });
    setisedit(params);
  };

  const handleAdd = () => {

    let requiredFields;
    if (OverallPurchaseRecieveFormData.ProductCategory === 'MedicalConsumable' ||
        OverallPurchaseRecieveFormData.ProductCategory === 'MedicalNonConsumable'
    ) {
        requiredFields = [
            "Item_Code",
            "Item_Name",
            "Generic_Name",
            "Strength",
            "UOM",
            "Pack_type",
            "Return_Quantity",
            "Return_TotalQuantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "MRP",
            "Tax_Percentage",
            "Selling_Rate",
            "Taxable_Selling_Rate",
            "TotalAmount",
        ];
    } else {
        requiredFields = [
            "Item_Code",
            "Item_Name",
            "Generic_Name",
            "Pack_type",
            "Return_Quantity",
            "Return_TotalQuantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "MRP",
            "Tax_Percentage",
            "Selling_Rate",
            "Taxable_Selling_Rate",
            "TotalAmount",
        ];
    }
    
    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      console.log("PurchaseRecieveFormData", PurchaseRecieveFormData);
      let id = 0;
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData };

      console.log("dataArray", data);
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  const handleUpdate = () => {
    let requiredFields;
    if (OverallPurchaseRecieveFormData.ProductCategory === 'MedicalConsumable' ||
        OverallPurchaseRecieveFormData.ProductCategory === 'MedicalNonConsumable'
    ) {
        requiredFields = [
            "Item_Code",
            "Item_Name",
            "Generic_Name",
            "Strength",
            "UOM",
            "Pack_type",
            "Return_Quantity",
            "Return_TotalQuantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "MRP",
            "Tax_Percentage",
            "Selling_Rate",
            "Taxable_Selling_Rate",
            "TotalAmount",
        ];
    } else {
        requiredFields = [
            "Item_Code",
            "Item_Name",
            "Generic_Name",
            "Pack_type",
            "Return_Quantity",
            "Return_TotalQuantity",
            "HSNCode",
            "BatchNo",
            "ManufactureDate",
            "ExpiryDate",
            "MRP",
            "Tax_Percentage",
            "Selling_Rate",
            "Taxable_Selling_Rate",
            "TotalAmount",
        ];
    }
    
    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // non medical
  const handleRequestEditNon = (params) => {
    console.log(params);
    setPurchaseRecieveFormData1({
      Item_Code: params.Item_Code,
      Item_Name: params.Item_Name,
      Pack_type: params.Pack_type,
      Pack_Quantity: params.Pack_Quantity,
      Return_Quantity: "",
      Return_TotalQuantity: "",
      MRP: "",
      TotalAmount: "",
    });
  };
  const handleRequestEditNon1 = (params) => {
    setPurchaseRecieveFormData1({ ...params });
    setisedit(params);
  };
  const handleClear = () => {
    if (OverallPurchaseRecieveFormData.ProductCategory === "MedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "MedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "NonMedicalNonConsumable" ||
    OverallPurchaseRecieveFormData.ProductCategory === "Surgical") {
      setPurchaseRecieveFormData({
        Item_Code: "",
        Item_Name: "",
        Generic_Name: "",
        Strength: "",
        UOM: "",
        Pack_type: "",
        Pack_Quantity: "",
        Return_Quantity: "",
        Return_TotalQuantity: "",
        HSNCode: "",
        BatchNo: "",
        ManufactureDate: "",
        ExpiryDate: "",
        MRP: "",
        Tax_Percentage: "",
        Selling_Rate: "",
        Taxable_Selling_Rate: "",
        TotalAmount: "",
      });
    } else {
      setPurchaseRecieveFormData1({
        Item_Code: "",
        Item_Name: "",
        Pack_type: "",
        Pack_Quantity: "",
        Return_Quantity: "",
        Return_TotalQuantity: "",
        MRP: "",
        TotalAmount: "",
      });
    }
  };

  const handleAddNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Return_Quantity",
      "Return_TotalQuantity",
      "MRP",
      "TotalAmount",
    ];

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }
    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData1[field]
    );

    if (missingFields.length === 0) {
      const data = { id: datalist1.length + 1, ...PurchaseRecieveFormData1 };
      setdatalist1((prev) => [...prev, data]);
      setdatalist((prev) =>
        prev.filter(
          (item) => item.Item_Code !== PurchaseRecieveFormData1.Item_Code
        )
      );
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };
  const handleUpdateNon = () => {
    const requiredFields = [
      "Item_Code",
      "Item_Name",
      "Pack_type",
      "Pack_Quantity",
      "Return_Quantity",
      "Return_TotalQuantity",
      "MRP",
      "TotalAmount",
    ];

    if (
      (PurchaseRecieveFormData1.GST === null &&
        PurchaseRecieveFormData1.IGST === null) ||
      (PurchaseRecieveFormData1.GST === 0 &&
        PurchaseRecieveFormData1.IGST === 0) ||
      (PurchaseRecieveFormData1.GST === "" &&
        PurchaseRecieveFormData1.IGST === "")
    ) {
      requiredFields.push("GST");
    }

    const missingFields = requiredFields.filter(
      (field) => !PurchaseRecieveFormData1[field]
    );

    if (missingFields.length === 0) {
      const indexOfItemToUpdate = datalist1.findIndex(
        (item) => item.Item_Code === isedit.Item_Code
      );
      setdatalist1((prev) => {
        const updatedCart = [...prev];
        updatedCart[indexOfItemToUpdate] = PurchaseRecieveFormData1;
        return updatedCart;
      });
      setisedit(null);
      handleClear();
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  // const handleExportToExcel = () => {
  //     if(datalist1.length !==0){
  //         let columns;
  //         if(OverallPurchaseRecieveFormData.ProductCategory === 'Medical'){
  //            columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Generic_Name', dataKey: 'Generic_Name' },
  //                 { header: 'Strength', dataKey: 'Strength' },
  //                 { header: 'UOM', dataKey: 'UOM' },
  //                 { header: 'ManufactureDate', dataKey: 'ManufactureDate' },
  //                 { header: 'ExpiryDate', dataKey: 'ExpiryDate' },
  //                 { header: 'HSNCode', dataKey: 'HSNCode' },
  //                 { header: 'BatchNo', dataKey: 'BatchNo' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Return_Quantity', dataKey: 'Return_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }else{
  //             columns = [
  //                 { header: 'Item Code', dataKey: 'Item_Code' },
  //                 { header: 'Item Name', dataKey: 'Item_Name' },
  //                 { header: 'Pack_type', dataKey: 'Pack' },
  //                 { header: 'Order_Quantity', dataKey: 'Order_Quantity' },
  //                 { header: 'PerOrder_Quantity', dataKey: 'PerOrder_Quantity' },
  //                 { header: 'RecievedPack', dataKey: 'RecievedPack' },
  //                 { header: 'Return_Quantity', dataKey: 'Return_Quantity' },
  //                 { header: 'RecievedPerOrder_Quantity', dataKey: 'RecievedPerOrder_Quantity' },
  //                 { header: 'PendingQuantity', dataKey: 'PendingQuantity' },
  //                 { header: 'Reason', dataKey: 'Reason' },
  //                 { header: 'MRP', dataKey: 'MRP' },
  //                 { header: 'GST', dataKey: 'GST' },
  //                 { header: 'IGST', dataKey: 'IGST' },
  //                 { header: 'DiscountType', dataKey: 'DiscountType' },
  //                 { header: 'Discount', dataKey: 'Discount' },
  //                 { header: 'TotalAmount', dataKey: 'TotalAmount' },
  //               ];
  //         }

  //         const header = columns.map(col => col.header);

  //         const csv = [
  //             `ProductCategory : ${OverallPurchaseRecieveFormData.ProductCategory}`,
  //             `IndentRaiseInvoice : ${OverallPurchaseRecieveFormData.IndentRaiseInvoice}`,
  //             `PurchaseRaiseDate : ${OverallPurchaseRecieveFormData.Purchaserise_date}`,
  //             `Raised By : ${OverallPurchaseRecieveFormData.CreatedBy}`,
  //             `SupplierCode : ${OverallPurchaseRecieveFormData.SupplierCode}`,
  //             `Request_From : ${OverallPurchaseRecieveFormData.Request_From}`,
  //             `GRN Invoice : ${OverallPurchaseRecieveFormData.PurchaseRecieveInvoice}`,
  //             `GRN Date : ${OverallPurchaseRecieveFormData.Purchaserecieve_date}`,
  //             `GRN Recieved By : ${OverallPurchaseRecieveFormData.RecievedBy}`,
  //             " ",
  //             '\ufeff' + header.join(","), // BOM + header row
  //             ...datalist1.map(row => columns.map(col => row[col.dataKey])),
  //             '', // Empty row for spacing
  //         ].join("\r\n");

  //         var data = new Blob([csv], { type: "text/csv" });
  //         // Fix the template literal for saveAs function
  //         saveAs(data, `Report_${new Date().toISOString()}.csv`);
  //     }else{
  //         alert('No Data to Save')
  //     }
  // };

  const handleSubmit = () => {
    const requiredFields = [
      "Finel_NetAmount",
      "Return_From",
      "Return_To",
      "Reason",
    ];

    const missingFields = requiredFields.filter(
      (field) => !OverallPurchaseRecieveFormData[field]
    );

    if (missingFields.length === 0) {
      const newdataa = {
        OverallPurchaseRecieveFormData: { ...OverallPurchaseRecieveFormData },
        datalist1: [...datalist1],
      };

      axios
        .post(
          "http://127.0.0.1:8000/IndentRaise/All_indent_Return_insert",
          newdataa
        )
        .then((response) => {
          console.log(response.data);
          navigate("/Home/Indent_Return_list");
        })
        .catch((error) => {
          console.error("Error posting data:", error);
        });

      console.log(newdataa);
    } else {
      alert(`Please fill out all required fields: ${missingFields.join(", ")}`);
    }
  };

  return (
    <>
      <div className="Supplier_Master_Container">
        <div className="Product_Master_h_head">
          <h3>Indent Return Invoice</h3>
        </div>
        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Product Category :
                </label>

                <select
                  value={OverallPurchaseRecieveFormData.ProductCategory}
                  name="ProductCategory"
                  onChange={handlechangeReturnto}
                >
                  <option value="">Select</option>
                  <option value="MedicalConsumable">Medical Consumable</option>
                  <option value="MedicalNonConsumable">
                    Medical Non-Consumable
                  </option>
                  <option value="NonMedicalConsumable">
                    Non MedicalConsumable
                  </option>
                  <option value="NonMedicalNonConsumable">
                    Non MedicalNon-Consumable
                  </option>
                  <option value="Surgical">Surgical</option>
                  <option value="Assets">Assets</option>
                  <option value="Stationary">Stationary</option>
                  <option value="Non_Stationary">Non Stationary</option>
                </select>
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Indent Return Invoice No :
                </label>
                <input
                  type="text"
                  name="Indent_Return_Invoice_NO"
                  placeholder="Enter Product Category"
                  value={
                    OverallPurchaseRecieveFormData.Indent_Return_Invoice_NO
                  }
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Indent Return Date :
                </label>
                <input
                  type="date"
                  name="Indent_Return_Date"
                  placeholder="Enter Invoice No"
                  value={OverallPurchaseRecieveFormData.Indent_Return_Date}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Return From :
                </label>
                {/* <select
                                name='Return_From'
                                onChange={handlechangeReturnto}  // Event handler for when the selection changes
                                value={OverallPurchaseRecieveFormData.Return_From} // The selected value from the state or props
                            >
                                <option value=''>Select</option>
                                <option value='CHENNAI'>CHENNAI</option>
                                <option value='Location2'>Location2</option>
                                <option value='Location3'>Location3</option>
                            </select> */}

                <input
                  type="text"
                  name="Return_From"
                  placeholder="Enter Return From"
                  value={OverallPurchaseRecieveFormData.Return_From}
                  readOnly
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="Stoct_Product_master_Container">
          <div className="Stoct_Product_master_form">
            <div className="Stoct_Product_master_form_row">
              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Return To :
                </label>
                <input
                  type="text"
                  name="Return_To"
                  placeholder="Enter Return To"
                  value={OverallPurchaseRecieveFormData.Return_To}
                  className="Stoct_Product_master_form_Input"
                  readOnly
                />
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Reason :
                </label>

                <select
                  value={OverallPurchaseRecieveFormData.Reason}
                  name="Reason"
                  onChange={handlechangeReturnto}
                >
                  <option value="">Select</option>
                  {(OverallPurchaseRecieveFormData.ProductCategory ===
                    "MedicalConsumable" ||
                    OverallPurchaseRecieveFormData.ProductCategory ===
                      "MedicalNonConsumable" ||
                    OverallPurchaseRecieveFormData.ProductCategory ===
                      "NonMedicalConsumable" ||
                    OverallPurchaseRecieveFormData.ProductCategory ===
                      "NonMedicalNonConsumable" ||
                    OverallPurchaseRecieveFormData.ProductCategory ===
                      "Surgical") && <option value="Expired">Expired</option>}
                  <option value="Quality">Quality</option>
                  <option value="Damage">Damage</option>
                </select>
              </div>

              <div className="Stoct_Product_master_form_row_div">
                <label
                  className="Stoct_Product_master_form_Label"
                  htmlFor="ProductType"
                >
                  Net Amount :
                </label>
                <input
                  type="number"
                  name="Finel_NetAmount"
                  placeholder="Enter Total Amount"
                  value={OverallPurchaseRecieveFormData.Finel_NetAmount}
                  readOnly
                  required
                  className="Stoct_Product_master_form_Input"
                />
              </div>
            </div>
          </div>
        </div>

        {(OverallPurchaseRecieveFormData.ProductCategory ===
          "MedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory ===
            "MedicalNonConsumable") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>

                  <input
                    type="text"
                    name="Item_Code"
                    list="ItemCode_list"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />
                  <datalist id="ItemCode_list">
                    {ItemCodedetailes.map((item, index) => (
                      <option key={index} value={item.ItemCode}></option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    list="ItemCode_name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                  <datalist id="ItemCode_name">
                    {ItemCodedetailes.map((item, index) => (
                      <option key={index} value={item.ItemName}></option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Strength :
                  </label>
                  <input
                    type="text"
                    name="Strength"
                    placeholder="Enter Strength"
                    value={PurchaseRecieveFormData.Strength}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    UOM :
                  </label>
                  <input
                    type="text"
                    name="UOM"
                    placeholder="Enter UOM"
                    value={PurchaseRecieveFormData.UOM}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    id="browser"
                    list="browsers"
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />

                  <datalist id="browsers">
                    {Selecteditemdata.map((item, index) => (
                      <option key={index} value={`${item.Batch_No}`}>
                        {`${item.Item_Name} | BatchNo:${
                          item.Batch_No
                        } | Ava.Quantity:${
                          item.AvailableQuantity
                        } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(
                          2
                        )}`}
                      </option>
                    ))}
                  </datalist>
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Return Quantity :
                  </label>
                  <input
                    type="number"
                    name="Return_Quantity"
                    placeholder="Enter Return Pack Quantity "
                    value={PurchaseRecieveFormData.Return_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Return Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="Return_TotalQuantity"
                    placeholder="Enter Return TotalQuantity"
                    value={PurchaseRecieveFormData.Return_TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP (Per Quantity) :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Selling Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Selling_Rate"
                    placeholder="Enter Selling Rate"
                    value={PurchaseRecieveFormData.Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>
                  <input
                    type="number"
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Taxable Selling Rate :
                  </label>
                  <input
                    type="number"
                    name="Taxable_Selling_Rate"
                    placeholder="Enter Taxable Selling Rate"
                    value={PurchaseRecieveFormData.Taxable_Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {(OverallPurchaseRecieveFormData.ProductCategory ===
          "NonMedicalConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory ===
            "NonMedicalNonConsumable" ||
          OverallPurchaseRecieveFormData.ProductCategory === "Surgical") && (
          <div className="Stoct_Product_master_Container">
            <div className="Stoct_Product_master_form">
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Item Code :
                  </label>

                  <input
                    type="text"
                    name="Item_Code"
                    list="ItemCode_list"
                    placeholder="Enter Item Code"
                    value={PurchaseRecieveFormData.Item_Code}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />
                  <datalist id="ItemCode_list">
                    {ItemCodedetailes.map((item, index) => (
                      <option key={index} value={item.ItemCode}></option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Item Name :
                  </label>
                  <input
                    type="text"
                    name="Item_Name"
                    list="ItemCode_name"
                    placeholder="Enter Item Name"
                    value={PurchaseRecieveFormData.Item_Name}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                  <datalist id="ItemCode_name">
                    {ItemCodedetailes.map((item, index) => (
                      <option key={index} value={item.ItemName}></option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Generic Name :
                  </label>
                  <input
                    type="text"
                    name="Generic_Name"
                    placeholder="Enter Generic Name"
                    value={PurchaseRecieveFormData.Generic_Name}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Pack Type :
                  </label>
                  <input
                    type="text"
                    name="Pack_type"
                    placeholder="Enter Pack Type"
                    value={PurchaseRecieveFormData.Pack_type}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Pack Quantity :
                  </label>
                  <input
                    type="number"
                    name="Pack_Quantity"
                    placeholder="Enter Pack Quantity"
                    value={PurchaseRecieveFormData.Pack_Quantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Batch No :
                  </label>
                  <input
                    id="browser"
                    list="browsers"
                    type="text"
                    name="BatchNo"
                    placeholder="Enter Batch No"
                    value={PurchaseRecieveFormData.BatchNo}
                    onChange={handleInputFormChange}
                    required
                    className="Stoct_Product_master_form_Input"
                    autoComplete="off"
                  />

                  <datalist id="browsers">
                    {Selecteditemdata.map((item, index) => (
                      <option key={index} value={`${item.Batch_No}`}>
                        {`${item.Item_Name} | BatchNo:${
                          item.Batch_No
                        } | Ava.Quantity:${
                          item.AvailableQuantity
                        } | MRP:${parseFloat(item.MRP_Per_Quantity).toFixed(
                          2
                        )}`}
                      </option>
                    ))}
                  </datalist>
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    HSN Code :
                  </label>
                  <input
                    type="text"
                    name="HSNCode"
                    placeholder="Enter HSNCode"
                    value={PurchaseRecieveFormData.HSNCode}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Manufacture Date :
                  </label>
                  <input
                    type="date"
                    name="ManufactureDate"
                    placeholder="Enter ManufactureDate"
                    value={PurchaseRecieveFormData.ManufactureDate}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>
              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="Item_Name"
                  >
                    Expiry Date :
                  </label>
                  <input
                    type="date"
                    name="ExpiryDate"
                    placeholder="Enter ExpiryDate"
                    value={PurchaseRecieveFormData.ExpiryDate}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Return Quantity :
                  </label>
                  <input
                    type="number"
                    name="Return_Quantity"
                    placeholder="Enter Return Pack Quantity "
                    value={PurchaseRecieveFormData.Return_Quantity}
                    onChange={handleInputFormChange}
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Return Total Quantity :
                  </label>
                  <input
                    type="number"
                    name="Return_TotalQuantity"
                    placeholder="Enter Return TotalQuantity"
                    value={PurchaseRecieveFormData.Return_TotalQuantity}
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    MRP (Per Quantity) :
                  </label>
                  <input
                    type="number"
                    name="MRP"
                    placeholder="Enter MRP"
                    value={PurchaseRecieveFormData.MRP}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_row">
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Selling Rate (Per Quantity):
                  </label>
                  <input
                    type="number"
                    name="Selling_Rate"
                    placeholder="Enter Selling Rate"
                    value={PurchaseRecieveFormData.Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Tax Percentage
                  </label>
                  <input
                    type="number"
                    name="Tax_Percentage"
                    placeholder="Enter Tax Percentage"
                    value={PurchaseRecieveFormData.Tax_Percentage}
                    readOnly
                    required
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="code"
                  >
                    Taxable Selling Rate :
                  </label>
                  <input
                    type="number"
                    name="Taxable_Selling_Rate"
                    placeholder="Enter Taxable Selling Rate"
                    value={PurchaseRecieveFormData.Taxable_Selling_Rate}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>

                <div className="Stoct_Product_master_form_row_div">
                  <label
                    className="Stoct_Product_master_form_Label"
                    htmlFor="ProductType"
                  >
                    Total Amount :
                  </label>
                  <input
                    type="number"
                    name="TotalAmount"
                    placeholder="Enter Total Amount"
                    value={PurchaseRecieveFormData.TotalAmount}
                    required
                    readOnly
                    className="Stoct_Product_master_form_Input"
                  />
                </div>
              </div>

              <div className="Stoct_Product_master_form_Save">
                <button
                  className=" Stoct_Product_master_form_Save_button"
                  onClick={isedit ? handleUpdate : handleAdd}
                >
                  {isedit ? "Update" : "Add"}
                </button>
              </div>
            </div>
          </div>
        )}

        {OverallPurchaseRecieveFormData.ProductCategory !== "MedicalConsumable" &&
          OverallPurchaseRecieveFormData.ProductCategory !== "MedicalNonConsumable" &&
          OverallPurchaseRecieveFormData.ProductCategory !=="NonMedicalConsumable" &&
          OverallPurchaseRecieveFormData.ProductCategory !=="NonMedicalNonConsumable" &&
          OverallPurchaseRecieveFormData.ProductCategory !== "Surgical" && (
            <div className="Stoct_Product_master_Container">
              <div className="Stoct_Product_master_form">
                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Item Code :
                    </label>
                    <input
                      type="text"
                      name="Item_Code"
                      list="ItemCode_list"
                      placeholder="Enter Item Code"
                      value={PurchaseRecieveFormData1.Item_Code}
                      onChange={handleInputFormNonChange}
                      required
                      className="Stoct_Product_master_form_Input"
                      autoComplete="off"
                    />
                    <datalist id="ItemCode_list">
                      {ItemCodedetailes.map((item, index) => (
                        <option key={index} value={item.ItemCode}></option>
                      ))}
                    </datalist>
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Item Name :
                    </label>
                    <input
                      type="text"
                      name="Item_Name"
                      list="ItemCode_name"
                      placeholder="Enter Item Name"
                      value={PurchaseRecieveFormData1.Item_Name}
                      onChange={handleInputFormNonChange}
                      required
                      className="Stoct_Product_master_form_Input"
                    />
                    <datalist id="ItemCode_name">
                      {ItemCodedetailes.map((item, index) => (
                        <option key={index} value={item.ItemName}></option>
                      ))}
                    </datalist>
                  </div>
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Pack Type :
                    </label>
                    <input
                      type="text"
                      name="Pack_type"
                      placeholder="Enter Pack Type"
                      value={PurchaseRecieveFormData1.Pack_type}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Pack Quantity :
                    </label>
                    <input
                      type="number"
                      name="Pack_Quantity"
                      placeholder="Enter Pack Quantity"
                      value={PurchaseRecieveFormData1.Pack_Quantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_row">
                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="Item_Name"
                    >
                      Return Quantity :
                    </label>
                    <input
                      type="number"
                      name="Return_Quantity"
                      placeholder="Enter Return Quantity"
                      value={PurchaseRecieveFormData1.Return_Quantity}
                      onChange={handleInputFormNonChange}
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="code"
                    >
                      Return Total Quantity :
                    </label>
                    <input
                      type="number"
                      name="Return_TotalQuantity"
                      placeholder="Enter Return TotalQuantity"
                      value={PurchaseRecieveFormData1.Return_TotalQuantity}
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      MRP (Per Quantity) :
                    </label>
                    <input
                      type="text"
                      name="MRP"
                      placeholder="Enter MRP"
                      value={PurchaseRecieveFormData1.MRP}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>

                  <div className="Stoct_Product_master_form_row_div">
                    <label
                      className="Stoct_Product_master_form_Label"
                      htmlFor="ProductType"
                    >
                      Total Amount :
                    </label>
                    <input
                      type="text"
                      name="TotalAmount"
                      placeholder="Enter Total Amount"
                      value={PurchaseRecieveFormData1.TotalAmount}
                      required
                      readOnly
                      className="Stoct_Product_master_form_Input"
                    />
                  </div>
                </div>

                <div className="Stoct_Product_master_form_Save">
                  <button
                    className=" Stoct_Product_master_form_Save_button"
                    onClick={isedit ? handleUpdateNon : handleAddNon}
                  >
                    {isedit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          )}

        {datalist1.length !== 0 && (
          <div className="IP_grid">
            <ThemeProvider theme={theme}>
              <div className="IP_grid_1">
                <DataGrid
                  rows={datalist1.slice(
                    page1 * pageSize,
                    (page1 + 1) * pageSize
                  )} // Display only the current page's data
                  columns={dynamicColumns1()} // Use dynamic columns here
                  pageSize={10}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10]}
                  onPageChange={handlePageChange1}
                  hideFooterPagination
                  hideFooterSelectedRowCount
                  className=" Ip_data_grid"
                />
                {showdown1 > 0 && datalist1.length > 10 && (
                  <div className="IP_grid_foot">
                    <button
                      onClick={() =>
                        setPage1((prevPage) => Math.max(prevPage - 1, 0))
                      }
                      disabled={page1 === 0}
                    >
                      Previous
                    </button>
                    Page {page1 + 1} of {totalPages1}
                    <button
                      onClick={() =>
                        setPage1((prevPage) =>
                          Math.min(prevPage + 1, totalPages - 1)
                        )
                      }
                      disabled={page1 === totalPages1 - 1}
                    >
                      Next
                    </button>
                  </div>
                )}
              </div>
            </ThemeProvider>
            {showdown1 !== 0 && datalist1.length !== 0 ? (
              ""
            ) : (
              <div className="IP_norecords">
                <span>No Records Found</span>
              </div>
            )}
          </div>
        )}
        {datalist1.length !== 0 && (
          <div className="Stoct_Product_master_form_Save">
            <button
              className=" Stoct_Product_master_form_Save_button"
              onClick={handleSubmit}
            >
              {Object.keys(IndentReturnListData).length !== 0
                ? "Update"
                : "Submit"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default IndentReturnMaster;
