import React, { useEffect, useState } from "react";
// import './PatientList.css'
import SearchIcon from "@mui/icons-material/Search";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

const PayRoll = ( ) => {

  const dispatchvalue = useDispatch();

  
  const userRecord = useSelector(state=>state.userRecord?.UserData)

  const [rows, setRows] = useState([ ]);
  const [EmployeeName,setEmployeeName]=useState("");
  const [Designation,setDesignation] = useState("");
  const [PhoneNumber,setPhoneNumber]=useState("");


  const [columns] = React.useState([
    { field: "id", headerName: "Serial No", width: 100 },
    { field: "employeeid", headerName: "Employee ID", width: 150 },
    {
      field: "employeephoto",
      headerName: "Employee Photo",
      width: 150,
      renderCell: (params) => (
        <img
          src={params.value}
          // src={`data:image/jpg;base64, ${params.value}`}
          alt="Employee"
          style={{ width: '40px', height: '40px', borderRadius: '50%' }}
        />
      ),
    },
    { field: "employeename", headerName: "Employee Name", width: 180 },
    { field: "phone", headerName: "Phone No", width: 150 },
    { field: "designation", headerName: "Designation", width: 200 },
    { field: "location", headerName: "Branch Location", width: 150 },

    {
      field: "Action ",
      headerName: "View",
      width: 150,
      renderCell: (params) => (
        <>
          {/* <Button className="cell_btn" onClick={() => handleEditList(params)}>
            <EditIcon />
          </Button> */}
          <Button className="cell_btn" onClick={() => handleList(params)}>
            <ArrowForwardIcon />
          </Button>
        
        </>
      ),
    },
  
   
  ]);
  

  useEffect(()=>{
    axios.get(`http://127.0.0.1:8000/HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`)
    .then((response)=>{
      console.log(response.data)
      const data = response.data
      const Records = data.map((userdata,index) => ({
        id: index + 1,
        employeeid: userdata.EmployeeID,
        employeephoto:userdata.EmployeePhoto,
        employeename: userdata.EmployeeName,
        phone: userdata.PhoneNumber,
        designation:userdata.Designation,
        location: userdata.Locations

        // department: userdata.Department
      }));
      setRows(Records)
    })
    .catch((error)=>{
      console.log(error)
    })
  },[userRecord?.location])




  const handleSearch = (e) => {
    e.preventDefault();
    axios.get(`http://127.0.0.1:8000/HRmanagement/get_employee_personaldetails?location=${userRecord?.location}`)
      .then((response) => {
        console.log(response.data);
        const data = response.data;
  
        const filteredData = data.filter((user) => {
          const lowerCaseFirstName = user.EmployeeName.toLowerCase();
          const lowerCasePhoneNo = user.PhoneNumber.toString();
  
          const lowerCaseSearch = EmployeeName.toLowerCase();
          const lowerCasePhoneSearch = PhoneNumber.toLowerCase();
  
          return (
            (EmployeeName === '' || lowerCaseFirstName.startsWith(lowerCaseSearch)) &&
            (PhoneNumber === '' || lowerCasePhoneNo.startsWith(lowerCasePhoneSearch))
          );
        });
  
        if (filteredData.length === 0) {
          alert('No matching employees found.');
          setRows([]);
        } else {
          const rows = filteredData.map((userdata, index) => ({
            id: index + 1,
            employeeid: userdata.EmployeeID,
            employeename: userdata.EmployeeName,
            phone: userdata.PhoneNumber,
            department: userdata.Department,
          }));
          setRows(rows);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const navigate = useNavigate();

  const handleList = (params) => {
    console.log(params)
    const employeeid = params.row.employeeid
    const location = params.row.location
    axios.get(`http://127.0.0.1:8000/HRmanagement/for_Employee_Payslip?employeeid=${employeeid}&location=${location}`)
    .then((response)=>{
      console.log(response.data)
      dispatchvalue({type : 'employeedata' , value : (response.data)})
    })
    .catch((error)=>{
      console.log(error)
    })

    navigate("/Home/Employee-PaySlip");
  };
  

  return (
    <div>
      <div className="appointment">
        <div className="h_head">
          <h3>Pay Roll</h3>
        </div>

          <div className="con_1 ">
            <div className="inp_1">
              <label htmlFor="input">Employee Name :</label>
              <input
                type="text"
                id="FirstName"

                onChange={(e)=>setEmployeeName(e.target.value)}
                value={EmployeeName}
                placeholder="Enter the First Name"
              />
            </div>
           
            <div className="inp_1">
                            <label htmlFor="input">Designation :</label>
                            <select name="designation" className="new-custom-input-phone vital_select" id="designation"  value={Designation} onChange={(e)=>setDesignation(e.target.value)}>
                                <option value=""> Select </option>
                                <option value="doctor">Doctor</option>
                                <option value="Nurse"> Nurse</option>
                                <option value="frontoffice">Front Office </option>
                            </select>
                        </div>
                        <div className="inp_1">
              <label htmlFor="input">Phone No :</label>
              <input
                type="text"
                id="PhoneNo"
                onChange={(e)=>setPhoneNumber(e.target.value)}
                value={PhoneNumber}
                placeholder="Enter the Phone No"
              />
            </div>
            <button className="btn_1" onClick={handleSearch}>
              <SearchIcon />
            </button>
          </div>

        <ThemeProvider theme={theme}>
          <div className=" grid_1">
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={100}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              hideFooterPagination
              hideFooterSelectedRowCount
              className=" data_grid"
            />
            {/* {showdown > 10 && (
              <div className="grid_foot">
                <button
                  onClick={() =>
                    setPage((prevPage) => Math.max(prevPage - 1, 0))
                  }
                  disabled={page === 0}
                >
                  Previous
                </button>
                Page {page + 1} of {totalPages}
                <button
                  onClick={() =>
                    setPage((prevPage) =>
                      Math.min(prevPage + 1, totalPages - 1)
                    )
                  }
                  disabled={page === totalPages - 1}
                >
                  Next
                </button>
              </div>
            )}
         */}
          </div>
        </ThemeProvider>
      </div>
    </div>
  );
};

export default PayRoll;

