
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
// import './Navigation.css';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import axios from 'axios';
import Vital2 from './Vital2';
import PastHistory2 from './PastHistory2';
import Treatment2 from './TreatmentComponent2';
import Prescription2 from './Prescription2';
import Procedure2 from './Procedure2';
import { useSelector } from 'react-redux';

function Navigation2( ) {

  const isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen);

  const userRecord = useSelector(state=>state.userRecord?.UserData);
 
  const [activeTab, setActiveTab] = useState('vitalForm2');  
  const [isToggled, setIsToggled] = useState(false);
  const [formData, setFormData] = useState({
    SerialNo: '',
    PatientID: '',
    AppointmentID: '',
    visitNo: '',
    firstName: '',
    lastName: '',
  });
 

  const toggle = () => setIsToggled(!isToggled);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    closeToggle();
  };

  const closeToggle = () => {
    setIsToggled(false);
  };

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (!event.target.closest('.new-kit')) {
        closeToggle();
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  });


  useEffect(() => {
    // Function to fetch data using Axios
    const fetchData = async () => {
      try {
        // Define the data to send in the POST request
        const data = {
          serialid: localStorage.getItem('appointment_patientregisterid'), // The SerialNo you want to retrieve
        };



        // Send a POST request to your PHP endpoint
        const response = await axios.get(`http://127.0.0.1:8000/doctorsworkbench/get_fortheraphist_queuelist?location=${userRecord?.location}`);

        // Handle the response from the server
        // if (response.data.success) {
        //   // Data retrieval successful
        //   const appointmentData = response.data.data[0]; // Assuming there's only one record
        const matchingAppointment = response.data.find(appointment => appointment.PatientID.toString() === data.serialid);

        console.log(matchingAppointment)

          setFormData({
            SerialNo: matchingAppointment.appointment_patientregisterID,
            PatientID: matchingAppointment.PatientID,
            AppointmentID: matchingAppointment.AppointmentID,
            visitNo: matchingAppointment.VisitID,
            firstName: matchingAppointment.FirstName,
            lastName: matchingAppointment.LastName,
            DoctorName :matchingAppointment.DoctorName,
            Status: matchingAppointment.Status

          });
  //       } else {
  //         // Data retrieval failed
  //         console.error('Data retrieval failed:', response.data.message);
  //       }
      } catch (error) {
        console.error('Error:', error);
      }
    };

  //   // Call the fetchData function when the component mounts
    fetchData();
  }, []);

   
  return (
    <>
          <Helmet>
              <title>Navigation</title>
           </Helmet>
      <div className="new-patient-registration-form">
        <h4>
          <table>
            <tbody>
              <tr>
                <td><label htmlFor="FirstName">Patient Name: </label></td>
                <td><label htmlFor="FirstName">{formData.firstName +" "+ formData.lastName} </label></td>
              </tr>
            </tbody>
          </table>
        </h4>
        <br />
        <div className="new-patient-registration-form1">
          <div className="new-navigation">
            <h2>
              <button onClick={() => handleTabChange('vitalForm2')}>Vital Form</button>
              |
              <button onClick={() => handleTabChange('pastHistory2')}>Past History</button>
              |
              <button onClick={() => handleTabChange('Treatment2')}>Treatment</button>
              |
              <button onClick={() => handleTabChange('Prescription2')}>Prescription</button>
              |
              <button onClick={() => handleTabChange('Procedure2')}>Procedure</button>
              {/* Add buttons for other tabs */}
            </h2>
          </div>

          <div className='new-kit '>
            <button className="new-tog" onClick={toggle}>
              {isToggled ? <ToggleOffIcon /> : <ToggleOnIcon />}
            </button>
         
          <div>
            {isToggled && (
              <div className="new-navigation-toggle">
                <h2>
                  <button onClick={() => handleTabChange('vitalForm2')}>Vital Form</button>
                  |
                  <button onClick={() => handleTabChange('pastHistory2')}>Past History</button>
                  |
                  <button onClick={() => handleTabChange('Treatment2')}>Treatment</button>
                  |
                  <button onClick={() => handleTabChange('Prescription2')}>Prescription</button>
                  |
                  <button onClick={() => handleTabChange('Procedure2')}>Procedure</button>
                  {/* Add buttons for other tabs */}
                </h2>
              </div>
            )}
          </div>
          </div>
        </div>
      </div>

      {activeTab === 'vitalForm2' && <Vital2 formData={formData} userRecord={userRecord} />}
      {activeTab === 'pastHistory2' && <PastHistory2 formData={formData} userRecord={userRecord} />}
      {activeTab === 'Treatment2' && <Treatment2 formData={formData} userRecord={userRecord} isSidebarOpen={isSidebarOpen} />}
      {activeTab === 'Prescription2' && <Prescription2 formData={formData} userRecord={userRecord} />}
      {activeTab === 'Procedure2' && <Procedure2 formData={formData} userRecord={userRecord} isSidebarOpen={isSidebarOpen}/>}

    
     
    </>
  );
}

export default Navigation2;


