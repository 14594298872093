import React, { useState } from "react";
import LoupeIcon from "@mui/icons-material/Loupe";
import "./Accounts.css";

function PaymentVoucher() {
  const [selectedPayment, setSelectedPayment] = useState([]);
  const [serialNumber, setSerialNumber] = useState(1);

  const addPayment = () => {
    const newPayment = {
      SerialNo: serialNumber,
      PaymentMethod: "", // Add your default value
      Description: "", // Add your default value
      AccountNo: "", // Add your default value
      Amount: "", // Add your default value
    };

    setSerialNumber((prevSerialNumber) => prevSerialNumber + 1);
    setSelectedPayment((prevSelectedPayment) => [
      ...prevSelectedPayment,
      newPayment,
    ]);
  };

  return (
    <div className="appointment">
      <div className="h_head">
        <h3>Payment Voucher</h3>
      </div>
      <form>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Cheque Payable To:</label>
            <input
              type="text"
              id="chequePayablrTo"
              // value={searchQuery}
              // onChange={handleSearchChange}
              placeholder="Enter Payable To"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input"> Voucher No :</label>
            <input
              type="text"
              id="voucherNo"
              // value={searchQuery1}
              // onChange={handleSearchChange}
              placeholder="Enter Voucher No"
            />
          </div>
        </div>
        <div className="con_1 ">
          <div className="inp_1">
            <label htmlFor="input">Cheque Paid By :</label>
            <input
              type="text"
              id="FirstName"
              // value={searchQuery}
              // onChange={handleSearchChange}
              placeholder="Enter Cheque Paid By"
            />
          </div>
          <div className="inp_1">
            <label htmlFor="input">Date :</label>
            <input
              type="Date"
              id="PhoneNo"
              // value={searchQuery1}
              // onChange={handleSearchChange}
              placeholder="Enter the Date"
            />
          </div>
        </div>
      </form>
      <div className="Selected-table-container">
        <table className="selected-medicine-table2">
          <thead>
            <tr>
              <th>
                <div className="tfdrtdf6">
                  <div className="twsrtw3">
                    <button className="sxsxe" onClick={addPayment}>
                      <LoupeIcon />{" "}
                    </button>
                  </div>
                  Serial No{" "}
                </div>
              </th>
              <th>Payment Method</th>
              <th>Description</th>
              <th>Account No</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {selectedPayment.map((paymentInfo, index) => (
              <tr key={index}>
                <td>{paymentInfo.SerialNo}</td>
                <td>{paymentInfo.PaymentMethod}</td>
                <td>{paymentInfo.Description}</td>
                <td>{paymentInfo.AccountNo}</td>
                <td>{paymentInfo.Amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">The Sum Of :</label>
          <input
            type="text"
            id="theSumOf"
            // value={searchQuery}
            // onChange={handleSearchChange}
            placeholder="Enter the Sum Of"
          />
        </div>
      </div>

      <div className="con_1 ">
        <div className="inp_1">
          <label htmlFor="input">Prepared By :</label>
          <input
            type="text"
            id="preparedBy"
            // value={searchQuery}
            // onChange={handleSearchChange}
            placeholder="Enter Prepared By"
          />
        </div>
        <div className="inp_1">
          <label htmlFor="input">Received By :</label>
          <input
            type="text"
            id="receviedBy"
            // value={searchQuery}
            // onChange={handleSearchChange}
            placeholder="Enter the Received By"
          />
        </div>
      </div>
    </div>
  );
}

export default PaymentVoucher;
