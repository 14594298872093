import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useSelector } from 'react-redux';

function CounselorProcedure( ) {

  const userRecord=useSelector(state=>state.userRecord?.UserData)

  const create = userRecord?.username;
  const location = userRecord?.location
  

  const [proceduredata, setproceduredata] = useState([]);
  const [ServiceEdit, setServiceEdit] = useState(false);
  const [editedServiceIndex, setEditedServiceIndex] = useState(null);
  const [appointmentdate, setAppointmentdate] = useState("");
  const navigate = useNavigate();
  const { patientId } = useParams();
  const [formData, setFormData] = useState({
    SerialNo: '',
    PatientID: '',
    AppointmentID: '',
    visitNo: '',
    firstName: '',
    lastName: '',
    AppointmentDate:'',
    PhoneNumber: ''
});

console.log(formData)

useEffect(() => {
  axios.get(`http://127.0.0.1:8000/Counselor/get_procedure_forcounselor?patientid=${formData.PatientID}&visitid=${formData.visitNo}`)
    .then((response) => {
      console.log(response.data);
      setFormValues(response.data[0])
    })
    .catch((error) => {
      console.log(error);
    });
}, [formData.PatientID, formData.visitNo]);

  const [formValues, setFormValues] = useState({
    DoctorName: '',
    ProcedureName: '',
    Sessions: '',
    nextAppointment: '',
    Amount: '',
    TotalAmount: '',
    appointmentDate: '',
    instruction: '',
  });

  // useEffect(() => {
  //   const data = proceduredata.map((p) => ({
  //     ProcedureName: p.ProcedureName,
  //     Therapist_Name: p.DoctorName,
  //     no_of_session: p.Sessions,
  //     appointmentDate: appointmentdate,
  //     PatientID: formData.PatientID,
  //     createdBy: userRecord?.username,
  //     doctorname: formData.DoctorName,
  //     Status: formData.Status,
  //     VisitID: formData.visitNo,
  //     nextAppointment: p.nextAppointment,
  //     instruction: p.instruction,
  //   }));
  //   setProcedure(data);
  // }, [proceduredata, formData]);

  const handleAdd = () => {
    if (
      formValues.DoctorName &&
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.nextAppointment &&
      formValues.instruction
    ) {
      const form = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + ' ' + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: 'Procedure',
        DoctorName: formValues.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        PaidSessions: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount,
        nextAppointment: formValues.nextAppointment,
        Status: 'Pending',
        appointmentDate: formData.AppointmentDate,
        instruction: formValues.instruction,
        CreatedBy: create,
        location :location,
        PhoneNumber: formData.PhoneNumber,
        ConsultationStatus: formData.ConsultationStatus,
        ProcedureStatus: formData.ProcedureStatus
      };
      console.log('forms :',form)
      setproceduredata((prev) => [...prev, form]);
      setFormValues({
        DoctorName: '',
        ProcedureName: '',
        Sessions: '',
        Amount: '',
        TotalAmount: '',
        appointmentDate: '',
        nextAppointment: '',
        instruction: '',
      });
    } else {
      alert('Please fill in all the fields');
    }
  };

  const handleUpdate = () => {
    if (
      formValues.DoctorName &&
      formValues.ProcedureName &&
      formValues.Sessions &&
      formValues.Amount &&
      formValues.TotalAmount &&
      formValues.instruction 
    ) {
      const updatedProcedure = {
        PatientID: formData.PatientID,
        PatientName: formData.firstName + ' ' + formData.lastName,
        VisitID: formData.visitNo,
        ServiceType: 'Procedure',
        DoctorName: formValues.DoctorName,
        ProcedureName: formValues.ProcedureName,
        Sessions: +formValues.Sessions,
        PaidSessions: 0,
        RemainingSessions: +formValues.Sessions,
        Amount: formValues.Amount,
        TotalAmount: formValues.TotalAmount,
        nextAppointment: formValues.nextAppointment,
        Status: 'Pending',
        appointmentDate: appointmentdate,
        instruction: formValues.instruction,
        CreatedBy: create,
        location: location,
        PhoneNumber: formData.PhoneNumber
      };

      const updatedProcedureData = [...proceduredata];
      updatedProcedureData[editedServiceIndex] = updatedProcedure;

      setproceduredata(updatedProcedureData);
      setFormValues({
        DoctorName: '',
        ProcedureName: '',
        Sessions: '',
        Amount: '',
        TotalAmount: '',
        appointmentDate: '',
        nextAppointment: '',
        instruction: '',
        CreatedBy: create,
        location: location,

      });
      setServiceEdit(false);
      setEditedServiceIndex(null);
    } else {
      alert('Please fill in all the fields');
    }
  };

  const handleEdit = (index) => {
    setFormValues(proceduredata[index]);
    setEditedServiceIndex(index);
    setServiceEdit(true);
  };

  const handleSave = () => {
    // const postData = {
    //   procedureData: proceduredata,
    // };

    // console.log(postData)
    console.log('procedure data :',proceduredata)
    axios.post('http://127.0.0.1:8000/Counselor/insert_counselorprocedure', proceduredata)
      .then(response => {
        console.log('Data saved successfully:', response.data);
        navigate("/Treament-QueueList2");
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = {
          serialid: localStorage.getItem('PatientID'),
        };
        console.log(data)
        const response = await axios.get(`http://127.0.0.1:8000/Counselor/for_counselor_queuelist?location=${userRecord?.location}`);
        console.log(response.data)
        const matchingAppointment = response.data.find(appointment => appointment.PatientID.toString() === data.serialid);

        setFormData({
          PatientID: matchingAppointment.PatientID,
          visitNo: matchingAppointment.VisitID,
          firstName: matchingAppointment.FirstName,
          lastName: matchingAppointment.LastName,
          DoctorName: matchingAppointment.DoctorName,
          AppointmentDate: matchingAppointment.AppointmentDate,
          PhoneNumber: matchingAppointment.PhoneNumber,
          ConsultationStatus: matchingAppointment.ConsultationStatus,
          ProcedureStatus: matchingAppointment.ProcedureStatus
        });
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
  }, [userRecord]);

  return (
    <>
      <Helmet>
        <title>Procedure</title>
      </Helmet>
      <div className="new-patient-registration-form">
        <div className='h_head'>
          <h3>Counselor Procedure</h3>
        </div>
        <h4>
          <table>
            <tbody>
              <tr>
                <td><label htmlFor="FirstName">Patient Name: </label></td>
                <td><label htmlFor="FirstName">{formData.firstName + " " + formData.lastName} </label></td>
              </tr>
            </tbody>
          </table>
        </h4>
        <div className="new-patient-info-container">
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="ProcedureName" className="new-custom-label-title">Treatment Procedure:</label>
            <input
              id="ProcedureName"
              name="ProcedureName"
              placeholder='Enter Treatment Procedure'
              className="new-custom-select-doctor-name"
              value={formValues?.ProcedureName}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, ProcedureName: e.target.value }))}
              readOnly
            >
            </input>
          </div>
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="DoctorName" className="new-custom-label-title">
              Therapist Name:
            </label>
            <input
              id="DoctorName"
              name="DoctorName"
              placeholder='Enter Therapist Name'
              className="new-custom-select-doctor-name"
              value={formValues?.DoctorName}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, DoctorName: e.target.value }))}
              readOnly
            >
            </input>
          </div>
        </div>

        <div className="new-patient-info-container">

          <div className="new-custom-form-row width_procedure">
            <label htmlFor="Amount" className="new-custom-label-title">Procedure Amount:</label>
            <input
              type="number"
              id="Amount"
              name="Amount"
              className="new-custom-select-doctor-input"
              placeholder='Enter Procedure Amount'
              value={formValues?.Amount}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, Amount: e.target.value }))}
              readOnly
            />
          </div>
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="Sessions" className="new-custom-label-title">Number of Sessions:</label>
            <input
              type="number"
              id="Sessions"
              name="Sessions"
              className="new-custom-select-doctor-input"
              placeholder='Enter No of Sessions'
              value={formValues?.Sessions}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, Sessions: e.target.value }))}
              readOnly
            />
          </div>

        </div>

        <div className="new-patient-info-container">
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="Amount" className="new-custom-label-title">Total Procedure Amount:</label>
            <input
              type="number"
              id="Amount"
              name="TotalAmount"
              readOnly
              className="new-custom-select-doctor-input"
              placeholder='Enter Total Procedure Amount'
              value={formValues?.TotalAmount}
            />
          </div>
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="nextAppointment" className="new-custom-label-title label_procedure">Follow up Date :</label>
            <input
              type="date"
              id="nextAppointment"
              name="nextAppointment"
              className="new-custom-select-doctor-input"
              value={formValues?.nextAppointment}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, nextAppointment: e.target.value }))}
              required
            />
          </div>

        </div>

        <div className="new-patient-info-container">
          <div className="new-custom-form-row width_procedure">
            <label htmlFor="nextAppointment" className="new-custom-label-title label_procedure">Reason :</label>
            <textarea
              type="text"
              id="instruction"
              name="instruction"
              className="inst-counlr-with"
              value={formValues?.instruction}
              onChange={(e) => setFormValues((prevValues) => ({ ...prevValues, instruction: e.target.value }))}
              required
            />
          </div>

        </div>
        <div className="new-button-container">
          <button className="btncon_add" onClick={ServiceEdit ? handleUpdate : handleAdd}>{ServiceEdit ? "Update" : "Add"}</button>
        </div>
        <div className="Selected-table-container">
          <table className="selected-medicine-table2 ">
            <thead>
              <tr>
                <th id="vital_Twidth">Service Type</th>
                <th id="vital_Twidth">Therapist Name</th>
                <th id="vital_Twidth">Procedure Name</th>
                <th id="vital_Twidth"> Charge </th>
                <th id="vital_Twidth">Sessions</th>
                <th id="vital_Twidth">Total </th>
                <th id="vital_Twidth"> Follow-up Date </th>
                <th id="vital_Twidth">Action</th>
              </tr>
            </thead>
            <tbody>
              {proceduredata.map((client, index) => (
                <tr key={index}>
                  <td>{client.ServiceType}</td>
                  <td>{client.DoctorName}</td>
                  <td>{client.ProcedureName}</td>
                  <td>{client.Amount}</td>
                  <td>{client.Sessions}</td>
                  <td>{client.TotalAmount}</td>
                  <td>{client.nextAppointment}</td>
                  <td>
                  <button className='edit-edit34' onClick={() => handleEdit(index)}>
                    <EditNoteIcon />
                    </button>

                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="new-button-container">
          <button className="btncon_add" onClick={handleSave}>Save</button>
        </div>
      </div>
    </>
  );
}

export default CounselorProcedure;
