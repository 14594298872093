import React, { useState, useEffect } from 'react';
import './AppointmentCalendar.css';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import axios from 'axios';
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const Months = ( ) => {

const dispatchvalue = useDispatch();
  
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [appointments, setAppointments] = useState([]);
  const [totalBookedCount, setTotalBookedCount] = useState(0);
  const [selectedDoctor, setSelectedDoctor] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [selectedAppointments, setSelectedAppointments] = useState([]);
  const [Doctors, setDoctors] = useState([]);
  // const [dayAppointments, setDayAppointments] = useState([]);
  // const [counts, setCounts] = useState({});
  const [stateforview,setstateview]=useState('');
  const navigate =useNavigate();
  const userRecord=useSelector(state=>state.userRecord?.UserData)
  
  const Location = userRecord?.location
  console.log('wertyuio',userRecord)

  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/usercontrol/get_doctor_info?location=${Location}`)
      .then((response) => {
      
        setDoctors(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [Location]);

  // useEffect(() => {
  //   axios
  //     .get(`http://127.0.0.1:8000/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(currentMonth, 'yyyy-MM-dd')}`)
  //     .then((response) => {
  //       const data = response.data;
  //       console.log(response.data,'+++')
  //       // const allPatientsData = data.reduce((acc, appointment) => {
  //       //   if (appointment.patient_data) {
  //       //     const patientData = appointment.patient_data.map((patient) => ({
  //       //       patientName: patient.patientname,
  //       //       time: patient.time,
  //       //     }));
  //       //     acc.push(...patientData);
  //       //   }
  //       //   return acc;
  //       // }, []);
  //       // console.log(allPatientsData)
  //       // setDayAppointments(data);
  //       // setCounts({
  //       //   Total: data[0].no_of_slot,
  //       //   Available: data[0].AvailableSlot,
  //       //   Booked: data[0].BookedSlot,
  //       //   Canceled: data[0].cancelled,
  //       // });
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, [currentMonth, selectedDoctor]);

  useEffect(() => {
    axios.get(`http://127.0.0.1:8000/appointmentmanagement/get_doctor_slots_by_month?doctor_name=${selectedDoctor}&year=${currentMonth.getFullYear()}&month=${currentMonth.getMonth() + 1}&location=${Location}`)
      .then((response) => {
        console.log(response.data,'---')
        setAppointments(response.data);
        const data = response.data
        let totalCount = 0;

      data.forEach((entry) => {
        totalCount += entry.booked_slot;
      });
        setTotalBookedCount(totalCount);
      })
      .catch((error) => {
        console.log(error);
      });
    

  }, [selectedDoctor, currentMonth,Location]);

  const handlePreviousMonth = () => {
    setCurrentMonth((prevMonth) => {
      const prevMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() - 1, 1);
      return prevMonthDate;
    });
  };

  const handleNextMonth = () => {
    setCurrentMonth((prevMonth) => {
      const nextMonthDate = new Date(prevMonth.getFullYear(), prevMonth.getMonth() + 1, 1);
      return nextMonthDate;
    });
  };

  const getStartingDayOfMonth = () => {
    const firstDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
    return firstDay.getDay();
  };

  const daysInMonth = () => {
    const year = currentMonth.getFullYear();
    const month = currentMonth.getMonth();
    const lastDay = new Date(year, month + 1, 0).getDate();
    const startingDay = getStartingDayOfMonth();
    const daysArray = [];

    for (let i = 0; i < startingDay; i++) {
      daysArray.push(null);
    }

    for (let day = 1; day <= lastDay; day++) {
      const date = new Date(year, month, day);
      const appointmentData = appointments.find((item) => new Date(item.date).toDateString() === date.toDateString()) || {
        available_slot: 0,
        booked_slot: 0,
        cancelled: 0,
      };

      daysArray.push({
        date,
        appointments: appointmentData,
      });
    }

    return daysArray;
  };

  const handleShowModal = (appointments,Name) => {
    setstateview(Name)
    if(Name === 'Booked'){
      axios
    .get(`http://127.0.0.1:8000/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(appointments, 'yyyy-MM-dd')}&location=${Location}`)
    .then((response) => {
      console.log(response.data)
      const data = response.data;
      const allPatientsData = data.reduce((acc, appointment) => {
        if (appointment.Register_patient_data) {
          const patientData = appointment.Register_patient_data.map((patient) => ({
            patientName: patient.patientname,
            time: patient.time,
          }));
          acc.push(...patientData);
        }
        return acc;
      }, []);
      setSelectedAppointments(allPatientsData);
      console.log(allPatientsData)
    })
    // setSelectedAppointments(appointments);
    setShowModal(true);
    }else if(Name === 'Request'){
      axios
    .get(`http://127.0.0.1:8000/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(appointments, 'yyyy-MM-dd')}&location=${Location}`)
    .then((response) => {
      console.log(response.data)
      const data = response.data;
      const allPatientsData = data.reduce((acc, appointment) => {
        if (appointment.Request_patient_data) {
          const patientData = appointment.Request_patient_data.map((patient) => ({
            patientName: patient.patientname,
          }));
          acc.push(...patientData);
        }
        return acc;
      }, []);
      setSelectedAppointments(allPatientsData);
      console.log(allPatientsData)
    })
    // setSelectedAppointments(appointments);
    setShowModal1(true);
    }else if(Name === 'Canceled'){
      axios
    .get(`http://127.0.0.1:8000/appointmentmanagement/get_doctor_slots_per_day?doctor_name=${selectedDoctor}&date=${format(appointments, 'yyyy-MM-dd')}&location=${Location}`)
    .then((response) => {
      console.log(response.data)
      const data = response.data;
      const allPatientsData = data.reduce((acc, appointment) => {
        if (appointment.Canceled_Register_patient_data) {
          const patientData = appointment.Canceled_Register_patient_data.map((patient) => ({
            patientName: patient.patientname,
          }));
          acc.push(...patientData);
        }
        return acc;
      }, []);
      setSelectedAppointments(allPatientsData);
      console.log(allPatientsData)
    })
    // setSelectedAppointments(appointments);
    setShowModal2(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowModal1(false);
    setShowModal2(false);
  };

  const renderCalendar = () => {
    const days = daysInMonth();
    let rows = [];
    let cells = [];

    days.forEach((day, index) => {
      if (index % 7 === 0) {
        rows.push(cells);
        cells = [];
      }

      if (day) {
        cells.push(
          <td key={index} className='cal_flex'>
            <div className="day" >
              <span className="date">{day.date.getDate()}</span>
              <div className="calendar_app">
                {/* <div className="appointment_cal">
                  <h3>Total Appointments &rArr; {day.appointments.total_no_of_slot}</h3>
                </div> */}
                <div className="appointment_body_1">
                  {/* <div className="appointment_data_1" title='Available'>
                    <div>
                      <span>Available</span>
                      <span>:</span>
                    </div>
                    <span className='appointment_count_1'>{day.appointments.available_slot}</span>
                  </div> */}
                 
                  <div className="appointment_data_1" title='Booked'onClick={() => handleShowModal(day.date,'Booked')}>
                    <div>
                      <span>Booked</span>
                      <span>:</span>
                    </div>
                    <span className='appointment_count_1'>{day.appointments.booked_slot ||0}</span>
                  </div>
                  <div className="appointment_data_1" title='Request'onClick={() => handleShowModal(day.date,'Request')}>
                    <div>
                      <span>Request</span>
                      <span>:</span>
                    </div>
                    <span className='appointment_count_1'>{day.appointments.request_slot||0}</span>
                  </div>
                  <div className="appointment_data_1" title='Canceled' onClick={() => handleShowModal(day.date,'Canceled')}>
                    <div>
                      <span>Canceled</span>
                      <span>:</span>
                    </div>
                    <span className='appointment_count_1'>{day.appointments.cancel||0}</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        );
      } else {
        cells.push(<td key={index} className='cal_flex empty-cell'></td>);
      }
    });

    rows.push(cells);

    return (
      <>
        <div className="calendar">
          <div className="cal_mon_1">
            <button onClick={handlePreviousMonth}>
              <KeyboardDoubleArrowLeftIcon/>
            </button>
            <h3>{currentMonth.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })}</h3>
            <button onClick={handleNextMonth}>
              <KeyboardDoubleArrowRightIcon/>
            </button>
          </div>
          <div className="select_items_appointment">
            <div className="doctor_select appoint_booked_count">
              <label htmlFor="names">Doctor Name <span>:</span></label>
              <select
                name="Doctor Name"
                onChange={(e) => setSelectedDoctor(e.target.value)}
                value={selectedDoctor}
              >
                <option value="all">Select</option>
                {Doctors.map((p, index) => (
                  <option key={index} value={p}>
                    {p}
                  </option>
                ))}
              </select>
            </div>
            <div className="appoint_booked_count"> 
            <label>Booked <span>:</span> <span>{totalBookedCount}</span></label>
            </div>
          </div>
        </div>
        <div className="calender_table_overall">
          <div className="calender_table">
            <table>
              <thead>
                <tr>
                  <th>Sun</th>
                  <th>Mon</th>
                  <th>Tue</th>
                  <th>Wed</th>
                  <th>Thu</th>
                  <th>Fri</th>
                  <th>Sat</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr key={index}>{row}</tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };
  const handlePatients=(patient)=>{
    if(stateforview ===  'Booked'){
      navigate('/Home/Appoinment-RegisterList')

      dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })

    }else if(stateforview === 'Request'){
      navigate('/Home/Appoinment-RequestList')

      dispatchvalue({type:'SelectedPatientCalender' , value: (patient) })
    }
   
  }
  return (
    <div className="calendar-container">
      {renderCalendar()}

      {showModal && (
        <div className="modal-container" onClick={handleCloseModal}>
          <div className="App_Cal_modal" onClick={(e) => e.stopPropagation()}>
          
           {selectedAppointments.length !== 0 ?
          
            
              <>
                <h3>Booked Appointments</h3>
              <ul>
                {selectedAppointments.map((appointment, index) => (
                  <li key={index} onClick={()=>handlePatients(appointment.patientName)}>
                    {appointment.patientName}
                  </li>
                ))}
              </ul>
              <button onClick={handleCloseModal} className='booked_app_btn'><HighlightOffIcon /></button>
              </>
           
            
           :
           <div style={{height:'100%',color:'hsl(33,100%,50%)',display:'grid',placeItems:'center'}}> No Appointments</div>
            }
          </div>
        </div>
      )}
      {showModal1 && (
        <div className="modal-container" onClick={handleCloseModal}>
          <div className="App_Cal_modal" onClick={(e) => e.stopPropagation()}>
           {selectedAppointments.length !== 0 ?
          
            <>
             <h3> Appointments Request</h3>
            <ul>
              {selectedAppointments.map((appointment, index) => (
                <li key={index} onClick={()=>handlePatients(appointment.patientName)}>
                  {appointment.patientName}
                </li>
              ))}
            </ul>
            <button onClick={handleCloseModal} className='booked_app_btn'><HighlightOffIcon /></button>
           
            </>
           :
           <div style={{height:'100%',color:'hsl(33,100%,50%)',display:'grid',placeItems:'center'}}> No Appointments Request</div>
            }
          </div>
        </div>
      )}
      {showModal2 && (
        <div className="modal-container" onClick={handleCloseModal}>
          <div className="App_Cal_modal" onClick={(e) => e.stopPropagation()}>
           {selectedAppointments.length !== 0 ?
          
            <>
             <h3>Cancelled Appointments </h3>
            <ul>
              {selectedAppointments.map((appointment, index) => (
                <li key={index} onClick={()=>handlePatients(appointment.patientName)}>
                  {appointment.patientName}
                </li>
              ))}
            </ul>
            <button onClick={handleCloseModal} className='booked_app_btn'><HighlightOffIcon /></button>
           
            </>
           :
           <div style={{height:'100%',color:'hsl(33,100%,50%)',display:'grid',placeItems:'center'}}> No Cancelled Appointments </div>
            }
          </div>
        </div>
      )}
    </div>
  );
};

export default Months;
