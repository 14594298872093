import React, { useCallback, useEffect, useState } from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../src/App.css';
import LoginPage from './LoginPage/LoginPage';
import HomePage from './HomePage/HomePage';
import RoleManagement from './UserControls/RoleManagement';
import UserRegister from './UserControls/UserRegister';
import UserList from './UserControls/UserList';
import PatientQueueList from './TreatmentRecords/PatientQueueList';
import RequestList from './AppointmentManagement/RequestList';
import RegisterList from './AppointmentManagement/RegisterList';
import Register from './AppointmentManagement/Register';
import Profile from './Profile/Profile';
import PasswordChange from './PasswordChange/PasswordChange';
import AccountSettings from './UserControls/Account Settings';
import ClinicDetails from './UserControls/ClinicDetails';
import Navigation from './TreatmentRecords/Navigation';
import BillingHistory from './Billing/BillingHistory';
import ClinicMetrics from './ClinicMetrics/ClinicMetrics';
import PatientProfile from './PatientManagement/PatientProfile';
import AppoinmentCalendar from './AppointmentManagement/AppointmentCalendar';
import TreatmentPro from './UserControls/TreatmentPro';
import Preview from './TreatmentRecords/Preview';
import SlotManagement from './UserControls/SlotManagement';
import PatientList from './TreatmentRecords/PatientList';
import PatientQueueList2 from './Therophist/PatientQueueList2';
import Navigation2 from './Therophist/Navigation2';
import Vital2 from './Therophist/Vital2';
import RegistrationForm from './RegistrationForm/Registration';
import PatientList2 from './Therophist/PatientList2';
import NewProcedure from './Therophist/NewProcedure';
import CounselorQueueList from './Counselor/CounselorQueueList';
import CounselorProcedure from './Counselor/CounselorProcedure';
import CounselorFullList from './Counselor/CounselorFullList';
import ExpensesMaster from './Pettycash/ExpensesMaster';
import CashExpenses from './Pettycash/CashExpenses';
import CashExpenseReport from './Pettycash/CashExpenseReport';
import HandOverSummary from './Pettycash/HandOverSummary';
import DayBookReport from './Pettycash/DayBookReport';
import DayClosing from './Pettycash/DayClosing';
import DueHistoryList from './Billing/Duehistory/DueHistoryList';
import GeneralBillingQueList from './Billing/OverallBilling/GeneralBillingQueList';
import GeneralBilling from './Billing/OverallBilling/GeneralBilling';
import BillCancelation from './Billing/BillCancelation';
import Concern from './AppointmentManagement/ConcernForms/Concern';
import Concernlist from './AppointmentManagement/ConcernForms/Concernlist';
import RateCardMaster from './UserControls/RateCardMaster/RateCardMaster';
import LabQueueList from './AppointmentManagement/LabQueueList';
import LabReportFN from './AppointmentManagement/LabReportFN';
import PatientQueueList3 from './Nurse/PatientQueueList3';
import Navigation3 from './Nurse/Navigation3';
import OverallDayreport from './Pettycash/OverallDayreport';
import Walkinbilling from './Pharmacy/Walkinbilling';
import PharmacyBillingLIst from './Pharmacy/PharmacyBillingLIst';
import PharmacyBilling from './Pharmacy/PharmacyBilling';
import DeuHistory from './Billing/Duehistory/DeuHistory';
import PharmacyBillcancelation from './Pharmacy/Pharmacy_Billcancelation';
import EmployeeRegister from './HR Management/EmployeeRegister';
import EmployeeList from './HR Management/EmployeeList';
import EmployeeProfile from './HR Management/EmployeeProfile';
import EmployeeAttendance from './HR Management/Attendance';
import EmployeeCalendar from './HR Management/EmployeeCalendar';
import EmployeeQueList from './UserControls/EmployeeQueList';
import VisitingDoctorBilling from './UserControls/VisitDoctorBilling';
import LeaveMangement from './HR Management/LeaveMangement';
import PayRoll from './HR Management/PayRoll';
import PaySlip from './HR Management/PaySlip';
import VisitingDoctor from './VisitingDoctor/VisitingDoctor';
import PatientVisitingDoctor from './VisitingDoctor/PatientVisitingDoctor';
import PerformanceAppraisal from './HR Management/Performance';
import PerformanceManagement from './HR Management/PerformanceManagement';
import AddvaceApproval from './HR Management/AdvanceApproval';
import AddvaceManagement from './HR Management/AdvanceManagement';
import LeaveNavigation from './EmployeeRequest/LeaveNavigation';
import AdvanceNavigation from './EmployeeRequest/AdvanceNavigation';
import LeaveApproval from './HR Management/LeaveApproval';
import SupplierStock from './Stock/SupplierPay/SupplierStock';
import SupplierDetails from './Stock/SupplierPay/SupplierDetails';
import SupplierList from './Stock/SupplierMaster/SupplierList';
import SupplierMaster from './Stock/SupplierMaster/SupplierMaster';
import ProductList from './Stock/ProductMaster/ProductList';
import ProductMaster from './Stock/ProductMaster/ProductMaster';
import PurchaseRaiseList from './Stock/PurchaseRaiseMaster/PurchaseRaiseList';
import PurchaseRaiseMaster from './Stock/PurchaseRaiseMaster/PurchaseRaiseMaster';
import PurchaseRecieceList from './Stock/PurchaseRecieveMaster/PurchaseRecieveList';
import PurchaseRecieveMaster from './Stock/PurchaseRecieveMaster/PurchaseRecieveMaster';
import IndentRaiseMaster from './Stock/IndentRaise/IndentRaiseMaster';
import IndentRaiseList from './Stock/IndentRaise/IndentRaiseList';
import IndentRecieveList from './Stock/IndentRecieve/IndentRecieveList';
import IndentRecieveMaster from './Stock/IndentRecieve/IndentRecieveMaster';
import IndentIssueMaster from './Stock/IndentIssue/IndentIssueMaster';
import IndentIssueList from './Stock/IndentIssue/IndentIssueList';
import IndentApprove from './Stock/ApprovePages/IndentApprove';
import IndenIssueApprove from './Stock/ApprovePages/IndenIssueApprove';
import IndentReturnList from './Stock/IndentReturn/IndentReturnList';
import IndentReturnMaster from './Stock/IndentReturn/IndentReturnMaster';
import PurchaseApprove from './Stock/ApprovePages/PurchaseApprove';
import GrnApprove from './Stock/ApprovePages/GrnApprove';
import PurchaseReturnMaster from './Stock/PurchaseReturn/PurchaseReturnMaster';
import CentralStockList from './Stock/CentralStock/CentralStockList';
import LocationStock from './Stock/CentralStock/LocationStock';
import QuickStockListLoc from './Stock/QuickStockRecieve/QuickStockListLoc';
import QuickStockMasterLoc from './Stock/QuickStockRecieve/QuickStockMasterLoc';
import IndentRecieveApprove from './Stock/ApprovePages/IndentRecieveApprove';
// import Accounts from './Accounts/Accounts';
import PaymentVoucher from './Accounts/PaymentVoucher';
import AccountPayable from './Accounts/AccountPayable';
// import PatientView from './patientView';
import Patientview from './PatientView'
import NotFound from './404Page';
import PrivacyPolicy from './Footer/PrivacyPolicy';
import TermsOfUse from './Footer/TermsOfUse';
import { useDispatch, useSelector } from 'react-redux';
import PatientListupdate from './AppointmentManagement/patientlistupdate';
import Expenses from './Pettycash/Expenses';
import DigitalExpenses from './Pettycash/DigitalExpense';
import QuickStockMasterCentral from './Stock/QuickStockRecieve/QuickStockMasterCentral';
import SuplierPaidlist from './Stock/SupplierPay/SuplierPaidlist';
import IndentMovementmaster from './Stock/IndentMovement/IndentMovementmaster';
import IndentMovementlist from './Stock/IndentMovement/IndentMovementlist';
import IndentReturnApprove from './Stock/ApprovePages/IndentReturnApprove';
import PurchaseReturnLocMaster from './Stock/PurchaseReturn/PurchaseReturnLocMaster';
import PurchaseRegister from './Finance/PurchaseRegister';
import AccountGroupMaster from './Finance/AccountGroupMaster';
import SalesRegister from './Finance/SalesRegister';
import Journalentry from './Finance/Journalentry';
import LedgerList from './Finance/LedgerList';
import BalanceSheet from  './Finance/BalanceSheet'
import AccountGroupList from  './Finance/AccountGroupList'
import Accounts from './Finance/Accounts'



import PatientView from './PatientView';
import VisitingDoctorBillingList from './UserControls/VisitDoctorBillingList';
import Followuplist from './AppointmentManagement/Followuplist';
import DutyManagement from './HR Management/DutyManagement';
import PharmachyMasterList from './Stock/PharmachyMaster/PharmachyMasterList';
import PharmachyMaster from './Stock/PharmachyMaster/PharmachyMaster';
import GeneralMasterList from './Stock/GeneralMaster/GeneralMasterList';
import GeneralMaster from './Stock/GeneralMaster/GeneralMaster';


import RoomMaster from './IpManagement/RoomCreate/RoomMaster';
import RoomMasterCreate from './IpManagement/RoomCreate/RoomMasterCreate';
import NurseQueList from './IpManagement/IPNurseflow/NurseQueList';
import IpNurseWorkbench from './IpManagement/IPNurseflow/IpNurseWorkbench';
import IpDoctorQueList from './IpManagement/IpDoctorflow/IpDoctorQueList';
import IpDoctorWorkbench from './IpManagement/IpDoctorflow/IpDoctorWorkbench';
import IpRegistrationlist from './IpManagement/IPRegistration/IpRegistrationlist';
import RoomsAvail from './IpManagement/RoomCreate/RoomsAvail';
import IpRegistration1 from './IpManagement/IPRegistration/IpRegistration1';
import TheatreBooking from './IpManagement/Operationtheatre/TheatreBooking';
import IPrateCardMaster from './UserControls/Ipratecard';
import FrequencyMaster from './IpManagement/Frequencymaster/FrequencyMaster';





const App = () => {


  const [userRecord, setUserRecord] = useState(null);

  // const [isSidebarOpen, setSidebarOpen] = useState(false);
  // // const [isModalOpen, setModalOpen] = useState(false);
  // const [toggled, setToggled] = useState(false);
  // const [selectedRequestList, setselectedRequestList] = useState([]);
  // const [SelectedMedicine, setSelectedMedicine] = useState('');
  // const [forpatientdata, setforPatientData] = useState([]);
  // const [Selected_Patient_Pharmacy, setSelected_Patient_Pharmacy] = useState([])
  // const [labformdata, setlabformdata] = useState([]);
  // const [foremployeedata, setforemployeedata] = useState([]);
  // const [foruserregisteremployeedata, setforuserregisteremployeedata] = useState([]);
  // const [Data, setData] = useState([])
  // const [selectedRowData, setSelectedRowData] = useState(null);
  // const [foremployeeedit, setforemployeeedit] = useState([]);
  // const [employeedata, setemployeedata] = useState([]);
  // const [forpatienteedit, setforpatienteedit] = useState([])
  // const [foredituserregisteremployeedata,setforedituserregisteremployeedata]=useState([])
  // const [employeeIdGet , setEmployeeIdGet] =useState ([]);

  // //for calender
  // const [SelectedPatientCalender, setSelectedPatientCalender] = useState('')
  // const [registerdata, setregisterdata] = useState([]);
  // //  for stock
  // const [Supplierpay, setSupplierpay] = useState(null);
  // const [selectedSupplierIndex, setSelectedSupplierIndex] = useState(null);
  // const [selectedProductIndex, setSelectedProductIndex] = useState({});
  // const [PurchaseInvoiceGRN, setPurchaseInvoiceGRN] = useState({});
  // const [IndentIssueListData, setIndentIssueListData] = useState(null);
  // const [IndentRecieveListData, setIndentRecieveListData] = useState(null);
  // const [ExpiryProductsData, setExpiryProductsData] = useState(null);
  // const [GRNedit, setGRNedit] = useState({});
  // const [IndentReturnListData, setIndentReturnListData] = useState({})


  const navigate = useNavigate();
  const location = useLocation();

  const dispatchvalue = useDispatch();
  useEffect(()=>{
    const fullURL = window.location.href;
    console.log('location',fullURL)
  },[])

  const  isModalOpen = useSelector(state=>state.userRecord?.isModalOpen )

  const getuserrecord = useCallback(() => {
    const storedToken = localStorage.getItem('token');
    if (storedToken) {
      const decodedToken = (token) => {
        const payloadBase64 = token.split('.')[1];
        const decodedPayload = atob(payloadBase64);
        return JSON.parse(decodedPayload);
      };
      const decodedTokenData = decodedToken(storedToken);
      setUserRecord(decodedTokenData)
      dispatchvalue({ type: 'UserData', value: decodedTokenData })
    } else {
      if (location.pathname !== '/') {
        navigate('/')
      }
    }
  }, [navigate, location.pathname])



  useEffect(() => {
    const loc = location.pathname
    if (loc === '/') {
      localStorage.clear()
    }
    getuserrecord();
  }, [location, location.pathname, navigate, getuserrecord])

  console.log(userRecord)


  useEffect(() => {
    const sessionTimeout = 30 * 60 * 1000; // 3 minutes in milliseconds
    let timeoutId;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        localStorage.clear();
        navigate('/');
      }, sessionTimeout);
    };

    // Add event listeners to reset the timeout on user activity
    window.addEventListener('mousemove', resetTimeout);
    window.addEventListener('keydown', resetTimeout);

    resetTimeout(); // Initial setup

    // Clean up event listeners on unmount
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('mousemove', resetTimeout);
      window.removeEventListener('keydown', resetTimeout);
    };
  }, [navigate]);


  useEffect(() => {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.setAttribute('autocomplete', 'off');
      input.addEventListener('copy', (event) => {
        event.preventDefault();
      });
    });
  }, []);

  // Function to open the modal
  const openModal = () => {
    dispatchvalue({type:'isModalOpen',value:true})
  };

  // Function to close the modal
  const closeModal = () => {
    dispatchvalue({type:'isModalOpen',value:false})
  };

  return (
    <>
      <Routes>
        <Route path='/Home' element={<HomePage  />}>
          {/* Header */}
          <Route path="Profile" element={ <Profile />} />
          <Route path="PasswordChange" element={ <PasswordChange  />} />

          {/* Patient Management */}
          <Route path="PatientProfile" element={<PatientProfile  />} />


          {/* Dashboard */}
          <Route path="ClinicMetrics" element={<ClinicMetrics  />} />


          {/* Front Office */}
          <Route path="Register-Patient2" element={<RegistrationForm />} />
          <Route path="Appoinment-RequestList" element={<RequestList />} />
          <Route path="Register" element={<Register  />} />
          <Route path="Appoinment-RegisterList" element={<RegisterList   />} />
          <Route path="Appointment-Calendar" element={<AppoinmentCalendar  />} />
          <Route path="Register_concern_List" element={<Concernlist  />} />
          <Route path="Register_concern_Form" element={<Concern />} />
          <Route path="Lab-QueueList" element={<LabQueueList  />} />
          <Route path="Lab-ReportFN" element={<LabReportFN   />} />
          <Route path="Patient-List-update" element={<PatientListupdate  />} />
          <Route path="Followuplist" element={<Followuplist  />} />

        

          {/* Nurse */}
          <Route path="Treament-QueueList3" element={<PatientQueueList3  />} />
          <Route path="Navigation3" element={<Navigation3  />} />


          {/* ip management*/}
          {/* IP Management */}
          <Route path="IP-Registration1" element={<IpRegistration1  />} />
          <Route path="OT-Management"element={<TheatreBooking />}/>
          <Route path="Room_Master" element={<RoomMaster />}/>
          <Route path="Room-Management"element={<RoomsAvail />}/>          
          <Route path="IpRegistrationlist" element={<IpRegistrationlist  />} />
          <Route path="IpNurseQuelist" element={<NurseQueList/>} />
          <Route path="IpNurseWorkBench" element={<IpNurseWorkbench/>} />
          <Route path="IpDocQuelist" element={<IpDoctorQueList/>} />
          <Route path="IpDocWorkBench" element={<IpDoctorWorkbench/>}/>
          <Route path="IPratecard" element={<IPrateCardMaster/>}/>
          <Route path="Room_Create_Master" element={<RoomMasterCreate/>}/>
          <Route path="Frequency_Master" element={<FrequencyMaster/>}/>

          {/* Stock */}

          {/* supplier Master */}

          <Route path='Supplier_List' element={<SupplierList  />} />
          <Route path='Supplier_Master' element={<SupplierMaster  />} />
          {/*  Product Master */}
          <Route path='Product_List' element={<ProductList  />} />
          <Route path='Product_Master' element={<ProductMaster  />} />


      {/* Pahrmachy Master */}
      <Route path='Pharmacy_MasterList' element={<PharmachyMasterList  />} />
          <Route path='Pharmacy_Master' element={<PharmachyMaster  />} />

          {/* General Master */}

          <Route path='General_MasterList' element={<GeneralMasterList />} />
          <Route path='General_Master' element={<GeneralMaster  />} />



          {/* purchase raise master */}
          <Route path='Purchase_Raise_list' element={<PurchaseRaiseList  />} />
          <Route path='Purchase_Raise_Master' element={<PurchaseRaiseMaster  />} />
          {/* purchase recieve master */}
          <Route path='Purchase_Recieve_list' element={<PurchaseRecieceList  />} />
          <Route path='Purchase_Recieve_Master' element={<PurchaseRecieveMaster  />} />
          {/* purchase Indent Raise master */}
          <Route path='Indent_Raise_list' element={<IndentRaiseList  />} />
          <Route path='Indent_Raise_Master' element={<IndentRaiseMaster  />} />
          {/* purchase Indent Recieve master */}
          <Route path='Indent_Recieve_list' element={<IndentRecieveList  />} />
          <Route path='Indent_Recieve_Master' element={<IndentRecieveMaster  />} />
          {/* purchase Indent Issue master */}
          <Route path='Indent_Issue_list' element={<IndentIssueList  />} />
          <Route path='Indent_Issue_Master' element={<IndentIssueMaster  />} />


          <Route path='Indent_Return_list' element={<IndentReturnList  />} />
          <Route path='Indent_Return_Master' element={<IndentReturnMaster  />} />
          <Route path="IndentMovementmaster" element={<IndentMovementmaster  />} />
          <Route path="IndentMovementlist" element={<IndentMovementlist  />} />

          {/* indent Approve */}
          <Route path='Indent_appprove_List' element={<IndentApprove  />} />
          <Route path='IndentIssue_appprove_List' element={<IndenIssueApprove />} />
          <Route path='PurchaseApprove_List' element={<PurchaseApprove  />} />
          <Route path='IndentRecieve_appprove_List' element={<IndentRecieveApprove  />} />
          <Route path='GRN_appprove_List' element={<GrnApprove  />} />
          <Route path='IndentReturnApprove' element={<IndentReturnApprove />} />

          {/* expiry products */}
          <Route path='PurchaseReturn_Loc_Master' element={<PurchaseReturnLocMaster />} />
          <Route path='PurchaseReturn_Master' element={<PurchaseReturnMaster />} />
          {/* stock list */}
          <Route path='CentralStock_List' element={<CentralStockList />} />
          <Route path='LocationStock_List' element={<LocationStock  />} />
          {/* QuickStock Location */}
          <Route path='Quick_Stock_Recieve_List' element={<QuickStockListLoc  />} />
          <Route path='Quick_Stock_Recieve' element={<QuickStockMasterLoc  />} />
          <Route path='Quick_Stock_Recieve_Central' element={<QuickStockMasterCentral  />} />
          {/* Supplier Pay */}
          <Route path='Supplier-Stock-Manager' element={<SupplierStock  />} />
          <Route path='Supplier-Stock-Details' element={<SupplierDetails  />} />
          <Route path='Supplier_Pay_List' element={<SuplierPaidlist />} />



          {/*------------------------------ Finance -------------------------*/}
          <Route path='PurchaseRegister' element={<PurchaseRegister/>} />
          <Route path='SalesRegister' element={<SalesRegister/>} />
          <Route path='Journalentry' element={<Journalentry/>} />
          <Route path='LedgerList' element={<LedgerList/>} />
          <Route path="Add_Accounts" element={<Accounts />} />
          <Route path="BalanceSheet" element={<BalanceSheet />} />
          <Route path="AccountGroupList" element={<AccountGroupList />} />
          {/* Accounts */}
          <Route path="Payment-Voucher" element={<PaymentVoucher />} />
          {/* <Route path="/Accounts_payable" element={<AccountPayable/>} /> */}
          <Route path='AccountGroupMaster' element={<AccountGroupMaster />}/>
          

          {/* Doctor work bench */}
          <Route path="Treament-QueueList" element={<PatientQueueList  />} />

          <Route path="Navigation" element={<Navigation  />} />
          <Route path="Patient-List" element={<PatientList  />} />
          <Route path="vitalForm2" element={<Vital2  />} />   {/*Pending formData*/}

          <Route path="PatientView" element={<PatientView  />} />

          <Route path="Preview" element={<Preview  />} /> {/*Pending formData*/}

          {/* <Route path="vitalForm3" element={<Vital3 userRecord={userRecord} />} /> */}




          {/* Theraphist */}
          <Route path="Treament-QueueList2" element={<PatientQueueList2  />} />
          <Route path="Navigation2" element={<Navigation2  />} />
          <Route path="Patient-List2" element={<PatientList2 />} />
          <Route path="Treament-NewProcedure" element={<NewProcedure  />} />


          {/* Counselor */}
          <Route path="Treament-CounselorQueueList" element={<CounselorQueueList  />} />
          <Route path="Treament-CounselorProcedure" element={<CounselorProcedure  />} />
          <Route path="Treament-CounselorFullList" element={<CounselorFullList />} />


          {/* Billing */}
          <Route path="Billing-History" element={<BillingHistory  />} />
          <Route path="Deu-History" element={<DeuHistory  />} />
          <Route path="Deu-History-List" element={<DueHistoryList  />} />
          {/* <Route path="Deu-Close" element={<DueHistory />} /> */}
          <Route path="Billing-Invoice" element={<GeneralBillingQueList />} />
          <Route path="Billing-Invoice-All" element={<GeneralBilling  />} />
          <Route path="Bill-Cancellation" element={<BillCancelation  />} />

          <Route path="Pharmacy_Billing_List" element={<PharmacyBillingLIst  />} />
          <Route path="Pharmacy-Billing" element={<PharmacyBilling  />} />
          <Route path="Pharmacy-Walkinbilling" element={<Walkinbilling  />} />

          <Route path="Pharmacy_Billcancelation" element={<PharmacyBillcancelation  />} />
          <Route path="Bill-Report" element={<OverallDayreport />} />



          {/* Petty Cash */}
          <Route path="Expenses-Master" element={<ExpensesMaster  />} />
          <Route path="Cash-Expenses" element={<CashExpenses  />} />
          <Route path="Digital-Expenses" element={<DigitalExpenses />} />
          <Route path="Cash-Expenses-Report" element={<CashExpenseReport  />} />
          <Route path="Hand-Over-Summary" element={<HandOverSummary  />} />
          <Route path="Day-Book-Report" element={<DayBookReport  />} />
          <Route path="Day-Closing" element={<DayClosing  />} />

          {/* HR management */}
          <Route path="Employee-Register" element={<EmployeeRegister />} />
          <Route path="Employee-List" element={<EmployeeList  />} />
          <Route path="Employee-Profile" element={<EmployeeProfile  />} />
          <Route path="Employee-Attendance" element={<EmployeeAttendance  />} />
          <Route path="Employee-Performance" element={<PerformanceAppraisal  />} />
          <Route path="Employee-PerformanceManagement" element={<PerformanceManagement  />} />
          <Route path="Employee-AdvanceApproval" element={<AddvaceApproval  />} />
          <Route path="Employee-LeaveApproval" element={<LeaveApproval  />} />
          <Route path="Employee-LeaveManage" element={<LeaveMangement  />} />
          <Route path="Employee-AdvanceManagement" element={<AddvaceManagement />} />
          <Route path="Employee-AttendanceCalendar" element={<EmployeeCalendar  />} />
          <Route path="Employee-PayRoll" element={<PayRoll  />} />
          <Route path="Employee-PaySlip" element={<PaySlip  />} />
          <Route path="Duty-Management" element={<DutyManagement  />} />




          {/* Employee Request */}
          <Route path="Navigation-leave" element={<LeaveNavigation  />} />
          <Route path="Navigation-Advance" element={<AdvanceNavigation  />} />

          {/* Visiting Doctor */}
          <Route path="Patient-Visiting-Doctor" element={<PatientVisitingDoctor  />} />
          <Route path="Visiting-Doctor" element={<VisitingDoctor  />} />

          {/* User Control */}
          <Route path="Role-Management" element={<RoleManagement />} />
          <Route path="Register-User" element={<UserRegister  />} />
          <Route path="User-List" element={<UserList  />} />
          <Route path="Account Settings" element={<AccountSettings  />} />
          <Route path="Clinic Details"element={<ClinicDetails  />} />
          {/* <Route path="Doctor Consulation Charges" element={<DoctorCharges closeModal={closeModal} openModal={openModal} isModalOpen={isModalOpen} isSidebarOpen={isSidebarOpen} userRecord={userRecord} />} /> */}
          <Route path="EmployeeQue-List" element={ <EmployeeQueList /> } />
          {/* <Route path="Employee-LeaveManagement" element={<LeaveMangement userRecord={userRecord} setSelectedRowData={setSelectedRowData} isSidebarOpen={isSidebarOpen} />} /> */}
         <Route  path="Doctor Consulation Charges"  element={<RateCardMaster  />}/>
         <Route path="Visit-Doctor-Billing-List" element={<VisitingDoctorBillingList />} />
         <Route path="Visit-Doctor-Billing" element={<VisitingDoctorBilling />} />



          {/* Footer */}
          <Route path="Privacy-Policy" element={<PrivacyPolicy />} />
          <Route path="Terms-of-Use" element={<TermsOfUse />} />

          {/* NotFound */}
          <Route path="*" element={<NotFound />} />

        </Route>
        <Route path='/' element={<LoginPage />} />
        {/* NotFound */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App;