import React, { useState, useEffect } from 'react';
import './Leave.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const EmployeeLeave = () => {
  const userRecord=useSelector(state=>state.userRecord?.UserData)
  // const [selectedRows, setSelectedRows] = React.useState([]);


  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    employeeId: '',
    employeeName: '',
    leaveType: '',
    fromDate: '',
    toDate: '',
    designation:'',
    fromtime: '',
    totime: '',
    prDate: '',
    reason: '',
    locations: userRecord.location,
    createdby: userRecord.username,
    photo: null,
  });

  const [errors, setErrors] = useState({});
 

  const handleChange = (e, field) => {
    const { name, value, files } = e.target;

    if (field === 'photo') {
      setFormData((prevState) => ({
        ...prevState,
        [field]: files[0], // Update file in state
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    if (formData.fromDate && formData.toDate) {
      const fromDate = new Date(formData.fromDate);
      const toDate = new Date(formData.toDate);
      const daysDifference = Math.floor((toDate - fromDate) / (1000 * 60 * 60 * 24));

      setFormData(prevState => ({
        ...prevState,
        days: daysDifference.toString()
      }));
    }

    if (formData.fromtime && formData.totime) {
      const fromTime = new Date(`2022-01-01T${formData.fromtime}`);
      const toTime = new Date(`2022-01-01T${formData.totime}`);
      const timeDifference = (toTime - fromTime) / (1000 * 60 * 60);

      setFormData(prevState => ({
        ...prevState,
        timeDifference: timeDifference.toString()
      }));
    }
  }, [formData.fromDate, formData.toDate, formData.fromtime, formData.totime]);


  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};
    if (!formData.employeeId.trim()) {
      validationErrors.employeeId = 'Employee ID is required';
    }
    if (!formData.employeeName.trim()) {
      validationErrors.employeeName = 'Employee Name is required';
    }
    

    setErrors(validationErrors);

    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    const formData1 = new FormData();
    Object.keys(formData).forEach((key) => {
      formData1.append(key, formData[key]);
    });
    console.log(formData1)

    axios.post('http://127.0.0.1:8000/HRmanagement/insert_leave_register', formData1, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        console.log(response);
        alert(response.data.message);
        // navigate('/LeaveStatus')
      })
      .catch((error) => {
        console.error(error);
        alert(error);
      });
  };

  useEffect(() => {
    const employeeId = userRecord.EmployeeId;
  
    // Check if employeeId is available (not empty) before making the API request
    if (employeeId && employeeId.length === 9) {
      axios.get(`http://127.0.0.1:8000/HRmanagement/employeedetails_forleave?employeeid=${employeeId}&location=${userRecord.location}`)
        .then((response) => {
          console.log(response.data);
          if (response.data.error){
            alert(response.data.error)
            setFormData({
              employeeName:  '',
              role:  '',
              employeeId:  '',
               });
          }
          else{
            
            setFormData({
              employeeName: response.data?.employeeName || '',
              designation: response.data?.designation || '',
              employeeId: response.data?.employeeId || '',
              location: userRecord.location,
              createdby: userRecord.First_Name
               });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("EmployeeId must be 9 characters in length");
    }
    
  
  }, [formData.employeeId,userRecord]);





  // console.log("userRecord", leavecount)
  return (
    <div className='leaveform'>
      
      <div className="RegisFormcon leavecon">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="employeeId">Employee ID <span>:</span></label>
          <input type="text" id="employeeId" name="employeeId" placeholder="Enter Employee ID" value={userRecord.EmployeeId} onChange={handleChange} readOnly/>
        </div>
      </div>
      <div className="RegisFormcon leavecon ">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="employeeName">Employee Name <span>:</span></label>
          <input type="text" id="employeeName" name="employeeName" placeholder="Enter Employee Name" onChange={handleChange} value={formData.employeeName} readOnly/>
        </div>
      </div>
      <div className="RegisFormcon leavecon ">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="designation">Designation <span>:</span></label>
          <input type='text' onChange={handleChange} value={formData.designation} readOnly/>
        </div>
      </div>
      <div className="RegisFormcon leavecon">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="leaveType">Leave Type <span>:</span></label>
          <select id="leaveType" name="leaveType" onChange={handleChange} required>
            <option value="select">Select Type</option>
            <option value="casual">Casual Leave</option>
            <option value="sick">Sick Leave</option>
            <option value="permission">Permission</option>
            {/* <option value="annual">Annual Leave</option> */}
          </select>
        </div>
      </div>
      {(formData.leaveType === "casual" || formData.leaveType === "sick") && (
        <>
          <div className="RegisFormcon leavecon">
            <div className='RegisForm_1 leaveform_1'>
              <label htmlFor="fromDate">From Date <span>:</span> </label>
              <input type="date" id="fromDate" name="fromDate" onChange={handleChange} required/>
            </div>
          </div>
          <div className="RegisFormcon leavecon">
            <div className='RegisForm_1 leaveform_1'>
              <label htmlFor="toDate">To Date <span>:</span></label>
              <input type="date" id="toDate" name="toDate" onChange={handleChange} required/>
            </div>
          </div>
          <div className="RegisFormcon leavecon">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="days">Days Count <span>:</span></label>
          <input type='text' name='days' value={formData.days} readOnly />
        </div>
      </div>
        </>
      )}
      {( formData.leaveType === "sick" && formData.days>=3 ) && (
      <>
      <div class="RegisForm_1 leaveform_1">
                <label>
                  Medical Certificate <span>:</span>
                </label>
                <div className="RegisterForm_2">
                  <input
                    type="file"
                    id="photo"
                    name="photo"
                    className="hiden-nochse-file"
                    accept="image/*,.pdf"
                    onChange={(e) => handleChange(e, "photo")}
                    required
                  />
                  <label htmlFor="photo" className="RegisterForm_1_btns">
                    Choose File
                  </label>
                </div>
      </div>
        </>
      )}
      {formData.leaveType === "permission" && (
        <>
          <div className="RegisFormcon leavecon">
            <div className='RegisForm_1 leaveform_1'>
              <label htmlFor="prDate"> Date <span>:</span> </label>
              <input type="date" id="prDate" name="prDate" onChange={handleChange} required />
            </div>
          </div>
          <div className="RegisFormcon leavecon">
            <div className='RegisForm_1 leaveform_1'>
              <label htmlFor="fromtime">From Time <span>:</span></label>
              <input type="time" id="fromtime" name="fromtime" onChange={handleChange} required/>
            </div>
          </div>
          <div className="RegisFormcon leavecon">
            <div className='RegisForm_1 leaveform_1'>
              <label htmlFor="totime">To Time <span>:</span></label>
              <input type="time" id="totime" name="totime" onChange={handleChange} required/>
            </div>
          </div>
          <div className="RegisFormcon leavecon">
          <div className='RegisForm_1 leaveform_1'>
            <label htmlFor="timeDifference">Time Difference (hours) <span>:</span></label>
            <input type='text' name='timeDifference' value={formData.timeDifference} readOnly />
          </div>
        </div>
        </>
      )}
      <br></br>
      <div className="RegisFormcon leavecon">
        <div className='RegisForm_1 leaveform_1'>
          <label htmlFor="reason">Reason for Leave <span>:</span></label>
          <textarea id="reason" name="reason" rows="4" cols="3" onChange={handleChange} required></textarea>
        </div>
      </div>
      <div className="new-button-container">
        <button className="btncon_add prs-ad-sub-btn" onClick={handleSubmit}>Submit</button>
      </div>
          </div>
    
  );
}

export default EmployeeLeave;
