import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import MoneyIcon from "@mui/icons-material/Money";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import MedicalServicesIcon from "@mui/icons-material/MedicalServices";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DvrIcon from "@mui/icons-material/Dvr";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import ArtTrackIcon from "@mui/icons-material/ArtTrack";
import Button from "@mui/material/Button";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import '../../../assets/logo.png'
import logo from "../assets/logo.png";
import Modal from "@mui/material/Modal";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import BiotechIcon from "@mui/icons-material/Biotech";
import { Form } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeader: {
          backgroundColor: "var(--ProjectColor)",
        },
        root: {
          "& .MuiDataGrid-window": {
            overflow: "hidden !important",
          },
        },
        cell: {
          borderTop: "0px !important",
          borderBottom: "1px solid  var(--ProjectColor) !important",
        },
      },
    },
  },
});

function EmployeeProfile() {

  const foremployeedata = useSelector(state=>state.userRecord?.foremployeedata)

  console.log(foremployeedata);

  const userRecord=useSelector(state=>state.userRecord?.UserData)
  const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )



  const [bankdetails, setbankdetails] = useState({});
  const [financedetails, setfinancedetails] = useState({});
  const [epfdetails, setepfdetails] = useState({});
  const [documentsdetails, setdocumentsdetails] = useState({});

  console.log(foremployeedata);
  console.log(userRecord);

  useEffect(() => {
    const forfinance =  foremployeedata?.[2] || [];
    const forbank =  foremployeedata?.[3] || [];
    const forepf =  foremployeedata?.[4] || [];
    const fordocuments =  foremployeedata?.[5] || [];

    setbankdetails([forbank]);
    setfinancedetails([forfinance]);
    setepfdetails([forepf]);
    setdocumentsdetails([fordocuments]);
  }, []); // Empty dependency array ensures the effect runs only once after the initial render

  const employeepersonaldetails =  foremployeedata?.[0] || [];
  const employeerolldata =  foremployeedata?.[1] || [];
  const employeefinance =  foremployeedata?.[2] || [];
  const employeebank =  foremployeedata?.[3] || [];
  const employeeepfdetails =  foremployeedata?.[4] || [];
  const employeedocuments =  foremployeedata?.[5] || [];

  console.log("employeepersonaldetails :", employeepersonaldetails);
  console.log("employeerolldata :", employeerolldata);
  console.log("employeefinance :", employeefinance);
  console.log("employeebank :", employeebank);
  console.log("employeeepfdetails :", employeeepfdetails);
  console.log("employeedocuments :", employeedocuments);

  console.log("finance :", financedetails);
  console.log("bank :", bankdetails);
  console.log("epfdetails :", epfdetails);
  console.log("documents :", documentsdetails);

  // const isSidebarOpen = foremployeedata.isSidebarOpen;

  const [openModal, setOpenModal] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [Rowdata, setRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [show, setShow] = useState("");
  const [column, setColumn] = useState([]);
  const [salaryType, setSalaryType] = useState("fixed");


  const handleVisibilityClick = (View) => {
    if (View.toLowerCase().startsWith("data:application/pdf;base64,")) {
      // If it's a PDF, open a new window with the PDF data
      const newWindow = window.open();
      newWindow.document.write(
        `<iframe src="${View}" style="width: 100%; height: 100vh;"></iframe>`
      );
    } else {
      // If it's an image, open a new window and trigger the browser's print dialog for the image
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
        <html>
          <head>
            <title>Print</title>
            <style>
              body {
                margin: 0;
                padding: 0;
                background-color: transparent;
                overflow: hidden; /* Hide unnecessary whitespace */
              }
              img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
                display: block; /* Ensure images don't have extra spacing */
                margin: 0 auto; /* Center the image horizontally */
              }
            </style>
          </head>
          <body>
            <img src="${View}" />
            <script type="text/javascript">
              window.onload = function() {
                window.print();
                window.onafterprint = function() {
                  window.close();
                };
              };
            </script>
          </body>
        </html>
      `);
    }
  }; 
  
  const handleOpenModle = (summa) => {
    // alert(summa)
    setShow(summa);
    if (summa === "Bank Account Details") {
      setColumn([
        // { field: "id", headerName: "Serial No", width: 180 },
        // { field: "PatientID", headerName: "Patient ID", width: 180 },
        { field: "AccountName", headerName: "Account Holder Name", width: 180 },
        { field: "AccountNumber", headerName: "Account Number", width: 180 },
        { field: "BankName", headerName: "Bank Name", width: 180 },
        { field: "Branch", headerName: "Branch", width: 180 },
        { field: "IFSCCode", headerName: "IFSC Code", width: 180 },
        { field: "PanNumber", headerName: "Pan Number", width: 180 },
      ]);
      setOpenModal(true);
      console.log(bankdetails);
      setRowData(
        bankdetails.map((row, index) => ({
          id: index + 1,
          AccountName: row.AccountName,
          AccountNumber: row.AccountNumber,
          BankName: row.BankName,
          Branch: row.Branch,
          IFSCCode: row.IFSCCode,
          PanNumber: row.PanNumber,
        }))
      );
    } else if (summa === "Financial Details") {
      setOpenModal(true);
      console.log(financedetails);

      const commonColumns = [
        { field: "salarytype", headerName: "Salary Type", width: 275 },
        { field: "payscale", headerName: "Pay Scale", width: 275 },
      ];
      const salaryType = financedetails[0].SalaryType;

      console.log(salaryType);
      let salaryTypeColumns = [];
      if (salaryType === "fixed") {
        salaryTypeColumns = [
          { field: "basicPay", headerName: "Basic Payment", width: 250 },
        ];
      } else if (salaryType === "hourly") {
        salaryTypeColumns = [
          { field: "perHour", headerName: "Per Hour", width: 250 },
        ];
      } else if (salaryType === "commission") {
        salaryTypeColumns = [
          { field: "commission", headerName: "% of Commission", width: 250 },
          { field: "fixedAmount", headerName: "Fixed Amount", width: 250 },
          {
            field: "comissionAmount",
            headerName: "Commission Amount",
            width: 180,
          },
          { field: "remarks", headerName: "Remarks", width: 180 },
        ];
      } else if (salaryType === "allowance") {
        salaryTypeColumns = [
          { field: "travel", headerName: "Travel Allowance", width: 180 },
          { field: "hrallowance", headerName: "HRA", width: 180 },
          { field: "medical", headerName: "Medical Allowance", width: 180 },
        ];
      }

      const columns = [...commonColumns, ...salaryTypeColumns];
      setColumn(columns); // Assuming setColumns is the correct function to set your columns
      setRowData(
        financedetails.map((row, index) => ({
          id: index + 1,
          salarytype: row.SalaryType,
          payscale: row.PayScale,
          basicPay: row.Basic_Salary,
          perHour: row.PeHour,
          commission: row.PercentageOfCommission,
          fixedAmount: row.FixedAmount,
          comissionAmount: row.ComissionAmount,
          travel: row.TravelAllowance,
          hrallowance: row.HouseRentalAmount,
          medical: row.MedicalAllowance,
        }))
      );
    } else if (summa === "EPF Details") {
      setColumn([
        // { field: "id", headerName: "Serial No", width: 180 },
        // { field: "PatientID", headerName: "Patient ID", width: 180 },
        { field: "epfNumber", headerName: "EPF Number", width: 270 },
        { field: "uanNumber", headerName: "UAN Number", width: 265 },
        { field: "esiNumber", headerName: "ESI Number", width: 265 },
      ]);
      setOpenModal(true);
      setRowData(
        epfdetails.map((row, index) => ({
          id: index + 1,
          epfNumber: row.EPFNumber,
          uanNumber: row.UANNumber,
          esiNumber: row.ESINumber,
        }))
      );
    } else if (summa === "Documents Details") {
      const initialRowData = [
        { id: 1, documents: ["Resume"], View: documentsdetails[0]?.Resume || '', CreatedAt: documentsdetails[0]?.created_at || ''},
        { id: 2, documents: ["Offer Letter"], View: documentsdetails[0]?.OfferLetter || '', CreatedAt: documentsdetails[0]?.created_at || ''},
        { id: 3, documents: ["Contract"], View: documentsdetails[0]?.Contract || '', CreatedAt: documentsdetails[0]?.created_at || ''},
        { id: 4, documents: ["Joining Letter"], View: documentsdetails[0]?.JoiningLetter || '', CreatedAt: documentsdetails[0]?.created_at || ''},
        { id: 5, documents: ["Others"], View: documentsdetails[0]?.Others || '', CreatedAt: documentsdetails[0]?.created_at || ''},
        // Add more rows as needed
      ];

      setColumn([
        { field: "id", headerName: "Serial No", width: 200 },
        {
          field: "documents",
          headerName: "Documents",
          width: 200,
        },
        {
          field: "View",
          headerName: "View",
          width: 200,
          renderCell: (params) => (
            <>
              <Button
                className="cell_btn"
                onClick={() => handleVisibilityClick(params.value)}
              >
                <VisibilityIcon />
              </Button>
            </>
          ),
        },
        { field: "CreatedAt", headerName: "Date", width: 200 },
      ]);

      setRowData(initialRowData);

      setOpenModal(true);
    }
  };

  const DisplayData = () => {
    const showdown = Rowdata.length;
    const pageSize = 10;

    const handlePageChange = (params) => {
      setPage(params.page);
    };
    // const handleSelectionModelChange = (selectionModel) => {
    //   setSelectedRows(selectionModel);
    // };
    const totalPages = Math.ceil(Rowdata.length / 10);

    return (
      <>
        <Helmet>
          <title> {show + " List"} </title>
        </Helmet>
        <div className="appointment">
          <div className="h_head">
            <h3> {show + " List"}</h3>
          </div>
          <ThemeProvider theme={theme}>
            <div className="grid_1">
              <DataGrid
                rows={Rowdata.slice(page * pageSize, (page + 1) * pageSize)}
                columns={column}
                pageSize={pageSize} // Set the page size to your desired value
                // onSelectionModelChange={handleSelectionModelChange}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: pageSize, // Set the page size to your desired value
                    },
                  },
                }}
                pageSizeOptions={[pageSize]} // Set the page size options to your desired values
                onPageChange={handlePageChange}
                hideFooterPagination
                hideFooterSelectedRowCount
                className="data_grid"
              />
              {showdown > pageSize && (
                <div className="grid_foot">
                  <button
                    onClick={() =>
                      setPage((prevPage) => Math.max(prevPage - 1, 0))
                    }
                    disabled={page === 0}
                  >
                    Previous
                  </button>
                  Page {page + 1} of {totalPages}
                  <button
                    onClick={() =>
                      setPage((prevPage) =>
                        Math.min(prevPage + 1, totalPages - 1)
                      )
                    }
                    disabled={page === totalPages - 1}
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </ThemeProvider>

          {showdown !== 0 && Rowdata.length !== 0 ? (
            ""
          ) : (
            <div className="IP_norecords">
              <span>No Records Found</span>
            </div>
          )}
        </div>
      </>
    );
  };
  return (
    <div className="patient_profile">
      <div className="p_head">
        <h3>Employee Profile</h3>
      </div>
      <div className="p_data">
        <div className="p_p_detials hid-prof-til">
          <div className="p_profile_img_head">
            {employeepersonaldetails?.EmployeePhoto && (
              <div className="p_profile_img">
                <img src={`${employeepersonaldetails?.EmployeePhoto}`} />
              </div>
            )}

            <div className="p_profile_name">
              <h3>{employeepersonaldetails?.EmployeeName || ""}</h3>
            </div>
          </div>
          <div className="p_profile_body">
            <h3>Contact Details</h3>
            <div className="p_profile_body_icon icn-emp-pro-cs">
              <PhoneAndroidIcon />
              <h3>{employeepersonaldetails?.PhoneNumber || ""}</h3>
            </div>
            <div className="p_profile_body_icon icn-emp-pro-cs">
              <PhoneIcon />
              <h3>{employeepersonaldetails?.AlternatePhoneNumber || ""}</h3>
            </div>
            <div className="p_profile_body_icon icn-emp-pro-cs">
              <EmailIcon />
              <h3> {employeepersonaldetails?.Email || ""}</h3>
            </div>
            <div className="p_profile_body_icon icn-emp-pro-cs">
              <HomeIcon />
              {/* <textarea name="address" readOnly> */}
              <div className="text-hmeicn">
                <h3>{employeepersonaldetails?.CommunicationAddress || ""}</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="p_p_detial_1">
          <div className="p_p_data">
            <div className="p_profile_data overview">
              <h3>Overview </h3>
            </div>
            <div className="p_profile_items_11">
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Gender :</h6>
                  <h3>{employeepersonaldetails?.Gender || ""}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Age :</h6>
                  <h3>{employeepersonaldetails?.Age || ""}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6> Date of Birth :</h6>
                  <h3>{employeepersonaldetails?.DateofBirth || ""}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Qualification :</h6>
                  <h3>{employeepersonaldetails?.Qualification || ""}</h3>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Nationality :</h6>
                  <h3>{employeepersonaldetails?.Nationality || ""}</h3>
                </div>
                <div className="p_profile_data">
                  <h6>Aadhar Number :</h6>
                  <h3>{employeepersonaldetails?.AadhaarNumber || ""}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="p_p_data_1 ince-tble-emp-pro  ">
            <div className="p_profile_items_11">
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Employee ID :</h6>
                  <h4>{employeepersonaldetails?.EmployeeID || ""}</h4>
                </div>
                <div className="p_profile_data">
                  <h6>Department :</h6>
                  <h4>{employeerolldata?.Department || ""}</h4>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6> Designation :</h6>
                  <h4>{employeerolldata?.Designation || ""}</h4>
                </div>
                <div className="p_profile_data">
                  <h6>Date Of Joining :</h6>
                  <h4>{employeerolldata?.DateofJoining || ""}</h4>
                </div>
              </div>
              <div className="p_profile_items">
                <div className="p_profile_data">
                  <h6>Reporting Manager :</h6>
                  <h4>{employeerolldata?.ReportingManager || ""}</h4>
                </div>
                <div className="p_profile_data">
                  <h6>Employee Status :</h6>
                  <h4>{employeerolldata?.EmployeeStatus || ""}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p_data_1 emp-prof-cntraln">
        <div className="p_p_data_detial_1 gap-fr-empl-prof ">
          <div className="p_p_data_items_left flx-wrp-modals">
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Bank Account Details")}
            >
              <div className="p_profile_img cente_1">
                <AccountBalanceIcon />
              </div>
              <h3>Bank Account Details</h3>
              <ChevronRightIcon />
            </button>

            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Financial Details")}
            >
              <div className="p_profile_img cente_1">
                <MoneyIcon />
              </div>
              <h3>Financial Details</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("EPF Details")}
            >
              <div className="p_profile_img cente_1">
                <AssignmentIndIcon />
              </div>
              <h3>EPF Details</h3>
              <ChevronRightIcon />
            </button>
            <button
              className="p_p_data_items flex_1"
              onClick={() => handleOpenModle("Documents Details")}
            >
              <div className="p_profile_img cente_1">
                <AssignmentIcon />
              </div>
              <h3>Documents Details</h3>
              <ChevronRightIcon />
            </button>
          </div>
        </div>
        <div className="p_p_data_detial_2 img-mrgn-tp">
          <div className="p_p_data_detials_2_images">
            {employeepersonaldetails?.EmployeePhoto && (
              <div className="p_p_data_detials_2_img">
                <img src={`${employeepersonaldetails?.EmployeePhoto}`} alt="" />
              </div>
            )}
          </div>
          <div className="p_p_data_detials_2_view">Employee Photo</div>
        </div>
      </div>
      {openModal && (
        <div
          className={
            isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
          }
        >
          <div className="newwProfiles">
            <DisplayData />
            <button
              className="closeicon-cs"
              onClick={() => setOpenModal(false)}
            >
              close
            </button>
          </div>
        </div>
      )}

      {/* {openModal4 && (
                <div
                    className={
                        isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                    }
                >
                    <div className="newwProfiles">
                        <DisplayData />
                        <button
                            className="closeicon-cs"
                            onClick={() => setOpenModal(false)}
                        >
                            close
                        </button>
                    </div>
                </div>
            )}

            <Modal open={openModal3} onClose={handleCloseModal}>
                <div className="pdf_img_show">
                    {selectedImage &&
                        !selectedImage.startsWith("data:application/pdf;base64,") ? (
                        <img
                            src={selectedImage}
                            alt="ConsentForm"
                            style={{ width: "40%", height: "40%", marginTop: "80px" }}
                        />
                    ) : null}
                    <div className="jhuhhjh">
                        <Button
                            onClick={handleCloseModal}
                            style={{ color: "white" }}
                            className="clse_pdf_img"
                        >
                            <HighlightOffIcon
                                style={{
                                    fontSize: "40px",
                                    backgroundColor: "hsl(33, 100%, 50%)",
                                    borderRadius: "40px",
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </Modal>

            <Modal open={openModal4} onClose={handleCloseModal1}>
                <div className="pdf_img_show">
                    {selectedImage5 &&
                        !selectedImage5
                            .toLowerCase()
                            .startsWith("data:application/pdf;base64,") && (
                            <img
                                src={selectedImage5}
                                alt="Lab_report_document"
                                style={{ width: "40%", height: "40%", marginTop: "80px" }}
                            />
                        )}
                    <div className="jhuhhjh">
                        <Button
                            onClick={handleCloseModal1}
                            style={{ color: "white" }}
                            className="clse_pdf_img"
                        >
                            <HighlightOffIcon
                                style={{
                                    fontSize: "40px",
                                    backgroundColor: "hsl(33, 100%, 50%)",
                                    borderRadius: "40px",
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </Modal>

            {openImageView && (
                <div
                    onClick={() => setOpenImageView(false)}
                    className={
                        isSidebarOpen ? "sideopen_showcamera_profile" : "showcamera_profile"
                    }
                >
                    <div className="newwProfiles" onClick={(e) => e.stopPropagation()}>
                        <div className="images_show">
                            <div className="images_show_1">{Folders}</div>
                            <div className="images_show_2">
                                {displayedImages && displayedImages.length > 0 && (
                                    <div className="p_p_data_detials_2_images viw-all-allpic">
                                        {displayedImages}
                                    </div>
                                )}
                                <div style={{ display: "grid", placeItems: "center" }}>
                                    <button
                                        className="closeicon-cs"
                                        onClick={() => setOpenImageView(false)}
                                    >
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )} */}
    </div>
  );
}

export default EmployeeProfile;
